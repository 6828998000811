import theme from './theme'
import {mergeDeepLeft} from 'ramda'

const darkTheme2 = {
  palette: {
    'font-primary': theme.palette.neutral[300],
    'surface-primary': theme.palette.neutral[900],
    'surface-secondary': theme.palette.neutral[800],
    'surface-tertiary': theme.palette.neutral[700],
    miniatures: {
      text: theme.palette.neutral[0],
    },
    select: {
      placeHolder: {
        default: theme.palette.neutral[600],
        invalid: theme.palette.red[800],
      },
    },
    button: {
      disabled: {
        background: theme.palette.neutral[700],
        text: theme.palette.neutral[500],
      },
      contained: {
        primary: {
          text: theme.palette.neutral[0],
          background: {
            default: theme.palette.blue[500],
            hover: theme.palette.blue[600],
            active: theme.palette.blue[700],
          },
        },
        success: {
          text: theme.palette.neutral[0],
          background: {
            default: theme.palette.green[500],
            hover: theme.palette.green[600],
            active: theme.palette.green[700],
          },
        },
        error: {
          text: theme.palette.neutral[0],
          background: {
            default: theme.palette.red[500],
            hover: theme.palette.red[600],
            active: theme.palette.red[700],
          },
        },
      },
      outlined: {
        primary: {
          text: theme.palette.neutral[0],
          border: {
            default: theme.palette.neutral[600],
          },
          background: {
            default: theme.palette.transparent,
            hover: theme.palette.neutral[700],
            active: theme.palette.neutral[600],
          },
        },
      },
      text: {
        primary: {
          text: theme.palette.neutral[400],
          border: {
            default: theme.palette.neutral[500],
          },
          background: {
            default: theme.palette.transparent,
            hover: theme.palette.neutral[800],
            active: theme.palette.neutral[700],
          },
        },
      },
      navigation: {
        primary: {
          text: {
            default: theme.palette.neutral[300],
            active: theme.palette.neutral[0],
          },
          border: {
            default: theme.palette.neutral[300],
          },
          background: {
            inactive: {
              default: theme.palette.transparent,
              hover: theme.palette.neutral[700],
              active: theme.palette.neutral[600],
            },
            active: {
              default: theme.palette.blue[500],
              hover: theme.palette.blue[600],
              active: theme.palette.blue[700],
            },
          },
        },
      },
    },
    text: {
      primary: theme.palette.neutral[900],
      secondary: theme.palette.neutral[0],
      tertiary: theme.palette.neutral[300],
    },
    focus: {
      default: theme.palette.blue[800],
      invalid: theme.palette.red[800],
      disabled: theme.palette.neutral[800],
    },
    input: {
      background: theme.palette.transparent,
      border: {
        default: theme.palette.neutral[600],
        invalid: theme.palette.red[600],
        focus: theme.palette.blue[600],
      },
      placeHolder: {
        default: theme.palette.neutral[600],
        invalid: theme.palette.red[800],
      },
      text: {
        default: theme.palette.neutral[600],
        invalid: theme.palette.red[500],
        disabled: theme.palette.neutral[800],
      },
      component: {
        default: theme.palette.neutral[600],
        invalid: theme.palette.red[500],
      },
    },
    checkBox: {
      unchecked: {
        text: theme.palette.neutral[500],
        background: {
          default: theme.palette.transparent,
          hover: theme.palette.neutral[800],
          active: theme.palette.neutral[700],
          disabled: theme.palette.neutral[700],
        },
        border: {
          default: theme.palette.neutral[600],
          hover: theme.palette.neutral[500],
          active: theme.palette.neutral[400],
          disabled: theme.palette.neutral[400],
        },
      },
      checked: {
        text: theme.palette.neutral[0],
        background: {
          default: theme.palette.blue[500],
          hover: theme.palette.blue[600],
          active: theme.palette.blue[700],
          disabled: theme.palette.neutral[500],
        },
        border: {
          default: theme.palette.blue[500],
          hover: theme.palette.blue[600],
          active: theme.palette.blue[700],
          disabled: theme.palette.neutral[500],
        },
      },
    },
    tab: {
      inactive: {
        text: theme.palette.neutral[300],
        background: {
          default: theme.palette.transparent,
          hover: theme.palette.neutral[700],
          active: theme.palette.neutral[600],
        },
      },
      active: {
        text: theme.palette.neutral[0],
        background: {
          default: theme.palette.neutral[700],
          hover: theme.palette.neutral[700],
          active: theme.palette.neutral[700],
        },
      },
    },
    tabs: {
      underline: {
        color: theme.palette.neutral[500],
      },
    },
    'surface-tooltip': theme.palette.neutral[800],
    'border-tooltip': theme.palette.neutral[700],
    'surface-input': theme.palette.neutral[900],
    'surface-input-disabled': theme.palette.neutral[800],

    'border-color-input': theme.palette.neutral[600],

    line: theme.palette.neutral[700],
    'skeleton-primary': theme.palette.neutral[800],
    'skeleton-secondary': theme.palette.neutral[700],
  },
}

const darkTheme = mergeDeepLeft(theme, darkTheme2)
export default darkTheme
