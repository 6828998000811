import React, {memo} from 'react'
import {Handle, Position} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import './index.css'
import {map} from 'ramda'
import {isNotEmpty} from '../../../helpers'
import {Root} from './styles'
import DatasetColumn from './DatasetColumn'
import RButton from '../../../components/RButton'
import {LetterMiniature} from '../../../components/miniatures'

const CustomNode = (props) => {

  const {
    data,
    isConnectable,
    inEdges,
    outEdges,
    selected
  } = props;

  const fields = data?.fields || []
  return (
    <Root  className={'nodrag'} $selected={selected}>
      <RButton type={'database'} name={data?.name}
               IconComponent={LetterMiniature}
               iconComponentProps={{icon: 'database'}}
      />

      <div style={{padding: 8}}>
        {map(
          (data) => (
            <DatasetColumn {...data} />
          ),
          fields
        )}
      </div>
      {isNotEmpty(data?.inEdges) && (
        <Handle
          type="target"
          position={Position.Left}
          style={{
            background: '#555', border: 'none'
          }}
          isConnectable={false}
        />
      )}
      {isNotEmpty(data?.outEdges) && (
        <Handle
          type="source"
          position={Position.Right}
          id="b"
          style={{
            background: '#555', border: 'none'
          }}
          isConnectable={false}
        />
      )}
    </Root>
  )
}

export default memo(CustomNode)
