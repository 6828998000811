import {
  flatten,
  fromPairs,
  groupBy,
  intersperse,
  length,
  map,
  path,
  pipe,
  prop,
  propOr,
  replace,
  sortBy,
  sum,
  toLower,
} from 'ramda'
import React, {memo, useCallback, useMemo} from 'react'
import styled, {css} from 'styled-components'
import Line from '../line'
import {Tabs} from 'frontcore'
import {
  convertObjectToArray,
  isNotEmpty,
} from '../../helpers'
import {CountMiniature} from '../miniatures'
import {useSelector} from 'react-redux'
import {checkSearch} from '../../views/SettingsView'
import Highlight from '../highlight'
import {useLocation, useNavigate} from 'react-router-dom'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px 0 0 0;
`

const TitleContainer = styled.div`
  padding-bottom: 32px;
  padding-left: 16px;
`

const TabsContainer = styled.div`
  padding-left: 16px;
    height: 48px
`

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0 8px 0 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 16px;
  gap: 16px;
`

const WhiteSpace = styled.div`
  flex: 0.2;
`

const titleCss = css`
  color: ${({theme}) => theme.palette.blue[500]};
  font-family: Roboto, serif;
  font-weight: 500;
  font-size: 18px;
`

const Title = styled.div`
  ${titleCss};
`

const Row = styled.div`
  display: flex;
  gap: 80px;
  padding: 24px 0;
`

const FirstColumn = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;
`

const NameContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const DescriptionContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const SecondColumn = styled.div`
  flex: 1;
`

const TabRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({$invalid, theme}) =>
    $invalid && theme.palette.red[500]};
`

const Relevance = styled.div`
  & > * {
    background-color: blue;
  }
`

const TabComponent = ({
  name,
  value,
  active,
  search,
  relevance,
}) => {
  const errors = pipe(
    map(({componentProps}) => componentProps.errors),
    flatten
  )(value)

  return (
    <TabRoot $invalid={isNotEmpty(errors)}>
      <Highlight text={name} search={search} />
      <CountMiniature
        value={length(value)}
        active={active}
      />
      {relevance < 0 && (
        <Relevance>
          <CountMiniature
            value={Math.abs(relevance)}
            active={active}
          />
        </Relevance>
      )}
    </TabRoot>
  )
}

const useQueryParams = () => {
  const {search} = window.location
  return new URLSearchParams(search)
}

const SettingsFormLayout = ({title, buttons, fields}) => {
  const settingsSearch = useSelector(
    path(['settings', 'search'])
  )

  const sortingProperty = useCallback(
    (propName) => (element) => {
      if (!settingsSearch) return 0
      const propValue = prop(propName, element)
      return checkSearch(propValue, settingsSearch) ? -1 : 0
    },
    [settingsSearch]
  )

  const tabs = useMemo(
    () =>
      pipe(
        groupBy(prop('section')),
        convertObjectToArray
      )(fields),
    [fields]
  )

  const tabsRelevance = useMemo(() => {
    return pipe(
      map(({key, value}) => {
        const relevance = pipe(
          map(sortingProperty('name')),
          sum
        )(value)
        return [key, relevance]
      }),
      fromPairs
    )(tabs)
  }, [tabs, sortingProperty])

  const sortedTabs = useMemo(() => {
    return sortBy((tab) => {
      const relevance = tabsRelevance[tab.key]
      return sortingProperty('key')(tab) + relevance
    }, tabs)
  }, [tabs, tabsRelevance, sortingProperty])

  const {pathname} = useLocation()
  const tab = replace('/settings/', '', pathname)

  const tabMap = {
    theme: 'Theme',
    tooltip: 'Tooltip',
    license: 'License',
    metrics: 'Metrics',
    ai: 'AI',
  }

  const content = useMemo(
    () =>
      pipe(
        groupBy(prop('section')),
        propOr([], tabMap[tab]),
        sortBy(sortingProperty('name')),
        map(({id, element, name, description}) => (
          <Row key={id}>
            <FirstColumn>
              <NameContainer>
                <Highlight
                  text={name}
                  search={settingsSearch}
                />
              </NameContainer>
              <DescriptionContainer>
                {description}
              </DescriptionContainer>
            </FirstColumn>
            <SecondColumn>{element}</SecondColumn>
            <WhiteSpace />
          </Row>
        )),
        intersperse(<Line />)
      )(fields),
    [fields, tab, tabMap]
  )

  const navigate = useNavigate()

  return (
    <Root>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <TabsContainer>
        <Tabs
          value={tab}
          onChange={(value) => {
            navigate('/settings/' + value)
          }}
          options={map(
            ({key, value}) => ({
              id: toLower(key),
              children: key,
              componentProps: {
                name: key,
                value,
                relevance: tabsRelevance[key],
                search: settingsSearch,
              },
              Component: TabComponent,
            }),
            sortedTabs
          )}
        />
      </TabsContainer>
      <Line />
      <ContentContainer>{content}</ContentContainer>
      <Line />
      <ButtonsContainer>{buttons}</ButtonsContainer>
    </Root>
  )
}
export default memo(SettingsFormLayout)
