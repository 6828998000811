import DetailsViewLayout from '../../../components/layouts/DetailsViewLayout'
import {BreadCrumbs, Button, Icon} from 'frontcore'
import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import RButton from '../../../components/RButton'
import {LetterMiniature} from '../../../components/miniatures'
import {cond, startsWith, T} from 'ramda'

const BreadCrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 24px;
  height: 64px;
`

const SecurityDetailsView = ({}) => {

  const {pathname} = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const typeMap = {
    cluster: 'cluster',
    group: 'service',
    job: 'job',
    request: 'request',
    schedule: 'schedule',
  }

  const BreadCrumbButton = (props) => {
    const {onClick, data} = props

    const {label, id, type, active} = data

    const handleOnClick = useCallback(() => {
      onClick(data)
    })

    return (
      <RButton
        IconComponent={LetterMiniature}
        iconComponentProps={{type}}
        name={label}
        type={typeMap[type]}
        onClick={handleOnClick}
        onClist={handleOnClick}
      />
    )
  }

  const handleOnClickBreadCrumbs = useCallback((p) => {
    const {url, type, id, name} = p

    if (url) {
      navigate('/workloads/list/clusters')
    } else {
      const end = type === 'cluster' ? '/' + name : ''
      const result =
        '/security/details/' + type + '/' + id + end
      navigate(result)
    }
  }, [])

  const returnUrl = useMemo(
    () =>
      cond([
        [
          startsWith('/security/details/user'),
          () => '/security/list/users',
        ],
        [
          startsWith('/security/details/group'),
          () => '/security/list/groups',
        ],
        [
          startsWith('/security/details/role'),
          () => '/security/list/roles',
        ],
        [startsWith('/security/create/user'), () => -1],
        [startsWith('/security/create/group'), () => -1],
        [startsWith('/security/create/role'), () => -1],
        [T, () => -1],
      ])(pathname),
    [pathname]
  )

  const handleOnClick = useCallback(
    () => navigate(returnUrl),
    [returnUrl]
  )

  return (
    <DetailsViewLayout
      topNode={
        <BreadCrumbsContainer>
          <Button
            size={'small'}
            variant={'text'}
            StartComponent={Icon}
            startComponentProps={{
              icon: 'arrow-left',
              size: 18,
            }}
            tabIndex={-1}
            justifyContent={'center'}
            onClick={handleOnClick}
          >
            Back to List
          </Button>
          <BreadCrumbs
            onClick={handleOnClickBreadCrumbs}
            data={[]}
            ItemComponent={BreadCrumbButton}
            SeparatorComponent={() => <div>/</div>}
          />
        </BreadCrumbsContainer>
      }
    />
  )
}

export default SecurityDetailsView
