import styled, {css} from 'styled-components';

const horizontalCss = css`
  border-top-style: solid;
  border-top-color: ${({theme}) => theme.palette.line};
  border-top-width: 1px;
  width: 100%;
`

const verticalCss = css`
  border-left-style: solid;
  border-left-color: ${({theme}) => theme.palette.line};
  border-left-width: 1px;
  height: 100%;
`

const orientationMap = {
  horizontal: horizontalCss,
  vertical: verticalCss,
}

const Root = styled.div`
  ${({$orientation}) => orientationMap[$orientation]}
`

const Line = ({orientation = 'horizontal'}) => <Root $orientation={orientation}/>;

export default Line;