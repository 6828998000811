import ReactJson from 'react-json-view'
import React from 'react'
import {useSelector} from 'react-redux'
import {path} from 'ramda'
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 8px;
  box-sizing: border-box;
`

const Json = ({src}) => {
  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))

  return (
    <Root>
      <ReactJson theme={darkMode ? 'chalk' : 'rjv-default'} src={src} />
    </Root>
  )
}

export default Json
