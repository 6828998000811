import {Tabs} from 'frontcore'
import {path} from 'ramda'
import {memo, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Modes, resultsSetMode} from '../../../reducers/sql/results'

const ModeTabs = () => {
  const mode = useSelector(path(['sqlViewer', 'results', 'mode']))

  const dispatch = useDispatch()
  const handleSetMode = useCallback((mode) => {
    dispatch(resultsSetMode(mode))
  }, [dispatch])

  return (
    <div style={{ height: 42 }}>
      <Tabs
        value={mode}
        options={Object.values(Modes).map(elem => Object.freeze({
          id: elem,
          children: elem,
        }))}
        onChange={handleSetMode}
      />
    </div>
  )
}

export default memo(ModeTabs)

