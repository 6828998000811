import { Input, Icon, Select } from 'frontcore'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import FilterComplexItemComponent from '../../../../components/filters/filterComplexItemComponent'
import FilterComplexComponent from '../../../../components/filters/filterComplexComponent'

const schema = Object.freeze([
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Schedules Search',
      topLabelProps: {
        children: 'Schedules Search',
      },
    },
  },
  {
    id: 'scheduleStates',
    Component: Select,
    defaultValue: [],
    props: {
      label: 'Schedule States',
      size: 'small',
      value: [],
      options: ['ENABLED', 'DISABLED'],
      multiSelect: true,
    },
  },
  {
    id: 'clusterNames',
    Component: Select,
    defaultValue: [],
    valueProp: 'value',
    optionsApi: getClustersNamesFetch,
    props: {
      label: 'Clusters',
      value: [],
      valueProp: 'name',
      multiSelect: true,
      ItemComponent: FilterComplexItemComponent,
      buttonProps: {
        width: '200px',
        Component: FilterComplexComponent,
      },
    },
  },
])

export default schema
