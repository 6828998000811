import {useNavigate, useParams} from 'react-router-dom'
import FormLayout2 from '../../../../components/tabsFormLayout'
import React, {useCallback, useEffect, useMemo} from 'react'
import Form from '../../../../components/Form'
import schema from './schema'
import {fork} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {createGroup} from '../../../../api/security/createGroup'
import useFetch from '../../../../hooks/useFetch'
import getPermissionsFetch from '../../../../api/security/getPermissionsFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import {
  __,
  assoc,
  evolve,
  filter,
  find,
  map,
  or,
  path,
  pipe,
  prop,
} from 'ramda'
import getRolesFetch from '../../../../api/security/getRolesFetch'
import {EMPTY_ARRAY} from '../../../../constants'
import {useDispatch, useSelector} from 'react-redux'
import getUser from '../../../../api/security/getUser'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import getGroup from '../../../../api/security/getGroup'
import {editGroup} from '../../../../api/security/editGroup'
import SecurityFormLayout from "../../../../components/SecurityFormLayout";

const SecurityEditGroupView = () => {
  const navigate = useNavigate()

  const editGroupFuture = useFuture(editGroup)

  const {createNotification} = useNotificationContext()

  const {data: permissions} = useFetch(
    getPermissionsFetch,
    singleCallFetchOptions
  )

  const {data: roles} = useFetch(
    getRolesFetch,
    singleCallFetchOptions
  )

  const getUserFuture = useFuture(getGroup)

  const {groupId} = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (groupId) {
      fork(({message}) =>
        createNotification({
          title: 'Failed to fetch Group',
          message,
          autoHide: false,
          variant: 'error',
        })
      )(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getUserFuture({
          params: [{key: 'id', value: groupId}],
        })
      )
    }
  }, [groupId])

  const handleOnSubmit = useCallback(
    (fields) => {
      const result = pipe(
        evolve({
          permissions: pipe(or(__, []), map(prop('name'))),
          roles: pipe(or(__, []), map(prop('id'))),
          state: (value) =>
            value ? 'ENABLED' : 'DISABLED',
        })
      )(fields)
      result.params = [{key: 'id', value: groupId}]
      fork(({message}) => {
        createNotification({
          title: 'Failed to edit Group',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        navigate(-1)
        createNotification({
          title: 'Group created successfully',
          autoHide: true,
          variant: 'success',
        })
      })(editGroupFuture(result))
    },
    [groupId]
  )

  const data = useSelector(
    path(['security', 'data', 'group', 'response'])
  )

  const extendedData = useMemo(
    () =>
      pipe(
        evolve({
          permissions: filter(({sources}) => {
            return find(
              ({type}) => type === 'DIRECT',
              sources
            )
          }),
        }),
        evolve({
          roles: map((id) => {
            const array = roles?.content || EMPTY_ARRAY
            return find((a) => a.id === id, array)
          }),
          permissions: map((permission) => {
            const array = permissions || EMPTY_ARRAY
            const result = find(
              ({name}) => name === permission.name,
              array
            )
            return {...result, ...permission}
          }),
          state: (value) => value === 'ENABLED',
        })
      )(data),
    [data, permissions]
  )

  return (
    <Form
      autocomplete={'off'}
      initialData={extendedData}
      options={{
        pending: false,
        navigate,
        permissions,
        roles: roles?.content || EMPTY_ARRAY,
      }}
      LayoutComponent={SecurityFormLayout}
      layoutComponentProps={{
        title: 'NEW GROUP',
      }}
      schema={schema}
      onSubmit={handleOnSubmit}
    />
  )
}

export default SecurityEditGroupView
