import { curry } from "ramda"

/**
 * @deprecated moved into frontcore
 */
const withDebounce = curry((fn, time) => {
  let timeoutHandle = null
  return (...args) => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle)
    }
    timeoutHandle = setTimeout(() => {
      fn(...args)
      timeoutHandle = null
    }, time)
  }
})

export default withDebounce
