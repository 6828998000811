import Configuration from '../../../../components/configuration'
import styled from 'styled-components'
import {isEmpty} from 'ramda'
import NoResultFound from '../../../../components/NoResultFound'

export const Root = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
`
const JobParameters = ({data}) => {
  return (
    <Root>
      {isEmpty(data) ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NoResultFound
            title={'No Parameters Found'}
            text={'There is no parameters for this job'}
          />
        </div>
      ) : (
        <Configuration data={data} schema={[
          {
            id: 'key',
            label: 'Key',
            copy: true,
          },
          {
            id: 'value',
            label: 'Value',
            copy: true,
            obfuscate: true,
          },
        ]}/>
      )}
    </Root>
  )
}

export default JobParameters
