import {append, filter, find, flatten, includes, map, pipe, propOr, uniq} from 'ramda'
import {Button, MultiSelectTemplate, Select} from 'frontcore'
import Flex from '../Flex'
import PermissionsSelect from '../PermissionsSelect'
import Line from '../line'
import {EMPTY_ARRAY} from '../../constants'
import ToolTip from '../toolTip'
import {useCallback} from 'react'
import {DescriptionText} from '../typography'

const ButtonComponentMultiSelect = ({value, disabled}) => {
  const extendedValue = pipe(map(({name}) => name))(value)
  return <MultiSelectTemplate value={extendedValue} disabled={disabled} />
}

const ItemComponent = ({value, onClick, active, permissions = EMPTY_ARRAY}) => {

  const aaa = filter(({name}) => includes(name, value?.permissions), permissions)

  return (
    <ToolTip
      lockable={true}
      content={
        <div style={{padding: 8, maxWidth: 600}}>
          <PermissionsSelect options={permissions} value={aaa} />
        </div>
      }
    >
      <Button
        onClick={onClick}
        variant={'text'}
        rounding={false}
        fullWidth
        tabIndex={-1}
        active={active}
        Component={() => (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>{value?.name}</div>
          </div>
        )}
      />
    </ToolTip>
  )
}

const RolesSelect = ({
  value = EMPTY_ARRAY,
  disabled,
  options = EMPTY_ARRAY,
  permissions = EMPTY_ARRAY,
  onChange,
}) => {
  const extendedValue = pipe(
    map(propOr(EMPTY_ARRAY, 'permissions')),
    flatten,
    uniq,
    map((name) => find((permission) => permission.name === name, permissions))
  )(value)

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        disabled={disabled}
        ItemComponent={ItemComponent}
        itemComponentProps={{permissions}}
        buttonProps={{
          fullWidth: true,
          Component: ButtonComponentMultiSelect,
        }}
        multiSelect={true}
      />
      <DescriptionText>
        Below is a summary table displaying the aggregated permissions inherited from the roles you
        have selected.
      </DescriptionText>
      <div>
        <PermissionsSelect value={extendedValue} options={permissions} />
      </div>
    </Flex>
  )
}

export default RolesSelect
