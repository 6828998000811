import React, {useCallback, useMemo} from 'react'
import {fork} from 'fluture'
import {evolve, filter, isNil, isNotNil, map, pipe} from 'ramda'
import clusterFormSubmitFn from '../../helpers/clusterFormSubmitFn'
import {useNavigate} from 'react-router-dom'
import schema from './schema'
import useFuture from '../../../../hooks/useFuture'
import createCluster from '../../../../api/workloads/clusters/createCluster'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import usePending from '../../../../hooks/usePending'
import Form from '../../../../components/Form'
import SecurityFormLayout from "../../../../components/SecurityFormLayout";
import useFetch from '../../../../hooks/useFetch'
import getClusterFetch from '../../../../api/workloads/clusters/getClusterFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import getClusterDefaultMemorySettingsFetch
  from "../../../../api/workloads/clusters/getClusterDefaultMemorySettingsFetch";

const WorkloadsCreateClusterView = () => {
  const createClusterFuture = useFuture(createCluster)
  const {createNotification} = useNotificationContext()

  const navigate = useNavigate()

  const pending = usePending(['workloads', 'data'], ['createCluster'])

  const {data: clusterDefaultMemorySettings} = useFetch(getClusterDefaultMemorySettingsFetch, singleCallFetchOptions)

  const handleOnSubmit = useCallback((fields) => {
    const params = {...fields}
    const result = pipe(clusterFormSubmitFn)(params)

    pipe(
      fork(({message}) => {
        createNotification({
          title: 'Failed to create Cluster',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        navigate(-1)
        createNotification({
          title: 'Cluster created successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(createClusterFuture(result))
  }, [])

  const clusterId = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return params.get('template')
  }, [])

  const {data: clusterData, isLoading: clusterDataPending} = useFetch(
      clusterId ? getClusterFetch : {api: null},
    singleCallFetchOptions,
    {
      params: [
        {
          key: 'clusterId',
          value: clusterId,
        },
      ],
    }
  )

  const initialData = useMemo(() => {
    if (isNil(clusterData)) {
      return {type: 'local', ...clusterDefaultMemorySettings}
    }

    const {name, defaultApplicationConfig, storages, sparkStorage, ...rest} = clusterData

    const filtered = pipe(
      Object.entries,
      filter(([key, value]) => isNotNil(value)),
      Object.fromEntries
    )(rest)

    const mappedStorages = pipe(
      Object.entries,
      map(([key, value]) => ({
        ...value,
        name: key,
      })),
      map(
        evolve({
          extraBuckets: (data) => map((value) => ({value}), data || []),
        })
      )
    )(storages)

    const mappedConfig = pipe(
      Object.entries,
      map(([key, value]) => ({key, value}))
    )(defaultApplicationConfig)

    const mappedSparkStorage = mappedStorages.find(({name}) => {
      return name === sparkStorage
    })

    return {
      ...filtered,
      name: name + '_cloned',
      storages: mappedStorages,
      sparkStorage: mappedSparkStorage,
      defaultApplicationConfig: mappedConfig,
    }
  }, [clusterData, clusterDefaultMemorySettings])


  return (
    <Form
      autocomplete={'off'}
      options={{
        pending: pending || clusterDataPending,
        navigate,
      }}
      initialData={initialData}
      LayoutComponent={SecurityFormLayout}
      layoutComponentProps={{
        title: 'NEW CLUSTER',
      }}
      schema={schema}
      onSubmit={handleOnSubmit}
    />
  )
}

export default WorkloadsCreateClusterView
