import {useCallback, useState} from 'react'
import {Button, TextArea} from 'frontcore'

import {createOllama} from 'ollama-ai-provider'
import {streamObject, streamText} from 'ai'
import {EMPTY_STRING} from '../../constants'
import z from 'zod'
import {append, path} from 'ramda'
import {useSelector} from 'react-redux'
import Line from '../../components/line'

const AITestView = () => {
  const [question, setQuestion] = useState('')

  const [answer, setAnswer] = useState('')

  const schema = z.object({
    think: z
      .string()
      .describe('content of your thinking process'),
    html: z.string().describe('content of response in html format.provide link(can be fake) to shop with ingridients'),
  })

  const aaa = useSelector(
    path(['settings', 'settings', 'darkMode'])
  )

  const handleOnClick2 = useCallback(async () => {
    setAnswer(EMPTY_STRING)
    const ollama = createOllama({
      baseURL: 'http://localhost:11434/api',
    })

    const result = await streamObject({
      model: ollama('deepseek-r1:7b'),
      prompt: question,
      system:

        'divide you response to thinking proces and response.',
      schema,
    })

    for await (const object of result.partialObjectStream) {
      setAnswer(object)
    }
  }, [question, setAnswer])

  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(answer, 'text/html');

  return (
    <div style={{width: '100%'}}>
      <Button onClick={handleOnClick2}>Run</Button>
      <TextArea

        fullWidth={true}
        value={question}
        onChange={setQuestion}
      />
      <div
        style={{display: 'flex', flex: 1, height: '100%'}}
      >
        <div style={{flex: 1}}>  {answer?.think}</div>
        <Line orientation={'vertical'} />
        <div
            style={{display: 'flex', flex: 1, height: '100%'}}
        >
          <div className="Container" dangerouslySetInnerHTML={{__html: answer.html}}></div>

        </div>
      </div>

    </div>
  )
}

export default AITestView
