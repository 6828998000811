import Line from '../line'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  pointer-events: all;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 16px;
`

const MiddleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    padding: 8px 16px 0 24px;
    height: 48px;
`

const BottomContainer = styled.div`
    flex: 1;
    overflow: hidden;
    height: 100%;

`

const DetailsLayout = ({topContent, middleContent, bottomContent}) => (
  <Root>
    <TopContainer>{topContent}</TopContainer>

    <MiddleContainer>{middleContent}</MiddleContainer>
    <Line />
    <BottomContainer>{bottomContent}</BottomContainer>
  </Root>
)
export default DetailsLayout
