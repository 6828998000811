import {map} from 'ramda'
import React, {useMemo} from 'react'
import styled from 'styled-components'
import Line from '../../../../../components/line'
import {useParams} from 'react-router-dom'
import URLText from './URLText'

const LOCALHOST_URL = 'http://localhost:9888'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const FormLayout = ({fields, buttons}) => {
  const {serviceId} = useParams()

  const text = useMemo(() => {
    return `${LOCALHOST_URL}/api/v1/group/${serviceId}/job/execute`
  }, [serviceId])

  return (
    <Root>
      <div
        style={{
          display: 'flex',
          padding: '16px 24px',
          gap: 24,
        }}
      >
        <div>{buttons}</div>
        <URLText copy={true} text={text} />
      </div>
      <Line />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 32,
          height: '100%',
        }}
      >
        {map(({element}) => element, fields)}
      </div>
    </Root>
  )
}

export default FormLayout
