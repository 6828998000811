import {pathOr} from 'ramda'
import {useSelector} from 'react-redux'
import Configuration from '../../../../../components/configuration'
import NoResultFound from '../../../../../components/NoResultFound'
import styled from 'styled-components'

export const Root = styled.div`
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
`

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ScheduleDetailsArguments = () => {
  const args = useSelector(
    pathOr(
      [],
      ['workloads', 'data', 'schedule', 'response', 'args']
    )
  )
  return (
    <Root>
      {args.length > 0 ? (
        <Configuration
          data={args}
          schema={[
            {
              id: 'key',
              label: 'Key',
              copy: true,
            },
            {
              id: 'value',
              label: 'Value',
              copy: true,
              obfuscate: true,
            },
          ]}
        />
      ) : (
        <Centered>
          <NoResultFound
            title="No Arguments Found"
            text="There are no arguments for this schedule"
          />
        </Centered>
      )}
    </Root>
  )
}

export default ScheduleDetailsArguments
