import {map} from 'ramda';
import {MultiSelectTemplate} from 'frontcore';
import React from 'react';
import {EMPTY_ARRAY} from '../../constants';

const FilterComplexComponent = ({value = EMPTY_ARRAY}) => {
  const extendedValue = map(({name}) => name, value);
  return <MultiSelectTemplate value={extendedValue} />;
}

export default FilterComplexComponent;