import asyncThunk from "./index";

export const getLicenseKey = asyncThunk({
  method: "GET",
  type: "license/key",
  path: "/license/key",
  api: true,
});

export default getLicenseKey;
