import {isNil} from "ramda"
import React, {memo, useCallback, useEffect, useState} from "react"

const DEFAULT_HANDLE_SIZE = 16

const ResizableWindow = ({
  children,
  style,
  initialHeight,
  minHeight = initialHeight,
  handleSize = DEFAULT_HANDLE_SIZE,
}) => {
  const [height, setHeight] = useState(initialHeight)
  const [start, setStart] = useState(null)

  useEffect(() => {
    const handler = () => {
      setStart(null)
    }
    window.addEventListener('mouseup', handler)
    return () => {
      window.removeEventListener('mouseup', handler)
    }
  }, [])

  const handleMove = useCallback((event) => {
    if (isNil(start)) return
    event.stopPropagation()
    event.preventDefault()
    const { clientY } = event
    const delta = clientY - start.touch
    const nextHeight = Math.max(minHeight, start.height + delta)
    setHeight(nextHeight)
  }, [start, minHeight])

  useEffect(() => {
    if (isNil(start)) {
      return
    }
    window.addEventListener('mousemove', handleMove)
    return () => {
      window.removeEventListener('mousemove', handleMove)
    }
  }, [start, handleMove])

  const handleStart = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    const { clientY } = event
    setStart({
      height,
      touch: clientY,
    })
  }, [height])
  
  return (
    <div 
      style={{ 
        ...style, 
        position: 'relative',
        height,
        minHeight,
      }}
    >
      {children}
      <div 
        onMouseDown={handleStart}
        style={{ 
          position: 'absolute',
          left: 0, right: 0,
          bottom: 0,
          height: handleSize,
          cursor: 'ns-resize',
          transform: 'translateY(50%)',
        }}
      />
    </div>
  )
}

export default memo(ResizableWindow)
