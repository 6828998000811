import {memo, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import CodeMirror from '@uiw/react-codemirror'
import NotebookActions from './NotebookActions'
import SecondaryActions from './SecondaryActions'
import {useSelector} from 'react-redux'
import {path} from 'ramda'
import {markdown} from '@codemirror/lang-markdown'
// import {Markdown} from 'frontcore'
import Markdown from './MarkdownRenderer'
import {Button} from 'frontcore'
import {vim} from '@replit/codemirror-vim'

const Root = styled.div`
  background-color: ${({theme}) => theme.palette['surface-primary']};
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette['border-tooltip']};
  position: relative;
  border-left: 6px solid
    ${({theme, $chosen}) =>
      $chosen
        ? theme.palette.primary[500]
        : theme.palette.neutral[500]};
`

const MarkdownStyle = styled.div`
  cursor: pointer;
  padding-left: 16px;
  color: ${({theme}) => theme.palette.text.secondary};
`

const FontModifier = styled.div`
  .cm-content,
  .cm-gutters {
    font-size: ${({$fontSize}) => $fontSize}px;
  }
`

const MarkdownCell = ({statement, state, actions}) => {
  const isEdit = state.markdownEdit[statement.id] ?? false

  const setIsEdit = useCallback((value) => {
    actions.setMarkdownEdit(statement.id, value)
  }, [statement.id])

  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))

  const editorRef = useRef()
  useEffect(() => {
    editorRef?.current?.editor?.click()
  }, [])

  return (
    <Root $chosen={statement.id === state.pointer} onClick={() => actions.setPointer(statement.id)}>
      {isEdit && (
        <FontModifier $fontSize={state.fontSize}>
          <CodeMirror
            autoFocus
            ref={editorRef}
            theme={darkMode ? 'dark' : 'light'}
            value={statement.code}
            onChange={(newCode) => actions.changeStatement(statement.id, newCode)}
            extensions={[markdown(), ...(state.vimEnabled ? [vim({status: true})] : [])]}
          />
        </FontModifier>
      )}

      {!isEdit && (
        <MarkdownStyle onClick={() => setIsEdit(true)}>
          {!isEdit && statement.code.trim().length <= 0 && (
            <div style={{padding: 8}}>Empty markdown cell</div>
          )}

          <Markdown>{statement.code}</Markdown>
        </MarkdownStyle>
      )}

      {isEdit && (
        <div style={{padding: 8}}>
          <Button variant="text" onClick={() => setIsEdit(false)} size="small">
            Apply
          </Button>
        </div>
      )}

      <NotebookActions statement={statement} state={state} actions={actions} />
      <SecondaryActions statement={statement} state={state} actions={actions} />
    </Root>
  )
}

export default memo(MarkdownCell)
