import React, {useCallback, useMemo} from 'react'
import ToolTip from '../../../../components/toolTip'
import {Button, DropDownButton, Icon} from 'frontcore'
import DeleteContentComponent from './deleteContentComponent'
import styled from 'styled-components'
import ToolTipContentLayout from '../../../../components/layouts/toolTip/toolTipContentLayout'

const Root = styled.div`
  display: flex;
  gap: 8px;
`

const ClusterButtons = ({
  id,
  name,
  onDelete,
  onEdit,
  onClone,
  texts = true,
  pending = false,
  disabled = false,
}) => {
  const handleOnDelete = useCallback(() => onDelete({id}), [id, onDelete])

  const handleOnEdit = useCallback(() => onEdit({id}), [id, onEdit])

  const handleOnClone = useCallback(() => {
    onClone({id})
  }, [id, onClone])

  const size = 24
  const baseButtonsProps = useMemo(
    () => ({
      variant: 'text',
      tabIndex: -1,
      skeleton: pending,
    }),
    [pending]
  )

  const squareButtonProps = useMemo(
    () => (texts ? {square: false} : {square: true, justifyContent: 'center'}),
    [texts]
  )

  const editButtonProps = useMemo(
    () =>
      texts
        ? {children: 'Edit', StartComponent: Icon, startComponentProps: {icon: 'pencil', size}}
        : {Component: Icon, componentProps: {icon: 'pencil', size}},
    [texts]
  )

  const deleteButtonProps = useMemo(
    () =>
      texts
        ? {children: 'Delete', StartComponent: Icon, startComponentProps: {icon: 'trash', size}}
        : {Component: Icon, componentProps: {icon: 'trash', size}},
    [texts]
  )

  const cloneButtonProps = useMemo(() => {
    return texts
      ? {
          children: 'Clone',
          StartComponent: Icon,
          startComponentProps: {
            icon: 'plus',
            size,
          },
        }
      : {
          Component: Icon,
          componentProps: {
            icon: 'plus',
            size,
          },
        }
  }, [texts])

  return (
    <Root>
      {onClone && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Clone cluster: creates a cluster with the same settings
            </ToolTipContentLayout>
          }
        >
          <Button
            {...baseButtonsProps}
            {...squareButtonProps}
            {...cloneButtonProps}
            onClick={handleOnClone}
            disabled={disabled}
          />
        </ToolTip>
      )}
      {onEdit && (
        <ToolTip content={<ToolTipContentLayout>Edit</ToolTipContentLayout>}>
          <Button
            {...baseButtonsProps}
            {...squareButtonProps}
            {...editButtonProps}
            onClick={handleOnEdit}
            disabled={disabled}
          />
        </ToolTip>
      )}
      {onDelete && (
        <ToolTip content={<ToolTipContentLayout>Delete</ToolTipContentLayout>}>
          <DropDownButton
            ContentComponent={DeleteContentComponent}
            contentComponentProps={{
              onDelete: handleOnDelete,
              title: `Delete Cluster: ${name}`,
              text: 'Are you absolutely sure you want to delete this cluster? This action cannot be undone!',
            }}
            buttonProps={{
              ...baseButtonsProps,
              ...squareButtonProps,
              ...deleteButtonProps,
              disabled,
            }}
          >
            Delete
          </DropDownButton>
        </ToolTip>
      )}
    </Root>
  )
}

export default ClusterButtons
