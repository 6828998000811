import {Button, Input} from 'frontcore'

import {equals} from "ramda";

const schema = (data, options) => {
  const {pending, navigate} = options


  const repeatValidator = (a) => (value) => equals(a,value) ? '' : 'Please make sure your password match'
  return {
    fields: [
      {
        id: 'newPassword',
        section: 'Password',
        name: 'New Password',
        description: '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          type: 'password',
          fullWidth: true,
          autoFocus: true,
        },
      },
      {
        id: 'repeatPassword',
        section: 'Password',
        name: 'Repeat Password',
        description: '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          type: 'password',
          fullWidth: true,
          autoFocus: true,
        },
        validators: [repeatValidator(data?.newPassword)],
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
