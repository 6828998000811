import {path} from 'ramda'
import { memo, useMemo } from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import Configuration from '../../../../components/configuration'

const Root = styled.div`
  position: absolute;
  top: 0; bottom: 0;
  right: 0; left: 0;
  padding: 16px;
`

const Statistics = () => {
  const info = useSelector(path(['sqlViewer', 'results', 'data', 'result', 'response', 'info']))

  const timeData = useMemo(() => {
    const formatDate = (key) => {
      return new Date(info[key]).toISOString()
    }

    const formatDuration = (from, to) => {
      const timeUnits = Object.freeze([
        ['ms', 1000],
        ['s',    60],
        ['min',  60],
        ['h',    24],
      ])
      const difference = info[to] - info[from]
      const { fragments } = timeUnits.reduce(({difference, fragments}, [postfix, modulo]) => {
        return Object.freeze({
          difference: ~~(difference / modulo),
          fragments: [...fragments, {
            value: difference % modulo,
            postfix,
          }]
        })
      }, { difference, fragments: [] })
      return fragments
        .filter(({ value }) => value > 0)
        .map(({ value, postfix }) => `${value}${postfix}`)
        .reverse()
        .join(' ')
    }

    return Object.freeze([
      { key: 'Created', value: formatDate('creationTime') },
      { key: 'Started', value: formatDate('startTime') },
      { key: 'Completed', value: formatDate('completionTime') },
      { key: 'Query runtime', value: formatDuration('startTime', 'completionTime') },
      { key: 'Total elapsed', value: formatDuration('creationTime', 'completionTime') },
    ])
  }, [info])

  return (
    <Root>
      <Configuration 
        data={timeData}
        schema={[
          {
            id: 'key',
            label: 'Key',
            copy: true,
          },
          {
            id: 'value',
            label: 'Value',
            copy: true,
          },
        ]}
      />
    </Root>
  )
}

export default memo(Statistics)
