import React, {useCallback} from 'react'
import getServices from '../../../../api/workloads/services/getServices'

import Filters from '../../filters'
import {useListRefreshFuture} from '../../hooks'
import usePending from '../../../../hooks/usePending'
import {useNavigate} from 'react-router-dom'
import {Button, Icon} from 'frontcore'
import ListLayout from '../../../../components/layouts/listLayout'
import {isNotEmpty} from '../../../../helpers'
import schema from './schema'

import ServicesList from '../../Components/ServicesList'
import ServiceBatchButtons from "../../Components/ServiceBatchButtons";

const WorkloadsServicesListView = () => {
    const navigate = useNavigate()

    const listHandler = useListRefreshFuture(
        getServices,
        'workloads',
        'services',
        {
            page: 0,
            size: 25,
            sort: {
                createdTime: 'desc',
            },
            filter: {
                groupStates: ['ACTIVE'],
            },
        }
    )

    const {selected} = listHandler

    const pending = usePending(
        ['workloads', 'data'],
        [
            'services',
            'createService',
            'deleteService',
            'archiveService',
            'scaleService',
            'restartService',
            'pauseService',
            'resumeService'
        ]
    )


    const handleOnClickNewServiceButton = useCallback(() => {
        navigate('/workloads/create/service')
    }, [])

    return (
        <ListLayout
            batchButtonsNode={
                isNotEmpty(selected) && (
                    <ServiceBatchButtons {...listHandler} />
                )
            }
            filtersNode={
                <Filters
                    name={'services'}
                    schema={schema}
                    pending={pending}
                />
            }
            createButtonNode={
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleOnClickNewServiceButton}
                    EndComponent={Icon}
                    endComponentProps={{
                        icon: 'plus',
                        size: 18,
                        color: 'white',
                    }}
                >
                    New Service
                </Button>
            }
            contentNode={
                <ServicesList
                    pending={pending}
                    listHandler={listHandler}
                />
            }
        />
    )
}

export default WorkloadsServicesListView
