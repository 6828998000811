import asyncThunk from '../index'

export const getTable = asyncThunk({
  method: "GET",
  type: "/table/get",
  path: "/lineage?nodeId=${nodeType}:${namespace}:${nodeId}&depth=${depth}",
  api: true,
  lineage: true
});

export default getTable;
