import asyncThunk from "../../index";

export const getCluster = asyncThunk({
  method: "GET",
  type: "cluster/get",
  path: "/cluster/${id}",
  api: true,
});

export default getCluster;
