import React, {useEffect, useMemo} from 'react'
import useFuture from '../../hooks/useFuture'
import getClustersMetrics from '../../api/workloads/clusters/getClustersMetrics'
import {useInterval} from 'frontcore'
import {fork} from 'fluture'
import GlobalSearchLayout from '../../components/layouts/GlobalSearchLayout'
import WorkloadsGlobalSearch from './Components/WorkloadsGlobalSearch'

const WorkloadsView = () => {

  return (
    <GlobalSearchLayout searchNode={<WorkloadsGlobalSearch />} />
  )
}

export default WorkloadsView
