import List from '../../../../../components/list'

import HeaderComponent from '../../../../../components/list/headerComponent'
import ItemComponent from '../../../../../components/list/itemComponent'
import ToolTipContentComponent from '../../../../WorkloadsView/Components/ServicesList/toolTipContentComponent'
import React, {useEffect, useMemo} from 'react'
import {join, map, pipe} from 'ramda'
import {fork} from 'fluture'
import {EMPTY_FUNCTION} from '../../../../../constants'
import DateCell from '../../../../WorkloadsView/dateCell'
import LabelIcon from '../../../../../components/Icons/LabelIcon'
import GroupSingleButtons from '../../../Components/GroupButtons/GroupSingleButtons'
import StatusIndicator from '../../../../../components/StatusIndicator'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import Chip from "../../../../../components/Chip";

const GroupState = ({value}) => (
  <StatusIndicator
    value={value}
    variant={value === 'ENABLED' ? 'success' : 'default'}
  />
)

const schema = [
  {
    value: 'description',
    label: 'Description',
    size: 2,
  },
  {
    value: 'state',
    label: 'State',
    size: 2,
    Component: GroupState,
  },

  {
    value: 'creationTime',
    label: 'Creation Time',
    size: 2,
    Component: DateCell,
  },
  {
    value: 'users',
    label: 'Users',
    size: 2,
  },
  {
    value: 'roles',
    label: 'Roles',
    size: 2,
  },

  {
    value: 'permissions',
    label: 'Permissions',
    size: 2,
    Component: ({value}) => value && join(' ', value),
  },
]

const UsersList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists = {},
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    selected,
    setSelected,
  } = listHandler

  const {createNotification} = useNotificationContext()

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  useEffect(() => {
    fork(({message}) =>
      createNotification({
        title: 'Failed to fetch Groups',
        message,
        variant: 'error',
        autoHide: true,
      })
    )(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const {filter} = lists

  return (
    <List
      filters={filter}
      schema={schema}
      data={extendedData}
      HeaderComponent={HeaderComponent}
      headerComponentProps={{
        sort,
        onSort: handleOnSort,
        skeleton: pending,
        iconWidth: '120px',
      }}
      selected={selected}
      onSelect={setSelected}
      onReset={handleOnReset}
      ItemComponent={ItemComponent}
      itemComponentProps={{
        toFn: ({id}) => '/security/details/group/' + id,
        buttonsWidth: '0px',
        skeleton: pending,
        ToolTipComponent: ToolTipContentComponent,
        IconComponent: ({data}) => (
            <Chip type={'GROUP'}>  {data?.name}</Chip>

        ),
        iconWidth: '120px',
        iconComponentProps: {
          type: 'user',
        },
        ButtonsComponent: GroupSingleButtons,
        buttonsComponentProps: {
          pending,
          texts: false,
          getFuture: getFuture,
          redirect: false,
        },
      }}
      pagerProps={{
        size,
        page,
        totalPages,
        onChangeSize: handleOnSizeChange,
        onChangePage: handleOnPageChange,
      }}
    />
  )
}

export default UsersList
