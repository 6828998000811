import {useNavigate} from 'react-router-dom'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {pathOr} from 'ramda'
import styled, {css} from 'styled-components'
import {createTransitions, formatISODuration} from '../../helpers'
import {Button, Icon} from 'frontcore'

const greenStatusStyles = css`
  height: 0;
  min-height: 0;
`

const redStatusStyles = css`
  height: ${({show}) => (show ? '48x' : 0)};
  min-height: ${({show}) => (show ? '48px' : 0)};
`

const yellowStatusStyles = css`
  height: ${({show}) => (show ? '48px' : 0)};
  min-height: ${({show}) => (show ? '48px' : 0)};
`

const statusMap = {
  GREEN: greenStatusStyles,
  RED: redStatusStyles,
  YELLOW: yellowStatusStyles,
}

const Root = styled.div`
  padding-right: 8px;
  overflow: hidden;
  background-color: orange;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  ${({status}) => statusMap[status]};
  transition: ${createTransitions(['height', 'min-height'])};
`

const Container = styled.div`
  flex: 1;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  color: white;
  font-size: 16px;
`

const textMap = {
  RED: () => 'Your trial period has expired. Activate to full version.',
  YELLOW: (expire) =>
    `To use Ilum, you should activate a free-tier license. Your trial will expire in ${formatISODuration(expire)}`,
  GREEN: () => '',
}

const LicenseBar = () => {
  const navigate = useNavigate()

  const [show, setShow] = useState(true)

  const licenseStatus = useSelector(
    pathOr('GREEN', ['workloads', 'data', 'getLicense', 'response', 'status'])
  )

  const licenseExpire = useSelector(
    pathOr(0, ['workloads', 'data', 'getLicense', 'response', 'expire'])
  )

  return (
    <Root status={licenseStatus} show={show}>
      <Container>
        <TextContainer>{textMap[licenseStatus](licenseExpire)}</TextContainer>
        <Button
          tabIndex={-1}
          variant={'text'}
          color={'white'}
          onClick={() => {
            navigate('/settings/license')
          }}
        >
          MANAGE YOUR LICENSE
        </Button>
      </Container>
      <Button
        tabIndex={-1}
        variant={'text'}
        color={'white'}
        square={true}
        justifyContent={'center'}
        onClick={() => setShow(false)}
        Component={Icon}
        componentProps={{
          icon: 'close',
          size: 24,
        }}
      />
    </Root>
  )
}

export default LicenseBar
