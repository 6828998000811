import {Input, ResizeLayout, TextArea} from 'frontcore'
import styled from 'styled-components'
import {
  EMPTY_OBJECT,
  EMPTY_STRING,
  HORIZONTAL,
} from '../../../../../constants'
import {
  formatDate,
  formatDurationVerbose,
} from '../../../../../helpers'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  join,
  map,
  pathOr,
  pipe,
  reject,
  toPairs,
} from 'ramda'
import ExecuteCodeForm from './codeForm'
import ExecuteJobForm from './jobForm'
import {fork} from 'fluture'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import executeJob from '../../../../../api/workloads/services/executeJob'
import {initExecute} from '../../../../../reducers/workloads'

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const RightContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px 16px 16px;
  box-sizing: border-box;
`
const LeftContainer = styled.div`
  flex: 1;
`

const ServiceDetailsExecuteJob = ({data}) => {
  const {createNotification} = useNotificationContext()
  const {id, kind, language} = data
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(initExecute())
  }, [])

  const languagesMap = {
    SCALA: 'scala',
    PYTHON: 'python',
  }

  const {pending = false} = useSelector(
    pathOr(EMPTY_OBJECT, [
      'workloads',
      'data',
      'executeJob',
    ])
  )

  const {
    startTime = 0,
    endTime = 0,
    result = EMPTY_STRING,
    error = EMPTY_STRING,
  } = useSelector(
    pathOr(EMPTY_OBJECT, [
      'workloads',
      'data',
      'executeJob',
      'response',
    ])
  )
  const executeJobFuture = useFuture(executeJob)

  const duration = pipe(
    formatDurationVerbose,
    reject((a) => a === 0),
    toPairs,
    map((a) => a[1] + ' ' + a[0]),
    join(', ')
  )(endTime - startTime)

  const aaa = (
    <RightContainer>
      <Input
        skeleton={pending}
        readOnly={true}
        topLabelProps={{
          children: 'End Time',
        }}
        fullWidth
        value={endTime ? formatDate(endTime) : ''}
      />
      <Input
        skeleton={pending}
        readOnly
        topLabelProps={{
          children: 'Duration',
        }}
        fullWidth
        value={duration}
      />
      {!!error && (
        <Input
          skeleton={pending}
          topLabelProps={{
            children: 'Error',
          }}
          readOnly
          fullWidth
          value={error?.message}
        />
      )}
      {!!error && (
        <TextArea
          skeleton={pending}
          readOnly
          fullWidth
          fullHeight
          topLabelProps={{
            children: 'Stack Trace',
          }}
          value={error?.stackTrace || ''}
        />
      )}
      {!error && (
        <TextArea
          skeleton={pending}
          readOnly
          topLabelProps={{
            children: 'Result',
          }}
          fullWidth
          fullHeight
          value={result || ''}
        />
      )}
    </RightContainer>
  )

  const handleOnExecuteJob = useCallback(
    (fields) => {
      let body
      if (kind === 'CODE') {
        body = {
          code: fields.code,
          type: 'interactive_code_execute',
          cursor: 0,
        }
      } else {
        body = {
          config: JSON.parse(fields?.config),
          clazz: fields.clazz,
          type: 'interactive_job',
          cursor: 0,
        }
      }
      fork(({message}) => {
        createNotification({
          message,
          autoHide: false,
          variant: 'error',
        })
      })((data) => {
        if (data?.error) {
          createNotification({
            title: 'Failed to execute Job',
            message: data?.error?.message,
            autoHide: false,
            variant: 'error',
          })
        } else {
          createNotification({
            title: 'Job executed successfully',
            autoHide: true,
            variant: 'success',
          })
        }
      })(
        executeJobFuture({
          params: [{key: 'id', value: id}],
          ...body,
        })
      )
    },
    [id, kind]
  )

  useEffect(() => {
    const hotkeyExecuteHandler = (event) => {
      if (event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        const btn = document.body.querySelector(
          '#button-execute-job-container > button'
        )
        btn?.click()
      }
    }
    window.addEventListener('keydown', hotkeyExecuteHandler)
    return () => {
      window.removeEventListener(
        'keydown',
        hotkeyExecuteHandler
      )
    }
  }, [handleOnExecuteJob])

  const bbb = (
    <LeftContainer>
      {kind === 'CODE' && (
        <ExecuteCodeForm
          onSubmit={handleOnExecuteJob}
          language={languagesMap[language]}
        />
      )}
      {kind === 'JOB' && (
        <ExecuteJobForm
          onSubmit={handleOnExecuteJob}
          language={language}
        />
      )}
    </LeftContainer>
  )

  return (
    <Root>
      <ResizeLayout
        configuration={{
          orientation: HORIZONTAL,
          secondNode: {
            measurement: 'px',
            value: 700,
          },
          firstNode: {
            measurement: 'flex',
            value: 1,
          },
        }}
        firstNode={bbb}
        secondNode={aaa}
      />
    </Root>
  )
}

export default ServiceDetailsExecuteJob
