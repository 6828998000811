import ListLayout from '../../../../../components/layouts/listLayout'
import Filters from '../../../filters'
import schema from './schema'
import React, {useCallback} from 'react'
import usePending from '../../../../../hooks/usePending'
import {useListRefreshFuture} from '../../../hooks'
import {getJob} from '../../../../../api/workloads/jobs/getJob'
import {EMPTY_OBJECT} from '../../../../../constants'
import JobsList from '../../../Components/JobsList'
import {Button, Icon} from 'frontcore'
import {
  createSearchParams,
  useNavigate,
} from 'react-router-dom'
import JobBatchButtons from '../../../Components/JobBatchButtons'
import {isNotEmpty} from '../../../../../helpers'
import getJobs from "../../../../../api/workloads/jobs/getJobs";

const ClusterDetailsJobsList = ({
  clusterId,
  clusterName,
}) => {
  const navigate = useNavigate()

  const listHandler = useListRefreshFuture(
    getJobs,
    'workloads',
    'jobs',
    {
      page: 0,
        size: 25,
      sort: {
        submitTime: 'desc',
      },
      filter: EMPTY_OBJECT,
    },
    {clusterNames: [clusterName]}
  )

  const {selected} = listHandler

  const pending = usePending(
    ['workloads', 'data'],
    ['jobsDetails', 'killJob', 'deleteJob', 'deleteCluster']
  )

  const handleOnClickNewJobButton = useCallback(() => {
    navigate({
      pathname: `/workloads/create/job`,
      search: createSearchParams({
        cluster: clusterName,
      }).toString(),
    })
  }, [clusterName, navigate])

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <JobBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'jobsDetails'}
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleOnClickNewJobButton}
          EndComponent={Icon}
          endComponentProps={{
            icon: 'plus',
            size: 18,
            color: 'white',
          }}
        >
          New Job
        </Button>
      }
      contentNode={
        <JobsList
          listHandler={listHandler}
          borderTop={true}
          pending={pending}
        />
      }
    />
  )
}
export default ClusterDetailsJobsList
