import {createSlice} from "@reduxjs/toolkit"
import {extraReducersMapper} from "../../api"
import executeSQLStatement from "../../api/sql/executeSQLStatement"

const INITIAL_DATA = Object.freeze({
  response: null,
  pending: true,
  error: false,
})

export const Modes = Object.freeze({
  list: 'Data List',
  walker: 'Data Exploration',
  stats: 'Statistics',
  logs: 'Logs',
})

const initialState = Object.freeze({
  mode: Modes.list,
  logsWrap: true,
  errorMessage: null,
  data: {
    result: { ...INITIAL_DATA, pending: false },
  },
})

const resultsSlice = createSlice({
  name: 'sql/results',
  initialState,
  reducers: {
    resultsToggleWrap: (state) => {
      state.logsWrap = !state.logsWrap
    },
    resultsSetMode: (state, { payload }) => {
      state.mode = payload
    },
    resultsSetErrorMessage: (state, { payload }) => {
      state.errorMessage = payload
    }
  }, 
  extraReducers: extraReducersMapper([
    {
      asyncThunk: executeSQLStatement,
      name: 'result',
    },
  ]),
})

export default resultsSlice.reducer
export const {
  resultsSetMode,
  resultsToggleWrap,
  resultsSetErrorMessage,
} = resultsSlice.actions
