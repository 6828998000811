import {ILUM_GRAFANA_PATH} from "../../helpers/runtimeEnv";
import {useHistoryContext} from "../../hooks/useHistoryContext";
import {useEffect} from "react";

const GrafanaView = () => {
    const {addHistory} = useHistoryContext()

    useEffect(() => {
        addHistory({type: 'module', id: 'Grafana', name: 'Grafana'})
    }, [addHistory])

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_GRAFANA_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    );
};

GrafanaView.dispayName = "GrafanaView";

export default GrafanaView;

