import {calculateLastUpdate, formatDate} from '../../helpers'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  color: ${({theme}) => theme.palette['text-tertiary']};
`
const DateCell = ({value}) => {
  const formattedDate = formatDate(value)
  const duration = calculateLastUpdate(value)

  return value ? (
    <Root
      style={{
        display: 'flex',
        gap: 8,
        fontSize: 12,
        justifyContent: 'space-between',
      }}
    >
      <div style={{display: 'flex', gap: 8}}>
        <div>{formattedDate[0]}</div>
        <div>{formattedDate[1]}</div>
      </div>
      <div style={{fontWeight: 800}}>{duration}</div>
    </Root>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      N/A
    </div>
  )
}

export default DateCell
