import React from 'react'
import {Button, Icon} from 'frontcore'
import DropDownButtonContentLayout from '../../../../components/layouts/dropDownButtonContentLayout'
import styled, {useTheme} from 'styled-components'

const TitleContainer = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.palette.red[600]};
  font-weight: bold;
  display: flex;
  gap: 8px;
`

const MiddleContainer = styled.div`
  font-size: 13px;
  color: ${({theme}) => theme.palette.red[600]};
`

const DeleteContentComponent = ({
  closeFn,
  title = 'Delete',
  text = 'Are you sure you want to delete this item?',
  onDelete,
}) => {
  const theme = useTheme()
  return (
    <DropDownButtonContentLayout>
      <TitleContainer>
        <Icon icon="error-contained" size={20} color={theme.palette.red[500]} />
        {title}
      </TitleContainer>
      <MiddleContainer>{text}</MiddleContainer>
      <div
        style={{
          display: 'flex',
          gap: 8,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color={'error'}
          tabIndex={-1}
          onClick={() => {
            onDelete()
            closeFn()
          }}
        >
          Yes, Delete
        </Button>

        <Button
          tabIndex={-1}
          variant={'outlined'}
          onClick={() => {
            closeFn()
          }}
        >
          No, Cancel
        </Button>
      </div>
    </DropDownButtonContentLayout>
  )
}

export default DeleteContentComponent
