import {useStorage} from '../../hooks/useStorage'
import {Icon} from 'frontcore'
import {
  always,
  ascend,
  descend,
  map,
  pipe,
  prop,
  propOr,
  sortWith,
  splitEvery,
  toPairs,
  when,
} from 'ramda'
import {useNavigate} from 'react-router-dom'
import List from '../../components/list'
import React, {useMemo, useState} from 'react'
import ItemComponent from '../../components/list/itemComponent'
import HeaderComponent from '../../components/list/headerComponent'
import DateCell from '../WorkloadsView/dateCell'
import {EMPTY_ARRAY} from '../../constants'
import {DescriptionText} from '../../components/typography'
import {notificationIconMap} from '../../components/notification'
import {useTheme} from "styled-components";

const schema = [
  {
    value: 'variant',
    label: 'Variant',
    size: 1,
  },
  {
    value: 'title',
    label: 'Title',
    size: 3,
  },
  {
    value: 'message',
    label: 'Message',
    size: 3,
  },
  {
    value: 'data',
    label: 'Data',
    size: 3,
    Component: DateCell,
  },
]

const NotificationsView = () => {
  const {getLocalStorageItem, setLocalStorageItem} = useStorage()

  const data = getLocalStorageItem('notificationHistory')

  const [size, setSize] = useState(25)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState()

  const [key, direction] = pipe(toPairs, propOr(EMPTY_ARRAY, 0))(sort)

  const sortFn = key && (direction === 'asc' ? ascend(prop(key)) : descend(prop(key)))

  const extendedData = useMemo(
    () =>
      pipe(
        when(always(sortFn), sortWith([sortFn])),
        splitEvery(size),
        propOr(EMPTY_ARRAY, page),
        map((data) => ({data}))
      )(data),
    [data, size, page, sort]
  )

  const theme = useTheme()

  const colorMap = {
    success:  theme.palette.green[600],
    error: theme.palette.red[600],
    warning: theme.palette.yellow[600],
    information: theme.palette.blue[600]
  }

  const totalPages = Math.ceil(data.length / size)
  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden', height: '100%'}}>
      <div style={{flex: 1, overflow: 'hidden', width: '100%'}}>
        <List
          // filters={filter}
          schema={schema}
          data={extendedData}
          debug={false}
          // pending={pending}
          // onReset={handleOnReset}
          // selected={selected}
          // onSelect={setSelected}
          ItemComponent={ItemComponent}
          itemComponentProps={{
            // toFn: ({id, name}) =>
            //     '/workloads/details/cluster/' + id + '/' + name,
            // skeleton: pending,
            // ButtonsComponent: ClusterSingleButtons,
            // ToolTipComponent: ToolTipContentComponent,
            IconComponent: ({data}) =>console.log('data',data) || <Icon icon={notificationIconMap[data?.variant]} size={16} color={colorMap[data?.variant]} />,
            iconComponentProps: {
              type: 'notification',
            },
            // typeComponentProps: {
            //     type: 'notification-success',
            // },
            // buttonsComponentProps: {
            //     texts: false,
            //     pending,
            //     getFuture,
            //     redirect: false,
            // },
          }}
          HeaderComponent={HeaderComponent}
          headerComponentProps={{
            skeleton: false,
            sort,
            onSort: setSort,
          }}
          pagerProps={{
            size,
            page,
            totalPages,
            onChangeSize: setSize,
            onChangePage: setPage,
          }}
        />
      </div>
      {/*{map(*/}
      {/*  ({title, message, variant, data}) => (*/}
      {/*    <div style={{display: 'flex'}}>*/}
      {/*        <Notification variant={variant} title={title}*/}
      {/*        message={message}/>*/}
      {/*      <div>{title}</div>*/}
      {/*      <div>{message}</div>*/}
      {/*      <div>{variant}</div>*/}
      {/*        <DateCell value={data} />*/}

      {/*    </div>*/}
      {/*  ),*/}
      {/*  data*/}
      {/*)}*/}
    </div>
  )
}

export default NotificationsView
