import styled, {css} from "styled-components";
import {createTransitions} from "../../helpers";
import {style} from "d3";


const typeColorMap = {
    GROUP: {default: '#6474a8', hover: '#556494',  active: '#495681'},
    ROLE: {default: '#9c64a8', hover: '#8e599a', active: '#74477e'},

    DELETE: {default: '#E52535', hover: '#CD2F3D', active: '#A11C28'},
    POST: {default: '#48C387', hover: '#10A35F', active: '#0A663B'},
    PUT: {default: '#e5a225', hover: '#c78a18', active: '#af760e'},
}

const largeCss = css`
    height: 40px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
`

const Root = styled.div`
    user-select: none;
    cursor: default;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    box-sizing: border-box;
    height: 24px;
    font-weight: 500;
    min-width: 24px;
    transition: ${createTransitions(['background'])};
    font-family: Roboto, serif;
    background: ${({type}) => typeColorMap?.[type]?.default};
    color: white;
    border-radius: 4px;
    overflow: hidden;
    &:hover {
        background: ${({type}) => typeColorMap?.[type]?.hover};
    }

    &:active {
        background: ${({type}) => typeColorMap?.[type]?.active};
    }
    ${({size}) =>size === 'large' && largeCss}
`

const Text = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const Chip = (props) => {
    return <Root {...props}>
        <Text>{props.children}</Text>
    </Root>
}

export default Chip