import styled from 'styled-components'
import {Button, DropDownButton} from 'frontcore'
import {memo} from 'react'
import DropDownButtonContentLayout from '../layouts/dropDownButtonContentLayout'
import {EMPTY_STRING} from '../../constants'
import ToolTip from '../toolTip'

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`

const ConfirmEnhanced = ({
  title = 'Are you sure?',
  message = EMPTY_STRING,
  description = 'Potentially dangerous action',
  onClick,
  ...props
}) => {
  return (
    <ToolTip
      config={{position: 'bottom_right'}}
      content={<div style={{padding: 8}}>{description}</div>}
    >
      <DropDownButton
        buttonProps={props}
        ContentComponent={memo(({closeFn}) => (
          <DropDownButtonContentLayout>
            <div style={{fontSize: 16}}>{title}</div>
            <div style={{fontSize: 12}}>{message}</div>
            <ButtonContainer>
              <Button
                color="error"
                tabIndex={-1}
                onClick={() => {
                  onClick()
                  closeFn()
                }}
              >
                Yes
              </Button>
              <Button tabIndex={-1} variant="outlined" onClick={closeFn}>
                No
              </Button>
            </ButtonContainer>
          </DropDownButtonContentLayout>
        ))}
      />
    </ToolTip>
  )
}

export default memo(ConfirmEnhanced)
