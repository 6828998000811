
const DocumentationView = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
      }}
    >
      <iframe
        src="https://ilum.cloud/docs/category/user-guides/"
        style={{ height: "100%", width: "100%", border: "none" }}
      />
    </div>
  );
};

DocumentationView.dispayName = "DocumentationView";

export default DocumentationView;
