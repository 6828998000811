import {Tabs} from 'frontcore'
import React, {useCallback} from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import ListViewLayout from '../../../components/layouts/ListViewLayout'
import ListViewLayoutSecurity from "../../../components/layouts/ListViewLayoutSecurity";

const TabComponent = ({active, type, value, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
  </div>
)

const SecurityListView = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()

  const handleOnClickTab = useCallback(
    (value) => {
      navigate(value)
    },
    [navigate],
  )

  return (
    <ListViewLayoutSecurity

      tabsNode={
        <Tabs
          value={pathname}
          onChange={handleOnClickTab}
          options={[
            {
              id: '/security/list/users',
              Component: TabComponent,
              componentProps: {
                type: 'users',
                label: 'Users',
              },
            },
            {
              id: '/security/list/groups',
              Component: TabComponent,
              componentProps: {
                type: 'groups',
                label: 'Groups',
              },
            },
            {
              id: '/security/list/roles',
              Component: TabComponent,
              componentProps: {
                type: 'roles',
                label: 'Roles',
              },
            },
            {
              id: '/security/list/activities',
              Component: TabComponent,
              componentProps: {
                type: 'activities',
                label: 'Activities',
              },
            },
          ]}
        />
      }
    />
  )


}

export default SecurityListView
