import { EMPTY_ARRAY, EMPTY_STRING } from "../../constants";
import {
  fromPairs, isEmpty,
  map,
  pathOr,
  pipe,
  prop,
  split,
  startsWith,
  toPairs,
} from 'ramda'

export const onSubmitFnMultipleFiles = (data = EMPTY_ARRAY) =>
  pipe(
    map(({ data, filename }) => {
      if (startsWith("data", data)) {
        const result = pipe(split(","), prop(1))(data);
        return [filename, result];
      } else {
        return [filename, data];
      }
    }),
    fromPairs
  )(data);

export const onSubmitSingleFile = (data = EMPTY_ARRAY) => {
  const string = pathOr(EMPTY_STRING, [0, "data"], data);
  if(isEmpty(string)) {
    return
  } else if (startsWith("TableExplorerView:", string)) {
    return pipe(split(","), prop(1))(string);
  } else {
    return string;
  }
};

export const mapFiles = pipe(
  toPairs,
  map((a) => ({
    filename: a[0],
    data: a[1],
  }))
);

export const mapFile = (filename) => (file) =>
  file ? [{ filename, data: file }] : undefined;
