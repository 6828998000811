import {useMemo, useRef, useEffect, useCallback} from 'react'
import {Parser} from 'node-sql-parser'
import {useSelector} from 'react-redux'
import {pathOr, path, isNil, propOr} from 'ramda'
import useDebouncedWithDeps from '../../hooks/useDebouncedWithDeps'

export const useQueryLinterError = ({
  code = null, 
  dialect = 'hive', 
  debounce = 500,
  dependencies = [],
}) => {
  const parser = useRef(new Parser())
  const delayedCode = useDebouncedWithDeps(code, debounce, dependencies)
  return useMemo(() => {
    if (isNil(code)) return null
    try {
      parser.current.astify(delayedCode, {
        database: dialect,
        parseOptions: {
          includeLocations: true,
        },
      })
    } catch (error) {
      return error
    }
    return null
  }, [delayedCode, dialect])
}

export const useLinter = ({
  code, 
  onChange, 
  debounce = 500,
  dependencies = [],
  enabled = false,
}) => {
  const engine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const dialect = (() => {
    switch (engine) {
      case 'SPARK_SQL':
        return 'hive'
      case 'TRINO':
        return 'trino'
      default:
        return 'hive'
    }
  })()
  const error = useQueryLinterError({
    code,
    dialect,
    debounce,
    dependencies,
  })
  useEffect(() => {
    if (enabled) {
      onChange(error)
    } else {
      onChange(null)
    }
  }, [error, onChange, enabled])
}

export const useLinterDiagnostics = ({ 
  code, 
  onChange, 
  debounce = 500,
  dependencies = [],
  enabled = false,
}) => {
  const onErrorChange = useCallback(
    (error) => {
      if (isNil(error)) {
        onChange([])
        return
      }
      const message = propOr('Unknown error', 'message', error)
      const startOffset = path(['location', 'start', 'offset'], error)
      const endOffset = path(['location', 'end', 'offset'], error)
      onChange([
        {
          from: startOffset ?? 0,
          to: endOffset ?? 0,
          severity: 'error',
          message: message + (isNil(startOffset) ? ' (Linter did not provide the error location)' : ''),
        },
      ])
    },
    [onChange]
  )
  useLinter({
    code,
    onChange: onErrorChange,
    debounce,
    dependencies,
    enabled,
  })
}
