import {map} from 'ramda'
import React from 'react'
import GridItem from './GridItem'
import styled from 'styled-components'

const Grid = ({data, onClick}) => {
  return (
    <Root>
      {map(
        (item) => (
          <GridItem
            data={item}
            onClick={() => {
              onClick(item)
            }}
          />
        ),
        data
      )}
    </Root>
  )
}

export default Grid

const Root = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-left: 4px;
`
