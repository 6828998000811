import React from 'react';
import ToolTipDetailsGridContentLayout, {
  Key,
  Value,
} from '../../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout';

const ToolTipContentComponent = ({data}) => (
  <ToolTipDetailsGridContentLayout>
    <Key>Name</Key>
    <Value>{data?.name}</Value>
    <Key>Id</Key>
    <Value>{data?.id}</Value>

  </ToolTipDetailsGridContentLayout>
);

export default ToolTipContentComponent;