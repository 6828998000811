const toCSV = (results) => {
  const header = `data:text/csv;charset=utf-8,`
  const columns = results.columns.map(({ name }) => name)
  const csvRows = [columns, ...results.rows]
  const csvContent = csvRows
    .map(row => {
      return row
        .map(String)
        .map(value => {
          if (!/"|\,|\r?\n/g.test(value)) return value
          const escaped = value.replace(/"/g, '""')
          return '"' + escaped + '"'
        })
        .join(',')
    })
    .join('\r\n')
  return header + encodeURIComponent(csvContent)
}

const toExcel = (results) => {
  // TODO:
  const message = 'Excel files are not supported in this version'
  return 'TableExplorerView:text/plain;charset=utf-8,' + message
}

const toJSON = (results) => {
  const columns = results.columns.map(({ name }) => name)

  const header = `data:text/json;charset=utf-8,`
  const tail = JSON.stringify({ 
    columns,
    content: results.rows, 
  }, null, 2)
  return header + encodeURIComponent(tail)
}

const converter = Object.freeze({
  CSV: toCSV,
  Excel: toExcel,
  JSON: toJSON,
})

export default converter
