import {Button, Icon} from 'frontcore'
import {memo} from 'react'
import styled from 'styled-components'
import ToolTip from '../../../components/toolTip'
import ConfirmEnhanced from '../../../components/Confirm/enhanced'

const Root = styled.div`
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
`

const TooltipRoot = styled.div`
  padding: 8px;
`

const RightControls = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`

const NotebookControls = ({state, actions}) => {
  return (
    <Root>
      <ToolTip
        config={{position: 'bottom_right'}}
        content={<TooltipRoot>Runs all cells listed in the notebook</TooltipRoot>}
      >
        <Button
          onClick={actions.runAll}
          StartComponent={Icon}
          startComponentProps={{
            size: 16,
            icon: 'play',
          }}
          color="primary"
        >
          Run all
        </Button>
      </ToolTip>
      <ToolTip
        config={{position: 'bottom_right'}}
        content={<TooltipRoot>Executes the current cell</TooltipRoot>}
      >
        <Button
          onClick={() => actions.runStatement(state.pointer)}
          StartComponent={Icon}
          startComponentProps={{
            size: 16,
            icon: 'play',
          }}
          variant="text"
        >
          Run current
        </Button>
      </ToolTip>

      {state.isCell && (
        <>
          <ToolTip
            config={{position: 'bottom_right'}}
            content={
              <TooltipRoot>Executes cells from the 1st one till the current one</TooltipRoot>
            }
          >
            <Button
              onClick={() => actions.runBefore(state.pointer)}
              StartComponent={Icon}
              startComponentProps={{
                size: 16,
                icon: 'arrow-top',
              }}
              variant="text"
            >
              Execute before
            </Button>
          </ToolTip>

          <ToolTip
            config={{position: 'bottom_right'}}
            content={
              <TooltipRoot>Executes cells from the current one till the last one</TooltipRoot>
            }
          >
            <Button
              onClick={() => actions.runAfter(state.pointer)}
              StartComponent={Icon}
              startComponentProps={{
                size: 16,
                icon: 'arrow-down',
              }}
              variant="text"
            >
              Execute after
            </Button>
          </ToolTip>
        </>
      )}
      <ToolTip
        config={{position: 'bottom_right'}}
        content={<TooltipRoot>Executes cells from the current one till the last one</TooltipRoot>}
      >
        <Button
          onClick={actions.clearOutputs}
          StartComponent={Icon}
          startComponentProps={{
            size: 16,
            icon: 'trash',
          }}
          variant="text"
        >
          Clear outputs
        </Button>
      </ToolTip>

      <ConfirmEnhanced
        message="This action will delete all your code in this notebook"
        description="Removes all cells from the notebook"
        onClick={actions.clearAll}
        StartComponent={Icon}
        startComponentProps={{
          size: 16,
          icon: 'trash',
        }}
        variant="text"
        children="Clear cells"
      />

      <RightControls>
        <ToolTip
          config={{
            position: 'bottom_right',
          }}
          content={<div style={{padding: 8}}>Increase font</div>}
        >
          <Button
            StartComponent={Icon}
            startComponentProps={{
              size: 16,
              icon: 'arrow-top',
            }}
            variant="outlined"
            onClick={actions.increaseFont}
          >
            Font
          </Button>
        </ToolTip>
        <ToolTip
          config={{
            position: 'bottom_right',
          }}
          content={<div style={{padding: 8}}>Decrease font</div>}
        >
          <Button
            StartComponent={Icon}
            startComponentProps={{
              size: 16,
              icon: 'arrow-down',
            }}
            variant="outlined"
            onClick={actions.decreaseFont}
          >
            Font
          </Button>
        </ToolTip>

        <ToolTip
          config={{position: 'bottom_right'}}
          content={
            <TooltipRoot>
              {state.linterEnabled
                ? 'Disable linter'
                : 'Enable linter: it will be showing you hypothetical errors in your code'}
            </TooltipRoot>
          }
        >
          <Button
            StartComponent={Icon}
            startComponentProps={{
              size: 16,
              icon: state.linterEnabled ? 'close' : 'plus',
            }}
            onClick={actions.toggleLinter}
            variant="outlined"
          >
            SQL Linter
          </Button>
        </ToolTip>
        <ToolTip
          config={{position: 'left_bottom'}}
          content={
            <TooltipRoot>
              {state.vimEnabled
                ? 'Disable Vim'
                : 'Enable Vim: allows you to move across your code faster using Vim motions'}
            </TooltipRoot>
          }
        >
          <Button
            StartComponent={Icon}
            startComponentProps={{
              size: 16,
              icon: state.vimEnabled ? 'close' : 'plus',
            }}
            onClick={actions.toggleVim}
            variant="outlined"
          >
            Vim
          </Button>
        </ToolTip>
      </RightControls>
    </Root>
  )
}

export default memo(NotebookControls)
