import StatusIndicator from '../../../../components/StatusIndicator'
import {propOr, toLower} from 'ramda'
import {EMPTY_STRING} from '../../../../constants'
import statusVariantMap from "../../../../helpers/statusVariantMap";

const LABEL_MAPPING = Object.freeze({
  ENABLED: 'ACTIVE',
  DISABLED: 'ARCHIVED',
})

const ScheduleState = ({ value = EMPTY_STRING }) => {
  const variant = propOr('default', value, statusVariantMap)
  const label = propOr(value, value, LABEL_MAPPING)

  return <StatusIndicator
    value={toLower(label || EMPTY_STRING)}
    variant={statusVariantMap[label]}
  />
}

export default ScheduleState
