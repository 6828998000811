import {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import useFuture from '../../../../hooks/useFuture'
import stopSchedule from '../../../../api/workloads/schedules/stopSchedule'
import {useNavigate} from 'react-router-dom'
import deleteSchedule from '../../../../api/workloads/schedules/deleteSchedule'
import {and, fork} from 'fluture'
import ScheduleButtons from '../../Components/ScheduleButtons'
import pauseSchedule from '../../../../api/workloads/schedules/pauseSchedule'
import resumeSchedule from '../../../../api/workloads/schedules/resumeSchedule'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'

const ScheduleSingleButtons = ({
  pending,
  data,
  texts,
  getFuture = () => {},
  redirect = true,
}) => {
  const stopScheduleFuture = useFuture(stopSchedule)
  const deleteScheduleFuture = useFuture(deleteSchedule)
  const pauseScheduleFuture = useFuture(pauseSchedule)
  const resumeScheduleFuture = useFuture(resumeSchedule)

  const {createNotification} = useNotificationContext()
  const navigate = useNavigate()

  const handleOnStop = useCallback(
    ({id}) => {
      pipe(
        and(getFuture),
        fork(({message}) => {
          createNotification({
            title: 'Failed to stop schedule',
            message,
            variant: 'error',
            autoHide: false,
          })
        })(() => {
          createNotification({
            title: 'Schedule successfully stopped',
            variant: 'success',
            autoHide: true,
          })
        })
      )(
        stopScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [stopScheduleFuture]
  )

  const handleOnDelete = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to delete schedule',
            message,
            variant: 'error',
            autoHide: false,
          })
        })(() => {
          createNotification({
            title: 'Schedule successfully deleted',
            variant: 'success',
            autoHide: true,
          })
          redirect && navigate('/workloads/list/schedules')
        })
      )(
        deleteScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [deleteScheduleFuture, navigate]
  )

  const handleOnPause = useCallback(
    ({id}) => {
      pipe(
        and(getFuture),
        fork(({message}) => {
          createNotification({
            title: 'Failed to pause schedule',
            message,
            variant: 'error',
            autoHide: false,
          })
        })(() => {
          createNotification({
            title: 'Schedule successfully paused',
            message: 'Paused schedule',
            variant: 'success',
            autoHide: true,
          })
        })
      )(
        pauseScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [pauseScheduleFuture]
  )

  const handleOnResume = useCallback(
    ({id}) => {
      pipe(
        and(getFuture),
        fork(({message}) => {
          createNotification({
            title: 'Failed to resume schedule',
            message,
            variant: 'error',
            autoHide: true,
          })
        })(() => {
          createNotification({
            title: 'Schedule successfully resumed',
            variant: 'success',
            autoHide: true,
          })
        })
      )(
        resumeScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [resumeScheduleFuture]
  )

  const handleOnEdit = useCallback(({id}) => {
    navigate(`/workloads/edit/schedule/${id}`)
  }, [])

  const handleOnToggle = useCallback(
    ({id}) => {

      if (data?.state === 'ENABLED') {
        handleOnPause({id})
      }
      if (data?.state === 'PAUSED') {
        handleOnResume({id})
      }
    },
    [handleOnPause, handleOnResume, data?.state]
  )

  return (
    <ScheduleButtons
      texts={texts}
      {...data}
      pending={pending}
      onEdit={data?.state !== 'DISABLED' && handleOnEdit}
      onArchive={data?.state !== 'DISABLED' && handleOnStop}
      onDelete={data?.state === 'DISABLED' && handleOnDelete}
      onToggle={
        data?.state !== 'DISABLED' && handleOnToggle
      }
    />
  )
}

export default ScheduleSingleButtons
