import {memo, useCallback, useMemo} from 'react'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {useDispatch, useSelector} from 'react-redux'
import {path, pathOr} from 'ramda'
import {Button} from 'frontcore'
import {sidebarMutateQuery} from '../../../reducers/sql/sidebar'
import ToolTip from '../../../components/toolTip'
import {Icon} from 'frontcore'

const extractQuery = (input) =>
  new Promise((resolve, reject) => {
    input.onchange = () => {
      const filesAmount = pathOr(
        0,
        ['files', 'length'],
        input
      )
      if (filesAmount <= 0) {
        reject('There were no files attached')
        return
      }
      const file = input.files[0]
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = (event) => {
        const query = Object.freeze({
          name: file.name,
          statement: event.target.result,
        })
        resolve(query)
      }
      reader.onerror = () => {
        reject('Failure while reading the file')
      }
    }
  })

const FileImportExport = () => {
  const queryId = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenQuery'])
  )
  const queries = useSelector(
    path(['sqlViewer', 'sidebar', 'queries'])
  )
  const query = useMemo(() => {
    return queries.find(({id}) => {
      return id === queryId
    })
  }, [queryId, queries])

  const dispatch = useDispatch()
  const loadQuery = useCallback(
    (query) => {
      dispatch(sidebarMutateQuery({
        id: queryId,
        ...query,
      }))
    },
    [queryId]
  )

  const {createNotification} = useNotificationContext()

  const handleDownload = useCallback(
    (event) => {
      try {
        event.stopPropagation()
        const link = document.createElement('a')
        const content = encodeURIComponent(query.statement)
        const filename = `${query.name}.sql`
        link.setAttribute(
          'href',
          'TableExplorerView:text/plain;charset=utf-8,' + content
        )
        link.setAttribute('download', filename)
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        createNotification({
          title: `Query "${filename}" was downloaded`,
          message: 'Check your browser downloads folder',
          variant: 'success',
          autoHide: true,
        })
      } catch {
        createNotification({
          title: 'Download failure',
          message:
            "For some reason, your SQL query wasn't downloaded. Please, try again",
          variant: 'error',
          autoHide: true,
        })
      }
    },
    [query, createNotification]
  )

  const handleUpload = useCallback(
    async (event) => {
      try {
        event.stopPropagation()
        const input = document.createElement('input')
        input.type = 'file'
        input.style.display = 'none'
        document.body.appendChild(input)
        input.click()
        document.body.removeChild(input)
        const query = await extractQuery(input)
        loadQuery(query)
        createNotification({
          title: `Query "${query.name}" uploaded`,
          message: 'Check the code in your editor',
          variant: 'success',
          autoHide: true,
        })
      } catch {
        createNotification({
          title: 'Upload failure',
          message:
            "For some reason, your SQL query wasn't loaded into the editor. Please, try again",
          variant: 'error',
          autoHide: true,
        })
      }
    },
    [loadQuery, createNotification]
  )

  return (
    <>
      <ToolTip
        config={{position: 'bottom_right'}}
        content={
          <div style={{padding: 8}}>
            Upload from local filesystem
          </div>
        }
      >
        <Button
          onClick={handleUpload}
          square
          variant="outlined"
          StartComponent={Icon}
          justifyContent="center"
          startComponentProps={{
            icon: 'pencil',
            size: 16,
          }}
        />
      </ToolTip>

      <ToolTip
        config={{position: 'bottom_right'}}
        content={
          <div style={{padding: 8}}>
            Download to your computer
          </div>
        }
      >
        <Button
          onClick={handleDownload}
          square
          variant="outlined"
          StartComponent={Icon}
          justifyContent="center"
          startComponentProps={{
            icon: 'download',
            size: 16,
          }}
        />
      </ToolTip>
    </>
  )
}

export default memo(FileImportExport)
