import {Button, Icon} from 'frontcore';
import {map} from 'ramda';
import ModuleCard from './ModuleCard';
import {useCallback} from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModulesContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  padding: 32px 0 0 0;
`;

const Section = ({id, data = [], expanded, onClickExpand}) => {

  const handleOnClickExpand = useCallback(() => {
    onClickExpand(id);
  }, [id, onClickExpand]);

  return <Root>
    <Button
      StartComponent={Icon}
      startComponentProps={{icon: 'arrow-down', size: 24}}
      tabIndex={-1}
      justifyContent={'flex-start'}
      onClick={handleOnClickExpand}
      fullWidth
      variant={'text'}
    >{id}</Button>
    {expanded && <ModulesContainer>
      {map(module => <ModuleCard module={module} />, data)}
    </ModulesContainer>}
  </Root>;
};

export default Section;
