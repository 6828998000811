import {pathOr} from 'ramda'
import { memo, useCallback } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import NoResultFound from '../../../../components/NoResultFound'
import {resultsToggleWrap} from '../../../../reducers/sql/results'
import ToolTip from '../../../../components/toolTip'
import {Button} from 'frontcore'

const Root = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette['surface-primary']};
  padding: 20px 20px;
  overflow: auto;
  position: absolute;
  top: 0; bottom: 0;
  right: 0; left: 0;
`

const Container = styled.div`
  font-size: 16px;
  color: grey;
  p {
    margin: 0; padding: 0;
    font-family: monospace;
    font-size: 14px;
    text-wrap: ${({ $wrap }) => $wrap ? 'wrap' : 'nowrap'};
  }
`

const WrapButtonContainer = styled.div`
  position: fixed;
  bottom: 8px;
  right: 8px;
  background-color: ${({ theme }) => theme.palette['surface-primary']};
`

const Logs = () => {
  const dispatch = useDispatch()

  const logs = useSelector(pathOr([], ['sqlViewer', 'results', 'data', 'result', 'response', 'logs']))

  const wrapEnabled = useSelector(pathOr(true, ['sqlViewer', 'results', 'logsWrap']))
  const handleToggleWrap = useCallback(() => {
    dispatch(resultsToggleWrap())
  }, [dispatch])

  return (
    <Root>
      { logs 
        ? (
          <Container $wrap={wrapEnabled}>
            {logs.map((log, index) => (
              <p key={index}>{log}</p>
            ))}
          </Container>
        ) 
        : (
          <NoResultFound
            title='No logs are available'
            text='This query left no logs after execution'
          />
        )
      }
      <WrapButtonContainer>
        <ToolTip content={(
          <div style={{ padding: 8 }}>
            {wrapEnabled ? (<>
              When wrap is <b>enabled</b>,
              long lines will be shown as
              multiple ones
            </>) : (<>
              When wrap is <b>disabled</b>,
              long lines will be scrollable.
            </>)}
          </div>
        )}>
          <Button
            onClick={handleToggleWrap}
            variant='outlined'
          >{wrapEnabled ? 'Wrap' : 'No wrap'}</Button>
        </ToolTip>
      </WrapButtonContainer>
    </Root>
  )
}

export default memo(Logs)
