import React, {useEffect, useMemo, useState} from 'react'
import {fork} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {path, pathOr, pipe, propOr} from 'ramda'
import SlideTransition from '../../../../components/transitions/slideTransition'
import {Tabs} from 'frontcore'
import getService from '../../../../api/workloads/services/getService'
import {EMPTY_FUNCTION, EMPTY_OBJECT} from '../../../../constants'
import {CountMiniature} from '../../../../components/miniatures'
import {initService, setBreadCrumbs} from '../../../../reducers/workloads'
import DateCellRow from '../../dateCellRow'
import usePending from '../../../../hooks/usePending'
import {useParams} from 'react-router-dom'
import ServiceState from '../../../../components/ServiceState'
import DetailsLayout from '../../../../components/layouts/detailsLayout'
import ServiceDetailsJobsList from './ServiceDetailsJobsList'
import ServiceDetailsRequestsList from './ServiceDetailsRequestsList'
import {convertObjectToArrayFn} from '../../../../helpers'
import ServiceParameters from './ServiceParameters'
import ServiceTags from './ServiceTags'
import ServiceSingleButtons from '../../Components/ServiceSingleButtons'
import ServiceDetailsExecuteJob from './ServiceDetailsExecuteJob'
import DetailsHeader from '../../../../components/DetailsHeader'
import ServiceDetailsMemory from './ServiceDetailsMemory'
import ResourceIcon from '../../../../components/Icons/ResourceIcon'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useHistoryContext} from "../../../../hooks/useHistoryContext";

const TabComponent = ({active, type, value, label}) => (
  <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
    {label}
    {value !== undefined && <CountMiniature value={value} active={active} type={type} />}
  </div>
)

const WorkloadsServiceDetailsView = () => {
  const {serviceId} = useParams()
  const [tab, setTab] = useState('jobs')

  const getServiceFuture = useFuture(getService)

  const dispatch = useDispatch()
  useEffect(() => {
    return () => dispatch(initService())
  }, [])

  const group = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'service', 'response']))

  const {createNotification} = useNotificationContext()

    const {history, addHistory} = useHistoryContext()

  useEffect(() => {
    if (serviceId) {
      fork(({message}) => {
        createNotification({
          title: 'Failed to fetch Service',
          message,
          variant: 'error',
          autoHide: true,
        })
      })((data) => {
        const {id, name, clusterId, clusterName} = data
          addHistory({type: 'service', id: serviceId})
        dispatch(
          setBreadCrumbs({
            cluster: {
              id: clusterId,
              name: clusterName,
            },
            group: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getServiceFuture({
          params: [{key: 'id', value: serviceId}],
        })
      )
    }
  }, [serviceId])

  const parameters = useMemo(
    () => pipe(propOr(EMPTY_OBJECT, 'jobConfig'), convertObjectToArrayFn('key', 'value'))(group),
    [group]
  )

  const tags = useMemo(
    () => pipe(propOr(EMPTY_OBJECT, 'tags'), convertObjectToArrayFn('key', 'value'))(group),
    [group]
  )

  const memory = useMemo(
    () =>
      pipe(propOr(EMPTY_OBJECT, 'memorySettings'), convertObjectToArrayFn('key', 'value'))(group),
    [group]
  )

  const pending = usePending(
    ['workloads', 'data'],
    ['scaleService', 'archiveService', 'deleteService', 'service']
  )

  const topContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'service'} />}
      id={group?.id}
      name={group?.name}
      pending={pending}
      data={[
        {
          key: 'type',
          value: group?.kind,
        },
        {
          key: 'language',
          value: group?.language,
        },
        {
          key: 'created time',
          value: group?.createdTime,
          Component: DateCellRow,
        },
        {
          key: 'State',
          value: group?.state,
          Component: ServiceState,
        },
      ]}
    />
  )

  const middleContent = (
    <>
      <Tabs
        value={tab}
        onChange={setTab}
        options={[
          {
            id: 'jobs',
            Component: TabComponent,
            componentProps: {
              type: 'job',
              label: 'Jobs',
              value: group.jobsCount,
            },
          },
          {
            id: 'requests',
            Component: TabComponent,
            componentProps: {
              type: 'request',
              label: 'Requests',
              value: group.totalRequests,
            },
          },
          {
            id: 'parameters',
            Component: TabComponent,
            componentProps: {
              type: 'default',
              label: 'Parameters',
              value: parameters.length,
            },
          },
          {
            id: 'tags',
            Component: TabComponent,
            componentProps: {
              type: 'default',
              label: 'Tags',
              value: tags.length,
            },
          },
          {
            id: 'memory',
            Component: TabComponent,
            componentProps: {
              type: 'default',
              label: 'Memory',
            },
          },
          {
            id: 'executeJob',
            Component: TabComponent,
            componentProps: {
              type: 'default',
              label: 'Execute Job',
            },
          },
        ]}
      />
      <ServiceSingleButtons
        pending={pending}
        data={group}
        getFuture={getServiceFuture({
          params: [{key: 'id', value: serviceId}],
        })}
        redirect={true}
      />
    </>
  )

  const bottomContent = (
    <SlideTransition trigger={tab} duration={150}>
      {tab === 'jobs' && <ServiceDetailsJobsList serviceId={serviceId} />}
      {tab === 'requests' && <ServiceDetailsRequestsList serviceId={serviceId} />}
      {tab === 'parameters' && <ServiceParameters data={parameters} />}
      {tab === 'tags' && <ServiceTags data={tags} />}
      {tab === 'executeJob' && <ServiceDetailsExecuteJob data={group} />}
      {tab === 'memory' && <ServiceDetailsMemory data={memory} />}
    </SlideTransition>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default WorkloadsServiceDetailsView
