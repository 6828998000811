import Configuration from '../../../../../components/configuration'
import React, {useEffect, useMemo} from 'react'
import {
  evolve,
  length,
  map,
  pathOr,
  pick,
  pipe,
  prop,
  propOr,
  when,
} from 'ramda'
import {isNotNull} from '../../../../../helpers'
import {formatUrlParam} from '../../../helpers'
import useFuture from '../../../../../hooks/useFuture'
import getDatasetVersions from '../../../../../api/lineage/getDatasetVersions'
import {fork} from 'fluture'
import {useSelector} from 'react-redux'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../../../../constants'

const DatasetVersions = () => {
  const model = useSelector(prop('lineage'))
  const selectedNode = useMemo(
    () => propOr(EMPTY_OBJECT, 'selectedNode', model),
    [model]
  )

  const {name, namespace} = selectedNode

  const getDatasetVersionsFuture = useFuture(
    getDatasetVersions
  )

  const versions = useMemo(
    () =>
      pathOr(
        EMPTY_ARRAY,
        ['data', 'datasetVersions', 'response', 'versions'],
        model
      ),
    [model]
  )

  useEffect(() => {
    fork((message) => {})(() => {})(
      getDatasetVersionsFuture({
        params: [
          {
            key: 'namespace',
            value: formatUrlParam(namespace),
          },
          {
            key: 'dataset',
            value: formatUrlParam(name),
          },
        ],
      })
    )
  }, [namespace, name])

  const extendedData = useMemo(
    () =>
      pipe(
        map(
          pick([
            'version',
            'createdAt',
            'fields',
            'createdByRun',
            'lifecycleState',
          ])
        ),
        map(
          when(
            ({createdByRun}) => isNotNull(createdByRun),
            evolve({createdByRun: ({id}) => id})
          )
        )
      )(versions),
    [versions]
  )

  return (
    <div
      style={{
        padding: 8,
        overflow: 'hidden',
        height: '100%',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <Configuration
        data={extendedData}
        schema={[
          {
            id: 'version',
            label: 'version',
            copy: true,
          },
          {
            id: 'createdAt',
            label: 'Created At',
            copy: false,
          },
          {
            id: 'fields',
            label: 'DataSetFields',
            copy: false,
            Component: ({value = []}) => length(value),
          },
          {
            id: 'createdByRun',
            label: 'Created By Run',
            copy: true,
          },
          {
            id: 'lifecycleState',
            label: 'Lifecycle State',
            copy: false,
          },
        ]}
      />
    </div>
  )
}

export default DatasetVersions
