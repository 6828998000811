import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
`

export const LeftContainer = styled.div`
    width: 256px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

export const RightContainer = styled.div`
    flex: 1;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const SearchContainer = styled.div`
    gap: 8px;
  
    display: flex;
`

export const DetailsContainer = styled.div`
  
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    padding: 16px;
    justify-content: space-between;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 800;
  font-family: Inter, serif;
  color: ${({theme}) => theme.palette.text.tertiary};
`

export const Border = styled.div`
    background-color: ${({theme}) =>
            theme.palette['surface-primary']};
    border-color: ${({theme}) => theme.palette.line};
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    gap: 2px;
    display: flex;
    flex-direction: column;
`