import React from 'react';

import styled from 'styled-components';
import {reverse} from 'ramda';
import {mapWithKey} from '../../helpers';
import {useNotificationContext} from '../../hooks/useNotificationsContext';
import {Transition, TransitionGroup} from 'react-transition-group';
import Notification from '../notification';

const Root = styled.div`
    pointer-events: none;
    position: absolute;
    color: white;
    top: 0;
    bottom: 0;
    left: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    padding-top: 8px;
`

const Notifications = () => {
  const { notifications, closeNotification } = useNotificationContext();

  return (
    <Root>
      <TransitionGroup appear={true} component={null}>
        {mapWithKey(
          (notification, index) => (
            <Transition key={notification.id} timeout={300}>
              {(state) => (
                <Notification
                  key={notification.id}
                  id={notification.id}
                  state={state}
                  index={index}
                  title={notification.title}
                  variant={notification.variant}
                  closeNotification={closeNotification}
                  message={notification.message}
                  autoHide={notification.autoHide}
                />
              )}
            </Transition>
          ),
          reverse(notifications)
        )}
      </TransitionGroup>
    </Root>
  );
};

export default Notifications;
