import RadioButton from "../RadioButton";
import { EMPTY_ARRAY } from "../../constants";
import styled, { css } from "styled-components";
import { mapWithKey } from "../../helpers";
import {useCallback} from "react";
import {isNil} from "ramda";

const RadioButtons = ({
  options = EMPTY_ARRAY,
  value,
  onChange,
  fullWidth = true,
  disabled = false,
}) => {
  const handleOnChange = useCallback((...args) => {
    if (isNil(onChange) || disabled) return
    onChange(...args)
  }, [disabled, onChange])

  return (
    <Root $fullWidth={fullWidth}>
      {mapWithKey(
        (option, index) => (
          <RadioButton
            // added key to supress react warnings
            key={index}
            {...option}
            disabled={disabled && option.id !== value}
            value={option.id === value}
            onClick={handleOnChange}
          />
        ),
        options,
      )}
    </Root>
  );
};

export default RadioButtons;

const Root = styled.div`
  display: flex;
  gap: 32px;
  ${({ $fullWidth }) =>
  $fullWidth && css`
    width: 100%;
  `}
`;
