import React from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import styled, { css } from "styled-components";
import { createTransitions } from "../../helpers";

const Root = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
`;

const entering = css`
  opacity: 0;
`;

const entered = css`
  opacity: 1;
`;

const exiting = css`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const exited = css`
  opacity: 0;
`;

const stateMap = {
  entering,
  entered,
  exiting,
  exited,
};

const Container = styled.div`
  transition: ${({ duration }) => createTransitions(["opacity"], duration)};
  height: 100%;
  ${({ state }) => stateMap[state]};
`;

const FadeTransition = ({ children, trigger, duration = 150 }) => {
  return (
    <Root>
      <TransitionGroup component={null}>
        <Transition key={trigger} timeout={{ exit: duration}}>
          {(state) => (
            <Container state={state} duration={duration}>
              {children}
            </Container>
          )}
        </Transition>
      </TransitionGroup>
    </Root>
  );
};

export default FadeTransition;
