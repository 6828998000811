import { Input, Icon, Select } from 'frontcore'

const schema = Object.freeze([
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Schedules Search',
      topLabelProps: {
        children: 'Schedules Search',
      }
    }
  },
  {
    id: 'scheduleStates',
    Component: Select,
    defaultValue: [],
    props: {
      label: 'Schedule States',
      size: 'small',
      value: [],
      options: ['ENABLED', 'DISABLED'],
      multiSelect: true,
    },
  },
])

export default schema
