import Filters from '../../../filters'
import JobsList from '../../../Components/JobsList'
import ListLayout from '../../../../../components/layouts/listLayout'
import React, {useCallback} from 'react'
import schema from './schema'
import usePending from '../../../../../hooks/usePending'
import {useListRefreshFuture} from '../../../hooks'
import getJobs from '../../../../../api/workloads/jobs/getJobs'
import {EMPTY_OBJECT} from '../../../../../constants'
import JobBatchButtons from '../../../Components/JobBatchButtons'
import {
    createSearchParams,
    useNavigate,
} from 'react-router-dom'
import {Button, Icon} from 'frontcore'
import {isNotEmpty} from '../../../../../helpers'

const ServiceDetailsJobsList = ({serviceId}) => {
    const navigate = useNavigate()


    const listHandler = useListRefreshFuture(
        getJobs,
        'workloads',
        'jobs',
        {
            page: 0,
            size: 25,
            sort: {
                submitTime: 'desc',
            },
            filter: EMPTY_OBJECT,
        },
        {groupIds: [serviceId]}
    )

    const {selected} = listHandler

    const pending = usePending(
        ['workloads', 'data'],
        [
            'jobsDetails',
            'killJob',
            'deleteJob',
            'scaleGroup',
            'archiveGroup',
            'deleteGroup',
            'service'
        ]
    )

    const handleOnClickNewJobButton = useCallback(() => {
        navigate({
            pathname: `/workloads/create/job`,
            search: createSearchParams({
                cluster: 'sss',
            }).toString(),
        })
    }, [])

    return (
        <ListLayout
            batchButtonsNode={
                isNotEmpty(selected) && (
                    <JobBatchButtons {...listHandler} />
                )
            }
            contentNode={
                <JobsList
                    listHandler={listHandler}
                    borderTop={true}
                    pending={pending}
                />
            }
            createButtonNode={
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleOnClickNewJobButton}
                    EndComponent={Icon}
                    endComponentProps={{
                        icon: 'plus',
                        size: 18,
                        color: 'white',
                    }}
                >
                    New Job
                </Button>
            }
            filtersNode={
                <Filters
                    name={'jobs'}
                    schema={schema}
                    pending={pending}
                />
            }
        />
    )
}

export default ServiceDetailsJobsList
