import styled, {css} from 'styled-components'
import React from 'react'
import {map, path} from 'ramda'
import {EMPTY_ARRAY, EMPTY_STRING} from '../../../constants'
import {c} from '@codemirror/legacy-modes/mode/clike'
import {lab} from 'd3'
import {mapWithKey} from '../../../helpers'
import {useSelector} from 'react-redux'

const Root = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
    color: ${({theme}) => theme.palette.text.tertiary};
`

const Label = styled.div`
    font-size: 10px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
`

const BarContainer = styled.div`
    box-sizing: border-box;

    gap: 4px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
        // border-color: ${({theme}) =>
            theme.palette.neutral[200]};
`

const progressCss = css`
    width: 300px;
    height: 16px;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    border-radius: 2px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
`

const ProgressBar = styled.div`
    width: 300px;
    height: 16px;
    padding-left: 4px;
    padding-right: 4px;
    background: ${({theme}) => theme.palette['surface-secondary']};
    position: relative;
    ${progressCss}
`

const Background = styled.div`
    ${progressCss}
`

const Foreground = styled.div`
    ${progressCss};
    background: ${({theme, color}) =>
            theme.palette[color][600]};
    color: white;
    padding-left: 4px;
    padding-right: 4px;
`

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: ${({value}) => value};
    overflow: hidden;
`


const Wrapper = ({name, label, max, value}) => {
    const {warningThreshold, criticalThreshold} = useSelector(
        path(['settings', 'settings'])
    )

    const extendedValue = (value * 100) / max.value || 0
    return (
        <ProgressBar>
            <Background>
                <div>{name}</div>
                <div>{label + ' / ' + max.label}</div>
                <div>{Math.round(extendedValue)}%</div>
            </Background>
            <Container value={extendedValue + '%'}>
                <Foreground
                    color={
                        extendedValue >= Number(criticalThreshold)
                            ? 'red'
                            : extendedValue >= Number(warningThreshold)
                                ? 'yellow'
                                : 'blue'
                    }
                >
                    <div>{name}</div>
                    <div>{label + ' / ' + max.label}</div>
                    <div>{Math.round(extendedValue)}%</div>
                </Foreground>
            </Container>
        </ProgressBar>
    )
}

const SingleMetricBarChart = ({
                                  label = EMPTY_STRING,
                                  values = EMPTY_ARRAY,
                                  max,
                              }) => {
    return (
        <Root>
            <Label>
                <div>{label}</div>
            </Label>

            <BarContainer>
                {mapWithKey(
                    ({key, label, value}, index) => (
                        <Wrapper
                            key={key}
                            name={key}
                            label={label}
                            value={value}
                            index={index}
                            max={max}
                        />
                    ),
                    values
                )}
            </BarContainer>
        </Root>
    )
}

export default SingleMetricBarChart
