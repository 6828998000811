import { createSlice } from '@reduxjs/toolkit'

import { EMPTY_STRING } from '../../constants'

const initialState = {
  chosenModule: null,
  overlayOpened: false,
  filterInput: EMPTY_STRING
}

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setChosenModule: (state, { payload }) => ({
      ...state,
      chosenModule: payload,
      overlayOpened: true
    }),
    closeOverlay: state => ({
      ...state,
      overlayOpened: false
    }),
    setFilterInput: (state, { payload }) => ({
      ...state,
      filterInput: payload
    })
  }
})

export const { 
  setChosenModule,
  closeOverlay,
  setFilterInput
} = modulesSlice.actions
export default modulesSlice.reducer
