import asyncThunk from "../../index";
import { path, prop } from "ramda";

export const getStagesMetrics = asyncThunk({
  method: "GET",
  type: "stagesExecutors/get",
  path: "/job/${jobId}/metrics/executors",
});

export default getStagesMetrics;
