import ReactJson from 'react-json-view'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {prop, propOr} from 'ramda'
import {EMPTY_OBJECT} from '../../../../../constants'
import Json from "../../../../../components/Json";

const Raw = () => {
  const model = useSelector(prop('lineage'))
  const selectedNode = useMemo(
    () => propOr(EMPTY_OBJECT, 'selectedNode', model),
    [model]
  )

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 0,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: 1,
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          overflow: 'auto',
          padding: 16,
          boxSizing: 'border-box',
        }}
      >
        <Json src={selectedNode} />
      </div>
    </div>
  )
}

export default Raw
