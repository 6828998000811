import {useCallback, useEffect, useState} from 'react'
import {notEquals} from '../helpers'

const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState()

  useEffect(() => {
    window.addEventListener("storage", function (event,b,c) {
      if (event.key === key) {
        try {
          let result = JSON.parse(event.newValue)
          setState(result)
        } catch(error) {
          console.error(error)
        }
      }
    }, false);
  }, [key]);

  useEffect(() => {
    let result

    const string = localStorage.getItem(key)
    if (string) {
      try {
        result = JSON.parse(string)
      } catch (error) {
        result = null
        console.error(error)
      }
    } else {
      result = defaultValue
    }
    setState(result)
  }, [key])

  const handleSetState = useCallback(
    (valueOrFn) => {
      let nextValue
      if (typeof valueOrFn === 'function') {
        nextValue = valueOrFn(state)
      } else {
        nextValue = valueOrFn
      }

      if (notEquals(nextValue, state)) {
        const string = JSON.stringify(nextValue)
        setState(nextValue)
        localStorage.setItem(key, string)
      }
    },
    [state]
  )

  return [state, handleSetState]
}

export default useLocalStorageState
