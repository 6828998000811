import { memo, useCallback, useMemo } from 'react'
import { Button } from 'frontcore'
import ToolTip from '../../../components/toolTip'
import {useDispatch, useSelector} from 'react-redux'
import {path} from 'ramda'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import useFuture from '../../../hooks/useFuture'
import createSQLStatement from '../../../api/sql/createSQLStatement'
import saveSQLStatement from '../../../api/sql/saveSQLStatement'
import {fork} from 'fluture'
import {sidebarQueryMakeRemote} from '../../../reducers/sql/sidebar'
import saveSQLNotebook from '../../../api/sql/saveSQLNotebook'
import updateSQLNotebook from '../../../api/sql/updateSQLNotebook'

const EditorSaveQuery = () => {
  const chosenQuery = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryDetails = useMemo(() => {
    return queries.find(({ id }) => id === chosenQuery)
  }, [chosenQuery, queries])

  const { createNotification } = useNotificationContext()
  const handleError = useCallback(({ message }) => {
    createNotification({
      title: 'Error',
      message,
      variant: 'error',
      autoHide: true,
    })
  }, [])

  const dispatch = useDispatch()

  const chosenEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const maxRows = useSelector(path(['sqlViewer', 'editor', 'maxRows']))

  const createSQLStatementFuture = useFuture(createSQLStatement)
  const createQuery = useCallback(() => {
    const future = createSQLStatementFuture({
      name: queryDetails.name,
      statement: queryDetails.statement,
      engine: chosenEngine,
      maxRows,
    })
    fork(handleError)(remoteDetails => {
      createNotification({
        title: 'Success',
        message: 'Saved query on server',
        variant: 'success',
        autoHide: true,
      })
      dispatch(sidebarQueryMakeRemote({
        localId: queryDetails.id,
        remote: remoteDetails,
      }))
    })(future)
  }, [queryDetails, chosenEngine, maxRows])

  const createSQLNotebookFuture = useFuture(saveSQLNotebook)
  const createNotebook = useCallback(() => {
    const future = createSQLNotebookFuture({
      name: queryDetails.name,
      statements: queryDetails.statements.map(cell => {
        return {
          ...cell,
          language: cell?.language?.toUpperCase() ?? 'SQL',
        }
      }),
    })
    fork(handleError)(remoteDetails => {
      createNotification({
        title: 'Success',
        message: 'Saved notebook on server',
        variant: 'success',
        autoHide: true,
      })
      dispatch(sidebarQueryMakeRemote({
        localId: queryDetails.id,
        remote: {
          ...remoteDetails,
          statements: remoteDetails.statements.map(cell => {
            return {
              ...cell,
              language: cell?.language?.toLowerCase() ?? 'sql',
            }
          })
        },
      }))
    })(future)
  }, [queryDetails])

  const saveSQLStatementFuture = useFuture(saveSQLStatement)
  const saveQuery = useCallback(() => {
    if (queryDetails.local) return
    const future = saveSQLStatementFuture({
      params: [{ key: 'queryId', value: queryDetails.id }],
      ...queryDetails,
      engine: chosenEngine,
      maxRows,
    })
    fork(handleError)(() => {
      createNotification({
        title: 'Success',
        message: 'Updated query on the server',
        variant: 'success',
        autoHide: true,
      })
    })(future)
  }, [queryDetails, chosenEngine, maxRows])

  const updateSQLNotebookFuture = useFuture(updateSQLNotebook)
  const saveNotebook = useCallback(() => {
    if (queryDetails.local) return
    const future = updateSQLNotebookFuture({
      params: [{ key: 'notebookId', value: queryDetails.id }],
      id: queryDetails.id,
      name: queryDetails.name,
      statements: queryDetails.statements.map(cell => {
        return {
          ...cell,
          language: cell?.language?.toUpperCase() ?? 'SQL',
        }
      }),
    })
    fork(handleError)(() => {
      createNotification({
        title: 'Success',
        message: 'Updated notebook on the server',
        variant: 'success',
        autoHide: true,
      })
    })(future)
  }, [queryDetails])

  const handleGeneralSave = useCallback(() => {
    if (queryDetails.local) {
      if (queryDetails.type === 'notebook') {
        createNotebook()
      } else {
        createQuery()
      }
    } else {
      if (queryDetails.type === 'notebook') {
        saveNotebook()
      } else {
        saveQuery()
      }
    }
  }, [queryDetails])

  return (
    <ToolTip
      config={{position: 'bottom_right'}}
      content={
        <div style={{padding: 8}}>
          Query will be saved to remote database
        </div>
      }
    >
      <Button onClick={handleGeneralSave} variant="outlined">
        Save
      </Button>
    </ToolTip>
  )
}

export default memo(EditorSaveQuery)
