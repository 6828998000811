import asyncThunk from './index';

export const getElements = asyncThunk({
  method: "GET",
  type: "elements/get",
  path: "/minor",
  api: true
});

export default getElements;
