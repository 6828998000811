import React from 'react'

const DatasetColumn = ({name, type}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        height: 24,
      }}
    >
      <div>- {name}</div>
      <div>{type}</div>
    </div>
  )
}

export default DatasetColumn;