import {ResizeLayout} from 'frontcore'
import Line from '../line'
import {VERTICAL} from '../../constants'
import {Outlet} from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`
const TabsContainer = styled.div`
   display: flex;
  align-items: center;
  gap: 16px;
    padding-left: 16px;
    height: 48px;
`
const MetricsContainer = styled.div`
  position: relative;
  display: flex;
  padding: ${({$show}) => ($show ? '16px 16px' : 0)};
  box-sizing: border-box;
`

const ListViewLayout = ({tabsNode, metricsNode}) => {
  return (
    <Root>
      <TabsContainer>{tabsNode}</TabsContainer>
      <Line />
      <Outlet />
    </Root>
  )
}

export default ListViewLayout
