import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {
  __,
  add,
  clamp,
  isEmpty,
  isNil,
  length,
  modulo,
  or,
  path,
  pipe,
  toLower,
  toUpper,
  when,
} from 'ramda'
import {mapWithKey} from '../../../helpers'
import {DescriptionText} from '../../typography'
import {useSearchResults} from '../hooks/useSearchResults'
import {useHistoryContext} from '../../../hooks/useHistoryContext'
import {EMPTY_ARRAY} from '../../../constants'
import RButton from '../../RButton'
import StyledLink from '../../StyledLink'
import {LetterMiniature} from '../../miniatures'
import Chip from '../../Chip'
import UserIcon from '../../Icons/UserIcon'

const workloadTypes = ['cluster', 'service', 'schedule', 'job', 'request']
const securityTypes = ['user', 'group', 'role', 'activity']
const sqlTypes = ['sql']
const moduleTypes = ['module']

const ResultsContainer = styled.div`
  user-select: ${({pending}) => (pending ? 'none' : 'auto')};
  overflow-y: auto;
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 16px;
  box-sizing: border-box;
  flex-direction: column;
`


const SectionTitle = styled.div`
  font-size: 14px;
  flex: 1;
  height: 24px;
  font-weight:  ${({active, theme}) => active ? 600 : 400};
  background: ${({active, theme}) => active && theme.palette.neutral[100]};
  align-items: center;
  color: ${({theme}) => theme.palette.neutral[700]};
  display: flex;
  text-transform: uppercase;
  padding: 0 8px;
`

const ButtonWrapper = ({url, name, type,id, active}) => {
  if (workloadTypes.includes(type)) {
    return (
      <StyledLink to={url}>
        <RButton
          name={name || id}
          type={type}
          active={active}
          IconComponent={LetterMiniature}
          iconComponentProps={{type: type}}
        />
      </StyledLink>
    )
  }

  if (type === 'user') {
    return (
      <StyledLink to={url}>
        <RButton
          name={name}
          type={type}
          active={active}
          IconComponent={({name}) => (
            <UserIcon color="#FF8B96">{toUpper(name?.substring(0, 1) || '')}</UserIcon>
          )}
          iconComponentProps={{type: 'USER', name}}
        />
      </StyledLink>
    )
  }

  if (['group', 'role', 'activity'].includes(type)) {
    const typeMap = {
      'group': 'GROUP',
      'role': 'ROLE',
      'activity': 'ACTIVITY'
    }
    
    return (
      <StyledLink to={url}>
        <RButton
          name={name}
          type={type}
          active={active}
          IconComponent={Chip}
          iconComponentProps={{type: typeMap[type], children: name}}
        />
      </StyledLink>
    )
  }

  if (type === 'sql') {
    return (
      <StyledLink to={url}>
        <RButton name={name} type={type} active={active} icon={'database'} />
      </StyledLink>
    )
  }

  if (type === 'module') {
    const moduleIconMap = {
      Gitea: 'git3',
      Jupyter: 'jupyter',
      Minio: 'minio',
      Grafana: 'grafana',
      MLFlow: 'mlflow',
      Airflow: 'apacheairflow',
      Superset: 'superset',
    }

    return (
      <StyledLink to={url}>
        <RButton name={name} type={type} active={active} icon={moduleIconMap[name]} />
      </StyledLink>
    )
  }

  return (
    <StyledLink to={url}>
      <RButton name={name} type={type} active={active} />
    </StyledLink>
  )
}

const SearchResults = ({closeFn, search}) => {
  const [activeList, setActiveList] = useState(0)
  const [activeItem, setActiveItem] = useState(0)
  const activeItemRef = useRef(activeItem)
  const activeListRef = useRef(activeList)
  const navigate = useNavigate()

  const {history} = useHistoryContext()

  const {data: searchResults, pending} = useSearchResults(search)



  const groupedResults = useMemo(() => {
    const groups = {
      workloads: searchResults.filter((item) => workloadTypes.includes(item.data.type)),
      security: searchResults.filter((item) => securityTypes.includes(item.data.type)),
      sql: searchResults.filter((item) => sqlTypes.includes(item.data.type)),
      modules: searchResults.filter((item) => moduleTypes.includes(item.data.type)),
    }

    return Object.entries(groups)
      .filter(([_, items]) => !isEmpty(items))
      .map(([name, data]) => ({name, data}))
  }, [searchResults])

  const historyResults = useMemo(() => {
    const groups = {
      workloads: history.filter((item) => workloadTypes.includes(item.data.type)),
      security: history.filter((item) => securityTypes.includes(item.data.type)),
      sql: history.filter((item) => sqlTypes.includes(item.data.type)),
      modules: history.filter((item) => moduleTypes.includes(item.data.type)),
    }

    return Object.entries(groups)
      .filter(([_, items]) => !isEmpty(items))
      .map(([name, data]) => ({name, data}))
  }, [history])

  const extendedData = isNil(search) || isEmpty(search) ? historyResults : groupedResults

  useEffect(() => {
    activeItemRef.current = activeItem
  }, [activeItem])

  useEffect(() => {
    activeListRef.current = activeList
  }, [activeList])

  useEffect(() => {
    setActiveItem(0)
    setActiveList(0)
  }, [search])

  const dataRef = useRef(extendedData)

  useEffect(() => {
    dataRef.current = extendedData
  }, [extendedData])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.code === 'Space') return

      if (isEmpty(groupedResults)) return

      if (event.key === 'ArrowDown') {
        const {data} = dataRef.current[activeListRef.current] || EMPTY_ARRAY
        const rowSize = length(data)
        const result = pipe(add(1), modulo(__, rowSize), or(__, 0))
        setActiveItem(result)
      }
      if (event.key === 'ArrowUp') {
        const {data} = dataRef.current[activeListRef.current] || EMPTY_ARRAY
        const rowSize = length(data)
        const result = pipe(
          add(-1),
          when(
            (a) => a < 0,
            (a) => rowSize - 1
          ),
          modulo(__, rowSize),
          or(__, 0)
        )
        setActiveItem(result)
      }
      if (event.key === 'ArrowLeft') {
        const data = dataRef.current
        const columnsSize = length(data)
        const nextColumnFn = pipe(
          add(-1),
          when(
            (a) => a < 0,
            (a) => columnsSize - 1
          ),
          modulo(__, columnsSize)
        )
        const nextList = dataRef.current[nextColumnFn(activeListRef.current)]?.data || []
        const maxLimit = length(nextList) === 0 ? length(nextList) : length(nextList) - 1
        const nextItemIndex = clamp(0, maxLimit, activeItemRef.current)
        setActiveItem(() => nextItemIndex)
        setActiveList(nextColumnFn)
      }
      if (event.key === 'ArrowRight') {
        const data = dataRef.current
        const columnsSize = length(data)
        const nextColumnFn = pipe(
          add(1),
          when(
            (a) => a < 0,
            (a) => columnsSize - 1
          ),
          modulo(__, columnsSize)
        )
        const nextList = dataRef.current[nextColumnFn(activeListRef.current)]?.data || []
        const maxLimit = length(nextList) === 0 ? length(nextList) : length(nextList) - 1
        const nextItemIndex = clamp(0, maxLimit, activeItemRef.current)
        setActiveItem(() => nextItemIndex)
        setActiveList(pipe(add(1), modulo(__, columnsSize), Math.abs))
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        const link = path(
          ['current', activeListRef?.current, 'data', activeItemRef?.current, 'url'],
          dataRef
        )

        navigate(link)
        closeFn()
      }
    },
    [navigate, closeFn]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  if (isEmpty(groupedResults)) return null

  return (
    <ResultsContainer pending={pending}>
      <DescriptionText>{search ? 'Search Result' : 'Recently Visited'}</DescriptionText>
      <div style={{display: 'flex', gap: 8}}>
        {mapWithKey(
          ({name}, index) => (


              <SectionTitle  key={name} active={activeList === index}>{name}</SectionTitle>

          ),
          extendedData
        )}
      </div>
      <div style={{display: 'flex', gap: 8}}>
        {extendedData.map((group, groupIndex) => (
          <div key={group.name} style={{flex: 1, overflow: 'hidden'}}>
            {group.data.map((item, itemIndex) => (
              <ButtonWrapper
                url={item.url}
                {...item.data}
                active={groupIndex === activeList && itemIndex === activeItem}
              />
            ))}
          </div>
        ))}
      </div>
    </ResultsContainer>
  )
}

export default SearchResults
