import asyncThunk from "../../index";

export const deleteCluster = asyncThunk({
  method: "DELETE",
  type: "clusters/delete",
  path: "/cluster/${id}",
  api: true,
});

export default deleteCluster;
