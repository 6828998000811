import React, {useCallback, useMemo} from 'react'
import {Button, DropDownButton, Icon} from 'frontcore'
import ToolTip from '../../../../components/toolTip'

import styled from 'styled-components'
import ToolTipContentLayout from '../../../../components/layouts/toolTip/toolTipContentLayout'
import DropDownButtonContentLayout from '../../../../components/layouts/dropDownButtonContentLayout'
import ScaleDropDownComponent from './scaleDropDownComponent'
import Switch from "../../../../components/Switch";

const Root = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`
const ServiceButtons = (props) => {
    const {
        id,
        state,
        scale,
        kind,
        texts = true,
        pending = false,
        onArchive,
        onDelete,
        onScale,
        onPause,
        onRestart,
        onResume,
        onEdit,
        onToggle,
    } = props

    const handleOnDelete = useCallback(
        () => onDelete({id}),
        [id, onDelete]
    )

    const handleOnArchive = useCallback(
        () => onArchive({id}),
        [id, onArchive]
    )

    const size = 24

    const baseButtonsProps = useMemo(
        () => ({
            variant: 'text',
            tabIndex: -1,
            skeleton: pending,
        }),
        [pending]
    )

    const squareButtonProps = useMemo(
        () =>
            texts
                ? {square: false}
                : {square: true, justifyContent: 'center'},
        [texts]
    )

    const editButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {icon: 'pencil', size},
                    children: 'Edit',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'pencil', size},
                },
        [texts]
    )

    const scaleButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {icon: 'resize', size},
                    children: 'Scale',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'resize', size},
                },
        [texts]
    )

    const archiveButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {icon: 'archive', size},
                    children: 'Archive',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'archive', size},
                },
        [texts]
    )

    const deleteButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {icon: 'trash', size},
                    children: 'Delete',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'trash', size},
                },
        [texts]
    )

    const pauseButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {icon: 'pause', size: 20},
                    children: 'Pause',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'pause', size: 20},
                },
        [texts]
    )

    const restartButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {
                        icon: 'restart',
                        size: 20,
                    },
                    children: 'Restart',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'restart', size: 20},
                },
        [texts]
    )

    const resumeButtonProps = useMemo(
        () =>
            texts
                ? {
                    StartComponent: Icon,
                    startComponentProps: {icon: 'play', size: 20},
                    children: 'Resume',
                }
                : {
                    Component: Icon,
                    componentProps: {icon: 'play', size: 20},
                },
        [texts]
    )

    const handleOnScale = useCallback(
        (value) => onScale({id, value}),
        [id, onScale]
    )

    const scaleContentProps = useMemo(
        () => ({onScale: handleOnScale, scale}),
        [handleOnScale, scale]
    )

    const handleOnPause = useCallback(
        () => onPause({id}),
        [id, onPause]
    )

    const handleOnResume = useCallback(
        () => onResume({id}),
        [id, onResume]
    )

    const handleOnRestart = useCallback(
        () => onRestart({id}),
        [id, onRestart]
    )

    const handleOnEdit = useCallback(
        () => onEdit({id}),
        [id, onEdit]
    )

    const handleOnToggle = useCallback(
        () => onToggle({id}),
        [id, onToggle]
    )

    return (
        <Root>
            {onResume && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Resume Service
                        </ToolTipContentLayout>
                    }
                >
                    <Button
                        {...baseButtonsProps}
                        {...squareButtonProps}
                        {...resumeButtonProps}
                        onClick={handleOnResume}
                    />
                </ToolTip>
            )}
            {onPause && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Pause Service
                        </ToolTipContentLayout>
                    }
                >
                    <Button
                        {...baseButtonsProps}
                        {...squareButtonProps}
                        {...pauseButtonProps}
                        onClick={handleOnPause}
                    />
                </ToolTip>
            )}
            {onToggle && <Switch value={state === 'ACTIVE'} onClick={() => {
                handleOnToggle()
            }}/>}
            {onRestart && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Restart Service
                        </ToolTipContentLayout>
                    }
                >
                    <Button
                        {...baseButtonsProps}
                        {...squareButtonProps}
                        {...restartButtonProps}
                        onClick={handleOnRestart}
                    />
                </ToolTip>
            )}
            {((onScale &&
                    state !== 'ARCHIVED' &&
                    kind === 'JOB') ||
                (onScale &&
                    state === undefined &&
                    kind === undefined)) && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Scale
                        </ToolTipContentLayout>
                    }
                >
                    <DropDownButton
                        buttonProps={{
                            ...baseButtonsProps,
                            ...squareButtonProps,
                            ...scaleButtonProps,
                        }}
                        contentComponentProps={scaleContentProps}
                        ContentComponent={ScaleDropDownComponent}
                    />
                </ToolTip>
            )}
            {((onArchive && state !== 'ARCHIVED') ||
                (onArchive && !state)) && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Archive
                        </ToolTipContentLayout>
                    }
                >
                    <DropDownButton
                        buttonProps={{
                            ...baseButtonsProps,
                            ...squareButtonProps,
                            ...archiveButtonProps,
                        }}
                        ContentComponent={({closeFn}) => (
                            <DropDownButtonContentLayout>
                                <div style={{fontSize: 16}}>
                                    Archive Service: {}
                                </div>
                                <div style={{fontSize: 12}}>
                                    Are You sure you want to archive this
                                    service?
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 8,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        color={'error'}
                                        tabIndex={-1}
                                        onClick={() => {
                                            closeFn()
                                            handleOnArchive()
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        tabIndex={-1}
                                        variant={'outlined'}
                                        onClick={() => {
                                            closeFn()
                                        }}
                                    >
                                        No
                                    </Button>
                                </div>
                            </DropDownButtonContentLayout>
                        )}
                        variant={'text'}
                    />
                </ToolTip>
            )}
            {((onDelete && state === 'ARCHIVED') ||
                (onDelete && !state)) && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Delete
                        </ToolTipContentLayout>
                    }
                >
                    <DropDownButton
                        buttonProps={{
                            ...baseButtonsProps,
                            ...squareButtonProps,
                            ...deleteButtonProps,
                        }}
                        ContentComponent={({closeFn}) => (
                            <DropDownButtonContentLayout>
                                <div style={{fontSize: 16}}>
                                    Delete Service: {}
                                </div>
                                <div style={{fontSize: 12}}>
                                    Are You sure you want to archive this
                                    service?
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 8,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        color={'error'}
                                        tabIndex={-1}
                                        onClick={() => {
                                            closeFn()
                                            handleOnDelete()
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        tabIndex={-1}
                                        variant={'outlined'}
                                        onClick={() => {
                                            closeFn()
                                        }}
                                    >
                                        No
                                    </Button>
                                </div>
                            </DropDownButtonContentLayout>
                        )}
                    />
                </ToolTip>
            )}

            {onEdit && (
                <ToolTip
                    content={
                        <ToolTipContentLayout>
                            Edit
                        </ToolTipContentLayout>
                    }
                >
                    <Button
                        {...baseButtonsProps}
                        {...squareButtonProps}
                        {...editButtonProps}
                        onClick={handleOnEdit}
                    />
                </ToolTip>
            )}
        </Root>
    )
}

export default ServiceButtons
