import {useEffect, useRef} from 'react';
import {EventSourcePolyfill} from 'event-source-polyfill';
import {forEach, pick, pipe} from 'ramda';
import {EMPTY_ARRAY} from '../constants';
import {calculateHeaders} from '../api/helpers';

const useSSE = (url, eventListeners = EMPTY_ARRAY, deps = EMPTY_ARRAY) => {
  let source;

  const ref = useRef();

  useEffect(() => {
    const headers = pipe(
      pick(['Authorization']),
    )(calculateHeaders({}));

    source = new EventSourcePolyfill(url, {
      headers,
    });

    forEach(({name, handler}) => {
      source.addEventListener(name, handler);
    }, eventListeners);

    return () => {
      source.close();
    };
  }, [url, ref, ...deps]);
};

export default useSSE;
