import asyncThunk from "..";

export const deleteSQLStatement = asyncThunk({
  method: 'DELETE',
  type: 'sql/statement/delete',
  path: '/sql/statement/${queryId}',
  api: true,
})

export default deleteSQLStatement
