import asyncThunk from './index';

export const authenticateOauth2Config = asyncThunk({
  method: "GET",
  type: "authenticate/oauth2",
  path: "/authenticate/oauth2/config",
  api: false
});

export default authenticateOauth2Config;
