import styled, {css} from 'styled-components'

const sizeSmallCss = css`
  height: 20px;
`

const sizeMediumCss = css`
  font-size: 14px;
  height: 20px;
    min-width: 20px;
`

const sizeLargeCss = css`
  height: 40px;
  font-size: 24px;
    min-width: 40px;
`

const sizeCssMap = {
  small: sizeSmallCss,
  medium: sizeMediumCss,
  large: sizeLargeCss,
}

const Root = styled.div`
  background: ${({$color}) => $color};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({$size}) => sizeCssMap[$size]};
`

const Container = styled.div``

const UserIcon = ({color, size = 'medium', children}) => {
  return (
    <Root $color={color} $size={size}>
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Root>
  )
}

export default UserIcon
