import asyncThunk from "../../index";

export const restartService = asyncThunk({
  method: "POST",
  type: "service/restart",
  path: "/group/${id}/restart",
  api: true,
});

export default restartService;
