import {CheckBox, skeletonCss} from 'frontcore'
import {LetterMiniature} from '../miniatures'
import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {createTransitions, mapWithKey} from '../../helpers'
import ToolTip from '../toolTip'
import {EMPTY_FUNCTION} from '../../constants'
import StyledLink from '../StyledLink'

const ButtonsContainer = styled.div`
  transition: ${createTransitions(['opacity'])};
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`

const AAA = styled.div`
  background: ${({theme}) => theme.palette['surface-secondary']};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 64px;
  padding-right: 8px;
`

const BBB = styled.div`
    width: 128px;
    height: 100%;
    background: ${({theme}) => `linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, ${theme.palette['surface-secondary']} 100%`});
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LetterContainer = styled.div`
  width: ${({$width}) => $width};
  display: flex;
  align-items: center;
  overflow: hidden;
`

const Root = styled.div`
    position: relative;
    cursor: default;
    color: ${({theme}) => theme.palette.text.tertiary};
    transition: ${createTransitions(['background-color'])};
    display: flex;
    gap: 8px;
    padding-right: 8px;
    padding-left: 24px;
    overflow: hidden;
    font-family: Roboto, serif;
    &:hover {
        background-color: ${({theme}) =>
                theme.palette['surface-secondary']};

        ${ButtonsContainer} {
            opacity: 1;
        }
    }
`

const DataContainer = styled.div`
  flex: ${({$size}) => $size};
  width: ${({$width}) => $width};
  height: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SkeletonContainer = styled.div`
  ${skeletonCss};
  height: 16px;
  padding-left: 8px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  flex: 1;
`

const ItemComponent = (props) => {
  const {
    schema = [],
    data,
    debug,
    ButtonsComponent = () => null,
    buttonsComponentProps,
    ToolTipComponent = () => null,
    typeComponentProps,
    IconComponent,
    iconComponentProps,
    iconWidth = '48px',
    skeleton = false,
    selected,
    onSelect,
    toFn = EMPTY_FUNCTION,
  } = props

  const handleStopPropagation = useCallback(
    (event) => event.stopPropagation(),
    []
  )


  const toolTipContent = useMemo(() => {
    return !skeleton && <ToolTipComponent data={data} />
  }, [skeleton, data, ToolTipComponent])

  const handleOnSelect = useCallback(() => {
    onSelect(data)
  }, [data, onSelect])

  return (
    <ToolTip
      config={{position: 'top_right'}}
      content={toolTipContent}
    >
      <Root>
        {onSelect && (
          <CheckBoxContainer
            onClick={handleStopPropagation}
          >
            <CheckBox
              skeleton={skeleton}
              onChange={handleOnSelect}
              value={selected}
            />
          </CheckBoxContainer>
        )}
        <div style={{width: 8}}/>

        <div style={{flex: 1, overflow: 'hidden'}}>
          <StyledLink to={toFn(data)}>
            <div style={{display: 'flex', gap: 8}}>
              {iconComponentProps && (
                  <LetterContainer $width={iconWidth}>
                    <IconComponent
                        {...iconComponentProps}
                        data={data}
                    />
                  </LetterContainer>
              )}
              {typeComponentProps && (
                  <LetterContainer $width={iconWidth}>
                    <LetterMiniature
                        {...typeComponentProps}
                        skeleton={skeleton}
                    />
                  </LetterContainer>
              )}
              {mapWithKey(
                (
                  {
                    label,
                    size,
                    width,
                    value,
                    Component,
                    componentProps = {},
                  },
                  index
                ) => (
                  <DataContainer
                    key={index}
                    $size={size}
                    $width={width}
                    $debug={debug}
                  >
                    <SkeletonContainer $skeleton={skeleton}>
                      {Component ? (
                        <Component
                          {...componentProps}
                          value={data[value]}
                          data={data}
                        />
                      ) : (
                        data[value]
                      )}
                    </SkeletonContainer>
                  </DataContainer>
                ),
                schema
              )}
            </div>
          </StyledLink>
        </div>
        <ButtonsContainer onClick={handleStopPropagation}>
          <BBB

          ></BBB>
          <AAA>
            <ButtonsComponent
              {...buttonsComponentProps}
              data={data}
            />
          </AAA>
        </ButtonsContainer>
      </Root>
    </ToolTip>
  )
}

export default ItemComponent
