import {useLocation, useNavigate,} from 'react-router-dom'
import {BreadCrumbs, Button, Icon} from 'frontcore'
import React, {useCallback, useEffect, useMemo} from 'react'
import {cond, map, path, pipe, reject, startsWith, T, toPairs,} from 'ramda'
import {useDispatch, useSelector} from 'react-redux'
import {setBreadCrumbs} from '../../../reducers/workloads'
import RButton from '../../../components/RButton'
import {LetterMiniature} from '../../../components/miniatures'
import DetailsViewLayout from '../../../components/layouts/DetailsViewLayout'
import BreadCrumbsSeparatorComponent from '../../../components/BreadCrumbsSeparatorComponent'
import StyledLink from "../../../components/StyledLink";

const typeMap = {
    cluster: 'cluster',
    group: 'service',
    job: 'job',
    request: 'request',
    schedule: 'schedule',
}

const BreadCrumbButton = (props) => {
    const {onClick, data} = props

    const {label, id, type, active, name} = data

    const end = type === 'cluster' ? '/' + name : ''

    return (
        <StyledLink
            to={'/workloads/details/' + typeMap[type] + '/' + id + end}
        >
            <RButton
                size={'small'}
                IconComponent={LetterMiniature}
                iconComponentProps={{type: typeMap[type]}}
                name={label}
                type={typeMap[type]}
                active={active}
            />
        </StyledLink>
    )
}

const WorkloadsDetailsView = () => {
    const {pathname} = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(setBreadCrumbs())
        }
    }, [])

    const navigate = useNavigate()
    const returnUrl = useMemo(
        () =>
            cond([
                [
                    startsWith('/workloads/details/cluster'),
                    () => '/workloads/list/clusters',
                ],
                [
                    startsWith('/workloads/details/service'),
                    () => '/workloads/list/services',
                ],
                [
                    startsWith('/workloads/details/job'),
                    () => '/workloads/list/jobs',
                ],
                [
                    startsWith('/workloads/details/schedule'),
                    () => '/workloads/list/schedules'
                ],
                [
                    startsWith('/workloads/details/request'),
                    () => '/workloads/list/requests',
                ],
                [startsWith('/workloads/create/clusters'), () => -1],
                [startsWith('/workloads/create/service'), () => -1],
                [startsWith('/workloads/create/job'), () => -1],
                [T, () => -1],
            ])(pathname),
        [pathname]
    )

    const handleOnClick = useCallback(
        () => navigate(returnUrl),
        [returnUrl]
    )

    const data = useSelector(
        path(['workloads', 'breadCrumbs'])
    )
    const extendedData = useMemo(
        () =>
            pipe(
                toPairs,
                map((a) => ({
                    type: a[0],
                    id: a[1]?.id,
                    label: a[1]?.name,
                    active: a[1]?.active,
                    url: a[1]?.url,
                    name: a[1]?.name,
                })),
                reject((a) => !a.id)
            )(data),
        [data]
    )

    const handleOnClickBreadCrumbs = useCallback((p) => {
        const {url, type, id, name} = p

        if (url) {
            navigate('/workloads/list/clusters')
        } else {
            const end = type === 'cluster' ? '/' + name : ''
            const result =
                '/workloads/details/' + typeMap[type] + '/' + id + end
            navigate(result)
        }
    }, [])

    return (
        <DetailsViewLayout
            topNode={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 16,
                        paddingLeft: 24,
                        height: 64,
                    }}
                >
                    <Button
                        size={'small'}
                        variant={'text'}
                        StartComponent={Icon}
                        startComponentProps={{
                            icon: 'arrow-left',
                            size: 18,
                        }}
                        tabIndex={-1}
                        justifyContent={'center'}
                        onClick={handleOnClick}
                    >
                        Back to List
                    </Button>
                    <BreadCrumbs
                        onClick={handleOnClickBreadCrumbs}
                        data={extendedData}
                        ItemComponent={BreadCrumbButton}
                        SeparatorComponent={BreadCrumbsSeparatorComponent}
                    />
                </div>
            }
        />
    )
}

WorkloadsDetailsView.displayName = 'DetailsView'

export default WorkloadsDetailsView
