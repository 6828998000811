// Graphic Walker types

const AnalyticType = Object.freeze({
  dimension: 'dimension',
  measure: 'measure',
  questionMark: '?',
})

const SemanticType = Object.freeze({
  nominal: 'nominal',
  temporal: 'temporal',
  ordinal: 'ordinal',
  quantitative: 'quantitative',
  questionMark: '?',
})

const DataType = Object.freeze({
  number: 'number',
  string: 'string',
  time: 'time',
  boolean: 'boolean',
})

// Hive SQL Thrift types

const ThriftType = Object.freeze({
  boolean: 'BOOLEAN_TYPE',
  tinyint: 'TINYINT_TYPE',
  smallint: 'SMALLINT_TYPE',
  int: 'INT_TYPE',
  bigint: 'BIGINT_TYPE',
  float: 'FLOAT_TYPE',
  double: 'DOUBLE_TYPE',
  string: 'STRING_TYPE',
  timestamp: 'TIMESTAMP_TYPE',
  binary: 'BINARY_TYPE',
  array: 'ARRAY_TYPE',
  map: 'MAP_TYPE',
  struct: 'STRUCT_TYPE',
  union: 'UNION_TYPE',
  user: 'USER_DEFINED_TYPE',
  decimal: 'DECIMAL_TYPE',
  null: 'NULL_TYPE',
  date: 'DATE_TYPE',
  varchar: 'VARCHAR_TYPE',
  char: 'CHAR_TYPE',
  interval_year_month: 'INTERVAL_YEAR_MONTH_TYPE',
  interval_day_time: 'INTERVAL_DAY_TIME_TYPE',
  timestamplocaltz: 'TIMESTAMPLOCALTZ_TYPE',
})

// mappers

const AnalyticTypeMapping = Object.freeze({
  [AnalyticType.dimension]: [
    ThriftType.string,
    ThriftType.timestamp,
    ThriftType.date,
    ThriftType.varchar,
    ThriftType.char,
  ],
  [AnalyticType.measure]: [
    ThriftType.tinyint,
    ThriftType.smallint,
    ThriftType.int,
    ThriftType.bigint,
    ThriftType.float,
    ThriftType.double,
    ThriftType.decimal,
  ],
})

const inferAnalyticType = thriftType => {
  for (const [walkerType, thriftList] of Object.entries(AnalyticTypeMapping)) {
    if (thriftList.includes(thriftType)) {
      return walkerType
    }
  }
  return AnalyticType.questionMark
}

const SemanticTypeMapping = Object.freeze({
  [SemanticType.nominal]: [
    ThriftType.string,
    ThriftType.varchar,
    ThriftType.char,
    ThriftType.boolean,
    ThriftType.binary,
  ],
  [SemanticType.ordinal]: [
    ThriftType.tinyint,
    ThriftType.smallint,
    ThriftType.int,
    ThriftType.bigint,
  ],
  [SemanticType.quantitative]: [
    ThriftType.float,
    ThriftType.double,
    ThriftType.decimal,
  ],
  [SemanticType.temporal]: [
    ThriftType.timestamp,
    ThriftType.date,
    ThriftType.interval_year_month,
    ThriftType.interval_day_time,
    ThriftType.timestamplocaltz,
  ],
})

const inferSemanticType = thriftType => {
  for (const [walkerType, thriftList] of Object.entries(SemanticTypeMapping)) {
    if (thriftList.includes(thriftType)) {
      return walkerType
    }
  }
  return SemanticType.questionMark
}

const DataTypeMapping = Object.freeze({
  [DataType.number]: [
    ThriftType.tinyint,
    ThriftType.smallint,
    ThriftType.int,
    ThriftType.bigint,
    ThriftType.float,
    ThriftType.double,
    ThriftType.decimal,
  ],
  [DataType.string]: [
    ThriftType.string,
    ThriftType.varchar,
    ThriftType.char,
    ThriftType.binary,
    ThriftType.user,
  ],
  [DataType.boolean]: [
    ThriftType.boolean,
  ],
  [DataType.time]: [
    ThriftType.timestamp,
    ThriftType.date,
    ThriftType.interval_year_month,
    ThriftType.interval_day_time,
    ThriftType.timestamplocaltz,
  ],
})

const inferDataType = thriftType => {
  for (const [walkerType, thriftList] of Object.entries(DataTypeMapping)) {
    if (thriftList.includes(thriftType)) {
      return walkerType
    }
  }
  return DataType.string
}

const mapField = ({ name, type }) => {
  return Object.freeze({
    fid: name,
    name,
    analyticType: inferAnalyticType(type),
    semanticType: inferSemanticType(type),
    dataType: inferDataType(type),
  })
}

export default mapField
