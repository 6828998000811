import {Button} from 'frontcore'
import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledInput = styled.input`
  padding: 5px 16px;
  outline: none;
  font-family: inherit;
  border: 1px solid #ccc;
  font-size: 1em;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.secondary};
`

const Errors = styled.p`
  margin: 0; padding: 0;
  font-size: 12px;
  color: red;
`

/**
 * Accepts value in epoch milliseconds
 */
const DatetimeInput = ({ value, onChange, errors }) => {
  const handleOnChange = useCallback((event) => {
    try {
      const value = event.target.value
      const date = new Date(value)
      const time = date.getTime()
      onChange?.(time)
    } catch {}
  }, [onChange])

  const strValue = useMemo(() => {
    try {
      const date = new Date(value)
      const year = String(date.getFullYear()).padStart(4, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const localISOTime = `${year}-${month}-${day}T${hours}:${minutes}`
      return localISOTime
    } catch {}
  }, [value])

  const handleReset = useCallback(() => {
    onChange?.(undefined)
  }, [onChange])

  const handleSetNow = useCallback(() => {
    onChange?.(Date.now())
  }, [onChange])

  return (
    <Root>
      <RowContainer>
        <StyledInput
          type="datetime-local"
          value={strValue}
          onChange={handleOnChange}
        />
        <Button
          variant='outlined'
          onClick={handleSetNow}
        >
          Now
        </Button>
        <Button 
          variant='outlined'
          onClick={handleReset}
        >
          Reset
        </Button>
      </RowContainer>
      {errors?.length > 0 && (
        <Errors>
          {errors.join(', ')}
        </Errors>
      )}
    </Root>
  )
}

export default memo(DatetimeInput)
