import asyncThunk from "..";

export const createSQLEngine = asyncThunk({
  method: 'POST',
  type: 'sql/engine/create',
  path: '/sql/engine/${clusterId}',
  api: true,
})

export default createSQLEngine
