import useFuture from '../../../../hooks/useFuture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useDispatch} from 'react-redux'
import React, {useCallback, useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import {
    setBreadCrumbs,
    setDetails,
} from '../../../../reducers/workloads'
import Form from '../../../../components/Form'
import {
    assoc,
    evolve,
    forEach, head,
    map, mergeLeft,
    pick,
    pipe, prop, propOr,
    reduce,
    toLower,
    toPairs,
} from 'ramda'
import {isArray} from '../../../../helpers'
import useFetch from '../../../../hooks/useFetch'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import usePending from '../../../../hooks/usePending'
import {useNavigate} from 'react-router-dom'
import schema from './schema'
import getJobFetch from '../../../../api/workloads/jobs/getJobFetch'
import useQueryParams from '../../../../hooks/useQueryParams'
import {singleCallFetchOptions} from "../../../../api/helpers";
import createJob from "../../../../api/workloads/jobs/createJob";
import SecurityFormLayout from "../../../../components/SecurityFormLayout";
import getClusterMemorySettingsFetch from "../../../../api/workloads/clusters/getClusterMemorySettingsFetch";
import {EMPTY_OBJECT} from "../../../../constants";


const WorkloadsCreateJobView = () => {
    const createGroupFuture = useFuture(createJob)
    const {createNotification} = useNotificationContext()

    const navigate = useNavigate()

    const {data: clusters} = useFetch(getClustersNamesFetch)


    const cluster = useMemo(() => propOr(EMPTY_OBJECT,0, clusters), [clusters])

    const {data: clusterMemorySettings} = useFetch(
        getClusterMemorySettingsFetch,
        EMPTY_OBJECT,
        {
            params: [{key: 'id', value: cluster?.id}],
        }
    )

    const pending = usePending(
        ['workloads', 'data'],
        ['createJob']
    )

    const dispatch = useDispatch()
    const handleOnSubmit = useCallback((fields) => {

        const params = {...fields}

        const result = pipe(
            toPairs,
            reduce((acc, val) => {
                if (isArray(val[1])) {
                    forEach((value) => {
                        acc.append(val[0], value[0], value[1], {
                            type: 'application/json',
                        })
                    }, val[1])
                } else {
                    acc.append(val[0], val[1])
                }
                return acc
            }, new FormData())
        )(params)
        pipe(
            fork(({message}) => {
                createNotification({
                    title: 'Failed to create Job',
                    message,
                    autoHide: false,
                    variant: 'error',
                })
            })(() => {
                navigate(-1)
                createNotification({
                    message: 'Job created successfully',
                    autoHide: true,
                    variant: 'success',
                })
                dispatch(setDetails())
            })
        )(createGroupFuture(result))
    }, [])

    useEffect(() => {
        dispatch(
            setBreadCrumbs({
                job: {
                    name: 'Jobs',
                },
            })
        )
    }, [])

    const templateID = () => {
        const params = new URLSearchParams(
            window.location.search
        )
        return params.get('template')
    }
    const {data: templateData} = useFetch(
        templateID() ? getJobFetch : {api: null},
        singleCallFetchOptions,
        {
            params: [
                {
                    key: 'id',
                    value: templateID(),
                },
            ],
        }
    )

    const toKeyValuePairs = (obj) => {
        const entries = Object.entries(obj)
        return entries.map(([key, value]) => {
            return Object.freeze({key, value})
        })
    }

    const extendedData = useMemo(
        () =>
            pipe(
                (data) => ({...data, name: data?.jobName}),
                pick([
                    'name',
                    'clusterName',
                    'language',
                    'jobConfig',
                    'args',
                    'tags',
                    'jobClass',
                    'memorySettings'
                ]),
                evolve({
                    language: toLower,
                    jobConfig: toKeyValuePairs,
                    args: map((value) => ({value})),
                    tags: toKeyValuePairs,
                }),
                assoc('clusterName', cluster.name),
                mergeLeft(clusterMemorySettings)
            )(templateData),

        [templateData, clusterMemorySettings]
    )

    const query = useQueryParams()

    const options = useMemo(
        () => ({
            pending,
            clusters,
            cluster: query.get('cluster'),
            navigate,
        }),
        [pending, clusters]
    )

    return (
        <Form
            initialData={extendedData}
            options={options}
            LayoutComponent={SecurityFormLayout}
            layoutComponentProps={{
                title: 'NEW JOB',
            }}
            schema={schema}
            onSubmit={handleOnSubmit}
        />
    )
}

export default WorkloadsCreateJobView
