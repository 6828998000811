import { memo, useMemo } from 'react'
import styled from 'styled-components'
import cronstrue from 'cronstrue'
import ToolTip from '../../../../components/toolTip'

const ExplainCRON = ({ value }) => {
  const explanation = useMemo(() => {
    try {
      return cronstrue.toString(value, {
        throwExceptionOnParseError: true,
        verbose: false,
      })
    } catch {
      return 'Unknown'
    }
  }, [value])

  return (
    <ToolTip 
      content={<TooltipContent cron={value}/>}
      config={{ position: 'bottom_right' }}
    >
      <p>{explanation}</p>
    </ToolTip>
  )
}

export default memo(ExplainCRON)

const TooltipContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    & > * + * {
      margin-left: 6px;
    }
  }
`

const Part = styled.p`
  background-color: #ccc;
  border-radius: 4px;
  padding: 3px 6px;
  font-size: 14px;
`

const TooltipContent = memo(({ cron }) => {
  return (
    <TooltipContainer>
      <h3>Raw Expression: </h3>
      <div>
        {cron.split(' ').map((piece, index) => (
          <Part key={index}>{piece}</Part>
        ))}
      </div>
    </TooltipContainer>
  )
})
