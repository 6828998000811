import {path} from 'ramda'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import ToolTip from '../../../components/toolTip'

const INTERVAL_DELTA = 100

const DISCLAIMER = `
  This shows the time relative to the client side 
  of the application. The actual SQL execution time is shorter.
  If you want to see a more accurate time measurement, 
  you should go to the tab ‘Statistics’.
`

const Text = styled.div`
  margin-right: 3px;
  color: ${({ theme }) => theme.palette.text.tertiary};
`

const Disclaimer = styled.div`
  max-width: 260px;
  padding: 8px;
`

const QueryTimer = () => {
  const pending = useSelector(path(['sqlViewer', 'editor', 'data', 'executeQuery', 'pending']))

  const [time, setTime] = useState(null)
  const interval = useRef()

  useEffect(() => {
    if (!pending) return
    setTime(0)
    interval.current = setInterval(() => {
      setTime(prev => prev + INTERVAL_DELTA)
    }, INTERVAL_DELTA)
    return () => clearInterval(interval.current)
  }, [pending])

  const formatter = useRef(new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    minimumIntegerDigits: 1,
  }))

  const text = useMemo(() => {
    const totalSeconds = Math.round(time / 100) / 10
    const minutes = ~~(totalSeconds / 60)
    const seconds = totalSeconds % 60
    const fmt = value => formatter.current.format(value)
    if (minutes >= 1) {
      return `${fmt(minutes)}min ${fmt(seconds)}s`
    }
    return `${fmt(seconds)}s`
  }, [time])

  return time && (
    <ToolTip config={{ position: 'bottom_right' }} content={
      <Disclaimer>{DISCLAIMER}</Disclaimer>
    }>
      <Text>{ text }</Text>
    </ToolTip>
  )
}

export default memo(QueryTimer)
