import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, Icon} from 'frontcore'
import {EMPTY_STRING} from '../../constants'
import DropDownInput from '../dropDownInput'
import UserDropdown from '../userDropdown'
import Logo from './components/Logo'
import SearchResults from './components/SearchResults'
import useFetch from '../../hooks/useFetch'
import getVersionFetch from '../../api/getVersionFetch'
import {singleCallFetchOptions} from '../../api/helpers'
import {DescriptionText} from '../typography'
import {useStorage} from '../../hooks/useStorage'
import {add} from 'ramda'
import {useNavigate} from "react-router-dom";

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`

const LogoContainer = styled.div`
  flex: 1;
`

const SearchContainer = styled.div`
  flex: 2;
`

const UserContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`
const SearchBar = () => {
  const [search, setSearch] = useState(EMPTY_STRING)
  const dropdownRef = useRef()

    const navigate = useNavigate()
  const version = useFetch(getVersionFetch, singleCallFetchOptions)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.code === 'Space') {
        event.preventDefault()
        dropdownRef?.current?.open()
      }

      if (event.code === 'Escape') {
        event.preventDefault()
        if (search === EMPTY_STRING) {
          dropdownRef?.current?.close()
        } else {
          setSearch(EMPTY_STRING)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [search])

  const handleSetSearch = useCallback((value) => {
    dropdownRef?.current?.open()
    setSearch(value)
  }, [])

  const handleClearSearch = useCallback((event) => {
    event.stopPropagation()
    setSearch(EMPTY_STRING)
  }, [])

  return (
    <SearchBarContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <SearchContainer>
        <DropDownInput
          ref={dropdownRef}
          fullWidth={true}
          inputProps={{
            fullWidth: true,
            placeHolder: 'Search',
            StartComponent: Icon,
            startComponentProps: {size: 16, icon: 'search'},
            EndComponent: Button,
            paddingRight: '4px',
            endComponentProps: {
              square: true,
              size: 'small',
              onClick: handleClearSearch,
              variant: 'text',
              justifyContent: 'center',
              tabIndex: -1,
              Component: Icon,
              componentProps: {
                size: 16,
                icon: 'trash',
              },
            },
          }}
          value={search}
          onChange={handleSetSearch}
          ContentComponent={SearchResults}
          contentProps={{
            search,
            pending: false,
          }}
        />
      </SearchContainer>
      <UserContainer>
          <Button onClick={() => navigate('/notifications')} variant={'outlined'} tabIndex={-1} size={'small'}>Notifications</Button>
        <DescriptionText>version: {version?.data?.version}</DescriptionText>
        <UserDropdown />
      </UserContainer>
    </SearchBarContainer>
  )
}

export default SearchBar
