import {ILUM_JUPYTER_PATH} from '../../helpers/runtimeEnv'
import {useHistoryContext} from '../../hooks/useHistoryContext'
import {useEffect} from 'react'

const NotebookView = () => {
  const {addHistory} = useHistoryContext()

  useEffect(() => {
    addHistory({type: 'module', id: 'Jupyter', name: 'Jupyter'})
  }, [addHistory])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <iframe
        src={ILUM_JUPYTER_PATH}
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
        }}
      />
    </div>
  )
}

NotebookView.dispayName = 'NotebookView'

export default NotebookView
