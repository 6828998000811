import {Outlet} from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'
import LineageSearch from './LineageSearch'
import {ILUM_LINEAGE_ENABLED} from '../../helpers/runtimeEnv'
import ViewNotAvailable from '../../components/viewNotAvailable'

const LineageView = () => {
  return ILUM_LINEAGE_ENABLED.toLowerCase() === 'true' ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        overflow: 'hidden',

      }}
    >
      <div style={{flex: 1, overflow: 'hidden'}}>
        <Outlet />
      </div>
    </div>
  ) : (
    <ViewNotAvailable name={'Lineage'} />
  )
}

LineageView.dispayName = 'LineageView'

export default LineageView
