import asyncThunk from "..";

export const deleteSQLEngine = asyncThunk({
  method: 'DELETE',
  type: 'sql/engine/delete',
  path: '/sql/engine/${engineId}',
  api: true,
})

export default deleteSQLEngine
