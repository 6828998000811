import styled, {css} from 'styled-components'
import {useCallback} from 'react'
import {createTransitions} from '../../helpers'

const DotInside = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  transition: ${createTransitions(['width', 'height'])};
`

const Root = styled.div`
  user-select: none;
  display: flex;
  gap: 8px;
  align-items: center;

  ${DotInside} {
    background-color: ${({theme}) =>
      theme.palette.blue[500]};
    width: ${({$active}) => ($active ? '8px' : 0)};
    height: ${({$active}) => ($active ? '8px' : 0)};
  }

  ${({ $disabled }) => !$disabled && css`
      &:hover {
        ${DotInside} {
          width: ${({$active}) => ($active ? '10px' : '4px')};
          height: ${({$active}) => ($active ? '10px' : '4px')};
        }
      }
  ` }

  ${({ $disabled }) => $disabled && css`
    opacity: 0.6;
  `}
`

const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  border-color: ${({theme}) => theme.palette.neutral[300]};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  ${DotInside} {
    background-color: ${({theme}) =>
      theme.palette.blue[500]};
  }
`

const TextContainer = styled.div`
  letter-spacing: 0.25px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.palette.neutral[400]};
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const RadioButton = ({
  label,
  id,
  onClick,
  value,
  disabled = false,
}) => {
  const handleOnClick = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  return (
    <Root 
      onClick={handleOnClick} 
      $active={value}
      $disabled={disabled}
    >
      <Dot>
        <DotInside />
      </Dot>
      <TextContainer>{label}</TextContainer>
    </Root>
  )
}

export default RadioButton
