import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import ToolTip from '../../../../components/toolTip'
import ToolTipContentLayout from '../../../../components/layouts/toolTip/toolTipContentLayout'
import {Button, DropDownButton, Icon} from 'frontcore'
import DropDownButtonContentLayout from '../../../../components/layouts/dropDownButtonContentLayout'
import Switch from "../../../../components/Switch";

const Root = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
`

const ICON_SIZE = 24

const ScheduleButtons = ({
  id,
  name,
  state,
  texts = false,
  pending = false,
  onArchive,
  onDelete,
  onPause,
  onResume,
  onEdit,
  onToggle,
  onDisable,
  disabled = false,
}) => {
  const defaultProps = useMemo(
    () => ({
      variant: 'text',
      tabIndex: -1,
      skeleton: pending,
    }),
    [pending]
  )

  const buttonProps = useMemo(
    () =>
      texts
        ? {square: false}
        : {square: true, justifyContent: 'center'},
    [texts]
  )

  const deleteButtonProps = useMemo(
    () =>
      texts
        ? {
            StartComponent: Icon,
            startComponentProps: {
              icon: 'trash',
              size: ICON_SIZE,
            },
            children: 'Delete',
          }
        : {
            Component: Icon,
            componentProps: {
              icon: 'trash',
              size: ICON_SIZE,
            },
          },
    [texts]
  )

  const archiveButtonProps = useMemo(
    () =>
      texts
        ? {
            StartComponent: Icon,
            startComponentProps: {
              icon: 'archive',
              size: ICON_SIZE,
            },
            children: 'Archive',
          }
        : {
            Component: Icon,
            componentProps: {
              icon: 'archive',
              size: ICON_SIZE,
            },
          },
    [texts]
  )

  const handleOnArchive = useCallback(() => {
    onArchive && onArchive({id})
  }, [onArchive, id])

  const handleOnDelete = useCallback(() => {
    onDelete && onDelete({id})
  }, [onDelete, id])

  const handleOnEdit = useCallback(
    () => onEdit({id}),
    [id, onEdit]
  )

  const handleOnDisable = useCallback(() => {
    onDisable && onDisable({id})
  }, [onDisable, id])

  const editButtonProps = useMemo(() => {
    if (texts) {
      return Object.freeze({
        StartComponent: Icon,
        startComponentProps: {
          icon: 'pencil',
          size: ICON_SIZE,
        },
        children: 'Edit',
      })
    }
    return Object.freeze({
      Component: Icon,
      componentProps: {
        icon: 'pencil',
        size: ICON_SIZE,
      },
    })
  }, [texts])

  const handleOnPause = useCallback(() => {
    onPause && onPause({id})
  }, [onPause, id])

  const handleOnToggle = useCallback(
      () => onToggle({id}),
      [id, onToggle]
  )

  const pauseButtonProps = useMemo(() => {
    if (texts) {
      return Object.freeze({
        StartComponent: Icon,
        startComponentProps: {
          icon: 'pause',
          size: ICON_SIZE,
        },
        children: 'Pause',
      })
    }
    return Object.freeze({
      Component: Icon,
      componentProps: {
        icon: 'pause',
        size: ICON_SIZE,
      },
    })
  }, [texts])

  const handleOnResume = useCallback(() => {
    onResume && onResume({id})
  }, [onResume, id])

  const resumeButtonProps = useMemo(() => {
    if (texts) {
      return {
        StartComponent: Icon,
        startComponentProps: {
          icon: 'play',
          size: ICON_SIZE,
        },
        children: 'Resume',
      }
    }
    return {
      Component: Icon,
      componentProps: {
        icon: 'play',
        size: ICON_SIZE,
      },
    }
  }, [texts])

  return (
    <Root>
      {onToggle && <Switch value={state === 'ENABLED'} onClick={handleOnToggle}/>}
      {onEdit && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Edit
            </ToolTipContentLayout>
          }
        >
          <Button
            onClick={handleOnEdit}
            {...editButtonProps}
            {...defaultProps}
            {...buttonProps}
          />
        </ToolTip>
      )}
      {onPause && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Pause
            </ToolTipContentLayout>
          }
        >
          <Button
            onClick={handleOnPause}
            {...pauseButtonProps}
            {...defaultProps}
            {...buttonProps}
          />
        </ToolTip>
      )}
      {onResume && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Resume
            </ToolTipContentLayout>
          }
        >
          <Button
            onClick={handleOnResume}
            {...resumeButtonProps}
            {...defaultProps}
            {...buttonProps}
          />
        </ToolTip>
      )}
      {onArchive && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Archive
            </ToolTipContentLayout>
          }
        >
          <DropDownButton
            ContentComponent={({closeFn}) => (
              <DropDownButtonContentLayout>
                <div style={{fontSize: 16}}>
                  Archive Schedule: {name}
                </div>
                <div style={{fontSize: 12}}>
                  Are You sure you want to archive this
                  schedule? You cannot undo this action. If
                  you want to run this schedule later,
                  consider using <b>Pause</b> instead
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color={'error'}
                    tabIndex={-1}
                    onClick={() => {
                      handleOnArchive()
                      closeFn()
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    tabIndex={-1}
                    variant={'outlined'}
                    onClick={() => {
                      closeFn()
                    }}
                  >
                    No
                  </Button>
                </div>
              </DropDownButtonContentLayout>
            )}
            buttonProps={{
              ...archiveButtonProps,
              ...defaultProps,
              ...buttonProps,
              disabled,
            }}
          />
        </ToolTip>
      )}
      {onDelete && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Delete
            </ToolTipContentLayout>
          }
        >
          <DropDownButton
            ContentComponent={({closeFn}) => (
              <DropDownButtonContentLayout>
                <div style={{fontSize: 16}}>
                  Delete Schedule: {name}
                </div>
                <div style={{fontSize: 12}}>
                  Are You sure you want to delete this
                  schedule? You cannot undo this action
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color={'error'}
                    tabIndex={-1}
                    onClick={() => {
                      handleOnDelete()
                      closeFn()
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    tabIndex={-1}
                    variant={'outlined'}
                    onClick={() => {
                      closeFn()
                    }}
                  >
                    No
                  </Button>
                </div>
              </DropDownButtonContentLayout>
            )}
            buttonProps={{
              ...deleteButtonProps,
              ...defaultProps,
              ...buttonProps,
              disabled,
            }}
          />
        </ToolTip>
      )}
      {onDisable && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Disable
            </ToolTipContentLayout>
          }
        >
          <DropDownButton
            ContentComponent={({closeFn}) => (
              <DropDownButtonContentLayout>
                <div style={{fontSize: 16}}>
                  Delete Schedule: {name}
                </div>
                <div style={{fontSize: 12}}>
                  Are You sure you want to disabled this
                  schedule? You cannot undo this action
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color={'error'}
                    tabIndex={-1}
                    onClick={() => {
                      handleOnDisable()
                      closeFn()
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    tabIndex={-1}
                    variant={'outlined'}
                    onClick={() => {
                      closeFn()
                    }}
                  >
                    No
                  </Button>
                </div>
              </DropDownButtonContentLayout>
            )}
            buttonProps={{
              ...deleteButtonProps,
              ...defaultProps,
              ...buttonProps,
              disabled,
            }}
          />
        </ToolTip>
      )}
    </Root>
  )
}

export default ScheduleButtons
