import asyncThunk from "../../index";

export const killJob = asyncThunk({
  method: "POST",
  type: "job/kill",
  path: "/job/${id}/stop",
  api: true,
});

export default killJob;
