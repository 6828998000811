import asyncThunk from "../../index";

export const getJobsDetails = asyncThunk({
  method: "GET",
  type: "jobsDetails/get",
  path: "/job",
  api: true,
});

export default getJobsDetails;
