import {useListRefreshFuture} from '../hooks'
import getGroups from '../../../../api/security/getGroups'
import ListLayout from '../../../../components/layouts/listLayout'
import Filters from '../../../WorkloadsView/filters'
import {Button, Icon, Input, Select} from 'frontcore'
import React from 'react'
import GroupsList from './GroupsList'
import {useNavigate} from 'react-router-dom'
import usePending from '../../../../hooks/usePending'
import GroupBatchButtons from '../../Components/GroupButtons/GroupBatchButtons'
import {isNotEmpty} from '../../../../helpers'
import {EMPTY_ARRAY} from "../../../../constants";

const schema = [
    {
        id: 'search',
        Component: Input,
        defaultValue: '',
        props: {
            StartComponent: Icon,
            startComponentProps: {
                size: 16,
                icon: 'search',
            },
            placeHolder: 'Search',
            topLabelProps: {
                children: 'Groups Search',
            },
            label: 'Users Search',
        },
    },
    {
        id: 'state',
        Component: Select,
        defaultValue: EMPTY_ARRAY,
        props: {
            label: 'State',
            value: [],
            options: ['ENABLED', 'DISABLED'],
            multiSelect: true,
            buttonProps: {
                width: '200px',
            },
        },
    },
]

const SecurityGroupsListView = () => {
  const listHandler = useListRefreshFuture(
    getGroups,
    'security',
    'groups'
  )
  const {selected} = listHandler
  const navigate = useNavigate()

  const pending = usePending(
    ['security', 'data'],
    [
      'groups',
      'createGroup',
      'deleteGroup',
      'enableGroup',
      'disableGroup',
    ]
  )

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <GroupBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'groups'}
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <div style={{display: 'flex', gap: '10px'}}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              navigate('/security/create/group')
            }
            EndComponent={Icon}
            endComponentProps={{
              icon: 'plus',
              size: 18,
              color: 'white',
            }}
          >
            Add Group
          </Button>
        </div>
      }
      contentNode={
        <GroupsList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default SecurityGroupsListView
