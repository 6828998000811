import JobButtons from '../JobButtons'
import React, {useCallback} from 'react'
import {map, pipe} from 'ramda'
import {and, fork, parallel} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import deleteJob from '../../../../api/workloads/jobs/deleteJob'
import killJob from '../../../../api/workloads/jobs/killJob'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'

const JobBatchButtons = (props) => {
  const {selected, getFuture} = props
  const killJobFuture = useFuture(killJob)
  const deleteJobFuture = useFuture(deleteJob)

  const {createNotification} = useNotificationContext()

  const handleOnBatchDelete = useCallback(() => {
    pipe(
      map((id) =>
        deleteJobFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({ message }) => {
        createNotification({
          title: 'Failed to delete Jobs',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Jobs successfully deleted',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchKill = useCallback(() => {
    pipe(
      map((id) =>
        killJobFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({ message }) => {
        createNotification({
          title: 'Failed to kill Jobs',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Jobs successfully killed',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  return (
    <JobButtons
      texts={false}
      onDelete={handleOnBatchDelete}
      onKill={handleOnBatchKill}
    />
  )
}

export default JobBatchButtons