import asyncThunk from '../../index'

export const getServices = asyncThunk({
  method: 'GET',
  type: 'services/get',
  path: '/group',
  api: true,
  permission: 'READ_SERVICES'
})

export default getServices;
