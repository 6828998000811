import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import ToolTip from '../../../../../components/toolTip'
import ToolTipContentLayout from '../../../../../components/layouts/toolTip/toolTipContentLayout'
import {Button, Icon} from 'frontcore'
import React from 'react'
import {createTransitions} from '../../../../../helpers'
import styled from 'styled-components'
import StatusIndicator from '../../../../../components/StatusIndicator'

const ButtonContainer = styled.div`
  opacity: 0;
  transition: ${createTransitions(['opacity'])};
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
`

const ValueContainer = styled.div`
  flex: 1;
  color: ${({theme}) => theme.palette['font-primary']};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  &:hover {
    ${ButtonContainer} {
      opacity: 1;
    }
  }
`

const TooltipContainer = styled.div`
  padding: 8px;
  
`

const URLText = ({text}) => {
  const {createNotification} = useNotificationContext()
  return (
    <ToolTip lockable content={(
      <TooltipContainer>
        Learn more about this API endpoint in the Ilum API Reference documentation. <br/>
        <a href="https://ilum.cloud/docs/api_ref/">Ilum Documentation</a>
      </TooltipContainer>
    )} config={{ position: 'bottom_left' }}>
      <ValueContainer>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'flex',
            gap: 5,
          }}
        >
          <StatusIndicator
            value={'POST'}
            variant={'information'}
          />
          {text}
        </div>

        <ButtonContainer>
          <ToolTip
            content={
              <ToolTipContentLayout>
                Copy Value To Clipboard
              </ToolTipContentLayout>
            }
          >
            <Button
              variant={'text'}
              square
              size={'small'}
              Component={Icon}
              componentProps={{size: 16, icon: 'project'}}
              justifyContent={'center'}
              tabIndex={-1}
              onClick={() => {
                navigator.clipboard.writeText(text)
                createNotification({
                  message: 'Text copied to clipboard',
                  autoHide: true,
                  variant: 'information',
                  title: 'Clipboard',
                })
              }}
            />
          </ToolTip>
        </ButtonContainer>
      </ValueContainer>
    </ToolTip>
  )
}

export default URLText
