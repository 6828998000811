import {memo, useCallback, useState} from 'react'
import RButton from '../RButton'
import {equals, isEmpty, isNil, path} from 'ramda'
import {useDispatch, useSelector} from 'react-redux'
import ExpandListButton from '../ExpandListButton'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import {toggleChosenItem} from '../../reducers/dataExplorer/flatTree'
import {deleteData} from '../../reducers/data'
import useFuture from '../../hooks/useFuture'
import getCatalogs from '../../api/table/getCatalogs'
import getDatabases from '../../api/table/getDatabases'
import getColumns from '../../api/table/getColumns'
import getTables from '../../api/table/getTables'
import {fork} from 'fluture'
import {EMPTY_STRING} from '../../constants'
import {Button, Icon} from 'frontcore'
import ToolTip from '../toolTip'
import {sidebarAppendContent} from '../../reducers/sql/sidebar'
import styled from 'styled-components'

const ITEM_KIND = Object.freeze({
  METASTORE: 'metastore',
  CATALOG: 'catalog',
  DATABASE: 'database',
  TABLE: 'table',
  COLUMN: 'column',
  OTHER: 'other',
})

const PADDING_MAP = Object.freeze({
  [ITEM_KIND.METASTORE]: 4,
  [ITEM_KIND.CATALOG]: 16,
  [ITEM_KIND.DATABASE]: 30,
  [ITEM_KIND.TABLE]: 30 + 14,
  [ITEM_KIND.COLUMN]: 30 + 14 + 14 + 14,
  [ITEM_KIND.OTHER]: 4,
})

const ICON_MAP = Object.freeze({
  [ITEM_KIND.METASTORE]: 'server',
  [ITEM_KIND.CATALOG]: 'directory',
  [ITEM_KIND.DATABASE]: 'database',
  [ITEM_KIND.TABLE]: 'table',
  [ITEM_KIND.OTHER]: 'question',
})

const TypeTag = styled.p`
  padding: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blue[500]};
`

const ListItem = (item) => {
  const {id, name = 'Unnamed', kind = ITEM_KIND.OTHER, type = EMPTY_STRING, data = []} = item

  const {createNotification} = useNotificationContext()
  const dispatch = useDispatch()

  const chosenItem = useSelector(path(['flatTree', 'chosenItem']))
  const toggleChosen = useCallback(() => {
    if (kind === ITEM_KIND.COLUMN) return
    dispatch(toggleChosenItem(item))
  }, [item, kind])

  const getCatalogsFuture = useFuture(getCatalogs)
  const getDatabaseFuture = useFuture(getDatabases)
  const getColumnsFuture = useFuture(getColumns)
  const getTablesFuture = useFuture(getTables)

  const [pending, setPending] = useState(false)
  const toggleExpanded = useCallback(() => {
    if (data.length > 0) {
      dispatch(deleteData(item))
      return
    }
    setPending(true)
    const future = (() => {
      switch (kind) {
        case ITEM_KIND.METASTORE:
          return getCatalogsFuture({
            params: [{key: 'metaStoreId', value: item?.id}],
          })
        case ITEM_KIND.CATALOG:
          return getDatabaseFuture({
            params: [
              {
                key: 'metaStoreId',
                value: item?.metaStoreId,
              },
              {key: 'catalogId', value: item?.id},
            ],
          })
        case ITEM_KIND.DATABASE:
          return getTablesFuture({
            params: [
              {
                key: 'metaStoreId',
                value: item?.metaStoreId,
              },
              {key: 'catalogId', value: item?.catalogId},
              {key: 'databaseId', value: item?.id},
            ],
          })
        case ITEM_KIND.TABLE:
          return getColumnsFuture({
            params: [
              {
                key: 'metaStoreId',
                value: item?.metaStoreId,
              },
              {key: 'catalogId', value: item?.catalogId},
              {
                key: 'databaseId',
                value: item?.databaseId,
              },
              {key: 'id', value: item?.id},
            ],
          })
        default:
          return null
      }
    })()
    if (isNil(future)) return
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to fetch from Metastore',
        variant: 'error',
        autoHide: true,
      })
      setPending(false)
    })((data) => {
      if (isEmpty(data)) {
        createNotification({
          title: 'Empty container',
          message: 'Metastore container is empty. No data was fetched',
          variant: 'info',
          autoHide: true,
        })
      }
      setPending(false)
    })(future)
  }, [data, kind, item])

  const handleDragStart = useCallback(
    (event) => {
      let text = item.name
      if (item.kind === 'column') {
        text = `${item.tableName}.${item.name}`
      }
      event.dataTransfer.clearData()
      event.dataTransfer.setData('text/plain', text)
    },
    [item]
  )

  const [hover, setHover] = useState(false)

  const queryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const statementId = useSelector(path(['sqlViewer', 'notebook', 'pointer']))

  const handleAppendContent = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    let text = item.name
    if (item.kind === 'column') {
      text = `${item.tableName}.${item.name}`
    }
    dispatch(sidebarAppendContent({
      queryId,
      statementId,
      content: text,
    }))
  }, [item, queryId, statementId])

  return (
    <RButton
      name={name}
      padding={PADDING_MAP[kind]}
      icon={ICON_MAP[kind]}
      onClick={toggleChosen}
      active={equals(chosenItem, item)}
      StartComponent={kind !== ITEM_KIND.COLUMN && ExpandListButton}
      startComponentProps={{
        onClick: toggleExpanded,
        pending,
      }}
      rootProps={{
        draggable: ['table', 'column'].includes(item.kind),
        onDragStart: handleDragStart,
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
      }}
    >
      <div style={{display: 'flex', alignItems: 'center'}}>
        <TypeTag>
          {type.toLowerCase().replace(/^./, c => c.toUpperCase())}
        </TypeTag>
        {['table', 'column'].includes(item.kind) && hover && (
          <ToolTip
            config={{position: 'bottom_left'}}
            content={<div style={{padding: 8}}>Insert {item.kind} name into editor</div>}
          >
            <Button
              onClick={handleAppendContent}
              square
              variant="text"
              justifyContent="center"
              size="small"
              Component={Icon}
              componentProps={{
                icon: 'arrow-right',
                size: 16,
              }}
            />
          </ToolTip>
        )}
      </div>
    </RButton>
  )
}

export default memo(ListItem)
