import {useCallback, useEffect, useState} from 'react'
import {EMPTY_OBJECT} from '../../constants'
import {
  append,
  endsWith, isNil,
  map,
  pathOr,
  pipe,
  propOr,
  reject,
} from 'ramda'
import styled from 'styled-components'
import skeletonCss from '../../styles/skeletonCss'
import File from './file'
import {Icon} from 'frontcore'
import Line from '../line'
import {isArray} from '../../helpers'

const Root = styled.div`
  color: ${({theme}) => theme.palette.default[500]};
  fill: ${({theme}) => theme.palette.default[500]};
  display: flex;
  gap: 32px;
`

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32px;
`

const MetaDataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DropArea = styled.label`
  ${skeletonCss};
  width: 240px;
  min-width: 240px;
  height: 192px;
  padding: 16px;
  gap: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.3;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({theme}) => theme.palette.default[500]};
  border-radius: 2px;
  background-color: ${({drag}) =>
    drag ? 'lightgrey' : 'transparent'};

  &:hover {
    background-color: ${({theme}) =>
      theme.palette.default[100]};
  }
  &:active {
    background-color: ${({theme}) =>
      theme.palette.default[200]};
  }
`

const HiddenInput = styled.input`
  display: none;
`

const FileCountText = styled.div`
  font-weight: 800;
`

const FileUpload = ({
  value = [],
  onChange = () => {},
  accept = '',
  toBase64 = false,
  multiple = true,
  skeleton = false,
  errors = [],
  downloadable = true,
  disabled = false,
}) => {
  const [drag, setDrag] = useState(false)
  const [over, setOver] = useState(false)

  useEffect(() => {
    window.addEventListener('dragstart', (event) => {
      event.preventDefault()
    })
    window.addEventListener('dragstart', (event) => {
      event.preventDefault()
    })
    window.addEventListener('dragover', (event) => {
      event.preventDefault()
      setDrag(true)
    })

    window.addEventListener('drop', (event) => {
      event.preventDefault()
      setDrag(false)
      setOver(false)
    })
  }, [])

  const handleOnFileClick = useCallback((event) => {
    event.target.value = ''
  }, [])

  const handleOnAddFile = useCallback(
    ({file}) => {
      let result
      if (multiple) {
        result = append(
          {filename: file.name, data: file},
          value
        )
      } else {
        result = [{filename: file.name, data: file}]
      }

      if (toBase64) {
        const reader = new FileReader()
        reader.onload = (event) => {
          let result
          const data = event.target.result
          if (multiple) {
            result = append(
              {filename: file.name, data},
              value
            )
          } else {
            result = [{filename: file.name, data}]
          }
          onChange(result)
        }
        reader.readAsDataURL(file)
      } else {
        onChange(result)
      }
    },
    [value, onChange]
  )

  const handleOnChoseFile = useCallback(
    (event) => {
      const file = event.target.files[0]

      handleOnAddFile({file})
    },
    [handleOnAddFile]
  )

  const handleOnDropFile = useCallback(
    (event) => {
      const file = pathOr(
        EMPTY_OBJECT,
        ['dataTransfer', 'files', 0],
        event
      )
      const {name} = file
      if (endsWith(accept, name)) {
        handleOnAddFile({file})
      }
    },
    [handleOnAddFile]
  )

  const handleOnDragOver = useCallback(() => {
    if (over === false) {
      setOver(true)
    }
  }, [over, setOver])

  const handleOnDragLeave = useCallback(
    (a) => {
      setOver(false)
    },
    [setOver]
  )

  const handleOnDelete = useCallback(
    ({filename}) => {
      const result = reject(
        (element) => element.filename === filename,
        value
      )
      onChange(result)
    },
    [value, onChange]
  )

  const files = isArray(value) ? value : [value]

  return (
    <Root>
      {!disabled && (<DropArea
        $skeleton={skeleton}
        drag={over}
        onClick={handleOnFileClick}
        onChange={handleOnChoseFile}
        onDrop={handleOnDropFile}
        onDragOver={handleOnDragOver}
        onDragLeave={handleOnDragLeave}
      >
        <IconContainer>
          <Icon size={32} icon={'cloud-upload'} />
        </IconContainer>
        <TextContainer>
          {drag && <div>Drop Here</div>}
          {!drag && <div>Click To Upload</div>}
          {!drag && <div>Or Drag And Drop</div>}
        </TextContainer>
        <Line />
        <MetaDataContainer>
          {!multiple && (
            <FileCountText>Single File</FileCountText>
          )}
          {multiple && (
            <FileCountText>Multiple Files</FileCountText>
          )}
          <div>Extension: {accept || 'all'}</div>
        </MetaDataContainer>
        <HiddenInput type={'file'} accept={accept} />
      </DropArea>)}
      <ListContainer>
        {map(
          (file) => (
            <File
              data={file?.data}
              filename={file?.filename}
              onDelete={handleOnDelete}
              skeleton={skeleton}
              downloadable={downloadable}
            />
          ),
          files,
        )}
        {disabled && propOr(0, 'length', files) <= 0 && (
          <p>No files attached</p>
        )}
      </ListContainer>
    </Root>
  )
}

export default FileUpload
