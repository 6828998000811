import asyncThunk from '../index'

export const getJobs = asyncThunk({
  method: "GET",
  type: "/jobs/get",
  path: "/namespaces/${namespace}/jobs?limit=${size}&offset=${offset}",
  api: true,
  lineage: true
});

//https://ilum-test.cardinality.pl/external/lineage/api/v1/search?q=d&sort=UPDATE_AT&limit=100&filter=JOB
export default getJobs;
