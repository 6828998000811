import {useMemo} from 'react'
import getClustersFetch from '../api/workloads/clusters/getClustersFetch'
import {singleCallFetchOptions} from '../api/helpers'
import useFetch from './useFetch'
import {isNil} from 'ramda'

const DEFAULT_CLUSTER_NAME = 'default'

const useDefaultCluster = () => {
  const {data: clusters} = useFetch(
    getClustersFetch,
    singleCallFetchOptions
  )

  const result = useMemo(() => {
    if (isNil(clusters) || clusters.length <= 0) {
      return null
    }
    const defaultCluster = clusters.find(({name}) => {
      return name === DEFAULT_CLUSTER_NAME
    })
    return isNil(defaultCluster)
      ? clusters[0]
      : defaultCluster
  }, [clusters])

  return result
}

export default useDefaultCluster
