import asyncThunk from "../../index";

export const resumeService = asyncThunk({
  method: "POST",
  type: "service/resume",
  path: "/group/${id}/resume",
  api: true,
});

export default resumeService;
