import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {path} from 'ramda'
import {memo} from 'react'
import {SidebarTabs} from '../../../reducers/sql/sidebar'
import SidebarControls from './SidebarControls'
import SidebarQueries from './SidebarQueries'
import MetastoreBrowser from '../../../components/MetastoreBrowser'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Sidebar = () => {
  const tab = useSelector(
    path(['sqlViewer', 'sidebar', 'tab'])
  )

  return (
    <Root>
      {tab === SidebarTabs.queries && (
        <>
          <SidebarControls />
          <SidebarQueries />
        </>
      )}
      {tab === SidebarTabs.resources && (
        <>
          <MetastoreBrowser />
        </>
      )}
    </Root>
  )
}

export default memo(Sidebar)
