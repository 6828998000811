import asyncThunk from "../../index";

export const getJobOverview = asyncThunk({
  method: "GET",
  type: "jobOverview/get",
  path: "/job/${id}/metrics/overview",
  api: true,
});

export default getJobOverview;
