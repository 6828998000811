import {css, keyframes} from 'styled-components'
import {createTransitions} from '../helpers'

const FocusAnimation = keyframes`0% {
                                     opacity: 0.25;


                                 }
                                     50% {
                                         opacity: 1;


                                     }
                                     100% {
                                         opacity: 0.25;

                                     }`

const skeletonTrueCss = css`
    background-color: ${({theme}) => theme.palette.skeleton} !important;
    border-color: ${({theme}) => theme.palette.skeleton} !important;
    pointer-events: none;
    color: transparent;
    animation: ${FocusAnimation} 1s infinite;

    > * {
        opacity: 0 !important;
    }
`

const skeletonCss = css`
  transition: ${createTransitions(['color', 'background-color', 'border-color'])};

  > * {
    transition: ${createTransitions(['opacity'])};
    opacity: 1;
  }

  ${({$skeleton}) => $skeleton && skeletonTrueCss};
`

export default skeletonCss
