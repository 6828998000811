import styled, {css, keyframes} from 'styled-components'
import {createTransitions} from '../../../helpers'

const FocusAnimation = keyframes`0% {
                                     opacity: 0.25;
                                 }
                                     50% {
                                         opacity: 1;
                                     }
                                     100% {
                                         opacity: 0.25;
                                     }`

const selectedCss = css`
  border-color: ${({theme}) =>
    `${theme.palette.blue[500]}`};

  &::before {
    content: '';
    box-sizing: border-box;
    top: -4px;
    left: -4px;
    right: -4px;
    animation: ${FocusAnimation} 0.9s infinite;
    opacity: 1;
    bottom: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: ${({theme}) =>
      `${theme.palette.blue[200]}`};
    position: absolute;
  }
`
export const Root = styled.div`
   
  &::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-width: 0;
    transition: ${createTransitions([
      'top',
      'left',
      'right',
      'bottom',
      'border-width',
      'border-radius',
      'border-color',
      'border-width',
    ])};
  }

  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  box-sizing: border-box;
  background: white;
  width: 320px;
  overflow: hidden;
  transition: ${createTransitions(['border-color'])};
  ${({$selected}) => $selected && selectedCss};

  &:hover {
    background: ${({theme}) => theme.palette.neutral[50]};
  }

  &:active {
    background: ${({theme}) => theme.palette.neutral[100]};
  }
`
