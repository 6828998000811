import asyncThunk from "..";

export const updateSQLNotebook = asyncThunk({
  method: 'PUT',
  type: 'sql/notebook/update',
  path: '/sql/notebooks/${notebookId}',
  api: true,
})

export default updateSQLNotebook
