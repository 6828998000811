import {Button} from 'frontcore'
import styled from 'styled-components'
import {toUpper} from 'ramda'
import StyledLink from '../StyledLink'
import {c} from '@codemirror/legacy-modes/mode/clike'

const Root = styled.div`
  color: ${({theme}) => theme.palette.default[300]};
  font-family: Roboto, serif;
`

const TitleSpan = styled.span`
  color: ${({theme}) => theme.palette.default[500]};
`
const ViewNotAvailable = ({name = 'Module'}) => {
  return (
    <Root
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        gap: 20,
      }}
    >
      <>
        <span style={{fontSize: 32}}>
          <TitleSpan>{toUpper(name)}</TitleSpan> IS NOT
          AVAILABLE
        </span>
      </>
      <div style={{width: 600, textAlign: 'center'}}>
        <span style={{textTransform: 'capitalize'}}>
          {name}
        </span>{' '}
        is not available in this instance. Please contact
        administrator to turn it on. The documentation will
        provide you with more detailed information.
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
      <StyledLink to={'https://ilum.cloud/docs/production/'}>
        <Button>See Documentation</Button>
      </StyledLink>
      </div>
    </Root>
  )
}

export default ViewNotAvailable
