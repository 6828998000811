import asyncThunk from '../index'

export const getSearch = asyncThunk({
  method: "GET",
  type: "/search/get",
  path: "/search?q=${text}&sort=UPDATE_AT&limit=1000",
  api: true,
  lineage: true
});

export default getSearch;
