import asyncThunk from '../index'

export const getDatasets = asyncThunk({
  method: "GET",
  type: "/datasets/get",
  path: "/namespaces/${namespace}/datasets?limit=${size}&offset=${offset}",
  api: true,
  lineage: true
});

export default getDatasets;
