import {map, mergeDeepRight} from 'ramda'
import {EMPTY_STRING} from '../../constants'
import schema from '../../views/SettingsView/schema'

const defaultSettings = map(
  ({id, defaultValue}) => ({[id]: defaultValue}),
  schema
)

const SETTINGS_STORAGE_KEY = 'settings_saved_state'

const defaultState = Object.freeze({
  search: EMPTY_STRING,
  settings: defaultSettings,
})

export const loadSettingsState = () => {
  const maybeSavedState = window.localStorage.getItem(
    SETTINGS_STORAGE_KEY
  )
  if (maybeSavedState) {
    const savedState = JSON.parse(maybeSavedState)
    return mergeDeepRight(defaultState, savedState)
  }
  return defaultState
}

export const persistSettingsMiddleware =
  (store) => (next) => (action) => {
    const output = next(action)
    if (action.type.startsWith('settings/')) {
      const settingsState = store.getState().settings
      const settingsToSave = JSON.stringify(settingsState)
      window.localStorage.setItem(
        SETTINGS_STORAGE_KEY,
        settingsToSave
      )
    }
    return output
  }
