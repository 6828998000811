import {Input} from 'frontcore'
import ContextMenu from '../contextMenu'
import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react'
import styled from 'styled-components'

const Root = styled.div`
 
`

const DropDownInput = forwardRef(
  ({value, onChange, ContentComponent, contentProps, inputProps}, ref) => {
    const anchorRef = useRef()

    const [open, setOpen] = useState(false)

    const handleOnClick = useCallback(() => {
      setOpen(true)
    }, [])

    const handleOnBlur = useCallback(() => {
      setOpen(false)
    }, [])

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true)
        anchorRef.current?.focus()
      },
      close: () => {
        setOpen(false)
      },
      focus: () => {
        anchorRef.current?.focus()
      },
    }))

    return (
      <Root onClick={handleOnClick}>
        <Input {...inputProps} value={value} onChange={onChange} ref={anchorRef} />
        <ContextMenu
          open={open}
          ref={anchorRef}
          onBlur={handleOnBlur}
          topPadding={4}
          bottomPadding={4}
        >
          {ContentComponent && (
            <ContentComponent {...contentProps} closeFn={() => setOpen(false)} />
          )}
        </ContextMenu>
      </Root>
    )
  }
)

export default DropDownInput
