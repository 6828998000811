import {map, pipe} from 'ramda'
import React, {useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import List from '../../../../components/list'
import ItemComponent from '../../../../components/list/itemComponent'
import HeaderComponent from '../../../../components/list/headerComponent'
import ToolTipContentComponent from './toolTipContentComponent'
import styled from 'styled-components'
import schema from './schema'
import ClusterSingleButtons from '../ClusterSingleButtons'
import {EMPTY_FUNCTION} from '../../../../constants'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'

const Root = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const ClustersList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists,
    handleOnReset,
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    selected,
    setSelected,
  } = listHandler

  const {filter} = lists

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    fork(({message}) => {
      createNotification({
        title: 'Failed to fetch Clusters',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  return (
    <Root>
      <List
        filters={filter}
        schema={schema}
        data={extendedData}
        debug={false}
        pending={pending}
        onReset={handleOnReset}
        selected={selected}
        onSelect={setSelected}
        ItemComponent={ItemComponent}
        itemComponentProps={{
          toFn: ({id, name}) =>
            '/workloads/details/cluster/' + id + '/' + name,
          skeleton: pending,
          ButtonsComponent: ClusterSingleButtons,
          ToolTipComponent: ToolTipContentComponent,
          typeComponentProps: {
            type: 'cluster',
          },
          buttonsComponentProps: {
            texts: false,
            pending,
            getFuture,
            redirect: false,
          },
        }}
        HeaderComponent={HeaderComponent}
        headerComponentProps={{
          skeleton: pending,
          sort,
          onSort: handleOnSort,
        }}
        pagerProps={{
          size,
          page,
          totalPages,
          onChangeSize: handleOnSizeChange,
          onChangePage: handleOnPageChange,
        }}
      />
    </Root>
  )
}

export default ClustersList
