import {CheckBox} from 'frontcore'
import {dissoc} from 'ramda'
import HeaderButton from './headerButton'
import React, {useCallback} from 'react'
import styled from 'styled-components'
import {mapWithKey} from '../../helpers'
import {EMPTY_ARRAY} from '../../constants'

const Root = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 16px;
    padding-left: 24px;
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DataContainer = styled.div`
    flex: ${({$size}) => $size};
    width: ${({$width}) => $width};
    border: ${({$debug}) =>
            $debug === true ? '1px solid blue' : 'none'};
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
`

const TypeSpacer = styled.div`
    width: ${({$width}) => $width};
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderComponent = (props) => {
  const {
    schema = EMPTY_ARRAY,
    debug,
    sort,
    onSort,
    skeleton,
    selected,
    onSelect,
    typeSpacer = true,
    iconWidth = '48px',
  } = props

  const handleOnSort = useCallback(
    ({name, direction}) => {
      let result
      if (direction) {
        result = {[name]: direction}
      } else {
        result = dissoc(name, sort)
      }
      onSort(result)
    },
    [sort, onSort]
  )

  return (
    <Root>
      {onSelect && (
        <CheckBoxContainer>
          <CheckBox
            skeleton={skeleton}
            onChange={onSelect}
            value={selected}
          />
        </CheckBoxContainer>
      )}
      <div style={{width: 8}} />
      {typeSpacer && <TypeSpacer $width={iconWidth} />}
      {mapWithKey(
        (
          {
            label,
            size,
            width,
            value,
            description,
            Component,
            enableSort,
            lockable,
            ToolTipComponent
          },
          index
        ) => (
          <DataContainer
            key={index}
            $size={size}
            $width={width}
            $debug={debug}
          >
            <HeaderButton
              skeleton={skeleton}
              key={label}
              label={label}
              description={description}
              Component={Component}
              A={ToolTipComponent}
              ToolTipComponent={ToolTipComponent}
              size={size}
              value={value}
              sort={sort && sort[value]}
              enableSort={enableSort}
              onSortChange={handleOnSort}
              lockable={lockable}
            />
          </DataContainer>
        ),
        schema
      )}
    </Root>
  )
}

export default HeaderComponent
