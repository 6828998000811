import asyncThunk from "../../index";

export const getJobResources = asyncThunk({
  method: "GET",
  type: "jobResources/get",
  path: "/job/${id}/resources",
  api: true,
});

export default getJobResources;
