import {useEffect, useRef, useState} from "react"

const useDebouncedWithDeps = (value, delay, dependencies) => {
  const [debounced, setDebounced] = useState(value)
  const timerRef = useRef()

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setDebounced(value)
    }, delay)
    return () => {
      clearTimeout(timerRef.current)
      timerRef.current = undefined
    }
  }, [value, delay])

  useEffect(() => {
    clearTimeout(timerRef.current)
    timerRef.current = undefined
    setDebounced(value)
  }, dependencies)

  return debounced
}

export default useDebouncedWithDeps
