import styled from 'styled-components'
import {createTransitions} from '../../helpers'

const Root = styled.div`
  transition: ${createTransitions(['background-color'])};
  color: ${({theme}) => theme.palette.text.secondary};
  background-color: ${({theme}) => theme.palette['surface-primary']};
  display: flex;
  flex-direction: column;
  width: ${({$width}) => $width ||  '256px'};
  gap: 16px;
  padding: 16px;
`

const DropDownButtonContentLayout = ({children, width}) => <Root $width={width}>{children}</Root>

export default DropDownButtonContentLayout
