import {
  __,
  divide,
  map,
  pathOr,
  pipe,
  prop,
  propOr,
} from 'ramda'
import {useNavigate} from 'react-router-dom'
import {formatUrlParam} from '../../helpers'
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
} from '../../../../constants'
import React, {useEffect, useMemo, useState} from 'react'
import List from '../../../../components/list'
import HeaderComponent from '../../../../components/list/headerComponent'
import ItemComponent from '../../../../components/list/itemComponent'
import styled from 'styled-components'
import Line from '../../../../components/line'
import DetailsInfo from '../../../WorkloadsView/detailsInfo'
import useFuture from '../../../../hooks/useFuture'
import {fork} from 'fluture'
import getJobs from '../../../../api/lineage/getJobs'
import {useSelector} from 'react-redux'
import {isNotEmpty} from '../../../../helpers'

const Root = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const LineageJobsListView = () => {
  const [size, setSize] = useState(10)

  const [page, setPage] = useState(0)

  const model = useSelector(prop('lineage'))

  const namespaces = pipe(
    pathOr(EMPTY_ARRAY, [
      'data',
      'namespaces',
      'response',
      'namespaces',
    ]),
    map(prop('name'))
  )(model)

  const namespace = useMemo(
    () => propOr(EMPTY_STRING, 'namespace', model),
    [model]
  )

  const getJobsFuture = useFuture(getJobs)

  useEffect(() => {
    if (isNotEmpty(namespace)) {
      fork((r) => {})((r) => {})(
        getJobsFuture({
          params: [
            {
              key: 'namespace',
              value: formatUrlParam(namespace),
            },
            {key: 'size', value: size},
            {key: 'offset', value: page * size},
          ],
        })
      )
    }
  }, [namespace, size, page])

  const navigate = useNavigate()

  const extendedData = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, [
          'data',
          'jobs',
          'response',
          'jobs',
        ]),
        map((data) => ({data}))
      )(model),
    [model]
  )

  const totalElements = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, [
          'data',
          'jobs',
          'response',
          'totalCount',
        ])
      )(model),
    [model]
  )

  const totalPages = useMemo(
    () => pipe(divide(__, size), Math.ceil)(totalElements),
    [totalElements, size]
  )

  return (
    <Root
      style={{
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          height: 48,
          display: 'flex',
          alignItems: 'center',
          padding: '0 24px',
          justifyContent: 'space-between',
        }}
      >
        <DetailsInfo
          title={'Jobs'}
          data={[
            {key: 'Total Elements', value: totalElements},
          ]}
        />
      </div>
      <Line />
      <List
        schema={[
          {
            value: 'id',
            label: 'Name',
            size: 3,
            Component: ({value}) => value?.name,
            enableSort: false,
          },
          {
            value: 'id',
            label: 'Namespace',
            size: 1,
            Component: ({value}) => value?.namespace,
            enableSort: false,
          },

          {
            value: 'createdAt',
            label: 'Created At',
            size: 2,
            enableSort: false,
          },
          {
            value: 'latestRun',
            label: 'Latest Run Duration',
            size: 1.5,
            Component: ({value}) =>
              value?.durationMs || 'N/A',
            enableSort: false,
          },
          {
            value: 'latestRun',
            label: 'Latest Run State',
            size: 1.5,
            Component: ({value}) => value?.state || 'N/A',
            enableSort: false,
          },
        ]}
        data={extendedData}
        HeaderComponent={HeaderComponent}
        headerComponentProps={{
          sort: '',
          onSort: () => {},
          skeleton: false,
          typeSpacer: false,
        }}
        onReset={() => {}}
        ItemComponent={ItemComponent}
        itemComponentProps={{
          skeleton: false,
          toFn: ({id}) =>
            `/ilum-lineage/table-graph/job/${id?.name}/${formatUrlParam(namespace)}`,
        }}
        pagerProps={{
          size,
          page,
          totalPages,
          onChangeSize: setSize,
          onChangePage: setPage,
        }}
      />
    </Root>
  )
}

export default LineageJobsListView
