import asyncThunk from '../index'

export const getRole = asyncThunk({
  method: 'GET',
  type: 'role/get',
  path: '/roles/${id}',
  api: true,
  mock_disable: {
    "id": "8",
    "name": "finance_role",
    "description": "Finance team managing company budgets and financials.",
    "permissions": ["read", "manage"],
    "enabled": false,
    "users": 7,
    "groups": 1,
    "lastTimeCreated": 1679500000
  },
})

export default getRole
