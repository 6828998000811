import {useNavigate, useParams} from 'react-router-dom'
import FormLayout2 from '../../../../components/tabsFormLayout'
import React, {useCallback, useEffect} from 'react'
import Form from '../../../../components/Form'
import schema from './schema'
import useFuture from '../../../../hooks/useFuture'
import {fork} from 'fluture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {useDispatch} from 'react-redux'
import getUser from '../../../../api/security/getUser'
import {editUserPassword} from '../../../../api/security/editUserPassword'

const SecurityEditPasswordView = () => {
  const navigate = useNavigate()

  const editPasswordFuture = useFuture(editUserPassword)

  const getUserFuture = useFuture(getUser)

  const {createNotification} = useNotificationContext()

  const {userId} = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (userId) {
      fork(({message}) =>
        createNotification({
          title: 'Failed to fetch User',
          message,
          autoHide: false,
          variant: 'error',
        })
      )(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getUserFuture({
          params: [{key: 'id', value: userId}],
        })
      )
    }
  }, [userId])

  const handleOnSubmit = useCallback(
    (fields) => {
      fork(({message}) => {
        createNotification({
          title: 'Failed to edit password',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        navigate(-1)
        createNotification({
          message: 'Password edited successfully',
          autoHide: true,
          variant: 'success',
        })
      })(editPasswordFuture({...fields, params: [{key: 'id', value: userId}]}))
    },
    [userId]
  )

  return (
    <Form
      autocomplete={'off'}
      options={{
        pending: false,
        navigate,
      }}
      LayoutComponent={FormLayout2}
      layoutComponentProps={{
        title: 'CHANGE PASSWORD',
      }}
      schema={schema}
      onSubmit={handleOnSubmit}
    />
  )
}

export default SecurityEditPasswordView
