import {map, pipe} from 'ramda'
import React, {useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import styled from 'styled-components'
import List from '../../../../components/list'
import HeaderComponent from '../../../../components/list/headerComponent'
import ItemComponent from '../../../../components/list/itemComponent'
import ToolTipContentComponent from './toolTipContentComponent'
import schema from './schema'
import {EMPTY_FUNCTION} from "../../../../constants";
import {useNotificationContext} from "../../../../hooks/useNotificationsContext";

const Root = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const RequestsList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    lists,
    selected,
    setSelected,
  } = listHandler

  const {filter} = lists

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    fork(({message}) => {
      createNotification({
        title: 'Failed to fetch Requests',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  return (
    <Root>
      <List
        idProp={'requestId'}
        schema={schema}
        filters={filter}
        data={extendedData}
        debug={false}
        onReset={handleOnReset}
        selected={selected}
        onSelect={setSelected}
        HeaderComponent={HeaderComponent}
        headerComponentProps={{
          sort,
          onSort: handleOnSort,
          skeleton: pending,
        }}
        ItemComponent={ItemComponent}
        itemComponentProps={{
          skeleton: pending,
          toFn: ({id, requestId}) =>
            '/workloads/details/request/' + requestId,
          ToolTipComponent: ToolTipContentComponent,
          typeComponentProps: {
            type: 'request',
          },
          buttonsComponentProps: {
            texts: false,
          },
        }}
        pagerProps={{
          size,
          page,
          totalPages,
          onChangeSize: handleOnSizeChange,
          onChangePage: handleOnPageChange,
        }}
      />
    </Root>
  )
}

export default RequestsList
