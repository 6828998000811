import React, {memo, useState} from 'react'
import ExplorerWindow from './explorer'
import {Input, ResizeLayout} from 'frontcore'
import {HORIZONTAL} from '../../constants'
import {ILUM_SQL_ENABLED} from '../../helpers/runtimeEnv'
import ViewNotAvailable from '../../components/viewNotAvailable'
import List from './List'
import {singleCallFetchOptions} from '../../api/helpers'
import Switch from '../../components/Switch'

const TableExplorerView = () => {
  const [refreshRate, setRefreshRate] = useState(60000)
  const [autoRefresh, setAutoRefresh] = useState(true)

  const fetchOptions = autoRefresh ? {refreshInterval: refreshRate} : singleCallFetchOptions
  return (
    <div
      style={{
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <ResizeLayout
          configuration={{
            orientation: HORIZONTAL,
            firstNode: {
              measurement: 'px',
              value: 32 * 8,
              max: 32 * 16,
            },
            secondNode: {
              measurement: 'flex',
              value: 1,
            },
          }}
          firstNode={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <List fetchOptions={fetchOptions} />
            </div>
          }
          secondNode={<ExplorerWindow />}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          right: 16,
          top: 24,
          pointerEvents: 'all',
          display: 'flex',
          alignItems: 'center',
          gap: 16,
        }}
      >
        <div>Auto Refresh</div>
        <Switch value={autoRefresh} onClick={setAutoRefresh} />
        <Input
          value={refreshRate}
          onChange={setRefreshRate}
          topLabelProps={{
            children: 'Refresh Rate [ms]',
          }}
        />
      </div>
    </div>
  )
}

export default memo(() => {
  return ILUM_SQL_ENABLED?.toLowerCase() === 'true' ? (
    <TableExplorerView />
  ) : (
    <ViewNotAvailable name={'Data Explorer'} />
  )
})
