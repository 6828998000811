import asyncThunk from "../../index";

export const getScheduleMetricsMinor = asyncThunk({
  method: 'GET',
  type: 'schedule/metrics/minor/get',
  path: '/schedule/${scheduleId}/metrics/minor',
  api: true,
})

export default getScheduleMetricsMinor
