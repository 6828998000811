import asyncThunk from '../index'

export const getUsersSearch = asyncThunk({
  method: 'GET',
  type: 'usersSearch/get',
  path: '/users',
  api: true,

})

export default getUsersSearch
