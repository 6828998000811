import asyncThunk from "..";

export const saveSQLNotebook = asyncThunk({
  method: 'POST',
  type: 'sql/notebook/save',
  path: '/sql/notebooks',
  api: true,
})

export default saveSQLNotebook
