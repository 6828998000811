import {Button, CheckBox, Input} from 'frontcore'
import PermissionsSelect from '../../../../components/PermissionsSelect'
import {EMPTY_ARRAY} from '../../../../constants'
import requiredValidator from '../../../../validators/requiredValidator'
import RolesSelect from '../../../../components/RolesSelect'
import GroupsSelect from '../../../../components/GroupsSelect'
import {
  usernameDescription,
  passwordDescription,
  fullnameDescription,
  emailDescription,
  descriptionDescription,
  departmentDescription,
  groupsDescription,
  rolesDescription,
  permissionsDescription, enabledDescription
} from "../../../../texts/userForm";

const schema = (data, options) => {
  const {
    pending,
    navigate,
    permissions = EMPTY_ARRAY,
    roles = EMPTY_ARRAY,
    groups = EMPTY_ARRAY,
  } = options

  return {
    fields: [
      {
        id: 'name',
        section: 'General',
        name: 'Username',
        description: usernameDescription,
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'password',
        section: 'General',
        name: 'Password',
        description: passwordDescription,
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'fullname',
        section: 'General',
        name: 'Fullname',
        description: fullnameDescription,
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'email',
        section: 'General',
        name: 'Email',
        description: emailDescription,
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'description',
        section: 'General',
        name: 'Description',
        description: descriptionDescription,
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'department',
        section: 'General',
        name: 'Department',
        description: departmentDescription,
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'groups',
        section: 'Groups',
        name: 'Groups',
        description: groupsDescription,
        Component: GroupsSelect,
        componentProps: {
          permissions,
          options: groups,
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'roles',
        section: 'Roles',
        name: 'Roles',
        description: rolesDescription,
        Component: RolesSelect,
        componentProps: {
          permissions,
          roles,

          multiSelect: true,
          options: roles,
          skeleton: pending,

          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'permissions',
        section: 'Permissions',
        name: 'Permissions',
        description: permissionsDescription,
        Component: PermissionsSelect,
        componentProps: {
          options: permissions,
          skeleton: pending,
        },
        validators: [],
      },
      {
        id: 'state',
        section: 'General',
        name: 'Enabled',
        description: enabledDescription,
        Component: CheckBox,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
          validators: [],
        },
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
