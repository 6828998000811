import React, {useCallback, useMemo} from 'react'


import {map} from 'ramda'
import styled from 'styled-components'
import {Button, SliderV3} from 'frontcore'
import DropDownButtonContentLayout from '../../../../../components/layouts/dropDownButtonContentLayout'
import Form from '../../../../../components/Form'

const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 16px;
  padding: 16px;
  background-color: white;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const FormLayout = ({fields, buttons}) => {
  return (
    <DropDownButtonContentLayout width={'384px'}>
      <Title>Scale Service:</Title>
      {map(({element}) => element, fields)}
      <ButtonsContainer>
        {buttons}
      </ButtonsContainer>
    </DropDownButtonContentLayout>

  );
};

const schema = ({scale, closeFn}) => () => {
  return {
    fields: [
      {
        id: 'scale',
        defaultValue: scale,
        name: 'Scale',
        description: 'Scale description.',
        Component: SliderV3,
        componentProps: {
          skeleton: false,
          min: 1,
          max: 12,
          disabled: false,
          fullWidth: true,
          size: 'small',
        },
        validators: [],
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          children: 'Submit',
          type: 'submit',
          color: 'primary',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          onClick: closeFn,
          tabIndex: -1,
          children: 'Cancel',
          variant: 'outlined',
        },
      },
    ],
  };
};

const ScaleDropDownComponent = (a) => {
const {closeFn, scale, onScale} = a;
  const extendedSchema = useMemo(() => schema({scale, closeFn}), [schema, scale, closeFn]);
  const handleOnScale = useCallback(
    ({scale}) => {
      closeFn();
      onScale(scale);
    },
    [onScale, closeFn],
  );

  return (
    <Form schema={extendedSchema} LayoutComponent={FormLayout} onSubmit={handleOnScale} />
  );
};

export default ScaleDropDownComponent;
