import asyncThunk from '../index'

export const getRoles = asyncThunk({
  method: 'GET',
  type: 'roles/get',
  path: '/roles',
  api: true,
  mock_disable: {
    content: [
      {
        "id": "1",
        "name": "admin_group",
        "description": "Admin group with full permissions.",
        "permissions": ["read", "write", "delete", "manage"],
        "enabled": true,
        "users": 25,
        "groups": 3,
        "lastTimeCreated": 1675000000
      },
      {
        "id": "2",
        "name": "engineering_team",
        "description": "Engineering team focused on software development.",
        "permissions": ["read", "write"],
        "enabled": true,
        "users": 12,
        "groups": 2,
        "lastTimeCreated": 1677500000
      },
      {
        "id": "3",
        "name": "marketing_team",
        "description": "Marketing team responsible for campaigns and growth.",
        "permissions": ["read", "write", "manage"],
        "enabled": true,
        "users": 10,
        "groups": 1,
        "lastTimeCreated": 1678000000
      },
      {
        "id": "4",
        "name": "hr_group",
        "description": "Human resources group managing employee relations.",
        "permissions": ["read", "write"],
        "enabled": false,
        "users": 5,
        "groups": 1,
        "lastTimeCreated": 1676000000
      },
      {
        "id": "5",
        "name": "product_management",
        "description": null,
        "permissions": ["read", "manage"],
        "enabled": true,
        "users": 8,
        "groups": 2,
        "lastTimeCreated": 1675500000
      },
      {
        "id": "6",
        "name": "support_team",
        "description": "Customer support team for resolving client issues.",
        "permissions": ["read", "write"],
        "enabled": true,
        "users": 15,
        "groups": 1,
        "lastTimeCreated": 1674000000
      },
      {
        "id": "7",
        "name": "sales_team",
        "description": "Sales team focusing on generating revenue.",
        "permissions": ["read", "write", "manage"],
        "enabled": true,
        "users": 20,
        "groups": 1,
        "lastTimeCreated": 1677500000
      },
      {
        "id": "8",
        "name": "finance_group",
        "description": "Finance team managing company budgets and financials.",
        "permissions": ["read", "manage"],
        "enabled": false,
        "users": 7,
        "groups": 1,
        "lastTimeCreated": 1679500000
      },
      {
        "id": "9",
        "name": "data_science_team",
        "description": "Data science team analyzing data for insights.",
        "permissions": ["read", "write", "manage"],
        "enabled": true,
        "users": 10,
        "groups": 1,
        "lastTimeCreated": 1678500000
      },
      {
        "id": "10",
        "name": "operations_group",
        "description": "Operations group overseeing day-to-day activities.",
        "permissions": ["read", "manage"],
        "enabled": true,
        "users": 12,
        "groups": 2,
        "lastTimeCreated": 1675000000
      }
    ]
    ,
  },
})

export default getRoles
