import {Icon, Input, Select} from 'frontcore';
import {EMPTY_ARRAY} from '../../../../../constants';

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Jobs Search',
      topLabelProps: {children: 'Jobs Search'},
    },
  },
  {
    id: 'jobTypes',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Job Types',
      value: [],
      options: ['SINGLE', 'INTERACTIVE'],
      multiSelect: true,
      buttonProps: {
        width: '200px',
        justifyContent: 'space-between',
      },
    },
  },
  {
    id: 'jobStates',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Job States',
      size: 'small',
      value: [],
      options: [
        'SUBMITTED',
        'STARTING',
        'READY',
        'BUSY',
        'FAILED',
        'FINISHED',
        'UNKNOWN',
      ],
      multiSelect: true,
      buttonProps: {
        width: '200px',
        justifyContent: 'space-between',
      },
    },
  },
];

export default schema;