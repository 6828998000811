import asyncThunk from "./index";

export const getSparkUrl = asyncThunk({
  method: "GET",
  type: "sparkUrl/get",
  path: "/job/${id}/ui",
  api: true,
});

export default getSparkUrl;
