import styled, {useTheme} from 'styled-components'
import useFuture from '../../../../hooks/useFuture'
import getJobOverview from '../../../../api/workloads/jobs/getJobOverview'
import {useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import {useSelector} from 'react-redux'
import {map, pathOr} from 'ramda'

import {EMPTY_OBJECT} from '../../../../constants'
import {useParams} from 'react-router-dom'
import {convertObjectToArray} from '../../../../helpers'
import {GaugeChart, Icon, Resizer} from 'frontcore'
import MetricChart from '../../../../components/charts/MetricChart'
import formatBytes from '../../../../helpers/formatBytes'
import formatBigNumber from '../../../../helpers/formatBigNumber'
import Metric from '../../../../components/Metric'
import Layout from './JobDetailsOverview/Layout'
import ReactJson from 'react-json-view'
import BarChart from '../../../../components/charts/barChart'
import ChartPaper from '../../../../components/ChartPaper'
import Json from "../../../../components/Json";

const calculatePercent = (value) => (max) => {
  return (value * 100) / max
}

const Title = styled.div`
  font-size: 16px;
  height: 25px;
  font-weight: 800;
  font-family: Inter, serif;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const Grid = styled.div`
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`

const Grid2 = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
`

const Grid3 = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
`

const Grid5 = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`



const JobOverview = () => {
  const {jobId} = useParams()
  const getJobOverviewFuture = useFuture(getJobOverview)
  useEffect(() => {
    fork(console.log)(console.log)(
      getJobOverviewFuture({
        params: [{key: 'id', value: jobId}],
      })
    )
  }, [])

  const model = useSelector(
    pathOr(EMPTY_OBJECT, [
      'workloads',
      'data',
      'jobOverview',
      'response',
    ])
  )

  const {palette} = useTheme()

  const {
    inputRecords,
    inputBytes,
    outputRecords,
    outputBytes,
    shuffleReadRecords,
    shuffleReadBytes,
    shuffleWriteRecords,
    shuffleWriteBytes,
    numTasks,
    numActiveTasks,
    numCompleteTasks,
    numFailedTasks,
    numKilledTasks,
    numExecutors,
    driverMemory,
    jobMemory,
    memoryPerExecutor,
    driverCores,
    coresPerExecutor,
    jobCores,
    cpusPerTask,
    jvmHeapMemory,
    jvmOffHeapMemory,
    activeExecutors,
    deadExecutors,
    onHeapExecutionMemory,
    offHeapExecutionMemory,
    peakOnHeapStorageMemory,
    totalOnHeapStorageMemory,
    peakOffHeapStorageMemory,
    totalOffHeapStorageMemory,
    executorsMemoryUsage,
    executorsTaskTime,
    totalJVMHeapMemory,
    driverMemoryUsage,
    executorsMemory,
  } = model


  const totalExecutor =
    memoryPerExecutor * numExecutors * 1024 * 1024

  const colors = [
    palette.blue[500],
    palette.blue[400],
    palette.neutral[100],
  ]

  const totalMax = jobMemory * 1024 * 1024
  const totalValue = totalJVMHeapMemory
  const driverMax = driverMemory * 1024 * 1024
  const driverValue = driverMemoryUsage?.jvmHeapMemory || 0
  const executorsMax = executorsMemory * 1024 * 1024
  const executorsValue =
    executorsMemoryUsage?.jvmHeapMemory || 0

  const total = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 5,
      }}
    >
      <div
        style={{
          display: 'flex',
          minHeight: 200,
          flexDirection: 'column',
        }}
      >
        <Title>Total</Title>
        <ChartPaper>
          <Resizer>
            {({width, height}) => (
              <div
                style={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  height: '100%',
                }}
              >
                <GaugeChart
                  data={[
                    {
                      value: totalValue,
                      data: {},
                    },
                    {
                      value: 0,
                      data: {},
                    },
                    {
                      value: totalMax - totalValue,

                      data: {},
                    },
                  ]}
                  colors={colors}
                  centerNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric
                        value={calculatePercent(totalValue)(
                          totalMax
                        ).toFixed(2)}
                        unit={'%'}
                        size={'large'}
                      />
                    </div>
                  }
                  rightNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric
                        size={'small'}
                        {...formatBytes(totalMax)}
                      />
                    </div>
                  }
                  leftNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric size={'small'} value={0} />
                    </div>
                  }
                  bottomNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric
                        size={'small'}
                        value={0}
                        unit={'B'}
                      />
                      <Metric
                        size={'small'}
                        value={100}
                        unit={'MB'}
                      />
                    </div>
                  }
                  width={(height - 48) * 2}
                  height={height - 48}
                  thickness={24}
                  angularSize={180}
                />
              </div>
            )}
          </Resizer>
        </ChartPaper>
      </div>
      <Grid2>
        <MetricChart
          name={'Total Memory'}
          {...formatBytes(jobMemory * 1024 * 1024)}
        />
        <MetricChart
          name={'Total Cores'}
          {...formatBigNumber(jobCores)}
        />
        <div style={{gridColumn: '1/3'}}>
          <MetricChart
            name={'Total Executors'}
            {...formatBigNumber(numExecutors)}
          />
        </div>
      </Grid2>
    </div>
  )

  const executors = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 5,
      }}
    >
      <div
        style={{
          display: 'flex',
          minHeight: 200,
          flexDirection: 'column',
        }}
      >
        <Title>Executors</Title>
        <ChartPaper>
          <Resizer>
            {({width, height}) => (
              <div
                style={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  height: '100%',
                }}
              >
                <GaugeChart
                  data={[
                    {
                      value: executorsValue,
                      data: {},
                    },
                    {value: 0, data: {}},
                    {
                      value: executorsMax - executorsValue,
                      data: {},
                    },
                  ]}
                  colors={colors}
                  centerNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric
                        value={calculatePercent(
                          executorsValue
                        )(executorsMax).toFixed(2)}
                        unit={'%'}
                        size={'large'}
                      />
                    </div>
                  }
                  leftNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric size={'small'} value={0} />
                    </div>
                  }
                  rightNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric
                        size={'small'}
                        {...formatBytes(totalExecutor)}
                      />
                    </div>
                  }
                  bottomNode={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Metric
                        size={'small'}
                        value={0}
                        unit={'B'}
                      />
                      <Metric
                        size={'small'}
                        value={100}
                        unit={'MB'}
                      />
                    </div>
                  }
                  width={(height - 48) * 2}
                  height={height - 48}
                  thickness={24}
                  angularSize={180}
                />
              </div>
            )}
          </Resizer>
        </ChartPaper>
      </div>
      <Grid3>
        <MetricChart
          name={'Memory Per Executor'}
          {...formatBytes(memoryPerExecutor  * 1024 * 1024)}
        />
        <MetricChart
          name={'Cores Per Executor'}
          {...formatBigNumber(coresPerExecutor)}
        />
        <MetricChart
          name={'Active Executors'}
          {...formatBigNumber(activeExecutors)}
        />
        <MetricChart
          name={'Total Executors Memory'}
          {...formatBytes(totalExecutor)}
        />

        <MetricChart
          name={'Total Executors Cores'}
          {...formatBigNumber(numExecutors * coresPerExecutor)}
        />

        <MetricChart
          name={'Dead Executors'}
          variant={deadExecutors > 0 ? 'error' : 'default'}
          {...formatBigNumber(deadExecutors)}
        />
        {/*<MetricChart*/}
        {/*  name={'Cores Per Executor'}*/}
        {/*  {...formatBytes(coresPerExecutor)}*/}
        {/*/>*/}
        {/*<MetricChart*/}
        {/*  name={'Driver CPUs'}*/}
        {/*  value={2}*/}
        {/*  unit={'hardcoded'}*/}
        {/*/>*/}
      </Grid3>
    </div>
  )

  const driver = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 5
      }}
    >
      <Title>Driver</Title>

      <ChartPaper>
        <Resizer>
          {({width, height}) => (
            <div
              style={{
                display: 'flex',
                boxSizing: 'border-box',
                justifyContent: 'center',
                alignItems: 'flex-end',
                height: '100%',
              }}
            >
              <GaugeChart
                data={[
                  {value: driverValue, data: {}},
                  {value: 0, data: {}},
                  {
                    value: driverMax - driverValue,
                    data: {},
                  },
                ]}
                colors={colors}
                centerNode={
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Metric
                      value={calculatePercent(driverValue)(
                        driverMax
                      ).toFixed(2)}
                      unit={'%'}
                      size={'large'}
                    />
                  </div>
                }
                leftNode={
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Metric size={'small'} value={0} />
                  </div>
                }
                rightNode={
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Metric
                      size={'small'}
                      {...formatBytes(
                        driverMemory * 1024 * 1024
                      )}
                    />
                  </div>
                }
                bottomNode={
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Metric
                      size={'small'}
                      value={0}
                      unit={'B'}
                    />
                    <Metric
                      size={'small'}
                      value={100}
                      unit={'MB'}
                    />
                  </div>
                }
                width={(height - 48) * 2}
                height={height - 48}
                thickness={24}
                angularSize={180}
              />
            </div>
          )}
        </Resizer>
      </ChartPaper>

      <Grid2>
        <div style={{gridColumn: '1/3'}}>
          <MetricChart
            name={'Driver Memory'}
            {...formatBytes(driverMemory * 1024 * 1024)}
          />
        </div>
        <div style={{gridColumn: '1/3'}}>
          <MetricChart
            name={'Driver Cores'}
            {...formatBigNumber(driverCores)}
          />
        </div>
      </Grid2>
    </div>
  )

  const executorsData = useMemo(
    () =>
      map(
        ({executorId, taskTime}) => ({
          x: executorId,
          y: taskTime,
        }),
        executorsTaskTime || []
      ),
    [executorsTaskTime]
  )

  return (
    <Layout
      topLeftNode={total}
      topMiddleNode={driver}
      topRightNode={executors}
      bottomLeftNode={
        <div ></div>
      }
      bottomMiddleNode={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <div style={{flex: 1}}>
            <Title>Tasks</Title>
            <Grid5>
              <MetricChart
                name={'All Tasks'}
                {...formatBigNumber(numTasks)}
              />
              <MetricChart
                name={'Completed Tasks'}
                {...formatBigNumber(numCompleteTasks)}
              />
              <MetricChart
                name={'Failed Tasks'}
                variant={
                  numFailedTasks > 0 ? 'error' : 'default'
                }
                {...formatBigNumber(numFailedTasks)}
              />

              <MetricChart
                name={'Active Tasks'}
                {...formatBigNumber(numActiveTasks)}
              />
              <MetricChart
                name={'Killed Tasks'}
                {...formatBigNumber(numKilledTasks)}
              />
            </Grid5>
          </div>
          <div style={{flex: 1}}>
            <Grid5>
              <div
                style={{
                  gridColumn: '1/3',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title>Executors Task Time [hours]</Title>
                <ChartPaper>
                  <Resizer>
                    {({width, height}) => (
                      <BarChart
                        width={width}
                        height={height}
                        data={executorsData}
                        xAxisType={'String'}
                        yAxisType={'Number'}
                        xAxisScale={'band'}
                        yAxisScale={'linear'}
                        graphOrientation={'horizontal'}
                        colors={['#ffd445', 'red', 'blue']}
                      />
                    )}
                  </Resizer>
                </ChartPaper>
              </div>
              <div
                style={{
                  gridColumn: '3/6',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title>Processing</Title>
                <div style={{display: 'flex'}}>
                  <div
                    style={{
                      flex: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 5,
                    }}
                  >
                    <MetricChart
                      name={'Input Records'}
                      {...formatBigNumber(inputRecords)}
                    />
                    <MetricChart
                      name={'Input Bytes'}
                      {...formatBytes(inputBytes)}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: 5,
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      <Icon
                        icon={'arrow-right'}
                        size={48}
                        color={palette.neutral[500]}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 5,
                    }}
                  >
                    <MetricChart
                      name={'Output Records'}
                      {...formatBigNumber(outputRecords)}
                    />
                    <MetricChart
                      name={'Output Bytes'}
                      {...formatBytes(outputBytes)}
                    />
                  </div>
                </div>
              </div>
            </Grid5>
          </div>
        </div>
      }
      rightNode={
        <div
          style={{
            padding: 10,
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
            overflow: 'scroll',
          }}
        >
          <Json src={model} />
        </div>
      }
    />
  )
}

export default JobOverview
