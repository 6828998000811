import {ILUM_HISTORY_SERVER_PATH} from "../../helpers/runtimeEnv";
import {ILUM_HISTORY_SERVER_ENABLED} from "../../helpers/runtimeEnv";
import ViewNotAvailable from '../../components/viewNotAvailable'

const HistoryView = () => {
    return ILUM_HISTORY_SERVER_ENABLED.toLowerCase() === 'true' ? (

        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_HISTORY_SERVER_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    ): (
        <ViewNotAvailable name={'History Server'} />
    )
};

HistoryView.dispayName = "HistoryView";

export default HistoryView;

