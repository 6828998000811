import asyncThunk from "./index";

export const invalidateLicense = asyncThunk({
  method: "POST",
  type: "license/invalidate",
  path: "/license/invalidate",
  api: true,
});

export default invalidateLicense;
