import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFuture from '../../../../hooks/useFuture'
import {useNavigate} from 'react-router-dom'
import scaleService from '../../../../api/workloads/services/scaleService'
import deleteService from '../../../../api/workloads/services/deleteService'
import archiveService from '../../../../api/workloads/services/archiveService'
import pauseService from '../../../../api/workloads/services/pauseService'
import resumeService from '../../../../api/workloads/services/resumeService'
import restartService from '../../../../api/workloads/services/restartService'
import React, {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import {and, fork} from 'fluture'
import ServiceButtons from "../ServiceButtons";
import {active} from "d3";

const ServiceSingleButtons = ({
  pending,
  data,
  texts,
  getFuture = () => {},
  redirect = true,
}) => {
  const {createNotification} = useNotificationContext()

  const {id} = data

  const navigate = useNavigate()

  const scaleServiceFuture = useFuture(scaleService)
  const deleteServiceFuture = useFuture(deleteService)
  const archiveServiceFuture = useFuture(archiveService)
  const pauseServiceFuture = useFuture(pauseService)
  const resumeServiceFuture = useFuture(resumeService)
  const restartServiceFuture = useFuture(restartService)

  const handleOnDelete = useCallback(({id}) => {
    pipe(
      when(always(!redirect), and(getFuture)),
      fork(({message}) => {
        createNotification({
          title: 'Failed to delete Service',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        redirect && navigate('/workloads/list/services')
        createNotification({
          message: 'Service deleted successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteServiceFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnArchive = useCallback(({id}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to archive Service',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          message: 'Service archived successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      archiveServiceFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnScale = useCallback(({id, value}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to scale Service',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Service scaled successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      scaleServiceFuture({
        params: [
          {key: 'id', value: id},
          {key: 'scale', value},
        ],
      })
    )
  }, [])
  const handleOnPause = useCallback(({id}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to pause Service',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Service successfully paused',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      pauseServiceFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnResume = useCallback(({id}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to resume Service',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Service successfully resume',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      resumeServiceFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnRestart = useCallback(({id}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to restart Service',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Service successfully resume',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      restartServiceFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnEdit = useCallback(
    ({id}) => navigate('/workloads/edit/service/' + id),
    []
  )

  const handleOnToggle = useCallback(({id}) => {
    if(data?.state === 'ACTIVE') {
      handleOnPause({id})
    }
    if(data?.state === 'PAUSED') {
      handleOnResume({id})
    }
  }, [handleOnPause, handleOnResume, data?.state ])

  return (
    <ServiceButtons
      texts={texts}
      pending={pending}
      id={id}
      state={data?.state}
      kind={data?.kind}
      scale={data?.scale}
      onScale={handleOnScale}
      onArchive={handleOnArchive}
      onDelete={handleOnDelete}
      onRestart={data?.state !== 'ARCHIVED' && handleOnRestart}
      onEdit={data?.state !== 'ARCHIVED' && handleOnEdit}
      onToggle={data?.state !== 'ARCHIVED' && handleOnToggle}
    />
  )
}

export default ServiceSingleButtons
