import {memo, useMemo} from "react"
import styled from "styled-components"
import {State} from "./state"
import {EMPTY_FUNCTION} from "../../constants"
import { createTransitions } from "../../helpers"

const Root = styled.div`
  cursor: pointer;
  width: 36px;
  height: 20px;
  background: ${({$state, theme}) => {
    switch ($state) {
      case State.OFF: return theme.palette.neutral[400]
      case State.STARTING: return theme.palette.yellow[400]
      default: return theme.palette.green[400]
    }
  }};
  display: flex;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: ${createTransitions(['background'])};
`

const Spacer = styled.div`
  flex: ${({ $full = false }) => $full ? 1 : 0};
  transition: ${createTransitions(['flex'])};
`

const Circle = styled.div`
  height: 14px;
  width: 14px;
  background: white;
  border-radius: 50%;
`

const StateSwitch = ({
  state = State.OFF,
  onClick = EMPTY_FUNCTION,
}) => {
  const fullSpacer = useMemo(() => {
    return [State.STARTING, State.ON]
      .includes(state)
  }, [state])

  return (
    <Root $state={state} onClick={onClick}>
      <Spacer $full={fullSpacer} />
      <Circle />
    </Root>
  )
}

export default memo(StateSwitch)
