import {createSlice} from '@reduxjs/toolkit';
import {extraReducersMapper} from '../../api';
import authenticate from '../../api/authenticate';

const initialState = {

};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    init: () => {
      return initialState;
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: authenticate,
      name: "authenticate",
    },
  ]),
});

export const { init } = loginSlice.actions;

export default loginSlice.reducer;
