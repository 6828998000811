import React, {createContext, useState, useMemo, useCallback} from 'react';
import {append, reject} from 'ramda';
import Notifications from "../../components/notifications";
import {useStorage} from "../../hooks/useStorage";

export const NotificationContext = createContext(undefined);

export const NotificationProvider = ({children}) => {
  const [notifications, setNotifications] = useState([]);

  const {setLocalStorageItem} = useStorage()

  const createNotification = useCallback(({message, variant = 'default', autoHide, title}) => {

    setLocalStorageItem('notificationHistory', append({title, variant, message, data: Date.now()}))
    setNotifications((prevState) => {
      let id = Date.now().toString(36) + Math.random().toString(36).substring(2);
      return append({id, message, variant, autoHide, title}, prevState);
    });
  }, [setLocalStorageItem]);

  const closeNotification = useCallback((id) => {
    setNotifications((prevState) => {
      return reject((notification) => notification.id === id, prevState);
    });
  }, []);

  const context = useMemo(
    () => ({
      notifications,
      createNotification,
      closeNotification,
    }),
    [notifications, createNotification, closeNotification]
  );

  return (
    <NotificationContext.Provider value={context}>
      {children} <Notifications />
    </NotificationContext.Provider>
  );
};
