import asyncThunk from "../../index";

export const executeJob = asyncThunk({
  method: "POST",
  type: "job/execute",
  path: "/truly/group/${id}/job",
  api: true,
});

export default executeJob;
