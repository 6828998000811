import asyncThunk from "../../index";

export const pauseService = asyncThunk({
  method: "POST",
  type: "service/pause",
  path: "/group/${id}/pause",
  api: true,
});

export default pauseService;
