import asyncThunk from '../index'

export const getColumn = asyncThunk({
  method: "GET",
  type: "/column/get",
  path: "/column-lineage?nodeId=${nodeType}:${namespace}:${nodeId}&depth=${depth}&withDownstream=true",
  api: true,
  lineage: true
});

export default getColumn;
