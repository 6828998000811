import asyncThunk from '../index'

export const getActivity = asyncThunk({
  method: 'GET',
  type: 'activity/get',
  path: '/activities/${id}',
  api: true,
})

export default getActivity
