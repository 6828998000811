import {filter, isNil, map, path, pathOr, pipe, toPairs} from 'ramda'
import React, {useEffect, useState} from 'react'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useDispatch, useSelector} from 'react-redux'
import {EMPTY_ARRAY, EMPTY_STRING} from '../../../constants'
import getCatalog from '../../../api/table/getCatalog'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'
import {Tabs} from 'frontcore'
import useFetch from '../../../hooks/useFetch'
import {setSelectedElement} from '../../../reducers/tableExplorer'
import getDatabasesFetch from '../../../api/table/getDatabasesFetch'
import Json from '../../../components/Json'
import RButton from '../../../components/RButton'

const Databases = () => {
  const {metaStoreId, metaStoreName, catalogId, catalogName} = useSelector(
    path(['tableExplorer', 'selectedElement'])
  )

  const {data = EMPTY_STRING, isLoading} = useFetch(
    getDatabasesFetch,
    {},
    {
      params: [
        {key: 'metaStoreId', value: metaStoreId},
        {key: 'catalogId', value: catalogId},
      ],
    }
  )
  const dispatch = useDispatch()
  return (
    <div style={{display: 'flex', overflow: 'hidden', gap: 12, padding: 12, flexWrap: 'wrap'}}>
      {map(
        (id) => (
          <RButton
            icon={'database'}
            name={id}
            onClick={() =>
              dispatch(
                setSelectedElement({
                  type: 'database',
                  metaStoreId,
                  metaStoreName,
                  catalogId,
                  catalogName,
                  databaseId: id,
                  databaseName: id,
                })
              )
            }
          />
        ),
        data
      )}
    </div>
  )
}

const CatalogDetails = () => {
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))
  const [tab, setTab] = useState('databases')

  const {catalogId, metaStoreId} = selectedElement

  const getCatalogFuture = useFuture(getCatalog)

  const details = useSelector(pathOr(EMPTY_ARRAY, ['data', 'data', 'catalog', 'response']))

  const extendedDetailsData = pipe(
    toPairs,
    filter(([_, value]) => !isNil(value)),
    map((a) => ({key: a[0], value: a[1]}))
  )(details)

  const tabsContent = (
    <Tabs
      value={tab}
      onChange={setTab}
      options={[
        {
          id: 'databases',
          children: 'Databases',
        },
        {
          id: 'raw',
          children: 'Raw',
        },
      ]}
    />
  )

  useEffect(() => {
    fork(() => {})(() => {})(
      getCatalogFuture({
        params: [
          {
            key: 'metaStoreId',
            value: metaStoreId,
          },
          {key: 'catalogId', value: catalogId},
        ],
      })
    )
  }, [metaStoreId])

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'catalog'} />}
      name={details?.name}
      id={details?.type}
      pending={false}
      data={extendedDetailsData}
    />
  )

  const bottomContent = (
    <>
      {tab === 'databases' && <Databases />}
      {tab === 'raw' && (
        <div style={{padding: 16}}>
          <Json src={details} />
        </div>
      )}
    </>
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={tabsContent}
      filtersContent={null}
      bottomContent={bottomContent}
    />
  )
}

export default CatalogDetails
