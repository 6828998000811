import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {Button} from 'frontcore'
import {useDispatch, useSelector} from 'react-redux'
import {path} from 'ramda'
import {sidebarMutateQuery} from '../../../reducers/sql/sidebar'
import {editorDecreaseFontSize, editorIncreaseFontSize} from '../../../reducers/sql/editor'

const Root = styled.div`
  position: absolute;
  top: 10px; right: 10px;
  z-index: 1;
  display: flex;
  & > * + * {
    margin-left: 4px;
  }
`

const FontIndicator = styled.span`
  margin-right: 4px;
  color: grey;
  opacity: 0;
  transition: 0.1s opacity ease-in-out;
  ${({ $show }) => $show && css`
    opacity: 1;
  `}
`

const INDICATOR_TIME = 300

const EditorFontController = () => {
  const dispatch = useDispatch()

  const queryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryCode = useMemo(() => {
    const query = queries.find(({ id }) => id === queryId)
    return query?.statement
  }, [queryId, queries])

  /*
   * forceCodemirrorUpdate() is a hack of codemirror
   * that forces it to update the style
   * after font change
   */
  const forceCodemirrorUpdate = useCallback(() => {
    dispatch(sidebarMutateQuery({
      id: queryId,
      statement: ' ',
    }))
    setTimeout(() => {
      dispatch(sidebarMutateQuery({
        id: queryId,
        statement: queryCode,
      }))
    }, 0)
  }, [queryId, queryCode])

  const [showIndicator, setShowIndicator] = useState(false)
  const timeout = useRef()
  const requestIndicator = useCallback(() => {
    clearTimeout(timeout.current)
    setShowIndicator(true)
    timeout.current = setTimeout(() => {
      setShowIndicator(false)
    }, INDICATOR_TIME)
  }, [])

  const handleIncrease = useCallback(() => {
    dispatch(editorIncreaseFontSize())
    forceCodemirrorUpdate()
    requestIndicator()
  }, [forceCodemirrorUpdate, requestIndicator])

  const handleDecrease = useCallback(() => {
    dispatch(editorDecreaseFontSize())
    forceCodemirrorUpdate()
    requestIndicator()
  }, [forceCodemirrorUpdate, requestIndicator])

  const fontSize = useSelector(path(['sqlViewer', 'editor', 'fontSize']))

  return (
    <Root>
      <FontIndicator $show={showIndicator}>{fontSize}px</FontIndicator>
      <Button square
        variant='outlined'
        size='small'
        justifyContent='center'
        onClick={handleIncrease}
      >{'+'}</Button>
      <Button square
        variant='outlined'
        size='small'
        justifyContent='center'
        onClick={handleDecrease}
      >{'-'}</Button>
    </Root>
  )
}

export default memo(EditorFontController)
