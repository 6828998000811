import {assoc, assocPath, find, findIndex, gt, isEmpty, length, lt, pipe, propOr} from 'ramda'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../../constants'
import {AutoCompleteInput, Button, Input, Select, SingleSelectTemplate, SliderV3} from 'frontcore'
import FileUpload from '../../../../components/fileUpload'
import {onSubmitSingleFile} from '../../../../helpers/form'
import Parameters from '../../../../components/parameters'
import StorageMap from '../../../../components/storageMap'
import React from 'react'
import RadioButtons from '../../../../components/RadioButtons'
import SparkVersionAutoCompleteItem, {
  SparkVersions,
} from '../../../../components/SparkVersionAutoCompleteItem'
import requiredValidator from '../../../../validators/requiredValidator'
import MemorySlider from '../../../../components/MemorySlider'
import MemorySliderTemp from '../../../../components/MemorySliderTemp'
import minimumValidator from '../../../../validators/minimumValidator'
import memorySettings from "../../memorySettings";

const schema = (data, options) => {
  const {pending, navigate} = options
  const storagesOptions = pipe(propOr(EMPTY_ARRAY, 'storages'))(data)

  const yarn = data.type === 'yarn' ? [] : []

  const local =
    data.type === 'local'
      ? [
          {
            id: 'threads',
            section: 'Local',
            defaultValue: 6,
            name: 'Threads',
            description: 'Worker threads for running spark locally',
            Component: SliderV3,
            componentProps: {
              skeleton: pending,
              fullWidth: true,
              autoFocus: true,
              min: 1,
              max: 20,
            },
            validators: [
              (value) => (lt(value, 1) ? 'Minimum number of threads is 1' : ''),
              (value) => (gt(value, 20) ? 'Maximum number of threads is 20' : ''),
            ],
          },
        ]
      : EMPTY_ARRAY

  const kubernetes =
    data.type === 'k8s'
      ? [
          {
            id: 'url',
            section: 'Kubernetes - General',
            name: 'Url',
            description: '',
            Component: Input,
            componentProps: {
              skeleton: pending,
              fullWidth: true,
              autoFocus: true,
            },
            validators: [requiredValidator],
          },
          {
            id: 'caCert',
            section: 'Kubernetes - General',
            name: 'CaCert',
            description: 'Kubernetes clusters ca certificate file',
            Component: FileUpload,
            componentProps: {
              skeleton: pending,
              multiple: false,
              fullWidth: true,
              toBase64: true,
            },
            validators: [],
            onSubmitFn: onSubmitSingleFile,
          },
          {
            id: 'clientKey',
            section: 'Kubernetes - General',
            name: 'ClientKey',
            description: 'Kubernetes clusters client key file',
            Component: FileUpload,
            componentProps: {
              skeleton: pending,
              multiple: false,
              fullWidth: true,
              toBase64: true,
            },
            validators: [],
            onSubmitFn: onSubmitSingleFile,
          },
          {
            id: 'clientCert',
            section: 'Kubernetes - General',
            name: 'ClientCert',
            description: 'Kubernetes clusters client cert file',
            Component: FileUpload,
            componentProps: {
              skeleton: pending,
              multiple: false,
              fullWidth: true,
              toBase64: true,
            },
            validators: [],
            onSubmitFn: onSubmitSingleFile,
          },
          {
            id: 'username',
            section: 'Kubernetes - General',
            name: 'Username',
            description: 'Kubernetes clusters username',
            Component: Input,
            componentProps: {
              type: 'search',
              skeleton: pending,
              fullWidth: true,
              autoComplete: 'new-password',
            },
            validators: [],
          },
          {
            id: 'password',
            section: 'Kubernetes - General',
            name: 'Password',
            description: 'Kubernetes clusters password',
            Component: Input,
            componentProps: {
              skeleton: pending,
              type: 'password',
              autoComplete: 'new-password',
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'clientKeyPassphrase',
            section: 'Kubernetes - General',
            name: 'ClientKeyPassphrase',
            description: 'Kubernetes clusters client key passphrase',
            Component: Input,
            componentProps: {
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'clientKeyAlgorithm',
            section: 'Kubernetes - General',
            name: 'ClientKeyAlgorithm',
            description: 'Kubernetes clusters client key algorithm',
            Component: Input,
            componentProps: {
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'limitsPod',
            section: 'Kubernetes - Resource Quota',
            name: 'Limits Pod',
            description: 'Number of Pods cannot exceed this value.',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'limitsCpu',
            section: 'Kubernetes - Resource Quota',
            name: 'Limits Cpu',
            description: 'Sum of CPU limits cannot exceed this value.',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'limitsMemory',
            section: 'Kubernetes - Resource Quota',
            name: 'Limits Memory',
            description: 'Sum of memory limits cannot exceed this value.',
            Component: Input,
            componentProps: {
              placeHolder: 'default unit is [Gi] 1Gi [1 gigabyte], Ti for Gi, Ti',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'requestsCpu',
            section: 'Kubernetes - Resource Quota',
            name: 'Requests Cpu',
            description: 'Sum of CPU requests cannot exceed this value.',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'requestsMemory',
            section: 'Kubernetes - Resource Quota',
            name: 'Requests Memory',
            description: 'Sum of memory requests cannot exceed this value.',
            Component: Input,
            componentProps: {
              placeHolder: 'default unit is [Gi] 1Gi [1 gigabyte], Ti for Gi, Ti',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'cpuDefaultRequest',
            section: 'Kubernetes - CPU Limit Range',
            name: 'CPU Default Request',
            description: 'Sets default CPU request for single Pod',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'cpuMinRequest',
            section: 'Kubernetes - CPU Limit Range',
            name: 'CPU Minimum Request',
            description: 'Enforces minimum CPU usage per Pod.',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'cpuDefaultLimit',
            section: 'Kubernetes - CPU Limit Range',
            name: 'CPU Default Limit',
            description: 'Sets default CPU limit for single Pod',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'cpuMaxLimit',
            section: 'Kubernetes - CPU Limit Range',
            name: 'CPU Maximum Limit',
            description: 'Enforces maximum CPU usage per Pod',
            Component: Input,
            componentProps: {
              placeHolder: 'integer',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'cpuMaxLimitRequestRatio',
            section: 'Kubernetes - CPU Limit Range',
            name: 'CPU Max Limit Request Ratio',
            description: 'Enforces a maximum ratio between request and limit',
            Component: Input,
            componentProps: {
              placeHolder: 'float',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'memoryDefaultRequest',
            section: 'Kubernetes - Memory Limit Range',
            name: 'Memory Default Request',
            description: 'Sets default memory request for single Pod',
            Component: Input,
            componentProps: {
              placeHolder: 'default unit is [Gi] 1Gi [1 gigabyte], Ti for Gi, Ti',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'memoryMinRequest',
            section: 'Kubernetes - Memory Limit Range',
            name: 'Memory Minimum Request',
            description: 'Enforces minimum memory usage per Pod',
            Component: Input,
            componentProps: {
              placeHolder: 'default unit is [Gi] 1Gi [1 gigabyte], Ti for Gi, Ti',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'memoryDefaultLimit',
            section: 'Kubernetes - Memory Limit Range',
            name: 'Memory Default Limit',
            description: 'Sets default memory limit for single Pod',
            Component: Input,
            componentProps: {
              placeHolder: 'default unit is [Gi] 1Gi [1 gigabyte], Ti for Gi, Ti',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },

          {
            id: 'memoryMaxLimit',
            section: 'Kubernetes - Memory Limit Range',
            name: 'Memory Maximum Limit',
            description: 'Enforces maximum CPU/memory usage per Pod.',
            Component: Input,
            componentProps: {
              placeHolder: 'default unit is [Gi] 1Gi [1 gigabyte], Ti for Gi, Ti',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
          {
            id: 'memoryMaxLimitRequestRatio',
            section: 'Kubernetes - Memory Limit Range',
            name: 'Memory Max Limit Request Ratio',
            description: 'Enforces a maximum ratio between request and limit',
            Component: Input,
            componentProps: {
              placeHolder: 'float',
              floatingDigits: 21,
              skeleton: pending,
              fullWidth: true,
            },
            validators: [],
          },
        ]
      : []

  return {
    fields: [
      {
        id: 'name',
        section: 'General',
        name: 'Name',
        description: 'Cluster name',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'description',
        section: 'General',
        name: 'Description',
        description: 'Cluster description',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'type',
        section: 'General',
        defaultValue: 'local',
        name: 'Type',
        description: 'Cluster type',
        Component: RadioButtons,
        componentProps: {
          fullWidth: true,
          options: [
            {id: 'local', label: 'Local'},
            {id: 'k8s', label: 'Kubernetes'},
            {id: 'yarn', label: 'Yarn'},
          ],
        },
      },
      {
        id: 'sparkVersion',
        name: 'Spark Version',
        section: 'General',
        defaultValue: 'ilum/spark:3.5.3-delta',
        description: 'Choose Spark version that fits your needs',
        onChangeFn: (id, value, fields = EMPTY_OBJECT) => {
          const config = propOr(EMPTY_ARRAY, 'defaultApplicationConfig', fields)
          const index = pipe(
            findIndex(({key}) => key === 'spark.kubernetes.container.image'),
            (a) => (a === -1 ? length(config) : a)
          )(config)
          const result = {
            key: 'spark.kubernetes.container.image',
            value,
          }
          return assocPath(['defaultApplicationConfig', index], result, fields)
        },
        Component: AutoCompleteInput,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          options: SparkVersions,
          ItemComponent: SparkVersionAutoCompleteItem,
        },
      },
      {
        id: 'defaultApplicationConfig',
        section: 'Configuration',
        defaultValue: [
          {
            key: 'spark.kubernetes.container.image',
            value: 'ilum/spark:3.5.3-delta',
          },
        ],
        name: 'Default Application Config',
        description:
          'Default spark configuration properties to be attached for each job run on this clusters',
        Component: Parameters,
        onChangeFn: (id, value, fields = EMPTY_OBJECT) => {
          const config = propOr(EMPTY_ARRAY, 'defaultApplicationConfig', fields)
          const spark = pipe(find(({key}) => key === 'spark.kubernetes.container.image'))(config)
          return assoc('sparkVersion', spark?.value, fields)
        },
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'storages',
        section: 'Storage',
        name: 'Storages',
        description: 'Spark storages to be attach',
        Component: StorageMap,
        componentProps: {
          skeleton: pending,
          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'sparkStorage',
        section: 'Storage',
        name: 'Spark Storage',
        description: 'One of configured storages to be used as a storage for spark resources',
        Component: Select,
        componentProps: {
          ItemComponent: ({name, onClick, value}) => {
            return (
              <Button
                variant={'text'}
                tabIndex={-1}
                active={name === value}
                onClick={() => onClick(value)}
                fullWidth={true}
                rounding={false}
              >
                <div
                  style={{
                    display: 'flex',
                    overflow: 'hidden',
                    flexDirection: 'column',
                    justifyContent: 'start',
                  }}
                >
                  <div style={{fontSize: 14, display: 'flex'}}>{value?.name}</div>
                  <div style={{fontSize: 10, display: 'flex'}}>{value?.type}</div>
                </div>
              </Button>
            )
          },
          buttonProps: {
            skeleton: pending,
            disabled: isEmpty(storagesOptions),
            fullWidth: true,
            Component: (a) => {
              return <SingleSelectTemplate value={a?.value?.name} />
            },
          },
          fullWidth: true,
          options: storagesOptions,
        },
        validators: [requiredValidator],
      },
      ...memorySettings(data, options),
      ...local,
      ...kubernetes,
      ...yarn,
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
