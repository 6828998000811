import styled, {useTheme} from 'styled-components'
import React from 'react'
import {Icon} from 'frontcore'

const Root = styled.div`
  font-family: Roboto, serif;
  color: ${({theme}) => theme.palette.text.secondary};
  padding: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const TitleContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  color: ${({theme}) => theme.palette.text.tertiary};
 
`

const NoItemsFound = ({
  title = 'No Result Found',
  text = 'There are no items with the selected phrase',
}) => {
  const {palette} = useTheme()
  return (
    <Root>
      <Icon
        icon={'search'}
        size={48}
        color={palette.text.tertiary}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}
      >
        <TitleContainer>{title}</TitleContainer>
        <TextContainer>{text}</TextContainer>
      </div>
    </Root>
  )
}

export default NoItemsFound
