import DropDownInput from '../../../../components/dropDownInput'
import React, {useEffect, useMemo, useState} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {fork} from 'fluture'
import {mapWithKey} from '../../../../helpers'
import {useSelector} from 'react-redux'
import {isEmpty, pathOr, pipe, propOr, take} from 'ramda'
import {LetterMiniature} from '../../../../components/miniatures'
import getElements from '../../../../api/getElements'
import useDebounce from '../../../../hooks/useDebounce'
import {Button, Icon} from 'frontcore'
import {EMPTY_STRING, VERTICAL} from '../../../../constants'
import NoItemsFound from '../../../../components/NoResultFound'
import StyledLink from '../../../../components/StyledLink'
import RButton from '../../../../components/RButton'
import Line from '../../../../components/line'
import {DescriptionText} from '../../../../components/typography'
import RecentlyVisitedList from '../../../../components/RecentlyVisitedList'

const typeMap = {
  cluster: 'cluster',
  group: 'service',
  job: 'job',
  request: 'request',
  schedule: 'schedule',
}
export const ButtonWrapper = (a) => {
  const {type, name, id} = a

  const end = type === 'cluster' ? '/' + name : ''

  return (
    <StyledLink to={'/workloads/details/' + typeMap[type] + '/' + id + end}>
      <RButton
        name={name || id}
        IconComponent={LetterMiniature}
        iconComponentProps={{type: typeMap[type]}}
        type={typeMap[type]}
      />
    </StyledLink>
  )
}

const ContentComponent = ({closeFn, data, pending}) => {
  const clusters = () =>
    mapWithKey((props, index) => <ButtonWrapper key={index} {...props} pending={pending} />, data)

  const result = isEmpty(data) ? (
    <NoItemsFound />
  ) : (
    <div
      style={{
        width: 212,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div style={{padding: 4}}>
        <DescriptionText>Search Result</DescriptionText>
      </div>
      <Line />
      <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: 4}}>
        {clusters({closeFn})}
      </div>
    </div>
  )

  return (
    <div
      style={{
        userSelect: pending ? 'none' : 'auto',
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <RecentlyVisitedList />
      <Line orientation={VERTICAL} />
      {result}
    </div>
  )
}

const WorkloadsGlobalSearch = () => {
  const [search, setSearch] = useState(EMPTY_STRING)

  const getElementsFuture = useFuture(getElements)

  const data = useSelector(pathOr({}, ['workloads', 'data', 'elements']))

  const pending = useMemo(() => propOr(false, 'pending', data), [data])

  const extendedData = useMemo(() => pipe(propOr(false, 'response'), take(20))(data), [data])

  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    fork(() => {})(() => {})(
      getElementsFuture({
        search: debouncedSearch,
        size: 5,
        page: 0,
      })
    )
  }, [debouncedSearch])

  return (
    <DropDownInput
      inputProps={{
        placeHolder: 'Global Search',
        StartComponent: Icon,
        startComponentProps: {size: 16, icon: 'search'},
        EndComponent: Button,
        paddingRight: '4px',
        endComponentProps: {
          square: true,
          size: 'small',
          onClick: (event) => {
            event.stopPropagation()
            setSearch(EMPTY_STRING)
          },
          variant: 'text',
          justifyContent: 'center',
          tabIndex: -1,
          Component: Icon,
          componentProps: {
            size: 16,
            icon: 'trash',
          },
        },
      }}
      value={search}
      onChange={setSearch}
      ContentComponent={ContentComponent}
      contentProps={{
        data: extendedData,
        pending,
      }}
    />
  )
}

export default WorkloadsGlobalSearch
