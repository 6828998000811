import { memo, useEffect, useMemo } from 'react'
import {useSelector} from 'react-redux'
import {pathOr} from 'ramda'
import NoResultFound from '../../../../../components/NoResultFound'
import useFuture from '../../../../../hooks/useFuture'
import getScheduleMetricsMinor from '../../../../../api/workloads/schedules/getScheduleMetricsMinor'
import {fork} from 'fluture'
import {EMPTY_FUNCTION, EMPTY_OBJECT} from '../../../../../constants'
import { GaugeChart, skeletonCss } from 'frontcore'
import styled from 'styled-components'
import {formatBytes} from '../../../../../helpers'
import SingleMetricBarChart from '../../../../../components/charts/singleMericBarChart'

export const Root = styled.div`
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
`

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MetricsRoot = styled.div`
  width: 100%; height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 4px;
`

const MemoryContainer = styled.div`
  ${skeletonCss};
  padding: 16px 64px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`

const CPUContainer = styled.div`
  ${skeletonCss};
  flex: 1;
  padding: 16px 32px;
  padding-bottom: 64px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
  margin-right: 4px;
`

const adaptiveScale = ({ value, increase, start = 1 }) => {
  let scale = start
  while (scale < value) {
    scale *= increase
  }
  return scale
}

const ScheduleDetailsMetrics = ({ scheduleId }) => {
  const metricsFuture = useFuture(getScheduleMetricsMinor)

  useEffect(() => {
    if (!scheduleId) return
    const params = Object.freeze({
      params: [{ key: 'scheduleId', value: scheduleId }]
    })
    const future = metricsFuture(params)
    fork(({ message }) => {
      // TODO: add notification here
    })(EMPTY_FUNCTION)(future)
  }, [scheduleId])

  const { 
    response: metrics,
    pending,
    error
  } = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'scheduleMetrics']))

  const {
    allocatedCPU = 0,
    allocatedMemory = 0,
    runningContainers = 0,
  } = metrics ?? {}

  const maxAllocatedMemory = useMemo(() => {
    return adaptiveScale({
      value: allocatedMemory,
      increase: 1024,
    })
  }, [allocatedMemory])

  const maxAllocatedCPU = useMemo(() => {
    return adaptiveScale({
      value: allocatedCPU,
      increase: 2,
      start: 16,
    })
  }, [allocatedCPU])

  const maxRunningContainers = useMemo(() => {
    return adaptiveScale({
      value: runningContainers,
      increase: 2,
      start: 16,
    })
  }, [runningContainers])

  return (
    <Root>
      {error && (
        <Centered>
          <NoResultFound
            title='No Metrics Available'
            text='Please, try again later'
          />
        </Centered>
      )}
      {!error && (
        <MetricsRoot>
          <MemoryContainer $skeleton={pending}>
            <h1>Allocated Memory:</h1>
            <GaugeChart
              value={allocatedMemory}
              min={0}
              max={maxAllocatedMemory}
            />
            <h2 style={{ color: 'grey' }}>(Scale: {formatBytes(maxAllocatedMemory)})</h2>
          </MemoryContainer>
          <CPUContainer $skeleton={pending}>
            <h1>Resources:</h1>
            <SingleMetricBarChart
              label={`Allocated CPU: ${allocatedCPU} units (scale: ${maxAllocatedCPU} units)`}
              value={allocatedCPU}
              max={maxAllocatedCPU}
            />
            <SingleMetricBarChart
              label={`Running Containers: ${runningContainers} units (scale: ${maxRunningContainers} units)`}
              value={runningContainers}
              max={maxRunningContainers}
            />
          </CPUContainer>
        </MetricsRoot>
      )}
    </Root>
  )
}

export default ScheduleDetailsMetrics
