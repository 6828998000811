import { createAsyncThunk } from '@reduxjs/toolkit';

export const validateLicenseKeyFuture = createAsyncThunk(
    'license/validateKey',
    async ({ key }, { rejectWithValue }) => {
        try {
            const res = await fetch(
                "https://api.keygen.sh/v1/accounts/ilum-cloud/licenses/actions/validate-key",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({
                        meta: { key },
                    }),
                }
            );

            if (!res.ok) {
                const errorData = await res.json();
                return rejectWithValue(errorData);
            }

            const { meta, data, errors } = await res.json();
            return { meta, data, errors };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
