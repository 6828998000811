import asyncThunk from '../index'

export const getActivities = asyncThunk({
  method: 'GET',
  type: 'activities/get',
  path: '/activities',
  api: true,
})

export default getActivities
