import asyncThunk from "..";

export const getSQLNotebooks = asyncThunk({
  method: 'GET',
  type: 'sql/notebooks/get',
  path: '/sql/notebooks',
  api: true,
})

export default getSQLNotebooks
