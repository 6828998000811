import {GraphicWalker} from '@kanaries/graphic-walker'
import {VegaTheme} from '@kanaries/graphic-walker/vis/theme'
import useFuture from '../../../../hooks/useFuture'
import executeSQLStatement from '../../../../api/sql/executeSQLStatement'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import {resultsSetErrorMessage} from '../../../../reducers/sql/results'
import {EMPTY_FUNCTION} from '../../../../constants'
import {fromPairs, map, path, pipe, zip} from 'ramda'
import mapField from '../../../sql/results/modes/typeMapper'
import styled from 'styled-components'
import {skeletonCss} from 'frontcore'
import {
  ILUM_SQL_ENABLED,
} from '../../../../helpers/runtimeEnv'
import ViewNotAvailable from '../../../../components/viewNotAvailable'
import useDefaultCluster from '../../../../hooks/useDefaultCluster'
import darkTheme from "../../../../styles/darkTheme";
import lightTheme from "../../../../styles/lightTheme";

const Root = styled.div`
  ${skeletonCss}
`
const DataExploration = () => {
    const chosenItem = useSelector(path(['tableExplorer', 'selectedElement']))



  const a = useSelector(
    path([
      'sqlViewer',
      'results',
      'data',
      'result',
      'response',
    ])
  )

  const pending = useSelector(
    path([
      'sqlViewer',
      'results',
      'data',
      'result',
      'pending',
    ])
  )

  const columns = a?.columns || []
  const rows = a?.rows || []

  const executeSQLStatementFuture = useFuture(
    executeSQLStatement
  )
  const {createNotification} = useNotificationContext()

  const dispatch = useDispatch()

  const defaultCluster = useDefaultCluster()

  useEffect(() => {

    fork(({message}) => {
      dispatch(resultsSetErrorMessage(message))
      createNotification({
        title: 'Failed to run sql query',
        message,
        variant: 'error',
        autoHide: false,
      })
    })(EMPTY_FUNCTION)(
      executeSQLStatementFuture({
        maxRows: 1000,
        engine: 'SPARK_SQL',
        statement: `select * from ${chosenItem?.databaseName}.${chosenItem?.tableName} limit 1000`,
        clusterId: defaultCluster?.id,
      })
    )
  }, [chosenItem?.databaseName, chosenItem?.tableName, defaultCluster])

  const walkerFields = useMemo(() => {
    return columns.map(mapField)
  }, [columns])

  const walkerData = useMemo(() => {
    const fields = map(({name}) => name, columns)
    return map(pipe(zip(fields), fromPairs), rows)
  }, [columns, rows])

    const mode = useSelector(path(['settings', 'settings', 'darkMode']))

    return ILUM_SQL_ENABLED.toLowerCase() === 'true' ? (
    <Root
      $skeleton={pending}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      {!pending && (
        <GraphicWalker
          data={walkerData}
          fields={walkerFields}
          vizThemeConfig={VegaTheme}
          darkMode={mode ? "dark" : "light"}
        />
      )}
    </Root>
  ) : (
    <ViewNotAvailable name={'SQL'} />
  )
}

export default DataExploration
