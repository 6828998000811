import asyncThunk from "../../index";

export const deleteService = asyncThunk({
  method: "DELETE",
  type: "service/delete",
  path: "/group/${id}",
  api: true,
});

export default deleteService;
