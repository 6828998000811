import {flatten, groupBy, map, pathOr, pipe, prop, propOr} from 'ramda'
import React, {useEffect, useMemo, useState} from 'react'
import styled, {css} from 'styled-components'
import Line from '../line'
import {Tabs} from 'frontcore'
import {EMPTY_STRING} from '../../constants'
import {convertObjectToArray, isNotEmpty} from '../../helpers'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px 0;
  overflow: hidden;
`

const TitleContainer = styled.div`
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
`

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0 8px 0 0;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 16px;
  padding-right: 16px;
  gap: 16px;
`

const titleCss = css`
  color: ${({theme}) => theme.palette.blue[500]};
  font-family: Roboto, serif;
  font-weight: 500;
  font-size: 18px;
`

const Title = styled.div`
  ${titleCss};
`

const Row = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px 0;
  width: 960px;
`

const FirstColumn = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const NameContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const DescriptionContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const SecondColumn = styled.div`
  flex: 1;
`

const TabRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({$invalid, theme}) => $invalid && theme.palette.red[500]};
`

const TabComponent = ({name, value, active}) => {
  const errors = pipe(
    map(({componentProps}) => componentProps.errors),
    flatten
  )(value)

  return <TabRoot $invalid={isNotEmpty(errors)}>{name}</TabRoot>
}

const TabsContainer = styled.div`
  padding-left: 24px;
  display: flex;
  justify-content: center;
  height: 40px;
`

const SecurityFormLayout = ({title, buttons, fields}) => {
  const tabs = useMemo(() =>
    pipe(groupBy(prop('section')), convertObjectToArray)(fields),
    [fields]
  )

  const [activeTab, setActiveTab] = useState('')

  useEffect(() => {
    const initialTab = pathOr(EMPTY_STRING, [0, 'key'], tabs)
    setActiveTab(initialTab)
  }, [])

  const content = useMemo(() => {
    const sectionFields = pipe(
      groupBy(prop('section')),
      propOr([], activeTab)
    )(fields)

    return sectionFields.map(({id, element, name, description}, index) => (
      <React.Fragment key={`${id}-${index}`}>
        <Row>
          <FirstColumn>
            <NameContainer>{name}</NameContainer>
            <DescriptionContainer>{description}</DescriptionContainer>
          </FirstColumn>
          <SecondColumn>{element}</SecondColumn>
        </Row>
        {index < sectionFields.length - 1 && <Line key={`line-${id}`} />}
      </React.Fragment>
    ))
  }, [fields, activeTab])

  const tabOptions = useMemo(() =>
    map(
      ({key, value}) => ({
        id: key,
        children: key,
        componentProps: {
          name: key,
          value,
        },
        Component: TabComponent,
      }),
      tabs
    ),
    [tabs]
  )

  return (
    <Root>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <TabsContainer>
        <Tabs
          value={activeTab}
          onChange={setActiveTab}
          options={tabOptions}
        />
      </TabsContainer>
      <Line />
      <ContentContainer>{content}</ContentContainer>
      <Line />
      <ButtonsContainer>{buttons}</ButtonsContainer>
    </Root>
  )
}

export default SecurityFormLayout
