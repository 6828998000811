import { Fragment, memo, useEffect, useMemo } from 'react'
import cronstrue from 'cronstrue'
import ToolTipDetailsGridContentLayout, {
  Key,
  Row,
  Value,
} from '../../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'
import useFuture from '../../../../hooks/useFuture'
import getScheduleMetricsMinor from '../../../../api/workloads/schedules/getScheduleMetricsMinor'
import {identity, isNil, pathOr} from 'ramda'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import {EMPTY_FUNCTION, EMPTY_OBJECT} from '../../../../constants'
import {useSelector} from 'react-redux'
import ScheduleMetrics from './ScheduleMetrics'
import styled from 'styled-components'
import {skeletonCss} from 'frontcore'

const PendingMetrics = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  ${skeletonCss}
`

const mapping = Object.freeze({
  Name:        'name',
  ID:          'id',
  Cluster:     'clusterName',
  Description: 'description',
  Scheduled:   {
    key: 'cron',
    transform: (cron) => {
      try {
        return cronstrue.toString(cron, {
          throwExceptionOnParseError: true,
          verbose: true,
        })
      } catch {
        return 'Unknown'
      }
    }
  },
})

const ScheduleTooltip = ({ data }) => {
  const metricsFuture = useFuture(getScheduleMetricsMinor)
  const { createNotification } = useNotificationContext()
  useEffect(() => {
    const params = Object.freeze([{ key: 'scheduleId', value: data.id }])
    const futureObject = metricsFuture({ params })
    fork(({ message }) => {
      /* TODO: enable when time will come
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
      */
    })(EMPTY_FUNCTION)(futureObject)
  }, [])

  const metricsPath = Object.freeze(['workloads', 'data', 'scheduleMetrics'])
  const { response: metrics, pending, error } = useSelector(pathOr(EMPTY_OBJECT, metricsPath))

  const displayMetrics = useMemo(() => {
    return !isNil(metrics) && !pending && !error
  }, [metrics, pending, error])

  return (
    <ToolTipDetailsGridContentLayout>
      {Object.entries(mapping).map(([label, info], idx) => {
        let key, transform
        if (typeof info === 'string') {
          key = info
          transform = identity
        } else {
          key = info.key
          transform = info.transform
        }
      
        return (
          <Fragment key={idx}>
            <Key>{label}</Key>
            <Value style={{ maxWidth: 200 }}>
              {transform(data[key]) ?? 'Unknown'}
            </Value>
          </Fragment>
        )
      })}
      { displayMetrics && <ScheduleMetrics metrics={metrics} /> }
      { pending && (
        <>
          <Row>Fetching metrics...</Row>
          <Row><PendingMetrics $skeleton /></Row>
        </>
      ) }
      { error && <Row>No metrics available</Row> }
    </ToolTipDetailsGridContentLayout>
  )
}

export default memo(ScheduleTooltip)
