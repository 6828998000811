import DetailsInfo from '../../../WorkloadsView/detailsInfo'
import DateCell from '../../../WorkloadsView/dateCell'
import Line from '../../../../components/line'
import React, {useMemo} from 'react'
import DataSetDetails from './DataSetDetails'
import BatchDetails from './BatchDetails'
import {useSelector} from 'react-redux'
import {path, prop, propOr} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'

const typeMap = {
  DB_TABLE: {
    name: 'Dataset',
    Component: DataSetDetails,
  },
  BATCH: {
    name: 'Batch',
    Component: BatchDetails,
  },
}

const Details = ({namespace}) => {
  const model = useSelector(prop('lineage'))

  const selectedNode = useMemo(
    () => propOr(EMPTY_OBJECT, 'selectedNode', model),
    [model]
  )
  const {name, type, createdAt, updatedAt} = selectedNode

  const title = typeMap[type]?.name + ' Details'

  const Component = path([type, 'Component'], typeMap)

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{padding: 8, overflow: 'hidden'}}>
        <DetailsInfo
          title={title}
          skeleton={false}
          data={[
            {
              key: 'name',
              value: name,
            },
            {
              key: 'type',
              value: type,
            },
            {
              key: 'Created Time',
              value: createdAt,
              Component: DateCell,
            },
            {
              key: 'Modified Time',
              value: updatedAt,
              Component: DateCell,
            },
          ]}
        />
      </div>
      <Line />
      {Component && <Component namespace={namespace}/>}
    </div>
  )
}

export default Details
