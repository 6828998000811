import DropDownInput from '../../../../components/dropDownInput'
import React, {useCallback, useEffect, useState} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {fork} from 'fluture'
import {isNotEmpty, mapWithKey} from '../../../../helpers'
import {useDispatch, useSelector} from 'react-redux'
import {flatten, isEmpty, map, pathOr, toUpper} from 'ramda'
import {setDetails} from '../../../../reducers/workloads'
import {LetterMiniature} from '../../../../components/miniatures'
import useDebounce from '../../../../hooks/useDebounce'
import {Button, Icon} from 'frontcore'
import {EMPTY_FUNCTION, EMPTY_STRING, VERTICAL} from '../../../../constants'
import NoItemsFound from '../../../../components/NoResultFound'
import StyledLink from '../../../../components/StyledLink'
import RButton from '../../../../components/RButton'
import RecentlyVisitedList from '../../../../components/RecentlyVisitedList'
import Line from '../../../../components/line'
import getUsersSearch from '../../../../api/security/getUsersSearch'
import getRolesSearch from '../../../../api/security/getRolesSearch'
import getGroupsSearch from '../../../../api/security/getGroupsSearch'
import getActivitiesSearch from '../../../../api/security/getActivitiesSearch'
import {DescriptionText} from '../../../../components/typography'
import Chip from "../../../../components/Chip";
import UserIcon from "../../../../components/Icons/UserIcon";

const typeMap = {
  cluster: 'cluster',
  group: 'service',
  job: 'job',
  request: 'request',
  schedule: 'schedule',
}

const getIconComponent = (type, name, method) => {

    switch (type) {
        case 'role':
            return () => <Chip type="ROLE">{name}</Chip>
        case 'group':
            return () => <Chip type="GROUP">{name}</Chip>
        case 'activity':
            return () => <Chip type={method}>{method}</Chip>
        case 'user':
            return () => (
                <UserIcon color="#FF8B96">
                    {toUpper(name?.substring(0, 1) || '')}
                </UserIcon>
            )
        default:
            return LetterMiniature
    }
}




export const ButtonWrapper = (a) => {
  const {type, name, id, method} = a

  const end = type === 'cluster' ? '/' + name : ''

  return (
    <StyledLink to={'/security/details/' + type + '/' + id + end}>
      <RButton
        name={method ||name || id}
        IconComponent={getIconComponent(type,name || id, method)}
        iconComponentProps={{type, method}}
        type={type}
      />
    </StyledLink>
  )
}

const ContentComponent = ({closeFn, data, pending}) => {
  const clusters = () =>
    mapWithKey((props, index) => <ButtonWrapper key={index} {...props} pending={pending} />, data)

  const result = isEmpty(data) ? (
    <NoItemsFound />
  ) : (
    <div
      style={{
        width: 212,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div style={{padding: 4}}>
        <DescriptionText>Search Result</DescriptionText>
      </div>
      <Line />
      <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: 4}}>
        {clusters({closeFn})}
      </div>
    </div>
  )

  return (
    <div
      style={{
        userSelect: pending ? 'none' : 'auto',
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <RecentlyVisitedList />
      <Line orientation={VERTICAL} />
      {result}
    </div>
  )
}

const SecurityGlobalSearch = () => {
  const [search, setSearch] = useState(EMPTY_STRING)

  const getUsersSearchFuture = useFuture(getUsersSearch)
  const getGroupsSearchFuture = useFuture(getGroupsSearch)
  const getRolesSearchFuture = useFuture(getRolesSearch)
  const getActivitiesSearchFuture = useFuture(getActivitiesSearch)

  const users = useSelector(pathOr({}, ['security', 'data', 'usersSearch', 'response', 'content']))
  const groups = useSelector(
    pathOr({}, ['security', 'data', 'groupsSearch', 'response', 'content'])
  )
  const roles = useSelector(pathOr({}, ['security', 'data', 'rolesSearch', 'response', 'content']))
  const activities = useSelector(
    pathOr({}, ['security', 'data', 'activitiesSearch', 'response', 'content'])
  )

  const extendedUsers = map(({name, id}) => ({type: 'user', id, name}), users)
  const extendedRoles = map(({name, id}) => ({type: 'role', id, name}), roles)
  const extendedGroups = map(({name, id}) => ({type: 'group', id, name}), groups)
  const extendedActivities = map(({name, id, method}) => ({type: 'activity', id, name, method}), activities)

  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getActivitiesSearchFuture({
        search: debouncedSearch,
        size: 100,
        page: 0,
      })
    )
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getGroupsSearchFuture({
        search: debouncedSearch,
        size: 100,
        page: 0,
      })
    )
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getUsersSearchFuture({
        search: debouncedSearch,
        size: 100,
        page: 0,
      })
    )
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getRolesSearchFuture({
        search: debouncedSearch,
        size: 100,
        page: 0,
      })
    )
  }, [debouncedSearch])

  return (
    <DropDownInput
      inputProps={{
        placeHolder: 'Security Search',
        StartComponent: Icon,
        startComponentProps: {size: 16, icon: 'search'},
        EndComponent: Button,
        paddingRight: '4px',
        endComponentProps: {
          square: true,
          size: 'small',
          onClick: (event) => {
            event.stopPropagation()
            setSearch(EMPTY_STRING)
          },
          variant: 'text',
          justifyContent: 'center',
          tabIndex: -1,
          Component: Icon,
          componentProps: {
            size: 16,
            icon: 'trash',
          },
        },
      }}
      value={search}
      onChange={setSearch}
      ContentComponent={ContentComponent}
      contentProps={{
        data: flatten([extendedUsers, extendedGroups, extendedRoles, extendedActivities]),
        pending: false,
      }}
    />
  )
}

export default SecurityGlobalSearch
