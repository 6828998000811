const colors = {
  white: "#FAFAFA",
  defaultLight: {
    0: '#FFFFFF',
    100: '#EFF2F8',
    200: '#DFE6F2',
    300: '#C0C9DA',
    400: '#9BA3B5',
    500: '#6B7285',
    600: '#4E5672',
    700: '#353E5F',
    800: '#222A4D',
    900: '#141B3F',
  },
  defaultDark: {
    0: '#202530',
    100: '#303848',
    200: '#404B60',
    300: '#48556D',
    400: '#51607D',
    500: '#657698',
    600: '#8C99B3',
    700: '#B4BCCD',
    800: '#DCE0E8',
    900: '#F7F9FC',
  },
  primaryLight: {
    0: '#EBF0FF',
    100: '#CBDCFD',
    200: '#A1C4F2',
    300: '#82B1ED',
    400: '#639DE9',
    500: '#2476E0',
    600: '#1C65C4',
    700: '#1755A5',
    800: '#0F3567',
    900: '#000C4F',
  },
  primaryDark: {
    0: '#0A2547',
    100: '#0F3567',
    200: '#134586',
    300: '#1755A5',
    400: '#1C65C4',
    500: '#2476E0',
    600: '#639DE9',
    700: '#82B1ED',
    800: '#A1C4F2',
    900: '#E0EBFB',
  },

  green: {
    0: '#DCF2EA',
    100: '#C6EDDF',
    200: '#A3E6CD',
    300: '#59C39A',
    400: '#52BD94',
    500: '#429777',
    600: '#00825D',
    700: '#006E4A',
    800: '#005938',
    900: '#004626',
  },

  red: {
    0: '#FFDEDE',
    100: '#FFAFA3',
    200: '#FF988E',
    300: '#EC7A70',
    400: '#E36A6B',
    500: '#D13D4B',
    600: '#B73939',
    700: '#A73636',
    800: '#892A2A',
    900: '#7D2828',
  },
  yellow: {
    0: '#FFF4D5',
    100: '#FFDD7D',
    200: '#FFB729',
    300: '#EDA101',
    400: '#D48C00',
    500: '#BC7800',
    600: '#A36400',
    700: '#8C5100',
    800: '#7A3709',
    900: '#612B00',
  },
};

export default colors;
