import React from 'react'
import ListItem from './ListItem'
import {VirtualizedList} from 'frontcore'

const List = ({data, onClick}) => {

  return (
    <div style={{paddingLeft: 4, width: '100%', height: '100%', boxSizing: 'border-box'}}>
    <VirtualizedList
      items={data}
      itemHeight={40}
      gap={4}
      ItemComponent={ListItem}
      itemComponentProps={{

        onClick,
      }}
    />
    </div>
  )
}

export default List;
