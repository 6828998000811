import Line from '../../components/line'
import React from 'react'

const DetailsLayout = ({
  infoContent,
  tabsContent,
  bottomContent,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,

      }}
    >
      <div
        style={{
          paddingLeft: 16,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        {infoContent}
      </div>
      <div>
        <div style={{paddingLeft: 16, height: 48}}>{tabsContent}</div>
        <Line />
      </div>
    </div>
    <div style={{flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>{bottomContent}</div>
  </div>
)

export default DetailsLayout
