import asyncThunk from '../index'
export const getNamespaces = asyncThunk({
  method: "GET",
  type: "/namespaces/get",
  path: "/namespaces",
  api: true,
  lineage: true
});

export default getNamespaces;
