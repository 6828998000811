import {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import {and, fork} from 'fluture'
import {useNavigate} from 'react-router-dom'

import {deleteGroup} from '../../../../../api/security/deleteGroup'
import GroupButtons from '../index'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {enableGroup} from '../../../../../api/security/enableGroup'
import {disableGroup} from '../../../../../api/security/disableGroup'

const GroupSingleButtons = ({
  pending,
  data,
  texts,
  getFuture,
  redirect = true,
}) => {
  const {id} = data

  const navigate = useNavigate()

  const {createNotification} = useNotificationContext()

  const deleteGroupFuture = useFuture(deleteGroup)
  const enableGroupFuture = useFuture(enableGroup)
  const disableGroupFuture = useFuture(disableGroup)

  const handleOnEnable = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to enable Group',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(({message}) => {
          createNotification({
            title: 'Group enable successfully',
            message,
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        enableGroupFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnDisable = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to enable Group',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(({message}) => {
          createNotification({
            title: 'Group enable successfully',
            message,
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        disableGroupFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnDelete = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to delete Group',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(({message}) => {
          redirect && navigate('/security/list/groups')
          createNotification({
            title: 'Group deleted successfully',
            message,
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        deleteGroupFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnEdit = useCallback(
    ({id}) => navigate('/security/edit/group/' + id),
    []
  )
  const handleOnToggle = useCallback(
    ({id}) => {
      return data?.state === 'ENABLED'
        ? handleOnDisable({id})
        : handleOnEnable({id})
    },
    [data, handleOnDisable, handleOnEnable]
  )

  return (
    <GroupButtons
      texts={texts}
      id={id}
      data={data}
      pending={pending}
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
      onToggle={handleOnToggle}
    />
  )
}

export default GroupSingleButtons
