import asyncThunk from "../../index";

export const deleteSchedule = asyncThunk({
  method: 'DELETE',
  type: 'schedule/delete',
  path: '/schedule/${id}',
  api: true,
})

export default deleteSchedule
