import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'


const HomeView = () => {
   const navigate = useNavigate();

   useEffect(() => {
     navigate("/workloads/list/services");
   }, [navigate]);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                fontSize: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.4,
                fontWeight: 600,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                Home
                <span style={{ fontSize: 16, fontWeight: 400 }}>Coming Soon</span>
            </div>
        </div>
    );
};


export default HomeView;