import {Outlet} from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`

const TopContainer = styled.div`
  overflow: hidden;
  box-sizing: border-box;
`

const BottomContainer = styled.div`
  flex: 1;
  overflow: hidden;
`

const DetailsViewLayout = ({topNode}) => {
  return (
    <Root id={'form'}>
      <TopContainer>{topNode}</TopContainer>
      <BottomContainer>
        <Outlet />
      </BottomContainer>
    </Root>
  )
}

export default DetailsViewLayout
