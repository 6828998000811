const NODE = 'node';
const INPUT = 'input';
const OUTPUT = 'output';
const EDGE = 'edge';
const GROUP = 'group';
const WORKSPACE = 'workspace';
const MOUSE = 'mouse';
const ELEMENT = 'element';
const SKETCH_CONNECTION_TO_CONNECTION = 'SKETCH_CONNECTION_TO_CONNECTION';
const SKETCH_CONNECTION_TO_CONNECTION_REALM = 'SKETCH_CONNECTION_TO_CONNECTION_REALM';
const SKETCH_CONNECTION_TO_WORKSPACE = 'SKETCH_CONNECTION_TO_WORKSPACE';
const SKETCH_TRANSFORMATION_TO_WORKSPACE = 'SKETCH_TRANSFORMATION_TO_WORKSPACE';
const SKETCH_CONNECTION_TO_GROUP = 'SKETCH_CONNECTION_TO_GROUP';
const SKETCH_TRANSFORMATION_TO_GROUP = 'SKETCH_TRANSFORMATION_TO_GROUP';
const SKETCH_SOURCE_TO_GROUP = 'SKETCH_SOURCE_TO_GROUP';
const SKETCH_SINK_TO_GROUP = 'SKETCH_SINK_TO_GROUP';
const RIGHT = 'right';
const LEFT = 'left';
const TOP = 'top';
const BOTTOM = 'bottom';
const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const GROUPED = 'grouped';
const STACKED = 'stacked';
const DATA = 'data';
const GRAPH = 'graph';
const DIRECTION = 'direction';
const FORWARD = 'forward';
const BACKWARD = 'backward';
const SINK = 'SINK';
const SOURCE = 'SOURCE';
const TRANSFORMATION = 'TRANSFORMATION';
const CONNECTION = 'CONNECTION';
const TYPE = 'type';
const SUB_TYPE = 'subtype';
const REALM = 'realm';
const KEY = 'key';
const SIZE = 'size';
const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';
const CLASSES = 'classes';
const SIMULATION = 'simulation';
const RUNNING = 'running';
const EDITING = 'editing';
const EMPTY_STRING = '';
const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];
const EMPTY_FUNCTION = () => {}
const STRING = 'string';
const GRID_SIZE = 16;
const MAX_JAVA_INT32 = 2147483647;

export {
  NODE,
  INPUT,
  OUTPUT,
  EDGE,
  GROUP,
  WORKSPACE,
  ELEMENT,
  SKETCH_CONNECTION_TO_CONNECTION,
  SKETCH_CONNECTION_TO_CONNECTION_REALM,
  SKETCH_CONNECTION_TO_WORKSPACE,
  SKETCH_TRANSFORMATION_TO_WORKSPACE,
  SKETCH_CONNECTION_TO_GROUP,
  SKETCH_TRANSFORMATION_TO_GROUP,
  SKETCH_SOURCE_TO_GROUP,
  SKETCH_SINK_TO_GROUP,
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
  HORIZONTAL,
  VERTICAL,
  GROUPED,
  STACKED,
  DATA,
  GRAPH,
  DIRECTION,
  FORWARD,
  BACKWARD,
  SINK,
  SOURCE,
  TRANSFORMATION,
  CONNECTION,
  TYPE,
  SUB_TYPE,
  KEY,
  REALM,
  SIZE,
  SMALL,
  MEDIUM,
  LARGE,
  CLASSES,
  SIMULATION,
  RUNNING,
  EDITING,
  EMPTY_STRING,
  EMPTY_OBJECT,
  EMPTY_ARRAY,
  EMPTY_FUNCTION,
  STRING,
  GRID_SIZE,
  MAX_JAVA_INT32,
};
