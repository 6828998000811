import {map, path, pathOr, pick, pipe, propOr, toPairs} from 'ramda'
import React, {useEffect, useMemo, useState} from 'react'
import {Tabs} from 'frontcore'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useDispatch, useSelector} from 'react-redux'
import {EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING} from '../../../constants'
import getMetaStore from '../../../api/table/getMetaStore'
import Configuration from '../../../components/configuration'
import NoResultFound from '../../../components/NoResultFound'
import {convertObjectToArray, isNotEmpty} from '../../../helpers'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'
import useFetch from '../../../hooks/useFetch'
import getCatalogsFetch from '../../../api/table/getCatalogsFetch'
import {setSelectedElement} from '../../../reducers/tableExplorer'
import Json from '../../../components/Json'
import RButton from '../../../components/RButton'

const Catalogs = () => {
  const {metaStoreId, metaStoreName} = useSelector(path(['tableExplorer', 'selectedElement']))

  const {data = EMPTY_STRING, isLoading} = useFetch(
    getCatalogsFetch,
    {},
    {
      params: [{key: 'metaStoreId', value: metaStoreId}],
    }
  )
  const dispatch = useDispatch()
  return (
    <div style={{display: 'flex', overflow: 'hidden', gap: 12, padding: 12, flexWrap: 'wrap'}}>
      {map(
        (name) => (
          <RButton
            icon={'directory'}
            name={name}
            onClick={() =>
              dispatch(
                setSelectedElement({
                  type: 'catalog',
                  metaStoreId,
                  metaStoreName,
                  catalogId: name,
                  catalogName: name,
                })
              )
            }
          />
        ),
        data
      )}
    </div>
  )
}

const MetaStoreDetails = () => {
  const getMetaStoreFuture = useFuture(getMetaStore)
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))
  const [tab, setTab] = useState('catalogs')
  const {metaStoreId} = selectedElement

  const details = useSelector(pathOr(EMPTY_ARRAY, ['data', 'data', 'metaStore', 'response']))

  useEffect(() => {
    fork(() => {})(() => {})(
      getMetaStoreFuture({
        params: [{key: 'metaStoreId', value: metaStoreId}],
      })
    )
  }, [metaStoreId])

  const extendedDetailsData = pipe(
    pick(['name', 'id', 'address', 'type', 'version', 'description']),
    toPairs,
    map((a) => ({key: a[0], value: a[1]}))
  )(details)

  const tabsContent = (
    <Tabs
      value={tab}
      onChange={setTab}
      options={[
        {
          id: 'catalogs',
          children: 'Catalogs',
        },
        {
          id: 'configuration',
          children: 'Configuration',
        },
        {
          id: 'raw',
          children: 'Raw',
        },
      ]}
    />
  )

  const configurationData = useMemo(
    () =>
      pipe(propOr(EMPTY_OBJECT, 'configuration'), (a) => convertObjectToArray(a, 'key', 'value'))(
        details
      ),
    [details]
  )

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'metastore'} />}
      name={details?.name}
      id={details?.type}
      pending={false}
      data={extendedDetailsData}
    />
  )

  const listContent = isNotEmpty(configurationData) ? (
    <div style={{overflow: 'hidden', padding: 16}}>
      <Configuration
        data={configurationData}
        schema={[
          {
            id: 'key',
            label: 'Key',
            copy: true,
          },
          {
            id: 'value',
            label: 'Value',
            copy: true,
            obfuscate: true,
          },
        ]}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <NoResultFound title={'No Data'} text={'This Metastore have no configuration'} />
    </div>
  )

  const bottomContent = (
    <>
      {tab === 'catalogs' && <Catalogs />}
      {tab === 'configuration' && listContent}
      {tab === 'raw' && (
        <div style={{padding: 16}}>
          <Json src={details} />
        </div>
      )}
    </>
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={tabsContent}
      bottomContent={bottomContent}
    />
  )
}

export default MetaStoreDetails
