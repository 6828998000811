import {useCallback} from 'react';
import {map, pathOr} from 'ramda';
import Column from './column';
import {EMPTY_STRING} from '../../constants';
import styled from 'styled-components';
import {Button, Icon} from 'frontcore';

const Root = styled.div`
  display: flex; gap: 8px;
`

const Row = ({value, index, name, skeleton, onChange, onDelete, fields, parameters}) => {

  const handleOnChange = useCallback(({value, name}) => {
    onChange({index, name, value});
  }, [index, name, onChange]);

  const handleOnDelete = useCallback(() => {
    onDelete({index});
  }, [onDelete]);

  return <Root>
    {map(
      ({label, name}) => (
        <Column
          name={name}
          skeleton={skeleton}
          value={pathOr(EMPTY_STRING, [name], value)}
          label={index === 0 && label}
          onChange={handleOnChange}
        />
      ),
      fields,
    )}
    <Button
      tabIndex={-1}
      skeleton={skeleton}
      Component={Icon}
      componentProps={{
        icon: 'close',
        size: 24
      }}
      square
      justifyContent={'center'}
      variant={'text'}
      onClick={handleOnDelete}
    />
  </Root>;
};

export default Row;