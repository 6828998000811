import {createSlice} from '@reduxjs/toolkit'
import {extraReducersMapper} from '../../api'
import {
    assoc,
    assocPath,
    dissoc,
    findIndex,
    lensPath,
    map,
    over,
    path,
    pathOr,
    pipe,
    propEq,
    propOr,
    toPairs,
} from 'ramda'

import getCluster from '../../api/workloads/clusters/getCluster'

import getMetaStores from '../../api/table/getMetaStores'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants'
import getCatalogs from '../../api/table/getCatalogs'
import getDatabases from '../../api/table/getDatabases'
import getMetaStore from '../../api/table/getMetaStore'
import getCatalog from '../../api/table/getCatalog'
import getDatabase from '../../api/table/getDatabase'
import getTables from '../../api/table/getTables'
import getTable from '../../api/table/getTable'
import getColumns from '../../api/table/getColumns'


export const dataSlice = createSlice({
  name: 'data',
  initialState: {},
  reducers: {
    deleteData: (state, {payload}) => {
      if (payload.kind === 'metastore') {
        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(payload.id, 'id'))
        )(state)
        const metaStoreLens = lensPath([
          'tree',
          metaStoreIndex,
        ])
        return over(metaStoreLens, dissoc('data'), state)
      } else if (payload.kind === 'catalog') {
        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(payload.metaStoreId, 'id'))
        )(state)

        const catalogIndex = pipe(
          pathOr([], ['tree', metaStoreIndex, 'data']),
          findIndex(propEq(payload.id, 'id'))
        )(state)

        const storageLens = lensPath([
          'tree',
          metaStoreIndex,
          'data',
          catalogIndex,
        ])
        return over(storageLens, dissoc('data'), state)
      } else if (payload.kind === 'database') {
        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(payload.metaStoreId, 'id'))
        )(state)

        const catalogIndex = pipe(
          pathOr([], ['tree', metaStoreIndex, 'data']),
          findIndex(propEq(payload.catalogId, 'id'))
        )(state)

        const databaseIndex = pipe(
          pathOr(
            [],
            [
              'tree',
              metaStoreIndex,
              'data',
              catalogIndex,
              'data',
            ]
          ),
          findIndex(propEq(payload.id, 'id'))
        )(state)

        const databaseLens = lensPath([
          'tree',
          metaStoreIndex,
          'data',
          catalogIndex,
          'data',
          databaseIndex,
        ])
        return over(databaseLens, dissoc('data'), state)
      } else {
        return state
      }
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getMetaStores,
      name: 'metaStores',
      fulfilledFn: (payload) => (state) => {
        const result = pipe(
          propOr(EMPTY_ARRAY, 'content'),
          map(assoc('kind', 'metastore'))
        )(payload)
        return assoc('tree', result, state)
      },
    },
    {
      asyncThunk: getCatalogs,
      name: 'catalogs',
      fulfilledFn: (payload, meta) => (state) => {
        const metaStoreId = path(
          ['arg', 'params', 0, 'value'],
          meta
        )

        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(metaStoreId, 'id'))
        )(state)

        const metaStoreName = pipe(
          pathOr([], ['tree', metaStoreIndex, 'name'])
        )(state)

        const result = pipe(
          map((id) => ({
            id,
            name: id,
            kind: 'catalog',
            metaStoreId,
            metaStoreName,
          }))
        )(payload)
        if (metaStoreIndex >= 0) {
          return assocPath(
            ['tree', metaStoreIndex, 'data'],
            result,
            state
          )
        } else {
          return state
        }
      },
    },
    {
      asyncThunk: getDatabases,
      name: 'databases',
      fulfilledFn: (payload, meta) => (state) => {
        const metaStoreId = path(
          ['arg', 'params', 0, 'value'],
          meta
        )
        const catalogId = path(
          ['arg', 'params', 1, 'value'],
          meta
        )
        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(metaStoreId, 'id'))
        )(state)

        const metaStoreName = pipe(
          pathOr([], ['tree', metaStoreIndex, 'name'])
        )(state)

        const catalogIndex = pipe(
          pathOr([], ['tree', metaStoreIndex, 'data']),
          findIndex(propEq(catalogId, 'id'))
        )(state)

        const result = pipe(
          map((id) => ({
            id,
            name: id,
            kind: 'database',
            catalogName: catalogId,
            metaStoreId,
            metaStoreName,
            catalogId,
          }))
        )(payload)

        if (metaStoreIndex >= 0 && catalogIndex >= 0) {
          return assocPath(
            [
              'tree',
              metaStoreIndex,
              'data',
              catalogIndex,
              'data',
            ],
            result,
            state
          )
        } else {
          return state
        }
      },
    },
    {
      asyncThunk: getTables,
      name: 'tables',
      fulfilledFn: (payload, meta) => (state) => {
        const metaStoreId = path(
          ['arg', 'params', 0, 'value'],
          meta
        )
        const catalogId = path(
          ['arg', 'params', 1, 'value'],
          meta
        )
        const databaseId = path(
          ['arg', 'params', 2, 'value'],
          meta
        )
        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(metaStoreId, 'id'))
        )(state)

        const metaStoreName = pipe(
          pathOr([], ['tree', metaStoreIndex, 'name'])
        )(state)

        const catalogIndex = pipe(
          pathOr([], ['tree', metaStoreIndex, 'data']),
          findIndex(propEq(catalogId, 'id'))
        )(state)

        const databaseIndex = pipe(
          pathOr(
            [],
            [
              'tree',
              metaStoreIndex,
              'data',
              catalogIndex,
              'data',
            ]
          ),
          findIndex(propEq(databaseId, 'id'))
        )(state)

        const result = pipe(
          map((id) => ({
            id,
            name: id,
            kind: 'table',
            metaStoreId,
            metaStoreName,
            catalogId,
            catalogName: catalogId,
            databaseId,
            databaseName: databaseId,
          }))
        )(payload)
        if (
          metaStoreIndex >= 0 &&
          catalogIndex >= 0 &&
          databaseIndex >= 0
        ) {
          return assocPath(
            [
              'tree',
              metaStoreIndex,
              'data',
              catalogIndex,
              'data',
              databaseIndex,
              'data',
            ],
            result,
            state
          )
        } else {
          return state
        }
      },
    },
    {
      asyncThunk: getColumns,
      name: 'tables',
      fulfilledFn: (payload, meta) => (state) => {
        const metaStoreId = path(
          ['arg', 'params', 0, 'value'],
          meta
        )
        const catalogId = path(
          ['arg', 'params', 1, 'value'],
          meta
        )
        const databaseId = path(
          ['arg', 'params', 2, 'value'],
          meta
        )

        const tableId = path(
          ['arg', 'params', 3, 'value'],
          meta
        )

        const metaStoreIndex = pipe(
          propOr([], 'tree'),
          findIndex(propEq(metaStoreId, 'id'))
        )(state)

        const metaStoreName = pipe(
          pathOr([], ['tree', metaStoreIndex, 'name'])
        )(state)

        const catalogIndex = pipe(
          pathOr([], ['tree', metaStoreIndex, 'data']),
          findIndex(propEq(catalogId, 'id'))
        )(state)



        const databaseIndex = pipe(
          pathOr(
            [],
            [
              'tree',
              metaStoreIndex,
              'data',
              catalogIndex,
              'data',
            ]
          ),
          findIndex(propEq(databaseId, 'id'))
        )(state)



        const tableIndex = pipe(
          pathOr([], ['tree', metaStoreIndex, 'data', catalogIndex, 'data', databaseIndex, 'data']),
          findIndex(propEq(tableId, 'id'))
        )(state)

        const result = pipe(
          propOr(EMPTY_OBJECT, 'schema'),
          toPairs,
          map(([key,{comment, nullable, type}]) => ({
            id: key,
            name: key,
            kind: 'column',
            comment,
            nullable,
            type,
            metaStoreId,
            metaStoreName,
            catalogId,
            catalogName: catalogId,
            databaseId,
            databaseName: databaseId,
            tableId,
            tableName: tableId
          }))

        )(payload)


        if (
          metaStoreIndex >= 0 &&
          catalogIndex >= 0 &&
          databaseIndex >= 0 &&
          tableIndex >= 0
        ) {
          return assocPath(
            [
              'tree',
              metaStoreIndex,
              'data',
              catalogIndex,
              'data',
              databaseIndex,
              'data',
              tableIndex,
              'data'
            ],
            result,
            state
          )
        } else {
          return state
        }
      },
    },
    {
      asyncThunk: getMetaStore,
      name: 'metaStore',
    },
    {
      asyncThunk: getCatalog,
      name: 'catalog',
    },
    {
      asyncThunk: getDatabase,
      name: 'database',
    },
    {
      asyncThunk: getCluster,
      name: 'cluster',
    },
    {
      asyncThunk: getTable,
      name: 'table',
    },
  ]),
})

export const {deleteData} = dataSlice.actions

export default dataSlice.reducer
