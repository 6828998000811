import {EMPTY_ARRAY, EMPTY_OBJECT} from "../../../constants";
import {assoc, find, mergeLeft, pipe, prop} from "ramda";
import {calculateHeaders, calculateUrl} from "../../../api/helpers";

const clusterNameOnChangeFn = (clusters = EMPTY_ARRAY) => async (key, value, fields) => {
    let data = EMPTY_OBJECT
    try {
        const clusterId = pipe(
            find(({name}) => name === value),
            prop('id')
        )(clusters)

        const response = await fetch(
            calculateUrl(
                {},
                `/api/dev/reactive/cluster/${clusterId}/memorySettings`,
                'GET',
                false,
                false
            ),
            {method: 'GET', headers: calculateHeaders({})}
        )
        data = await response.json().catch(console.log)
    } catch (error) {
        console.error(error)
    }
    return pipe(mergeLeft(data), assoc(key, value))(fields)
}

export default clusterNameOnChangeFn;