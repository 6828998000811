import asyncThunk from '../index'

export const getGroup = asyncThunk({
  method: 'GET',
  type: 'group/get',
  path: '/usergroups/${id}',
  api: true,
  mock2: {
    id: '9',
    name: 'Finance Officer',
    description:
      'Manages financial operations and budgeting.',
    roles: ['finance', 'accountant'],
    permissions: ['read', 'manage'],
    users: 3,
    operations: 12,
    enabled: false,
    creationTime: 1678500000,
    lastActivity: 1681000300,
  },
})

export default getGroup
