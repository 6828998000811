import asyncThunk from '../index'

export const getGroupsSearch = asyncThunk({
  method: 'GET',
  type: 'groupsSearch/get',
  path: '/usergroups',
  api: true,
})

export default getGroupsSearch
