import {configureStore} from '@reduxjs/toolkit'

import workloadsReducer from './workloads'
import dataReducer from './data'
import loginReducer from './login'
import modulesReducer from './modules'
import sqlViewerReducer from './sql'
import fileExplorerReducer from './fileExplorer'
import tableExplorerReducer from './tableExplorer'
import lineageReducer from './lineage'

import settingsReducer from './settings'
import {persistSettingsMiddleware} from './settings/persist'
import securityReducer from './security'

const store = configureStore({
  reducer: {
    workloads: workloadsReducer,
    data: dataReducer,
    tableExplorer: tableExplorerReducer,
    login: loginReducer,
    modules: modulesReducer,
    settings: settingsReducer,
    fileExplorer: fileExplorerReducer,
    sqlViewer: sqlViewerReducer,
    lineage: lineageReducer,
    security: securityReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistSettingsMiddleware),
  devTools: true,
})

export default store
