import React from 'react';
import ToolTipDetailsGridContentLayout, {
  Key,
  Row,
  Value,
} from '../../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout';

const ToolTipContentComponent = ({data}) => (
  <ToolTipDetailsGridContentLayout>
      <Key>Name</Key>
      <Value> {data?.requestId}</Value>
      <Key>Class</Key>
      <Value>{data?.clazz}</Value>
  </ToolTipDetailsGridContentLayout>
);

export default ToolTipContentComponent;
