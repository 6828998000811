import React from 'react'
import styled from 'styled-components'
import Line from '../line'

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 12px;
  justify-content: space-between;
  padding: 24px 16px 0 16px;
`
const BatchButtonsContainer = styled.div`
    
  display: flex;
  gap: 16px;
  height: 100%;
  padding-right: 8px;
`

const FiltersContainer = styled.div`
  padding-left: 8px;
`

const BottomContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const LineContainer = styled.div`
  padding: 0 0 0 0;
`

const ListLayout = ({
  batchButtonsNode,
  filtersNode,
  createButtonNode,
  contentNode,
}) => {
  return (
    <Root>
      <TopContainer>
        {!!batchButtonsNode && (
          <BatchButtonsContainer>
            {batchButtonsNode}
          </BatchButtonsContainer>
        )}
        <FiltersContainer>{filtersNode}</FiltersContainer>
        <div style={{flex: 1}} />
        <div>{createButtonNode}</div>
      </TopContainer>
      <BottomContainer>
        <LineContainer>
          <Line />
        </LineContainer>
        {contentNode}
      </BottomContainer>
    </Root>
  )
}

export default ListLayout
