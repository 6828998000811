import {useLocation, useNavigate} from 'react-router-dom'
import {Button, Icon} from 'frontcore'
import React, {useCallback, useMemo} from 'react'
import DetailsViewLayout from '../../../components/layouts/DetailsViewLayout'
import {cond, startsWith, T} from 'ramda'

const WorkloadsEditView = () => {
    const {pathname} = useLocation()
    const navigate = useNavigate()

    const returnUrl = useMemo(
        () =>
            cond([
                [
                    startsWith('/workloads/edit/clusters'),
                    () => '/workloads/list/clusters',
                ],
                [
                    startsWith('/workloads/edit/group'),
                    () => '/workloads/list/services',
                ],
                [
                    startsWith('/workloads/edit/job'),
                    () => '/workloads/list/jobs',
                ],
                [T, () => -1],
            ])(pathname),
        [pathname]
    )

    const handleOnClick = useCallback(
        () => navigate(returnUrl),
        [returnUrl]
    )

    return (
        <DetailsViewLayout
            topNode={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 16,
                        paddingLeft: 24,
                        height: 64,
                    }}
                >
                    <Button
                        size={'small'}
                        variant={'text'}
                        StartComponent={Icon}
                        startComponentProps={{
                            icon: 'arrow-left',
                            size: 18,
                        }}
                        tabIndex={-1}
                        justifyContent={'center'}
                        onClick={handleOnClick}
                    >
                        Back to List
                    </Button>
                </div>
            }
        />
    )
}

export default WorkloadsEditView
