import React, {memo} from 'react'
import {Handle, Position} from '@xyflow/react'
import {Input, ResourceButton} from 'frontcore'
import '@xyflow/react/dist/style.css'
import './index.css'
import {
  ascend,
  filter,
  groupWith,
  map,
  path,
  pathOr,
  pipe,
  sortWith,
} from 'ramda'
import {mapWithKey} from '../../../helpers'
import {Root} from './styles'
import RButton from '../../../components/RButton'
import {LetterMiniature} from '../../../components/miniatures'

const Field = (data) => {
  return (
    <div style={{position: 'relative'}}>
      {mapWithKey(
        ({origin, inPortId}, index) => (
          <Handle
            id={origin + '_' + inPortId}
            type="target"
            position={Position.Left}
            style={{
              background: '#555',
              border: 'none',
              top: 10 * index + 10,
            }}
            isConnectable={false}
          />
        ),
        data?.inHandles || []
      )}

      {mapWithKey(
        ({origin, outPortId}, index) => (
          <Handle
            id={origin + '_' + outPortId}
            type="source"
            position={Position.Right}
            style={{
              background: '#555',
              border: 'none',
              top: 10 * index + 10,
            }}
            isConnectable={false}
          />
        ),
        data?.outHandles || []
      )}
      <Input value={data.data.field} />
    </div>
  )
}

const ColumnNode = (props) => {
  const {data, isConnectable, inEdges, outEdges, selected} =
    props

  const extendedInData = pipe(
    sortWith([ascend(path(['data', 'field']))]),
    groupWith((a, b) => a.data.field === b.data.field),
    map((data) => ({
      field: path([0, 'data', 'field'], data),
      handles: data,
    }))
  )(props.data.inEdges)

  const extendedOutData = pipe(
    sortWith([ascend(path(['data', 'field']))]),
    groupWith((a, b) => a.data.field === b.data.field),
    map((data) => ({
      field: path([0, 'data', 'field'], data),
      handles: data,
    }))
  )(props.data.outEdges)

  const fields = data?.fields || []

  const aaa = pipe(
    map((data) => {
      const inHandles = pipe(
        filter(({field}) => field === data.data.field),
        pathOr([], [0, 'handles'])
      )(extendedInData)
      const outHandles = pipe(
        filter(({field}) => field === data.data.field),
        pathOr([], [0, 'handles'])
      )(extendedOutData)

      return {...data, inHandles, outHandles}
    })
  )(fields)

  return (
    <Root className={'nodrag'} $selected={selected}>
      <RButton
        type={'Data'}
        name={data?.name}
        IconComponent={LetterMiniature}
        iconComponentProps={{type: 'data', icon: 'table'}}
      />

      <div
        style={{
          padding: 20,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <div style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
        {data.name}
        </div>
        {map(
          (b) => <Field {...b} />,
          aaa
        )}
      </div>
    </Root>
  )
}

export default memo(ColumnNode)
