import React from "react";
import styled, { css } from "styled-components";
import NoResultFound from '../../../components/NoResultFound'


const ErrorLayout = () => {
  return (
    <Root>
      <NoResultFound/>
    </Root>
  );
};

export default ErrorLayout;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

