import asyncThunk from "../../index";

export const archiveService = asyncThunk({
  method: "POST",
  type: "service/archive",
  path: "/group/${id}/archive",
  api: true
});

export default archiveService;
