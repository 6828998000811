import {isNil, path, pathOr, propOr} from 'ramda'
import {memo, useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {EMPTY_FUNCTION, EMPTY_OBJECT} from '../../../constants'
import useFuture from '../../../hooks/useFuture'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import getSQLEngines from '../../../api/sql/getSQLEngines'
import createSQLEngine from '../../../api/sql/createSQLEngine'
import deleteSQLEngine from '../../../api/sql/deleteSQLEngine'
import EngineState from '../EngineState'
import {State} from '../../../components/StateSwitch/state'
import StateSwitch from '../../../components/StateSwitch'

const EditorEngineStatus = () => {
  const chosenCluster = useSelector(pathOr(EMPTY_OBJECT, ['sqlViewer', 'sidebar', 'chosenCluster']))

  const {createNotification} = useNotificationContext()
  const getSQLEnginesFuture = useFuture(getSQLEngines)
  const refreshEngines = useCallback(() => {
    if (isNil(chosenCluster)) return
    const future = getSQLEnginesFuture({
      params: [{key: 'clusterId', value: chosenCluster.id}],
    })
    fork(({message}) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(future)
  }, [chosenCluster])

  const sqlEngines = useSelector(pathOr([], ['sqlViewer', 'editor', 'data', 'liveEngines', 'response']))
  const chosenEngineType = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const chosenEngineInfo = useMemo(() => {
    return sqlEngines.find((engine) => {
      return engine.type === chosenEngineType
    })
  }, [sqlEngines, chosenEngineType])

  const createSQLEngineFuture = useFuture(createSQLEngine)
  const deleteSQLEngineFuture = useFuture(deleteSQLEngine)

  const createEngine = useCallback(() => {
    if (isNil(chosenCluster)) return
    const future = createSQLEngineFuture({
      params: [{key: 'clusterId', value: chosenCluster.id}],
      type: chosenEngineType,
    })
    createNotification({
      title: 'Request is in process',
      message: 'Ilum is creating a new SQL engine',
      variant: 'info',
      autoHide: true,
    })
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to start SQL engine',
        variant: 'error',
        autoHide: true,
      })
    })(() => {
      refreshEngines()
    })(future)
  }, [chosenCluster, refreshEngines, chosenEngineType])

  const deleteEngine = useCallback(() => {
    if (isNil(chosenEngineInfo)) return
    const future = deleteSQLEngineFuture({
      params: [{key: 'engineId', value: chosenEngineInfo.id}],
    })
    createNotification({
      title: 'Request is in process',
      message: 'Ilum is stopping the chosen SQL engine',
      variant: 'info',
      autoHide: true,
    })
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to stop SQL engine',
        variant: 'error',
        autoHide: true,
      })
    })(() => {
      refreshEngines()
    })(future)
  }, [chosenEngineInfo, refreshEngines])

  const active = useMemo(() => {
    const state = propOr('OFF', 'state', chosenEngineInfo)
    return [EngineState.RUNNING, EngineState.STARTING].includes(state)
  }, [chosenEngineInfo])

  const switchState = useMemo(() => {
    switch (chosenEngineInfo?.state) {
      case EngineState.RUNNING: return State.ON
      case EngineState.STARTING: return State.STARTING
      default: return State.OFF
    }
  }, [chosenEngineInfo])

  const handleClickSwitch = useCallback(() => {
    if (active) {
      deleteEngine()
      return
    }
    createEngine()
  }, [active, createEngine, deleteEngine])

  return (
    
    <StateSwitch
      state={switchState}
      onClick={handleClickSwitch}
    />
  )
}

export default memo(EditorEngineStatus)
