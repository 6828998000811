import {path} from 'ramda'
import {Fragment, memo, useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  QuerySelect,
  QueryTypeSelect,
  sidebarNewNotebook,
  sidebarNewQuery,
  sidebarSetQuerySelect,
  sidebarSetQueryTypeSelect,
  sidebarSetSearch,
} from '../../../reducers/sql/sidebar'
import {EMPTY_STRING} from '../../../constants'
import styled from 'styled-components'
import {Button, Icon, Input, Select} from 'frontcore'
import ToolTip from '../../../components/toolTip'
import ToolTipDetailsGridContentLayout, {
  Key,
  Value,
} from '../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'

const Root = styled.div`
  padding: 16px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
`

const QuerySelectDescriptions = Object.freeze({
  [QuerySelect.all]: 'Shows all queries',
  [QuerySelect.local]: 'Shows queries saved only locally',
  [QuerySelect.remote]:
    'Shows queries saved on the remote server',
  [QueryTypeSelect.queries]:
    'Shows only queries, excluding notebooks',
  [QueryTypeSelect.notebooks]:
    'Shows only notebooks, excluding normal queries',
})

const SidebarControls = () => {
  const tab = useSelector(
    path(['sqlViewer', 'sidebar', 'tab'])
  )
  const search = useSelector(
    path(['sqlViewer', 'sidebar', 'search'])
  )
  const querySelect = useSelector(
    path(['sqlViewer', 'sidebar', 'querySelect'])
  )
  const queryTypeSelect = useSelector(
    path(['sqlViewer', 'sidebar', 'queryTypeSelect'])
  )

  const dispatch = useDispatch()

  const handleSearch = useCallback((newValue) => {
    dispatch(sidebarSetSearch(newValue))
  }, [])

  useEffect(() => {
    handleSearch(EMPTY_STRING)
  }, [tab, handleSearch])

  const handleQuerySelect = useCallback((newValue) => {
    dispatch(sidebarSetQuerySelect(newValue))
  }, [])

  const handleQueryTypeSelect = useCallback((newValue) => {
    dispatch(sidebarSetQueryTypeSelect(newValue))
  }, [])

  const handleNewQuery = useCallback(() => {
    dispatch(sidebarNewQuery())
  }, [])

  const handleNewNotebook = useCallback(() => {
    dispatch(sidebarNewNotebook())
  }, [])

  return (
    <Root>
      <SelectContainer>
        <Select
          value={querySelect}
          onChange={handleQuerySelect}
          label="Location"
          options={Object.values(QuerySelect)}
        />
        <Select
          value={queryTypeSelect}
          onChange={handleQueryTypeSelect}
          label="Type"
          options={Object.values(QueryTypeSelect)}
        />
        <ToolTip
          config={{position: 'right_bottom'}}
          content={
            <ToolTipDetailsGridContentLayout>
              {Object.entries(QuerySelectDescriptions).map(
                ([key, value]) => (
                  <Fragment key={key}>
                    <Key>{key}</Key>
                    <Value>{value}</Value>
                  </Fragment>
                )
              )}
            </ToolTipDetailsGridContentLayout>
          }
        >
          <div
            style={{
              position: 'relative',
              marginRight: 2,
              marginLeft: 'auto',
            }}
          >
            <Icon color="grey" icon="question" size={16} />
          </div>
        </ToolTip>
      </SelectContainer>
      <Input
        value={search}
        onChange={handleSearch}
        placeHolder={`Search in ${tab}...`}
      />
      <Button
        onClick={handleNewQuery}
        StartComponent={Icon}
        justifyContent="flex-start"
        startComponentProps={{
          icon: 'database',
          size: 16,
        }}
        variant="outlined"
      >
        New query
      </Button>
      <Button
        onClick={handleNewNotebook}
        StartComponent={Icon}
        justifyContent="flex-start"
        startComponentProps={{
          icon: 'pencil',
          size: 16,
        }}
        variant="outlined"
      >
        New notebook
      </Button>
    </Root>
  )
}

export default memo(SidebarControls)
