import asyncThunk from '../index'

export const getUsers = asyncThunk({
  method: 'GET',
  type: 'users/get',
  path: '/users',
  api: true,
  mock2: {
    content: [
      {
        id: '1',
        name: 'john_doe',
        fullname: 'John Doe',
        email: 'john.doe@example.com',
        description:
          'Software engineer passionate about AI.',
        department: 'Engineering',
        lastTimeOnline: 1679987600,
        lastTimeCreated: 1675000000,
        enabled: true,
        permissions: 3,
      },
      {
        id: '2',
        name: 'jane_smith',
        fullname: 'Jane Smith',
        email: 'jane.smith@example.com',
        description:
          'Product manager with a focus on user experience.',
        department: 'Product',
        lastTimeOnline: 1680198900,
        lastTimeCreated: 1677500000,
        enabled: true,
        permissions: 4,
      },
      {
        id: '3',
        name: 'mike_brown',
        fullname: 'Mike Brown',
        email: 'mike.brown@example.com',
        description:
          'UX/UI Designer with a knack for minimalistic design.',
        department: 'Design',
        lastTimeOnline: 1680295000,
        lastTimeCreated: 1676000000,
        enabled: false,
        permissions: 2,
      },
      {
        id: '4',
        name: 'lucy_wilson',
        fullname: 'Lucy Wilson',
        email: 'lucy.wilson@example.com',
        description:
          'Marketing strategist with a passion for storytelling.',
        department: 'Marketing',
        lastTimeOnline: 1680391100,
        lastTimeCreated: 1673500000,
        enabled: true,
        permissions: 5,
      },
      {
        id: '5',
        name: 'andy_miller',
        fullname: 'Andy Miller',
        email: 'andy.miller@example.com',
        description:
          'Full-stack developer with expertise in cloud computing.',
        department: 'Engineering',
        lastTimeOnline: 1680497200,
        lastTimeCreated: 1675500000,
        enabled: true,
        permissions: 3,
      },
      {
        id: '6',
        name: 'lisa_jones',
        fullname: 'Lisa Jones',
        email: 'lisa.jones@example.com',
        description: null,
        department: 'HR',
        lastTimeOnline: 1680593300,
        lastTimeCreated: 1674000000,
        enabled: true,
        permissions: 1,
      },
      {
        id: '7',
        name: 'charlie_davis',
        fullname: 'Charlie Davis',
        email: 'charlie.davis@example.com',
        description:
          'Data scientist with a love for machine learning.',
        department: 'Data Science',
        lastTimeOnline: 1680692500,
        lastTimeCreated: 1678500000,
        enabled: true,
        permissions: 4,
      },
      {
        id: '8',
        name: 'emily_clark',
        fullname: 'Emily Clark',
        email: 'emily.clark@example.com',
        description:
          'Customer support lead who thrives on solving problems.',
        department: 'Support',
        lastTimeOnline: 1680795100,
        lastTimeCreated: 1679000000,
        enabled: true,
        permissions: 2,
      },
      {
        id: '9',
        name: 'peter_hall',
        fullname: 'Peter Hall',
        email: 'peter.hall@example.com',
        description:
          'Financial analyst with a passion for optimizing processes.',
        department: 'Finance',
        lastTimeOnline: 1680898700,
        lastTimeCreated: 1679500000,
        enabled: false,
        permissions: 3,
      },
      {
        id: '10',
        name: 'susan_moore',
        fullname: 'Susan Moore',
        email: 'susan.moore@example.com',
        description:
          'Operations manager with experience in supply chain logistics.',
        department: 'Operations',
        lastTimeOnline: 1681000300,
        lastTimeCreated: 1676000000,
        enabled: true,
        permissions: 5,
      },
    ],
  },
})

export default getUsers
