import CodeMirror from '@uiw/react-codemirror'
import {langs} from '@uiw/codemirror-extensions-langs'
import React, {useMemo, useRef} from 'react'
import styled, {useTheme} from 'styled-components'
import {cond, equals, T} from 'ramda'
import {withResize} from 'frontcore'
import {createTransitions} from '../../helpers'

import {EditorView} from '@codemirror/view'

let myTheme = ({palette}) =>
  EditorView.theme(
    {
      '&': {
        color: palette.neutral[500],
        backgroundColor: palette.neutral[0],
      },
      '.cm-content': {
        caretColor: palette.neutral[0],
      },
      '&.cm-focused .cm-cursor': {
        borderLeftColor: palette.neutral[500],
      },
      '&.cm-focused': {
        outline: 'none',
      },
      '&.cm-focused .cm-selectionBackground, ::selection': {
        backgroundColor:
          palette.neutral[100] + ' !important',
      },
      '.cm-gutters': {
        backgroundColor: palette.neutral[50],
        color: palette.neutral[500],
        borderRight: '1px solid black',
        borderColor: palette.neutral[100],
      },
    },
    {dark: false}
  )

const CodeMirrorContainer = styled.div`
  border-color: ${({theme}) => theme.palette.neutral[200]};
  border-style: solid;
  border-width: 0px;
  border-radius: 4px;
  overflow: hidden;
  &:focus-within {
    border-color: ${({theme}) => theme.palette.blue[500]};
  }


  &:focus {
    border-color: ${({theme}) => theme.palette.blue[500]};
  }
`;

const Label = styled.div`
  position: absolute;
  padding: 0;
  left: 0;
  right: 4px;
  height: 24px;
  top: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  font-size: 12px;
  transform: translate(0, -100%);
  transition: ${createTransitions(['color', 'opacity'], 150)};

  span {
    font-style: italic;
  }

  color: #a0aab4;
`;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
    font-size: 14px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;


  &:focus-within {
    ${Label} {
      color: ${({theme}) => theme.palette.blue[500]};
    }
    ${CodeMirrorContainer} {
      border-color: ${({theme}) => theme.palette.blue[500]};
    }
  }
  
  &:focus {
    ${Label} {
      color: ${({theme}) => theme.palette.blue[500]};
    }
    ${CodeMirrorContainer} {
      border-color: ${({theme}) => theme.palette.blue[500]};
    }
  }
`;


const CodeEditor = ({value, onChange, language, width, height}) => {
  const ref = useRef();

  const theme = useTheme();

  const extensions = useMemo(
    () =>
      cond([
        [equals('scala'), () => [langs.scala()]],
        [equals('sql'), () => [langs.sql()]],
        [equals('python'), () => [langs.python()]],
        [equals('r'), () => [langs.r()]],
        [T, () => [langs.javascript()]],
      ])(language),
    [language],
  );




  const extendedWidth = useMemo(() => width + 'px', [width]);

  const extendedHeight = useMemo(() => height + 'px', [height]);
  return (
    <Root ref={ref}>
      {/*<Container>*/}
      {/*  <CodeMirrorContainer>*/}
          <CodeMirror
            value={value}
            width={extendedWidth}
            height={extendedHeight}
            extensions={extensions}
            onChange={onChange}
            autoFocus={true}
            theme={myTheme(theme)}
          />
      {/*  </CodeMirrorContainer>*/}
      {/*</Container>*/}
      {/*<ButtonContainer>*/}
      {/*  <Select*/}
      {/*    value={theme}*/}
      {/*    onChange={onChangeTheme}*/}
      {/*    options={themeOptions}*/}
      {/*    variant={'contained'}*/}
      {/*    color={'default'}*/}
      {/*    size={'small'}*/}
      {/*    buttonProps={{*/}
      {/*      variant: 'outlined',*/}
      {/*      tabIndex: -1,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</ButtonContainer>*/}
    </Root>
  );
};

export default withResize(CodeEditor);

