import useFuture from '../../../../hooks/useFuture'
import createCluster from '../../../../api/workloads/clusters/createCluster'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useDispatch} from 'react-redux'
import React, {useCallback, useEffect, useMemo, useState,} from 'react'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import Form from '../../../../components/Form'
import {assoc, evolve, find, isNil, map, pipe, prop, propOr, reject, toPairs,} from 'ramda'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../../constants'
import getCluster from '../../../../api/workloads/clusters/getCluster'
import usePending from '../../../../hooks/usePending'
import {useNavigate, useParams} from 'react-router-dom'
import schema from './schema'
import {convertObjectToArray, isNotEmpty,} from '../../../../helpers'
import clusterFormSubmitFn from '../../helpers/clusterFormSubmitFn'
import FormLayout2 from '../../../../components/tabsFormLayout'
import SecurityFormLayout from "../../../../components/SecurityFormLayout";

const EditCluster = () => {
    const createClusterFuture = useFuture(createCluster)
    const {createNotification} = useNotificationContext()

    const {clusterId} = useParams()
    const navigate = useNavigate()

    const pending = usePending(
        ['workloads', 'data'],
        ['createCluster']
    )

    const [data, setData] = useState(EMPTY_OBJECT)

    const getClusterFuture = useFuture(getCluster)

    useEffect(() => {
        fork(() => {
        })((data) => {
            const storages = pipe(
                propOr(EMPTY_OBJECT, 'storages'),
                toPairs,
                map((a) => ({name: a[0], ...a[1]})),
                map(evolve({
                    extraBuckets: data => map(value => ({value}), data || [])
                })),
                 map((array) => reject(isNil, array))
            )(data)
            const defaultApplicationConfig = pipe(
                propOr(EMPTY_OBJECT, 'defaultApplicationConfig'),
                toPairs,
                map((a) => ({key: a[0], value: a[1]}))
            )(data)

            const result = pipe(
                assoc('storages', storages),
                assoc(
                    'defaultApplicationConfig',
                    defaultApplicationConfig
                )
            )(data)

            setData(result)


            dispatch(
                setBreadCrumbs({
                    cluster: {
                        id: data.id,
                        name: data.name,
                    },
                })
            )
        })(
            getClusterFuture({
                params: [{key: 'id', value: clusterId}],
            })
        )
    }, [clusterId])

    const dispatch = useDispatch()
    const handleOnSubmit = useCallback((fields) => {
        const params = {...fields}

        const result = pipe(
            assoc('id', clusterId),
            clusterFormSubmitFn
        )(params)

        pipe(
            fork(({message}) => {
                createNotification({
                    title: 'Failed to Edit Cluster',
                    message,
                    autoHide: false,
                    variant: 'error',
                })
            })(() => {
                createNotification({
                    message: 'Ok',
                    autoHide: true,
                    variant: 'success',
                })
                navigate(-1)
            })
        )(createClusterFuture(result))
    }, [])

    const extendedData = useMemo(() => {
        if (isNotEmpty(data)) {
            const storages = propOr(EMPTY_ARRAY, 'storages', data)
            const sparkStorage = data?.sparkStorage
            const result = find(
                ({name}) => name === sparkStorage,
                storages
            )

            const storages2 = pipe(
                map(
                    evolve({
                        configurationFiles: (files) => {
                            return convertObjectToArray(
                                files,
                                'filename',
                                'data'
                            )
                        },
                        keyTab: (value) => ({
                            filename: 'keyTab',
                            data: value,
                        }),
                        trustStore: (value) => ({
                            filename: 'trustStore',
                            data: value,
                        }),
                        krb5: (value) => ({
                            filename: 'krb5',
                            data: value,
                        }),
                    })
                )
            )(storages)

            const sparkVersion = pipe(
                propOr(EMPTY_ARRAY, 'defaultApplicationConfig'),
                find(
                    ({key}) =>
                        key === 'spark.kubernetes.container.image'
                ), prop('value')
            )(data)

            return {
                ...data,
                sparkVersion,
                sparkStorage: result,
                storages: storages2,
            }
        }
    }, [data])

    return (
        <div
            id={'form'}
            style={{height: '100%', position: 'relative'}}
        >
            <Form
                options={{navigate, pending}}
                initialData={extendedData}
                LayoutComponent={SecurityFormLayout}
                layoutComponentProps={{
                    title: 'EDIT CLUSTER',
                }}
                schema={schema}
                onSubmit={handleOnSubmit}
            />
        </div>
    )
}

export default EditCluster
