import asyncThunk from '../index'

export const getDatasetVersions = asyncThunk({
  method: "GET",
  type: "/dataset/versions/get",
  path: "/namespaces/${namespace}/datasets/${dataset}/versions?limit=100&offset=0",
  api: true,
  lineage: true
});

export default getDatasetVersions;
