import {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import {and, fork} from 'fluture'
import {useNavigate} from 'react-router-dom'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFuture from '../../../../hooks/useFuture'
import deleteCluster from '../../../../api/workloads/clusters/deleteCluster'
import ClusterButtons from '../ClusterButtons'

const ClusterSingleButtons = ({pending, data, texts, getFuture, redirect = true}) => {
  const {id, name} = data

  const navigate = useNavigate()

  const {createNotification} = useNotificationContext()

  const deleteClusterFuture = useFuture(deleteCluster)

  const handleOnDelete = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to delete Cluster',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(() => {
          redirect && navigate('/workloads/list/clusters')
          createNotification({
            title: 'Cluster deleted successfully',
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        deleteClusterFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnEdit = useCallback(({id}) => navigate('/workloads/edit/cluster/' + id), [])

  const handleOnClone = useCallback(({id}) => {
    const params = new URLSearchParams({
      template: id,
    }).toString()
    navigate(`/workloads/create/cluster?${params}`)
    createNotification({
      title: 'Warning',
      message: 'Please, check the correctness of the configuration. Attach files again if needed',
      variant: 'warning',
      autoHide: true,
    })
  }, [])

  return (
    <ClusterButtons
      texts={texts}
      id={id}
      name={name}
      pending={pending}
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
      onClone={handleOnClone}
    />
  )
}

export default ClusterSingleButtons
