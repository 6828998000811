import React, {useEffect, useMemo, useRef} from 'react';
import {
  add,
  all,
  clamp,
  equals,
  filter,
  flatten,
  head,
  is,
  keysIn,
  length,
  map,
  max,
  pathOr,
  pipe,
  prop,
  reduce,
  take,
  uniq,
} from 'ramda';
import * as d3 from 'd3';
//import {changeAxisColor} from '../helpers';
import styled, {useTheme} from 'styled-components';
//import Legend from '../components/legend';

const Svg = styled.svg`
    width: 100%;
    height: 100%;

    text {
        color: ${({theme}) => theme.palette.text.tertiary};
        stroke: transparent;
        font-family: Inter, serif;
        font-size: 11px;
    }

    path {
        stroke: ${({theme}) => theme.palette.line};
    }

    line {
        stroke: ${({theme}) => theme.palette.line};
    }

    .graph rect {
        transition: 0.1s filter;

        :hover {
            filter: brightness(0.9);
        }

        :active {
            filter: brightness(0.8);
        }
    }
`;

const alignMap = {
  top: {graphOrientation: 'horizontal', xAxisOrientation: 'top'},
  bottom: {graphOrientation: 'horizontal', xAxisOrientation: 'bottom'},
  left: {graphOrientation: 'vertical', yAxisOrientation: 'left'},
  right: {graphOrientation: 'vertical', yAxisOrientation: 'right'},
};

const calculateAlign = ({graphOrientation, xAxisOrientation, yAxisOrientation}) =>
  pipe(
    map((ele) => {
      const g = ele['graphOrientation'] === graphOrientation;
      const a =
        graphOrientation === 'horizontal'
          ? ele['xAxisOrientation'] === xAxisOrientation
          : ele['yAxisOrientation'] === yAxisOrientation;

      return g && a;
    }),
    filter((a) => a),
    keysIn,
    head
  )(alignMap);

const BarChart = ({
  data,
  width = 200,
  height = 200,
  xAxisType,
  yAxisType,
  xAxisScale,
  yAxisScale,
  xAxisOrientation = 'bottom',
  yAxisOrientation = 'left',
  graphOrientation = 'horizontal',
  graphType = 'grouped',
  label,
  config,
  colors,
}) => {
  const ease = d3.easeExpInOut;

  const paddingTop = 8;
  const paddingBottom = 24;
  const paddingLeft = 48;
  const paddingRight = 24;

  const graphWidth = clamp(0, Infinity)(width - paddingLeft - paddingRight);
  const graphHeight = clamp(0, Infinity)(height - paddingTop - paddingBottom);

  const ref = useRef();

  const t = 300;

  const orientation = calculateAlign({
    xAxisOrientation,
    yAxisOrientation,
    graphOrientation,
  });

  const isYSeries = pipe(map(prop('y')), all(is(Array)))(data);
  const isXSeries = pipe(map(prop('x')), all(is(Array)))(data);
  const isDoubleBand = xAxisScale === 'band' && yAxisScale === 'band';

  const isStacked = equals('stacked', graphType) && !isDoubleBand;
  const isGrouped = !isStacked;
  const ySeriesSubDomain = pipe(
    map((a) => map(prop('x'), prop('y', a))),
    flatten,
    uniq
  )(data);
  const xSeriesSubDomain = pipe(
    map((a) => map(prop('y'), prop('x', a))),
    flatten,
    uniq
  )(data);
  const subDomain = (isXSeries && xSeriesSubDomain) || (isYSeries && ySeriesSubDomain) || [];

  const yDomain = useMemo(() => {
    const d = map(prop('y'), data);

    const stackedDomain = map((b) => reduce((a, v) => add(v.y, a), 0, b))(isYSeries && isStacked && d);

    const groupedDomain = pipe(flatten, map(prop('y')))(isYSeries && d);

    const domain =
      (isStacked && isYSeries && graphOrientation === 'horizontal' && stackedDomain) ||
      (isStacked && isYSeries && graphOrientation === 'vertical' && groupedDomain) ||
      (isGrouped && isYSeries && groupedDomain) ||
      d;

    if (equals(yAxisScale, 'linear')) {
      const yMax = reduce(max, 0, domain);
      return [0, yMax];
    } else if (equals(yAxisScale, 'logarithmic')) {
      const yMax = reduce(max, 0, domain);
      return [1, yMax];
    } else if (equals(yAxisScale, 'band')) {
      return domain;
    } else return [0, 0];
  }, [yAxisType, data, isYSeries]);

  const xDomain = useMemo(() => {
    const d = map(prop('x'), data);
    const dStacked = map((b) => reduce((a, v) => add(v.x, a), 0, b))(isXSeries && isStacked && d);
    const dGrouped = pipe(flatten, map(prop('x')))(isXSeries && d);

    const domain =
      (isStacked && isXSeries && graphOrientation === 'vertical' && dStacked) ||
      (isStacked && isXSeries && graphOrientation === 'horizontal' && dGrouped) ||
      (isGrouped && isXSeries && dGrouped) ||
      d;


    if (equals(xAxisScale, 'linear')) {
      const xMax = reduce(max, 0, domain);
      return [0, xMax];
    } else if (equals(xAxisScale, 'logarithmic')) {
      const xMax = reduce(max, 0, domain);
      return [1, xMax];
    } else if (equals(xAxisScale, 'band')) {
      return domain;
    } else return [0, 0];
  }, [xAxisType, data, isXSeries, isStacked, isGrouped, graphOrientation]);


  const yRange = xAxisOrientation === 'bottom' ? [graphHeight, 0] : [0, graphHeight];

  const xRange = yAxisOrientation === 'left' ? [0, graphWidth] : [graphWidth, 0];

  const xScale =
    (xAxisScale === 'band' && d3.scaleBand().domain(xDomain).range(xRange).padding(0.2)) ||
    (xAxisScale === 'logarithmic' && d3.scaleLog().domain(xDomain).range(xRange)) ||
    d3.scaleLinear().domain(xDomain).range(xRange);

  const yScale =
    (yAxisScale === 'band' && d3.scaleBand().domain(yDomain).range(yRange).padding(0.2)) ||
    (yAxisScale === 'logarithmic' && d3.scaleLog().domain(yDomain).range(yRange)) ||
    d3.scaleLinear().domain(yDomain).range(yRange);

  const xBandwidth = xScale.bandwidth ? xScale.bandwidth() : 1;
  const yBandwidth = yScale.bandwidth ? yScale.bandwidth() : 1;

  const xOffset = xAxisScale === 'band' ? xBandwidth / 2 : 0;
  const yOffset = yAxisScale === 'band' ? yBandwidth / 2 : 0;

  const xSubScale = isYSeries && d3.scaleBand().domain(ySeriesSubDomain).range([0, xBandwidth]);

  const ySubScale = isXSeries && d3.scaleBand().domain(xSeriesSubDomain).range([0, yBandwidth]);

  const xSubBandwidth = xSubScale.bandwidth ? xSubScale.bandwidth() : 1;
  const ySubBandwidth = ySubScale.bandwidth ? ySubScale.bandwidth() : 1;

  const colorLength = pipe(length, clamp(2, Infinity))(subDomain);

  const defaultDataColors = d3.schemeSpectral[11];

  const dataColors = ['red', 'green', 'blue']

  const {palette} = useTheme()


  const ccc2 = d3.quantize(d3.interpolateHcl(palette.green[300], palette.green[300]), colorLength);

  const ccc = dataColors;

  var color = d3.scaleOrdinal().domain(subDomain).range(ccc);

  const calculateWidth = ({x, xt}, i, d) => {
    const scaledX = xScale(x) || xScale(xt) || 0;

    const ddd = map(prop('__data__'), d);

    const stacked = pipe(take(i + 1), map(prop('x')), reduce(add, 0), clamp(1, Infinity), xScale)(ddd);

    const prev = pipe(take(i), map(prop('x')), reduce(add, 0), clamp(1, Infinity), xScale)(ddd);
    const dupa = stacked - prev;

    let result = 1;

    if (equals('top', orientation)) {
      result = (isStacked && isYSeries && xBandwidth) || (isGrouped && isYSeries && xSubBandwidth) || xBandwidth;
    } else if (equals('bottom', orientation)) {
      result = (isStacked && isYSeries && xBandwidth) || (isGrouped && isYSeries && xSubBandwidth) || xBandwidth;
    } else if (equals('left', orientation)) {
      if (isStacked && dupa) {
        result = dupa;
      } else {
        result = scaledX + xOffset;
      }
    } else if (equals('right', orientation)) {
      if (isStacked && dupa) {
        result = -dupa;
      } else {
        result = graphWidth - scaledX - xOffset;
      }
    }
    return result;
  };

  const calculateHeight = ({y, yt}, i, d) => {
    const scaledY = yScale(yt) || yScale(y) || 0;

    const ddd = map(prop('__data__'), d);

    const stacked = pipe(take(i + 1), map(prop('y')), reduce(add, 0), clamp(1, Infinity), yScale)(ddd);

    const prev = pipe(take(i), map(prop('y')), reduce(add, 0), clamp(1, Infinity), yScale)(ddd);
    const dupa = stacked - prev;

    let result = 1;

    if (equals('top', orientation)) {
      result = isStacked && dupa ? dupa : scaledY + yOffset;
    } else if (equals('bottom', orientation)) {
      result = isStacked && dupa ? -dupa : graphHeight - scaledY - yOffset;
    } else if (equals('left', orientation)) {
      result = isStacked ? yBandwidth : isXSeries ? ySubBandwidth : yBandwidth;
    } else if (equals('right', orientation)) {
      result = isStacked ? yBandwidth : isXSeries ? ySubBandwidth : yBandwidth;
    }
    return result;
  };

  const calculateX = ({x, xt}, i, d) => {
    const scaledX = xScale(x) || xScale(xt) || 0;

    const ddd = map(prop('__data__'), d);

    const stacked = pipe(take(i), map(prop('x')), reduce(add, 0), clamp(1, Infinity), xScale)(ddd);
    const dupa = pipe(take(i + 1), map(prop('x')), reduce(add, 0), xScale)(ddd);
    let result = 0;

    if (equals('top', orientation)) {
      if (isStacked && isYSeries) {
        result = 0;
      } else {
        result = (isXSeries && scaledX) || (xSubScale && xSubScale(x)) || 0;
      }
    } else if (equals('bottom', orientation)) {
      if (isStacked && isYSeries) {
        result = 0;
      } else {
        result = (isXSeries && scaledX) || (xSubScale && xSubScale(x)) || 0;
      }
    } else if (equals('left', orientation)) {
      result = isStacked && stacked ? stacked : 0;
    } else if (equals('right', orientation)) {
      result = isStacked && isXSeries ? -graphWidth + dupa : scaledX - graphWidth + xOffset;
    }

    return result;
  };

  const calculateY = ({y, yt}, i, d) => {
    const scaledY = yScale(y) || yScale(yt) || 0;

    const ddd = map(prop('__data__'), d);

    const stacked = pipe(take(i), map(prop('y')), reduce(add, 1), clamp(0, Infinity), yScale)(ddd);
    const dupa = pipe(take(i + 1), map(prop('y')), reduce(add, 0), yScale)(ddd);
    let result = 0;
    if (orientation === 'top') {
      result = isStacked && isYSeries ? stacked : 0;
    } else if (orientation === 'bottom') {
      if (isStacked && isYSeries) {
        result = -graphHeight + dupa;
      } else {
        result = scaledY - graphHeight + yOffset;
      }
    } else if (orientation === 'left') {
      if (isStacked && isXSeries) {
        result = 0;
      } else {
        result = (isXSeries && ySubScale(y)) || (isYSeries && yScale(y));
      }
    } else if (orientation === 'right') {
      if (isStacked && isXSeries) {
        result = 0;
      } else {
        result = (isXSeries && ySubScale(y)) || (isYSeries && yScale(y));
      }
    }
    return result || 0;
  };

  const transformData = (d) => {
    let result = [d];
    if (isYSeries) {
      result = pipe(
        prop('y'),
        map((element) => ({...element, xt: d.x}))
      )(d);
    } else if (isXSeries) {
      result = pipe(
        prop('x'),
        map((element) => ({...element, yt: d.y}))
      )(d);
    }

    return result;
  };

  const calculateTransform = ({x, y}) => {
    const scaledX = xScale(x) || 0;
    const scaledY = yScale(y) || 0;

    return (
      (orientation === 'top' && `translate(${scaledX},0)`) ||
      (orientation === 'bottom' && `translate(${scaledX},${graphHeight})`) ||
      (orientation === 'left' && `translate(0,${scaledY})`) ||
      (orientation === 'right' && `translate(${graphWidth},${scaledY})`)
    );
  };

  const calculateColor = ({x, y}) => (isYSeries && color(x)) || (isXSeries && color(y)) || color(0);

  const yAxisTransform =
    yAxisOrientation === 'left'
      ? `translate(${paddingLeft}, ${paddingTop})`
      : `translate(${paddingLeft + graphWidth}, ${paddingTop})`;
  const ticksHorizontal = Math.floor(height / 100);
  useEffect(() => {
    let svg = d3.select(ref.current);

    const graph = svg.select('.graph');

    const groupsUpdate = graph.selectAll('g').data(data);

    groupsUpdate.transition().ease(ease).duration(t).attr('transform', calculateTransform);

    const groupsEnter = groupsUpdate.enter().append('g').attr('transform', calculateTransform);

    const groupsExit = groupsUpdate.exit().transition().duration(t).remove();

    groupsEnter
      .selectAll('rect')
      .data(transformData)
      .enter()
      .append('rect')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 0.1)
      .attr(graphOrientation === 'vertical' ? 'x' : 'y', 0)
      .attr(graphOrientation === 'vertical' ? 'width' : 'height', 0)
      .transition()
      .ease(ease)
      .duration(t)
      .attr('x', calculateX)
      .attr('y', calculateY)
      .attr('width', calculateWidth)
      .attr('height', calculateHeight)
      .attr('fill', palette.blue[400]);

    groupsUpdate
      .selectAll('rect')
      .data(transformData)
      .enter()
      .append('rect')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 0.1)
      .attr(graphOrientation === 'vertical' ? 'x' : 'y', 0)
      .attr(graphOrientation === 'vertical' ? 'width' : 'height', 0)
      .transition()
      .ease(ease)
      .duration(t)
      .attr('x', calculateX)
      .attr('y', calculateY)
      .attr('width', calculateWidth)
      .attr('height', calculateHeight)
      .attr('fill', palette.blue[400]);

    groupsUpdate
      .selectAll('rect')
      .data(transformData)
      .transition()
      .ease(ease)
      .duration(t)
      .attr('x', calculateX)
      .attr('y', calculateY)
      .attr('width', calculateWidth)
      .attr('height', calculateHeight)
      .attr('fill', palette.blue[400]);

    groupsUpdate
      .selectAll('rect')
      .data(transformData)
      .exit()
      .transition()
      .ease(ease)
      .duration(t)
      .attr(graphOrientation === 'vertical' ? 'x' : 'y', 0)
      .attr(graphOrientation === 'vertical' ? 'width' : 'height', 0)
      .remove();

    groupsUpdate
      .exit()
      .selectAll('rect')
      .transition()
      .ease(ease)
      .duration(t)
      .attr(graphOrientation === 'vertical' ? 'x' : 'y', 0)
      .attr(graphOrientation === 'vertical' ? 'width' : 'height', 0)
      .remove();

    const xAxis = svg.select('.x-axis');
    const yAxis = svg.select('.y-axis');

    xAxis
      .transition()
      .duration(t)
      .attr(
        'transform',
        `translate(${paddingLeft}, ${xAxisOrientation === 'bottom' ? height - paddingBottom : paddingTop})`
      )
      .call(xAxisOrientation === 'bottom' ? d3.axisBottom(xScale) : d3.axisTop(xScale).ticks(2));

    yAxis
      .transition()
      .duration(t)
      .attr(
        'transform',
        yAxisOrientation === 'left'
          ? `translate(${paddingLeft}, ${paddingTop})`
          : `translate(${paddingLeft + graphWidth}, ${paddingTop})`
      )
      .call(
        yAxisOrientation === 'left'
          ? d3.axisLeft(yScale).ticks(4).tickFormat((a) => (a / 1000 / 60/ 60).toFixed(2))
          : d3.axisRight(yScale).ticks(ticksHorizontal)
      );

    xAxis
      .selectAll('.tick text')
      .attr('font-size', '10px');

    yAxis.attr('text-anchor', () => (yAxisOrientation === 'left' ? 'end' : 'start'));

   // const axesConfig = config?.axes;
   // changeAxisColor(xAxis, axesConfig);
  //  changeAxisColor(yAxis, axesConfig);
  }, [
    data,
    width,
    height,
    isYSeries,
    isXSeries,
    yDomain,
    xDomain,
    xScale,
    yScale,
    xAxisOrientation,
    yAxisOrientation,
    graphOrientation,
    orientation,
    xSubScale,
    ySubScale,
    calculateX,
    calculateY,
    calculateWidth,
    calculateHeight,
    yAxisTransform,
    ticksHorizontal,
  ]);

  const graphTransform = useMemo(() => `translate(${paddingLeft}, ${paddingTop})`, [width, height]);

  return (
    <div
      style={{
        padding: 0,
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          height: 30,
          left: 40,
          display: 'flex',
          alignItems: 'center',
          fontSize: 12
        }}
      >
        {label}
      </div>
      <div
        style={{
          pointerEvents: 'none',
          height: 40,
          position: 'absolute',
          left: paddingLeft,
          right: paddingRight,
          bottom: 0,
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
      </div>
      <Svg ref={ref} style={{overflow: 'visible'}}>
        <g className={'graph'} transform={graphTransform} />
        <g className={'x-axis'} />
        <g className={'y-axis'} />
      </Svg>
    </div>
  );
};
export default BarChart;
