import asyncThunk from "../../index";

export const resumeSchedule = asyncThunk({
  method: 'POST',
  type: 'schedule/resume',
  path: '/schedule/${id}/resume',
  api: true,
})

export default resumeSchedule
