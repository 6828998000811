import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {map, path, pathOr, pipe, propOr, toPairs} from 'ramda'
import {EMPTY_OBJECT} from '../../constants'
import Carusel from '../../components/carusel'
import MetricChart from '../../components/charts/MetricChart'
import {useSelector} from 'react-redux'
import {getBaseUrl} from '../../helpers'
import useSSE from '../../hooks/useSSE'
import useFuture from "../../hooks/useFuture";
import getClustersMetrics from "../../api/workloads/clusters/getClustersMetrics";
import {fork} from "fluture";
import {useInterval} from "frontcore";
import {singleCallFetchOptions} from "../../api/helpers";
import useFetch from "../../hooks/useFetch";
import getInfoCountFetch from "../../api/file/getInfoCountFetch";
import getClustersMetricsFetch from "../../api/workloads/clusters/getClustersMetricsFetch";

const Root = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
    background: ${({theme}) => theme.palette['surface-secondary']};
`

const Grid = styled.div`
  flex: 1;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`

const Metrics = () => {
  const apiUrl = process.env.REACT_APP_API_URL

  const baseUrl = getBaseUrl()
  const url = useMemo(
    () => `${baseUrl}${apiUrl}/truly/overview`,
    [baseUrl, apiUrl]
  )

  const [overviewData, setOverviewData] = useState()

  useSSE(
    url,
    [
      {
        name: 'message',
        handler: (event) => {
          const data = JSON.parse(event.data)

          setOverviewData(data)
        },
      },
    ],
    []
  )

  const {metricsRefreshRate = 600000} = useSelector(
      path(['settings', 'settings'])
  )

  const fetchOptions = Number(metricsRefreshRate) ? {refreshInterval: Number(metricsRefreshRate)} : singleCallFetchOptions

  const metrics = useFetch(
      getClustersMetricsFetch,
      fetchOptions
  )

  const extendedData = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'metrics'),
        toPairs,
        map(([name, {metric}]) => ({
          name,
          metric,
        }))
      )(metrics?.data),
    [metrics]
  )

  function replaceNanWithZero(value) {
    return value === 'NaN' ? 0 : Math.round(value)
  }

  return (
    <Root>
      <Grid>
        <MetricChart
          name={'Active Service Amount'}
          value={replaceNanWithZero(
            overviewData?.groupAmount
          )}
        />
        <MetricChart
          name={'Interactive Job Amount'}
          value={replaceNanWithZero(
            overviewData?.interactiveJobsAmount
          )}
        />

        <MetricChart
          name={'Interactive Job Per Service Ratio'}
          value={replaceNanWithZero(
            overviewData?.interactiveJobsPerGroupRatio
          )}
        />
        <MetricChart
          name={'Busy Interactive Job'}
          value={replaceNanWithZero(
            overviewData?.busyInteractiveJobs
          )}
        />
        <MetricChart
          name={'Ready Sessions'}
          value={replaceNanWithZero(
            overviewData?.readyInteractiveJobsPercent
          )}
          unit={'%'}
        />
        <MetricChart
          name={'Busy Sessions'}
          value={replaceNanWithZero(
            overviewData?.busyInteractiveJobsPercent
          )}
          unit={'%'}
        />
        <MetricChart
          name={'Ready/Busy Ratio'}
          value={replaceNanWithZero(
            overviewData?.readyToBusyInteractiveJobsRatio
          )}
          unit={'%'}
        />
        <MetricChart
          name={'Ready Jobs'}
          value={replaceNanWithZero(
            overviewData?.readyInteractiveJobs
          )}
        />
        <MetricChart
          name={'Busy Single Jobs'}
          value={replaceNanWithZero(
            overviewData?.busySingleJobs
          )}
        />
        <MetricChart
          name={'Failed Jobs'}
          value={replaceNanWithZero(
            overviewData?.failedSingleJobs
          )}
        />
        <MetricChart
          name={'Failed Jobs Percent'}
          value={replaceNanWithZero(
            overviewData?.failedSingleJobsPercent
          )}
          unit={'%'}
        />
        <MetricChart
          name={'Failed Jobs Ratio'}
          value={replaceNanWithZero(
            overviewData?.failedToFinishedSingleJobsRatio
          )}
          unit={'%'}
        />
      </Grid>

        <Carusel data={extendedData} />

    </Root>
  )
}

export default Metrics
