import React, {useEffect, useState} from 'react'
import {Button, DropDownButton} from 'frontcore'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from 'react-oidc-context'
import styled from 'styled-components'
import {getLocalStorage} from '../../views/WorkloadsView/hooks'
import {toBoolean} from '../../helpers'
import {append, path, prop, take, toUpper} from 'ramda'
import Flex from '../Flex'
import Switch from '../Switch'
import {useDispatch, useSelector} from 'react-redux'
import {setSettings} from '../../reducers/settings'
import Line from '../line'
import useFetch from '../../hooks/useFetch'
import {getOAuth2ConfigurationFetch} from '../../api/security/getOAuth2ConfigurationFetch'
import {singleCallFetchOptions} from '../../api/helpers'
import {EMPTY_OBJECT} from "../../constants";

const Font = styled.div`
  color: ${({theme}) => theme.palette.button.text.primary.text};
  font-size: 14px;
  font-family: Inter, serif;
  user-select: none;
`
const parseJwt = (token = '') => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

const Root = styled.div`
  background-color: ${({theme}) => theme.palette['surface-primary']};
`

const ContentComponent = ({closeFn, userId}) => {
  const [mode, setMode] = useState(false)

  useEffect(() => {
    const mode = getLocalStorage('mode')
    const result = toBoolean(mode)
    setMode(result)
  }, [])

  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))
  const auth = useAuth()
  const {removeUser} = auth
  const navigate = useNavigate()

  const {pathname} = useLocation()

  const dispatch = useDispatch()

  return (
    <Root>
      <Button
        fullWidth
        tabIndex={-1}
        variant={'text'}
        rounding={false}
        onClick={() => {
          closeFn()
          navigate('/settings/theme')
        }}
      >
        Settings
      </Button>

      <Button
        fullWidth
        tabIndex={-1}
        variant={'text'}
        rounding={false}
        onClick={() => {
          closeFn()

          navigate('/security/edit/password/' + userId)
        }}
      >
        Change Password
      </Button>
      <Flex
        style={{height: 32, paddingLeft: 16, paddingRight: 8}}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Font>Dark Mode</Font>
        <Switch
          value={darkMode}
          onClick={() => {
            dispatch(setSettings({darkMode: !darkMode}))
          }}
        />
      </Flex>
      <Line />
      <Button
        fullWidth
        tabIndex={-1}
        variant={'text'}
        rounding={false}
        onClick={() => {
          closeFn()
          auth.removeUser()
          if (pathname === '/') {
            localStorage.setItem('cachedUrl', 'WorkloadsView/list/services')
          } else if (pathname !== '/login') {
            localStorage.setItem('cachedUrl', pathname)
          }
          localStorage.removeItem('ilum_token')
          navigate('/login')
        }}
      >
        Log Out
      </Button>
    </Root>
  )
}

const Miniature = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.red[300]};
  color: ${({theme}) => theme.palette.neutral[0]};
  display: flex;
  align-items: center;
  justify-content: center;
`

const MiniatureComponent = ({text}) => <Miniature>{text}</Miniature>

const UserDropdown = () => {
  const expanded = true
  const {user} = useAuth()



  const token = localStorage.getItem('ilum_token') || user?.id_token

  const {data: configuration = EMPTY_OBJECT} = useFetch(getOAuth2ConfigurationFetch, singleCallFetchOptions)

  const {nameClaim, emailClaim} = configuration

  const parsedToken = token ? parseJwt(token) : {sub: 'none', email: 'none'}
  const {sub, userId} = parsedToken

  const name = prop(nameClaim, parsedToken)
  const email = prop(emailClaim, parsedToken)
  const letter = name ? toUpper(take(1, name)) : '?'

  const Component = expanded
    ? () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {name}
          </div>
          <div style={{fontSize: 12}}>{email}</div>
        </div>
      )
    : MiniatureComponent

  const StartComponent = expanded ? MiniatureComponent : null

  return (
    <DropDownButton
      buttonProps={{
        tabIndex: -1,
        variant: 'text',

        square: !expanded,
        justifyContent: expanded ? 'flex-start' : 'center',
        Component,
        componentProps: {
          text: letter,
        },
        StartComponent,
        startComponentProps: {
          text: letter,
        },
      }}
      ContentComponent={ContentComponent}
      contentComponentProps={{userId}}
    />
  )
}

export default UserDropdown
