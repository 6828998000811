import {memo, useCallback, useMemo} from 'react'
import StatusIndicator from '../../../components/StatusIndicator'
import {useDispatch, useSelector} from 'react-redux'
import {sidebarChooseQuery} from '../../../reducers/sql/sidebar'
import {isNotNil, path, pathOr} from 'ramda'
import RButton from '../../../components/RButton'

const Query = (query) => {
  const {id, type, local, name} = query

  const dispatch = useDispatch()

  const handlePickQuery = useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(sidebarChooseQuery(id))
    },
    [id]
  )

  const chosenQuery = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))

  const diagnostics = useSelector(pathOr([], ['sqlViewer', 'editor', 'diagnostics', id]))
  const notebookDiagnostics = useSelector(path(['sqlViewer', 'notebook', 'diagnostics']))
  const hasError = useMemo(() => {
    if (query.type !== 'notebook') {
      return diagnostics.length > 0
    }
    return query.statements.some(({ id }) => {
      return isNotNil(notebookDiagnostics[id]) && notebookDiagnostics[id].length > 0
    })
  }, [query, diagnostics, notebookDiagnostics])

  return (
    <RButton
      icon={type === 'notebook' ? 'pencil' : 'database'}
      name={name}
      active={chosenQuery === id}
      onClick={handlePickQuery}
    >
      {hasError > 0 && (
        <div style={{ marginRight: 8 }}>
          <StatusIndicator 
            variant="error" 
            value="ERROR" 
          />
        </div>
      )}
      {local && (
        <StatusIndicator
          variant="information"
          value="LOCAL"
        />
      )}
      {!local && (
        <StatusIndicator
          variant="default"
          value="REMOTE"
        />
      )}
    </RButton>
  )
}

export default memo(Query)
