import ClusterButtons from '../ClusterButtons'
import React, {useCallback} from 'react'
import {map, pipe} from 'ramda'
import {and, fork, parallel} from 'fluture'
import {EMPTY_ARRAY} from '../../../../constants'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFuture from '../../../../hooks/useFuture'
import deleteCluster from '../../../../api/workloads/clusters/deleteCluster'

const ClusterBatchButtons = ({
  selected,
  setSelected,
  getFuture,
}) => {
  const {createNotification} = useNotificationContext()

  const deleteClusterFuture = useFuture(deleteCluster)

  const handleOnBatchDelete = useCallback(() => {
    pipe(
      map((id) =>
        deleteClusterFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({ message }) => {
        createNotification({
          title: 'Failed to delete Clusters',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        setSelected(EMPTY_ARRAY)
        createNotification({
          title: 'Clusters successfully deleted',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  return (
    <ClusterButtons
      texts={false}
      onDelete={handleOnBatchDelete}
    />
  )
}

export default ClusterBatchButtons
