import {createSlice} from '@reduxjs/toolkit'
import {EMPTY_ARRAY, EMPTY_STRING} from '../../constants'
import {extraReducersMapper} from '../../api'
import {
  append,
  assoc,
  evolve,
  find,
  identity,
  isNil,
  isNotNil,
  last,
  mergeLeft,
  pipe,
  propOr,
  reject,
  reverse,
  takeWhile,
  uniq,
} from 'ramda'

import getSQLStatements from '../../api/sql/getSQLStatements'
import getSQLNotebooks from '../../api/sql/getSQLNotebooks'

const INITIAL_DATA = Object.freeze({
  response: null,
  pending: true,
  error: false,
})

export const SidebarTabs = Object.freeze({
  queries: 'Queries',
  resources: 'Resources',
})

export const QuerySelect = Object.freeze({
  all: 'All',
  local: 'Local',
  remote: 'Remote',
})

export const QueryTypeSelect = Object.freeze({
  all: 'All',
  queries: 'Queries',
  notebooks: 'Notebooks',
})

const initialState = Object.freeze({
  chosenCluster: null,
  tab: SidebarTabs.queries,
  search: EMPTY_STRING,
  querySelect: QuerySelect.all,
  queryTypeSelect: QueryTypeSelect.all,
  queries: EMPTY_ARRAY,
  queriesBar: EMPTY_ARRAY,
  chosenQuery: null,
  data: {
    queries: INITIAL_DATA,
  },
})

const createEmptyQuery = () => {
  return Object.freeze({
    id: `local:${Date.now()}`,
    local: true,
    name: `Query ${Date.now() % 1e3}`,
    statement: EMPTY_STRING,
  })
}

const createEmptyNotebook = () => {
  return Object.freeze({
    id: `local:${Date.now()}`,
    type: 'notebook',
    local: true,
    name: `Notebook ${Date.now() % 1e3}`,
    statements: EMPTY_ARRAY,
  })
}

const sidebarSlice = createSlice({
  name: 'sql/sidebar',
  initialState,
  reducers: {
    sidebarSetCluster: (state, {payload}) => assoc('chosenCluster', payload, state),
    sidebarSetTab: (state, {payload}) => assoc('tab', payload, state),
    sidebarSetSearch: (state, {payload}) => assoc('search', payload, state),
    sidebarSetQuerySelect: (state, {payload}) => assoc('querySelect', payload, state),
    sidebarSetQueryTypeSelect: (state, {payload}) => assoc('queryTypeSelect', payload, state),
    sidebarAppendContent: (state, { payload }) => {
      const {
        queryId,
        statementId,
        content,
      } = payload
      return {
        ...state,
        queries: state.queries.map(query => {
          if (query.id !== queryId) return query
          if (query.type !== 'notebook') {
            return {
              ...query,
              statement: query.statement + content,
            }
          }
          return {
            ...query,
            statements: query.statements.map(statement => {
              if (statement.id !== statementId) return statement
              return {
                ...statement,
                code: statement.code + content,
              }
            })
          }
        })
      }
    },
    sidebarNewQuery: (state, {payload}) => {
      let localQuery = createEmptyQuery()
      if (payload) {
        localQuery = mergeLeft(payload, localQuery)
      }
      state.queries.unshift(localQuery)
      state.chosenQuery = localQuery.id
      state.queriesBar.push(localQuery.id)
    },
    sidebarNewNotebook: (state, {payload}) => {
      let localNotebook = createEmptyNotebook()
      if (payload) {
        localNotebook = mergeLeft(payload, localNotebook)
      }
      state.queries.unshift(localNotebook)
      state.chosenQuery = localNotebook.id
      state.queriesBar.push(localNotebook.id)
    },
    sidebarAppendNotebookCell: (state, {payload: notebookId}) => ({
      ...state,
      queries: state.queries.map((query) => {
        if (query.id !== notebookId) return query
        return {
          ...query,
          statements: [
            ...(query?.statements ?? []),
            {
              id: `cell:${Date.now()}`,
              language: 'sql',
              code: EMPTY_STRING,
            },
          ],
        }
      }),
    }),
    sidebarAppendMarkdownCell: (state, {payload: notebookId}) => ({
      ...state,
      queries: state.queries.map((query) => {
        if (query.id !== notebookId) return query
        return {
          ...query,
          statements: [
            ...(query?.statements ?? []),
            {
              id: `cell:${Date.now()}`,
              language: 'markdown',
              code: EMPTY_STRING,
            },
          ],
        }
      }),
    }),
    sidebarSetCellLanguage: (state, { payload }) => {
      const {
        notebookId,
        statementId,
        language,
        flip = false
      } = payload
      return {
        ...state,
        queries: state.queries.map(query => {
          if (query.id !== notebookId) return query
          return {
            ...query,
            statements: query.statements.map(statement => {
              if (statement.id !== statementId) return statement
              const nextLanguage = flip
                ? (statement.language === 'markdown' ? 'sql' : 'markdown')
                : language
              return {
                ...statement,
                language: nextLanguage,
              }
            })
          }
        })
      }
    },
    sidebarAddNotebookCellBeforeOrAfter: (state, {payload}) => {
      const {
        notebookId, 
        statementId, 
        place,
        language = 'sql',
        code = EMPTY_STRING,
      } = payload
      return {
        ...state,
        queries: state.queries.map((query) => {
          if (query.id !== notebookId) {
            return query
          }
          const thisCell = pipe(
            propOr([], 'statements'),
            find((cell) => cell.id === statementId)
          )(query)
          if (isNil(thisCell)) {
            return query
          }
          const cellsBefore = pipe(
            propOr([], 'statements'),
            takeWhile((cell) => cell.id !== statementId)
          )(query)
          const cellsAfter = pipe(
            propOr([], 'statements'),
            reverse,
            takeWhile((cell) => cell.id !== statementId),
            reverse
          )(query)
          const newCell = {
            id: `cell:${Date.now()}`,
            language,
            code,
          }
          return {
            ...query,
            statements:
              place === 'before'
                ? [...cellsBefore, newCell, thisCell, ...cellsAfter]
                : [...cellsBefore, thisCell, newCell, ...cellsAfter],
          }
        }),
      }
    },
    sidebarMoveNotebookCellTopOrBottom: (state, {payload}) => {
      const {notebookId, statementId, place} = payload

      return {
        ...state,
        queries: state.queries.map((query) => {
          if (query.id !== notebookId) {
            return query
          }
          const thisCell = pipe(
            propOr([], 'statements'),
            find((cell) => cell.id === statementId)
          )(query)
          if (isNil(thisCell)) {
            return query
          }
          const swapCell = pipe(
            propOr([], 'statements'),
            place === 'top' ? identity : reverse,
            takeWhile((cell) => cell.id !== statementId),
            last
          )(query)
          if (isNil(swapCell)) {
            return query
          }
          return {
            ...query,
            statements: query.statements.map((cell) => {
              if (cell.id === statementId) return swapCell
              if (cell.id === swapCell.id) return thisCell
              return cell
            }),
          }
        }),
      }
    },
    sidebarDeleteNotebookCell: (state, {payload}) => {
      const {notebookId, statementId} = payload
      pipe(
        find(({id}) => id === notebookId),
        (notebook) => {
          if (isNil(notebook) || isNil(notebook.statements)) return
          notebook.statements = notebook.statements.filter(({id}) => id !== statementId)
        }
      )(state.queries)
    },
    sidebarChangeNotebookCell: (state, {payload}) => {
      const {notebookId, statementId, newCode} = payload
      pipe(
        find(({id}) => id === notebookId),
        (notebook) => {
          const statement = notebook?.statements?.find(({id}) => {
            return id === statementId
          })
          if (isNil(statement)) return
          statement.code = newCode
        }
      )(state.queries)
    },
    sidebarChooseQuery: (state, {payload}) => {
      return pipe(
        assoc('chosenQuery', payload),
        evolve({queriesBar: pipe(append(payload), uniq)})
      )(state)
    },

    sidebarMutateQuery: (state, {payload}) => {
      state.queries = state.queries.map((query) => {
        if (query.id !== payload.id) return query
        return mergeLeft(payload, query)
      })
    },
    removeQueriesBar: (state, {payload}) => {
      const filteredQueriesBar = state.queriesBar.filter((queryId) => {
        return queryId !== payload
      })
      if (payload !== state.chosenQuery) {
        state.queriesBar = filteredQueriesBar
        return
      }
      const firstAfter = pipe(
        reverse,
        takeWhile((queryId) => queryId !== payload),
        last
      )(state.queriesBar)
      if (isNotNil(firstAfter)) {
        state.queriesBar = filteredQueriesBar
        state.chosenQuery = firstAfter
        return
      }
      const firstBefore = pipe(
        takeWhile((queryId) => queryId !== payload),
        last
      )(state.queriesBar)
      if (isNotNil(firstBefore)) {
        state.queriesBar = filteredQueriesBar
        state.chosenQuery = firstBefore
        return
      }
      state.queriesBar = filteredQueriesBar
      state.chosenQuery = null
    },
    sidebarRemoveQuery: (state, {payload}) =>
      evolve(
        {
          queries: reject(({id}) => id === payload),
          queriesBar: reject((id) => id === payload),
          chosenQuery: () => null,
        },
        state
      ),
    sidebarQueryMakeRemote: (state, {payload}) => {
      const {localId, remote} = payload
      const updatedQueries = state.queries.map((query) => {
        if (query.id !== localId) return query
        return mergeLeft({...remote, local: false}, query)
      })
      return {
        ...state,
        queries: updatedQueries,
        chosenQuery: remote.id,
      }
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getSQLStatements,
      name: 'queries',
      fulfilledFn: (payload) => (state) => {
        const localQueries = state.queries.filter(({local, type}) => local && type !== 'notebook')
        const remoteQueries = payload.content.map((query) =>
          Object.freeze({
            ...query,
            local: false,
          })
        )
        const notebooks = state.queries.filter(({type}) => type === 'notebook')
        return {
          ...state,
          queries: [...notebooks, ...localQueries, ...remoteQueries],
        }
      },
    },
    {
      asyncThunk: getSQLNotebooks,
      name: 'notebooks',
      fulfilledFn: (payload) => (state) => {
        const localNotebooks = state.queries.filter(({local, type}) => local && type === 'notebook')
        const remoteNotebooks = payload.content.map((notebook) =>
          Object.freeze({
            ...notebook,
            type: 'notebook',
            local: false,
            statements: notebook.statements.map(cell => {
              return {
                ...cell,
                language: cell?.language?.toLowerCase() ?? 'sql'
              }
            })
          })
        )
        const queries = state.queries.filter(({type}) => type !== 'notebook')
        return {
          ...state,
          queries: [...localNotebooks, ...remoteNotebooks, ...queries],
        }
      },
    },
  ]),
})

export default sidebarSlice.reducer
export const {
  removeQueriesBar,
  sidebarSetCluster,
  sidebarSetTab,
  sidebarSetSearch,
  sidebarSetQuerySelect,
  sidebarSetQueryTypeSelect,
  sidebarNewQuery,
  sidebarNewNotebook,
  sidebarAppendNotebookCell,
  sidebarAppendMarkdownCell,
  sidebarChangeNotebookCell,
  sidebarDeleteNotebookCell,
  sidebarChooseQuery,
  sidebarMutateQuery,
  sidebarQueryMakeRemote,
  sidebarRemoveQuery,
  sidebarAddNotebookCellBeforeOrAfter,
  sidebarMoveNotebookCellTopOrBottom,
  sidebarSetCellLanguage,
  sidebarAppendContent,
} = sidebarSlice.actions
