import { useEffect, useState } from "react";
import { equals } from "ramda";

const useDebounce = (value, delay, timerIdRef) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!equals(value, debouncedValue)) {
        setDebouncedValue(value);
      }
    }, delay);

    if (timerIdRef) {
      timerIdRef.current = handler;
    }

    return () => {
      clearTimeout(handler);
    };
  }, [value]); // ... need to be able to change that dynamically. // You could also add the "delay" var to inputs array if you ... // Only re-call effect if value changes

  return debouncedValue;
};

export default useDebounce;
