import {memo, useMemo} from 'react'
import {useSelector} from 'react-redux'

import Configuration from '../../../../../components/configuration'
import NoResultFound from '../../../../../components/NoResultFound'
import {isNil, pathOr} from 'ramda'
import styled from 'styled-components'

export const Root = styled.div`
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
`

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ScheduleDetailsTags = () => {
  const tags = useSelector(
    pathOr({}, [
      'workloads',
      'data',
      'schedule',
      'response',
      'tags',
    ])
  )

  const tagPairs = useMemo(() => {
    if (isNil(tags)) return []
    return Object.entries(tags).map(([key, value]) => {
      return Object.freeze({key, value})
    })
  }, [tags])

  return (
    <Root>
      {tagPairs.length > 0 ? (
        <Configuration
          data={tagPairs}
          schema={[
            {
              id: 'key',
              label: 'Key',
              copy: true,
            },
            {
              id: 'value',
              label: 'Value',
              copy: true,
              obfuscate: true,
            },
          ]}
        />
      ) : (
        <Centered>
          <NoResultFound
            title="No Tags Found"
            text="There are no tags for this schedule"
          />
        </Centered>
      )}
    </Root>
  )
}

export default ScheduleDetailsTags
