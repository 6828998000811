import {Button, Input, Select} from 'frontcore'
import {any, equals, isEmpty, map, path} from 'ramda'
import RadioButtons from '../../../../components/RadioButtons'
import Parameters from '../../../../components/parameters'
import {
  onSubmitParametersFn,
  onSubmitSparkParamsFn,
} from '../../../../helpers'
import memorySettings from '../../memorySettings'
import resourcesSchema from '../../resourcesSchema'
import requiredValidator from "../../../../validators/requiredValidator";
import clusterNameOnChangeFn from "../../helpers/clusterNameOnChangeFn";

const schema = (data, options) => {
  const {pending, navigate, cluster, clusters} = options

  return {
    fields: [
      {
        section: 'General',
        id: 'name',
        name: 'Name',
        description: 'Name of the job.',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },

      {
        section: 'Memory',
        id: 'clusterName',
        onChangeFn: clusterNameOnChangeFn(clusters),
        name: 'Cluster',
        description:
          'Cluster on which job will be launched.',
        Component: Select,
        componentProps: {
          disabled: !!cluster,
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: map(
            (cluster) => cluster.name,
            options?.clusters || []
          ),
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'jobClass',
        name: 'Class',
        description:
          'Full class name of the job. (e.g. org.apache.spark.examples.SparkPi) or filename for python.',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'language',
        name: 'Language',
        description: 'Language of the job.',
        defaultValue: 'scala',
        Component: RadioButtons,
        componentProps: {
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'scala', label: 'Scala'},
            {id: 'python', label: 'Python'},
          ],
        },
      },
      {
        section: 'Configuration',
        id: 'jobConfig',
        name: 'Parameters',
        description: 'Key/Value configuration of the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [
          (value) => {
            const result = map(
              ({key}) => isEmpty(key),
              value || []
            )
            return any(equals(true), result)
              ? 'This field is required'
              : ''
          },
        ],
      },
      {
        section: 'Configuration',
        id: 'args',
        name: 'Arguments',
        description: 'Arguments of the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
          fields: [{label: 'Value', name: 'value'}],
        },
        onSubmitFn: onSubmitParametersFn,
        validators: [
          (value) => {
            const result = map(
              (a) => isEmpty(a.value),
              value || []
            )
            return any(equals(true), result)
              ? 'This field is required'
              : ''
          },
        ],
      },
      {
        section: 'Configuration',
        id: 'tags',
        name: 'Tags',
        description: 'Label application with a tag.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [
          (value) => {
            const result = map(
              ({key}) => isEmpty(key),
              value || []
            )
            return any(equals(true), result)
              ? 'This field is required'
              : ''
          },
        ],
      },
      ...resourcesSchema(data, options),
      ...memorySettings(data, options),
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          type: 'button',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
