import List from '../../../../../components/list'

import HeaderComponent from '../../../../../components/list/headerComponent'
import ItemComponent from '../../../../../components/list/itemComponent'
import ToolTipContentComponent from '../../../../WorkloadsView/Components/ServicesList/toolTipContentComponent'
import React, {useEffect, useMemo} from 'react'
import {map, pipe, toLower, toUpper} from 'ramda'
import {fork} from 'fluture'
import {EMPTY_FUNCTION, EMPTY_STRING} from '../../../../../constants'
import DateCell from '../../../../WorkloadsView/dateCell'
import UserIcon from '../../../../../components/Icons/UserIcon'
import UserSingleButtons from '../../../Components/UserButtons/UserSingleButtons'
import StatusIndicator from '../../../../../components/StatusIndicator'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'

import statusVariantMap from "../../../../../helpers/statusVariantMap";

const UserState = ({value}) => (
  <StatusIndicator
    value={toLower(value || EMPTY_STRING)}
    variant={statusVariantMap[value]}
  />
)

const schema = [
  {
    value: 'name',
    label: 'name',
    size: 2,
  },
  {
    value: 'fullname',
    label: 'Fullname',
    size: 2,
  },
  {
    value: 'email',
    label: 'Email',
    size: 2,
  },
  {
    value: 'description',
    label: 'Description',
    size: 2,
  },
  {
    value: 'creationTime',
    label: 'Creation Time',
    size: 2,
    Component: DateCell,
  },
  {
    value: 'state',
    label: 'State',
    size: 2,
    Component: UserState,
  },
  {
    value: 'permissions',
    label: 'Permissions',
    size: 2,
  },
]

const UsersList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists = {},
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    selected,
    setSelected,
  } = listHandler

  const {createNotification} = useNotificationContext()

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  useEffect(() => {
    fork(({message}) =>
      createNotification({
        title: 'Failed to fetch Users',
        message,
        variant: 'error',
        autoHide: true,
      })
    )(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const {filter} = lists

  return (
    <List
      filters={filter}
      schema={schema}
      data={extendedData}
      HeaderComponent={HeaderComponent}
      headerComponentProps={{
        sort,
        onSort: handleOnSort,
        skeleton: pending,
      }}
      selected={selected}
      onSelect={setSelected}
      onReset={handleOnReset}
      ItemComponent={ItemComponent}
      itemComponentProps={{
        toFn: ({id}) => '/security/details/user/' + id,
        buttonsWidth: '0px',
        skeleton: pending,
        ToolTipComponent: ToolTipContentComponent,
        IconComponent: ({data}) => (
          <UserIcon color={'#FF8B96'}>
            {toUpper(data?.name?.substring(0, 1) || '')}
          </UserIcon>
        ),
        iconComponentProps: {
          type: 'user',
        },
        ButtonsComponent: UserSingleButtons,
        buttonsComponentProps: {
          pending,
          texts: false,
          getFuture: getFuture,
          redirect: false,
        },
      }}
      pagerProps={{
        size,
        page,
        totalPages,
        onChangeSize: handleOnSizeChange,
        onChangePage: handleOnPageChange,
      }}
    />
  )
}

export default UsersList
