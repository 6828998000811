import asyncThunk from "../../index";

export const scaleService = asyncThunk({
  method: "POST",
  type: "service/scale",
  path: "/group/${id}/rescale/${scale}",
  api: true,
});

export default scaleService;
