import { useContext } from "react";

import { NotificationContext } from "../contexts/notificationProvider";
import {HistoryContext} from "../contexts/historyProvider";

export const useHistoryContext = () => {
  const context = useContext(HistoryContext);


  if (context === undefined) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider"
    );
  }

  return context;
};
