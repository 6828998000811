import {useSelector} from 'react-redux'
import {pathOr} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import styled from 'styled-components'

const Root = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const Pre = styled.pre`
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.tertiary};
  flex: 1;
`

const JobError = () => {
  const error = useSelector(
    pathOr(EMPTY_OBJECT, [
      'workloads',
      'data',
      'job',
      'response',
      'error',
    ])
  )

  return (
    <Root>
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div
          style={{
            overflow: 'auto',
            flex: 1,
            padding: '0 8px',
          }}
        >
          <Pre>{error}</Pre>
        </div>
      </div>
    </Root>
  )
}

export default JobError
