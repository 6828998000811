import {Button, CheckBox, Input, Select, SliderV2} from 'frontcore'
import {any, equals, isEmpty, map} from 'ramda'
import RadioButtons from '../../../../components/RadioButtons'
import Parameters from '../../../../components/parameters'
import {onSubmitParametersFn, onSubmitSparkParamsFn} from '../../../../helpers'
import memorySettings from '../../memorySettings'
import requiredValidator from "../../../../validators/requiredValidator";
import clusterNameOnChangeFn from "../../helpers/clusterNameOnChangeFn";

const schema = (data, options) => {

  const {jobConfig, tags} = data

  const {pending, navigate, cluster, clusters} = options;
  return {
    fields: [
      {
        section: 'General',
        id: 'name',
        name: 'Name',
        description: 'Name of the service.',
        Component: Input,
        componentProps: {
          disabled: true,
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        section: 'Memory',
        id: 'clusterName',
        name: 'Cluster',
        defaultValue: cluster,
        description: 'Cluster on which group and jobs will be launched.',
        onChangeFn: clusterNameOnChangeFn(clusters),
        Component: Select,
        componentProps: {
          disabled: true,
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: map((cluster) => cluster.name, options?.clusters || []),
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'description',
        name: 'Description',
        description: 'Service description.',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'kind',
        defaultValue: 'job',
        name: 'Type',
        description: 'Type of the service.',
        Component: RadioButtons,
        componentProps: {
          disabled: true,
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'job', label: 'Job'},
            {id: 'code', label: 'Code'},
          ],
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'language',
        name: 'Language',
        description: 'Language of the service.',
        defaultValue: 'scala',
        Component: RadioButtons,
        componentProps: {
          disabled: true,
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'scala', label: 'Scala'},
            {id: 'python', label: 'Python'},
          ],
        },
      },
      {
        section: 'General',
        id: 'scale',
        defaultValue: 1,
        name: 'Scale',
        description: 'Number of replicas handled by loadbalancer.',
        Component: SliderV2,
        componentProps: {
          skeleton: pending,
          min: 1,
          max: 12,
          disabled: data.kind === 'code',
          fullWidth: true,
          size: 'small',
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'autoPause',
        name: 'Auto-pause',
        description: 'Boolean flag to enable auto-pause feature on the group',
        Component: CheckBox,
        defaultValue: true,
        componentProps: {},
      },
      {
        section: 'Configuration',
        id: 'jobConfig',
        name: 'Parameters',
        defaultValue: jobConfig,
        description: 'Key/Value configuration of the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [
          (value) => {
            const result = map((a) => isEmpty(a.value), value || []);
            return any(equals(true), result) ? 'This field is required' : '';
          },
        ],
      },
      {
        section: 'Configuration',
        id: 'tags',
        name: 'Tags',
        defaultValue: tags,
        description: 'Label application with a tag.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [
          (value) => {
            const result = map(({key}) => isEmpty(key), value || []);
            return any(equals(true), result) ? 'This field is required' : '';
          },
        ],
      },
      // {
      //   section: 'Resources',
      //   id: 'files',
      //   name: 'Files',
      //   description: 'List of files to be placed in the working directory of each executor.',
      //   Component: FileUpload,
      //   componentProps: {
      //     disabled: true,
      //     skeleton: pending,
      //     fullWidth: true,
      //     downloadable: false
      //   },
      //   onSubmitFn: onSubmitFilesFn,
      //   validators: [],
      // },
      // {
      //   section: 'Resources',
      //   id: 'jars',
      //   name: 'Jars',
      //   description: 'JARs to be loaded in the classpath of drivers and executors.',
      //   Component: FileUpload,
      //   componentProps: {
      //     disabled: true,
      //     skeleton: pending,
      //     fullWidth: true,
      //     accept: '.jar',
      //     downloadable: false
      //   },
      //   onSubmitFn: onSubmitFilesFn,
      //   validators: [],
      // },
      // {
      //   section: 'Resources',
      //   id: 'pyFiles',
      //   name: 'PyFiles',
      //   description: 'List of .zip, .egg, or .py files to place on PYTHONPATH.',
      //   Component: FileUpload,
      //   componentProps: {
      //     disabled: true,
      //     skeleton: pending,
      //     fullWidth: true,
      //     accept: '.egg, .py, .zip',
      //     downloadable: false
      //   },
      //   onSubmitFn: onSubmitFilesFn,
      //   validators: [],
      // },
      {
        section: 'Resources',
        id: 'pyRequirements',
        name: 'Requirements',
        description: 'Additional Python packages to be installed before running the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          fields: [{label: 'Value', name: 'value'}],
        },
        onSubmitFn: onSubmitParametersFn,
        validators: [
          (value) => {
            const result = map((a) => isEmpty(a.value), value || []);
            return any(equals(true), result) ? 'This field is required' : '';
          },
        ],
      },
      ...memorySettings(data, options),
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  };
};

export default schema;
