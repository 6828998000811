import {useLocation, useNavigate} from 'react-router-dom'
import {Button, Icon} from 'frontcore'
import React, {useCallback, useMemo} from 'react'
import DetailsViewLayout from '../../../components/layouts/DetailsViewLayout'
import {cond, startsWith, T} from 'ramda'

const WorkloadsCreateView = () => {
  const {pathname} = useLocation()
  const navigate = useNavigate()

  const returnUrl = useMemo(
    () =>
      cond([
        [
          startsWith('/workloads/create/clusters'),
          () => '/workloads/list/clusters',
        ],
        [
          startsWith('/workloads/create/group'),
          () => '/workloads/list/services',
        ],
        [
          startsWith('/workloads/create/job'),
          () => '/workloads/list/jobs',
        ],
        [T, () => -1],
      ])(pathname),
    [pathname]
  )

  const handleOnClick = useCallback(
    () => navigate(returnUrl),
    [returnUrl]
  )

  return (
    <DetailsViewLayout
      topNode={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
            paddingLeft: 24,
            height: 64,
          }}
        >
          <Button
            size={'small'}
            variant={'text'}
            StartComponent={Icon}
            startComponentProps={{
              icon: 'arrow-left',
              size: 18,
            }}
            tabIndex={-1}
            justifyContent={'center'}
            onClick={handleOnClick}
          >
            Back to List
          </Button>
        </div>
      }
    />
  )
}

export default WorkloadsCreateView
