import React from 'react';
import styled, {css} from 'styled-components';

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const textCss = css`
  font-size: 16px;
`;

const EmptyText = styled.div`
  ${textCss};
  color: ${({theme}) => theme.palette.neutral[500]}
`;

const Pending = () => {
  return <Root>
    <EmptyText>
      Loading...
    </EmptyText>
  </Root>;
};

export default Pending;
