import asyncThunk from "../../index";

export const getClusters = asyncThunk({
  method: "GET",
  type: "clusters/get",
  path: "/cluster",
  api: true
});

export default getClusters;
