import {useCallback, useEffect, useMemo} from 'react'
import Form from '../../../../components/Form'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useNavigate, useParams} from 'react-router-dom'
import schema from './schema'
import useFuture from '../../../../hooks/useFuture'
import editSchedule from '../../../../api/workloads/schedules/editSchedule'
import getSchedule from '../../../../api/workloads/schedules/getSchedule'
import {fork} from 'fluture'
import {EMPTY_OBJECT} from '../../../../constants'
import {useDispatch, useSelector} from 'react-redux'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {
  evolve,
  isEmpty,
  isNil,
  map,
  mergeLeft,
  pathOr,
  pipe,
  reduce,
  reject,
  split,
  toLower,
  toPairs,
} from 'ramda'
import {isArray} from '../../../../helpers'
import usePending from '../../../../hooks/usePending'
import SecurityFormLayout from '../../../../components/SecurityFormLayout'

const WorkloadsEditScheduleView = () => {
  const {createNotification} = useNotificationContext()
  const {scheduleId} = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getScheduleFuture = useFuture(getSchedule)
  useEffect(() => {
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to fetch schedule TableExplorerView',
        variant: 'error',
        autoHide: true,
      })
    })((data = EMPTY_OBJECT) => {
      dispatch(
        setBreadCrumbs({
          schedule: {
            id: scheduleId,
            name: data.name,
          },
        })
      )
    })(
      getScheduleFuture({
        params: [{key: 'id', value: scheduleId}],
      })
    )
  }, [scheduleId])
  const editScheduleFuture = useFuture(editSchedule)
  const handleOnSubmit = useCallback(
    (params) => {
      const preparedParams = pipe(
        toPairs,
        reduce((formData, [fieldName, fieldValue]) => {
          if (isNil(fieldValue)) return formData
          if (isArray(fieldValue)) {
            fieldValue.forEach(([key, value]) => {
              formData.append(fieldName, key, value)
            })
          } else {
            formData.append(fieldName, fieldValue)
          }
          return formData
        }, new FormData())
      )(params)
      preparedParams.params = [{key: 'id', value: scheduleId}]
      fork(({message}) => {
        createNotification({
          title: 'Failed to Edit Schedule',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        navigate(-1)
        createNotification({
          title: 'Success',
          message: 'Schedule edited successfully',
          variant: 'success',
          autoHide: true,
        })
      })(editScheduleFuture(preparedParams))
    },
    [scheduleId]
  )

  const pending = usePending(['workloads', 'data'], ['editSchedule', 'schedule'])

  const schedule = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'schedule', 'response']))
  const initialData = useMemo(() => {
    const keyPairTransformation = pipe(
      toPairs,
      map(([key, value]) => Object.freeze({key, value}))
    )

    const dateTransformation = (value) => (isNil(value) ? undefined : new Date(value).getTime())

    return pipe(
      evolve({
        language: toLower,
        tags: keyPairTransformation,
        jobConfig: keyPairTransformation,
        startTime: dateTransformation,
        endTime: dateTransformation,
        pyRequirements: pipe(
          split(';'),
          reject(isEmpty),
          reject(isNil),
          map((value) => ({value}))
        ),
      }),
      mergeLeft(schedule.memorySettings)
    )(schedule)
  }, [schedule])

  return (
    <Form
      options={{navigate, pending}}
      LayoutComponent={SecurityFormLayout}
      schema={schema}
      initialData={initialData}
      onSubmit={handleOnSubmit}
      layoutComponentProps={{
        title: 'EDIT SCHEDULE',
      }}
    />
  )
}

export default WorkloadsEditScheduleView
