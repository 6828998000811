import asyncThunk from "../../index";

export const getService = asyncThunk({
  method: "GET",
  type: "service/get",
  path: "/group/${id}",
  api: true,
});

export default getService;
