import {memo, useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import QueryTabs from '../QueryTabs'
import NotebookControls from './NotebookControls'
import Line from '../../../components/line'
import NotebookEditor from './NotebookEditor'
import {useDispatch, useSelector} from 'react-redux'
import {
  decreaseFontSize,
  increaseFontSize,
  setDiagnostics,
  setMarkdownEdit,
  setPointer,
  setQueryError,
  setQueryPending,
  setResult,
  toggleLinter,
  toggleVim,
} from '../../../reducers/sql/notebook'
import {
  find,
  forEach,
  isNil,
  isNotNil,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  reverse,
  takeWhile,
} from 'ramda'
import {EMPTY_ARRAY, EMPTY_STRING} from '../../../constants'
import {
  sidebarAddNotebookCellBeforeOrAfter,
  sidebarAppendMarkdownCell,
  sidebarAppendNotebookCell,
  sidebarChangeNotebookCell,
  sidebarDeleteNotebookCell,
  sidebarMoveNotebookCellTopOrBottom,
  sidebarSetCellLanguage,
} from '../../../reducers/sql/sidebar'
import useFuture from '../../../hooks/useFuture'
import executeSQLStatement from '../../../api/sql/executeSQLStatement'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import {useSqlSchemaCompletion} from '../editor/sqlSupport'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Notebook = () => {
  // state
  const notebookId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const storedQueries = useSelector(pathOr(EMPTY_ARRAY, ['sqlViewer', 'sidebar', 'queries']))
  const notebook = useMemo(() => {
    return storedQueries.find(({id}) => {
      return id === notebookId
    })
  }, [notebookId, storedQueries])
  const statements = useMemo(() => {
    return propOr(EMPTY_ARRAY, 'statements', notebook)
  }, [notebook])

  const linterEnabled = useSelector(path(['sqlViewer', 'notebook', 'linter']))
  const vimEnabled = useSelector(path(['sqlViewer', 'notebook', 'vim']))

  const pointer = useSelector(path(['sqlViewer', 'notebook', 'pointer']))

  const fontSize = useSelector(path(['sqlViewer', 'notebook', 'fontSize']))

  const markdownEdit = useSelector(path(['sqlViewer', 'notebook', 'markdownEdit']))

  const statement = useMemo(() => {
    return statements.find(({ id }) => id === pointer)
  }, [statements, pointer])

  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))

  const notebookResults = useSelector(path(['sqlViewer', 'notebook', 'results']))
  const notebookPending = useSelector(path(['sqlViewer', 'notebook', 'queryPending']))
  const notebookErrors = useSelector(path(['sqlViewer', 'notebook', 'queryErrors']))
  const notebookDiagnostics = useSelector(path(['sqlViewer', 'notebook', 'diagnostics']))


  // actions
  const dispatch = useDispatch()

  const handleSetPointer = useCallback((newPointer) => {
    dispatch(setPointer(newPointer))
  }, [])

  const handleIncreaseFont = useCallback(() => {
    dispatch(increaseFontSize())
  }, [])

  const handleDecreaseFont = useCallback(() => {
    dispatch(decreaseFontSize())
  }, [])

  const handleAppendStatement = useCallback(() => {
    dispatch(sidebarAppendNotebookCell(notebookId))
  }, [notebookId])

  const handleChangeStatement = useCallback(
    (statementId, newCode) => {
      dispatch(
        sidebarChangeNotebookCell({
          notebookId,
          statementId,
          newCode,
        })
      )
    },
    [notebookId]
  )

  const handleDeleteStatement = useCallback(
    (statementId) => {
      dispatch(
        sidebarDeleteNotebookCell({
          notebookId,
          statementId,
        })
      )
      if (pointer === statementId) {
        dispatch(setPointer(null))
      }
    },
    [notebookId, pointer]
  )

  const maxRows = useSelector(pathOr(1000, ['sqlViewer', 'editor', 'maxRows']))

  // running query
  const {createNotification} = useNotificationContext()
  const executeSQLStatementFuture = useFuture(executeSQLStatement)
  const chosenEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const chosenClusterId = useSelector(path(['sqlViewer', 'sidebar', 'chosenCluster', 'id']))

  const setCellResult = useCallback((statementId, result) => {
    dispatch(
      setResult({
        statementId,
        result,
      })
    )
  }, [])

  const setCellPending = useCallback((statementId, pending = true) => {
    dispatch(
      setQueryPending({
        statementId,
        pending,
      })
    )
  }, [])

  const setCellError = useCallback((statementId, error) => {
    dispatch(
      setQueryError({
        statementId,
        error,
      })
    )
  }, [])

  const handleRunFragment = useCallback((statementId, fragment) => {
      const preparedFuture = executeSQLStatementFuture({
        maxRows,
        engine: chosenEngine,
        clusterId: chosenClusterId,
        statement: fragment,
      })
      setCellError(statementId, null)
      setCellResult(statementId, null)
      setCellPending(statementId, true)
      fork(({message}) => {
        createNotification({
          title: 'Error',
          message: 'Failed to run query. Check logs for details',
          variant: 'error',
          autoHide: true,
        })
        setCellError(statementId, message)
        setCellPending(statementId, false)
      })((response) => {
        setCellResult(statementId, response)
        setCellPending(statementId, false)
      })(preparedFuture)
  }, [maxRows, chosenEngine, chosenClusterId, setCellResult, setCellPending, setCellError])

  const handleRunStatement = useCallback(
    (statementId) => {
      const code = pipe(
        prop('statements'),
        find(({id}) => id === statementId),
        propOr(EMPTY_STRING, 'code')
      )(notebook)
      const preparedFuture = executeSQLStatementFuture({
        maxRows,
        engine: chosenEngine,
        clusterId: chosenClusterId,
        statement: code,
      })
      setCellError(statementId, null)
      setCellResult(statementId, null)
      setCellPending(statementId, true)
      fork(({message}) => {
        createNotification({
          title: 'Error',
          message: 'Failed to run query. Check logs for details',
          variant: 'error',
          autoHide: true,
        })
        setCellError(statementId, message)
        setCellPending(statementId, false)
      })((response) => {
        setCellResult(statementId, response)
        setCellPending(statementId, false)
      })(preparedFuture)
    },
    [notebook, maxRows, chosenEngine, chosenClusterId, setCellResult, setCellPending, setCellError]
  )

  const handleRunBeforeStatement = useCallback(
    (statementId) => {
      pipe(
        prop('statements'),
        takeWhile(({id}) => id !== statementId),
        map(prop('id')),
        forEach(handleRunStatement)
      )(notebook)
      handleRunStatement(statementId)
    },
    [notebook, handleRunStatement]
  )

  const handleRunAfterStatement = useCallback(
    (statementId) => {
      handleRunStatement(statementId)
      pipe(
        prop('statements'),
        reverse,
        takeWhile(({id}) => id !== statementId),
        reverse,
        map(prop('id')),
        forEach(handleRunStatement)
      )(notebook)
    },
    [notebook, handleRunStatement]
  )

  const handleRunAll = useCallback(() => {
    pipe(prop('statements'), map(prop('id')), forEach(handleRunStatement))(notebook)
  }, [notebook, handleRunStatement])

  const handleClearOutputs = useCallback(() => {
    for (const { id } of notebook.statements) {
      dispatch(setResult({
        statementId: id,
        result: null,
      }))
      dispatch(setQueryError({
        statementId: id,
        error: null,
      }))
    }
  }, [notebook])

  const handleClearAll = useCallback(() => {
    pipe(prop('statements'), map(prop('id')), forEach(handleDeleteStatement))(notebook)
    handleClearOutputs()
  }, [notebook, handleDeleteStatement, handleClearOutputs])

  const handleToggleLinter = useCallback(() => {
    dispatch(toggleLinter())
  }, [])

  const handleToggleVim = useCallback(() => {
    dispatch(toggleVim())
  }, [])

  const handleAddStatementBefore = useCallback(
    (statementId) => {
      dispatch(
        sidebarAddNotebookCellBeforeOrAfter({
          notebookId,
          statementId,
          place: 'before',
        })
      )
    },
    [notebookId]
  )

  const handleAddStatementAfter = useCallback(
    (statementId) => {
      dispatch(
        sidebarAddNotebookCellBeforeOrAfter({
          notebookId,
          statementId,
          place: 'after',
        })
      )
    },
    [notebookId]
  )

  const handleAddMarkdownBefore = useCallback(
    (statementId) => {
      dispatch(
        sidebarAddNotebookCellBeforeOrAfter({
          notebookId,
          statementId,
          place: 'before',
          language: 'markdown',
        })
      )
    },
    [notebookId]
  )

  const handleAddMarkdownAfter = useCallback(
    (statementId) => {
      dispatch(
        sidebarAddNotebookCellBeforeOrAfter({
          notebookId,
          statementId,
          place: 'after',
          language: 'markdown',
        })
      )
    },
    [notebookId]
  )

  const handleMoveStatementUp = useCallback(
    (statementId) => {
      dispatch(
        sidebarMoveNotebookCellTopOrBottom({
          notebookId,
          statementId,
          place: 'top',
        })
      )
    },
    [notebookId]
  )

  const handleMoveStatementBottom = useCallback(
    (statementId) => {
      dispatch(
        sidebarMoveNotebookCellTopOrBottom({
          notebookId,
          statementId,
          place: 'bottom',
        })
      )
    },
    [notebookId]
  )

  const handleFlipLanguage = useCallback((statementId) => {
    dispatch(sidebarSetCellLanguage({
      notebookId,
      statementId,
      flip: true,
    }))
  }, [notebookId])

  const handleAppendMarkdownCell = useCallback(() => {
    dispatch(sidebarAppendMarkdownCell(notebookId))
  }, [notebookId])

  // TODO: support duplication of markdown cells
  const handleDuplicateCell = useCallback((statementId) => {
    const code = pipe(
      propOr(EMPTY_ARRAY, 'statements'),
      find(({ id }) => id === statementId),
      propOr(EMPTY_STRING, 'code'),
    )(notebook)
    dispatch(sidebarAddNotebookCellBeforeOrAfter({
      notebookId: notebook.id,
      statementId,
      place: 'after',
      code,
    }))
  }, [notebook])

  const handleSetMarkdownEdit = useCallback((statementId, edit) => {
    dispatch(setMarkdownEdit({
      statementId,
      edit,
    }))
  }, [])

  const handleNotebookDiagnostics = useCallback((statementId, diagnostics) => {
    dispatch(setDiagnostics({
      statementId,
      diagnostics,
    }))
  }, [])

  // Ctrl+Enter to run a query
  useEffect(() => {
    if (isNil(pointer)) return

    const handler = (event) => {
      if (event.ctrlKey && event?.key?.toLowerCase() === 'enter') {
        event.preventDefault()
        event.stopPropagation()
        const language = statement?.language
        if (language === 'markdown') {
          const edit = markdownEdit[pointer] ?? false
          handleSetMarkdownEdit(pointer, !edit)
        } else {
          handleRunStatement(pointer)
        }
      }
    }
    window.addEventListener('keydown', handler, true)
    return () => {
      window.removeEventListener('keydown', handler, true)
    }
  }, [handleRunStatement, handleSetMarkdownEdit, pointer, statement, markdownEdit])

  // sql schema support
  const sqlSchema = useSqlSchemaCompletion()

  return (
    <Root>
      <QueryTabs />
      <Line />
      <NotebookControls
        state={{
          isCell: statement?.language !== 'markdown' && isNotNil(pointer),
          linterEnabled,
          vimEnabled,
          pointer,
        }}
        actions={{
          toggleLinter: handleToggleLinter,
          toggleVim: handleToggleVim,
          runStatement: handleRunStatement,
          runAll: handleRunAll,
          clearAll: handleClearAll,
          clearOutputs: handleClearOutputs,
          runBefore: handleRunBeforeStatement,
          runAfter: handleRunAfterStatement,
          increaseFont: handleIncreaseFont,
          decreaseFont: handleDecreaseFont,
        }}
      />
      <Line />
      <NotebookEditor
        state={{
          notebookId,
          markdownEdit,
          statements,
          sqlSchema,
          linterEnabled,
          vimEnabled,
          fontSize,
          pointer,
          darkMode,
          notebookResults,
          notebookPending,
          notebookErrors,
          notebookDiagnostics,
        }}
        actions={{
          appendStatement: handleAppendStatement,
          changeStatement: handleChangeStatement,
          deleteStatement: handleDeleteStatement,
          runStatement: handleRunStatement,
          runFragment: handleRunFragment,
          runBeforeStatement: handleRunBeforeStatement,
          runAfterStatement: handleRunAfterStatement,
          runAll: handleRunAll,
          setPointer: handleSetPointer,
          addCellBefore: handleAddStatementBefore,
          addCellAfter: handleAddStatementAfter,
          moveCellUp: handleMoveStatementUp,
          moveCellDown: handleMoveStatementBottom,
          addMarkdownBefore: handleAddMarkdownBefore,
          addMarkdownAfter: handleAddMarkdownAfter,
          flipLanguage: handleFlipLanguage,
          appendMarkdown: handleAppendMarkdownCell,
          duplicateCell: handleDuplicateCell,
          setMarkdownEdit: handleSetMarkdownEdit,
          setDiagnostics: handleNotebookDiagnostics,
        }}
      />
    </Root>
  )
}

export default memo(Notebook)
