import {
  all,
  and,
  append, both,
  includes,
  intersperse,
  isEmpty,
  map,
  path,
  pipe,
  reject,
} from 'ramda'
import {Button, Pager} from 'frontcore'
import React, {useCallback, useEffect, useMemo} from 'react'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants'
import styled from 'styled-components'
import Line from '../line'
import {isNotEmpty, mapWithKey} from '../../helpers'
import NoResultFound from '../NoResultFound'

const EmptyRoot = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Root = styled.div`
  pointer-events: ${({pending}) =>
    pending ? 'none' : 'all'};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`

const PagerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 16px 16px 0;
`

const MiddleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
`

const ListContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const List = (props) => {
  const {
    idProp = 'id',
    debug = false,
    schema,
    data,
    HeaderComponent,
    headerComponentProps,
    ItemComponent,
    itemComponentProps,
    pending = false,
    pagerProps = EMPTY_OBJECT,
    onReset,
    filters = EMPTY_OBJECT,
    selected = EMPTY_ARRAY,
    onSelect,
  } = props

  const handleOnItemSelect = useCallback(
    (props) => {
      const id = props[idProp]
      if (includes(id, selected)) {
        const result = reject((a) => a === id, selected)
        onSelect(result)
      } else {
        const result = append(id, selected)
        onSelect(result)
      }
    },
    [selected, onSelect, idProp]
  )

  const allSelected = useMemo(
    () =>
      pipe(
        both(
          all(({data}) => includes(data[idProp], selected)),
          isNotEmpty
        )
      )(data),
    [selected, data, idProp]
  )

  const handleOnHeaderSelect = useCallback(() => {
    if (allSelected) {
      onSelect(EMPTY_ARRAY)
    } else {
      const result = map(path(['data', idProp]), data)
      onSelect(result)
    }
  }, [allSelected, data, onSelect, idProp])

  const content = useMemo(
    () =>
      pipe(
        map(({data}) => ({
          Component: ItemComponent,
          props: {
            data,
            pending,
            debug,
            schema,
            ...itemComponentProps,
            onSelect: onSelect && handleOnItemSelect,
            selected: includes(data[idProp], selected),
          },
        })),
        intersperse({Component: Line, props: {}}),
        mapWithKey(({Component, props}, index) => (
          <Component key={index} {...props} />
        ))
      )(data),
    [data, schema, pending, debug, itemComponentProps]
  )

  return (
    <Root>
      <MiddleContainer>
        {HeaderComponent && (
          <HeaderComponent
            pending={pending}
            debug={debug}
            schema={schema}
            type
            {...headerComponentProps}
            itemComponentProps={itemComponentProps}
            selected={allSelected}
            onSelect={onSelect && handleOnHeaderSelect}
          />
        )}
        <Line />
        <ListContainer>
          {isNotEmpty(data) && content}
          {!pending &&
            isEmpty(data) &&
            isNotEmpty(filters) && (
              <EmptyRoot>
                <NoResultFound />
                <Button
                  size={'small'}
                  onClick={onReset}
                  tabIndex={-1}
                >
                  Reset Filters
                </Button>
              </EmptyRoot>
            )}
          {!pending &&
            isEmpty(data) &&
            isEmpty(filters) && (
              <EmptyRoot>
                <NoResultFound
                  title={'No Data'}
                  text={
                    'There is no items for selected list'
                  }
                />
              </EmptyRoot>
            )}
        </ListContainer>
        <Line />
      </MiddleContainer>
      <PagerContainer>
        <Pager {...pagerProps} />
      </PagerContainer>
    </Root>
  )
}

export default List
