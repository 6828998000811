import {map, slice} from 'ramda'
import {Pager} from 'frontcore'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import SingleMetricBarChart from '../charts/singleMericBarChart'
import Line from '../line'
import formatNumber from '../../helpers/formatNumber'
import {formatBytes} from '../../helpers'

const Root = styled.div`
    border: 1px solid black;
    border-color:  ${({theme}) => theme.palette.line};
    background-color: ${({theme}) => theme.palette["surface-primary"]};
    border-radius: 5px;
`

const Paper = styled.div`
  width: 100%;
  border-radius: 0;
  padding: 10px;
  gap: 2px;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: space-between;
  color: ${({theme}) => theme.palette.neutral[500]};
`;

const PagerContainer = styled.div`
    display: flex; justify-content: center; height: 36px
`

const Key = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`

const Carusel = ({data}) => {
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [size, setSize] = useState(2)

  useEffect(() => {
    const totalPages = Math.ceil(data.length / size)
    setTotalPages(totalPages)
  }, [data, size])

  const extendedData = slice(
    page * size,
    page * size + size,
    data
  )



  return (
    <Root
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        boxSizing: 'border-box',
        height: '100%',

      }}
    >
      <div style={{display: 'flex', gap: 2, flex: 1}}>
        {map(
          ({name, metric}) =>
           (
              <div
                key={name}
                style={{

                  flex: 2,
                }}
              >
                <Paper>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',

                    }}
                  >
                    <Key>Cluster</Key>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 500,

                      }}
                    >
                      {name}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 8,
                      justifyContent: 'space-between'
                    }}
                  >
                    <SingleMetricBarChart
                      label={'CPU'}
                      values={[
                        {
                          key: 'USED',
                          label: formatNumber(metric?.usedCpu, 2),
                          value: formatNumber(metric?.usedCpu, 2),
                        },
                        {
                          key: 'REQUESTED',
                          label: formatNumber(metric?.requestedCpu, 2),
                          value: formatNumber(metric?.requestedCpu, 2),
                        },
                      ]}
                      max={{
                        kay: 'AVAILABLE',
                        label: metric?.availableCpu,
                        value: metric?.availableCpu,
                      }}
                      />
                    <SingleMetricBarChart
                      label={'MEMORY'}
                      values={[
                        {
                          key: 'USED',
                          label: formatBytes(metric?.usedMemory),
                          value: metric?.usedMemory
                        },
                        {
                          key: 'REQUESTED',
                          label: formatBytes(metric?.requestedMemory),
                          value: metric?.requestedMemory
                        },
                      ]}
                      max={{
                        kay: 'AVAILABLE',
                        label: formatBytes(metric?.availableMemory),
                        value: metric?.availableMemory,
                      }}
                    />
                  </div>
                </Paper>
              </div>
            ),
          extendedData
        )}
      </div>
      <Line/>
      <PagerContainer>
        <Pager
          page={page}
          onChangePage={(value) => setPage(value)}
          size={size}
          totalPages={totalPages}
          showInput={false}
        />
      </PagerContainer>
    </Root>
  )
}

export default Carusel
