import { memo, useMemo } from 'react'
import { Key, Value } from '../../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'
import {formatBytes} from '../../../../helpers'

const ScheduleMetrics = ({ metrics }) => {
  const {
    allocatedCPU = 0,
    allocatedMemory = 0,
    runningContainers = 0,
  } = metrics

  const formattedBytes = useMemo(() => {
    return formatBytes(allocatedMemory)
  }, [allocatedMemory])

  return (
    <>
      <Key>CPU</Key>
      <Value>{allocatedCPU}</Value>
      
      <Key>Memory</Key>
      <Value>{formattedBytes}</Value>

      <Key>Containers</Key>
      <Value>{runningContainers}</Value>
    </>
  )
}

export default memo(ScheduleMetrics)
