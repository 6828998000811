import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect} from 'react'
import DetailsHeader from '../../../../components/DetailsHeader'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {fork} from 'fluture'
import {useParams} from 'react-router-dom'
import {pathOr} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import ReactJson from 'react-json-view'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import {Tabs} from 'frontcore'
import UserSingleButtons from '../../Components/UserButtons/UserSingleButtons'
import {CountMiniature} from '../../../../components/miniatures'
import getActivity from '../../../../api/security/getActivity'
import LabelIcon from '../../../../components/Icons/LabelIcon'
import {useNotificationContext} from "../../../../hooks/useNotificationsContext";
import Chip from "../../../../components/Chip";
import Json from "../../../../components/Json";
import {useHistoryContext} from "../../../../hooks/useHistoryContext";
import ActivitySingleButtons from "../../Components/ActivityButtons/ActivitySingleButtons";

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

const SecurityActivityDetailsView = () => {
  const getActivityFuture = useFuture(getActivity)

  const {activityId, clusterName} = useParams()

  const dispatch = useDispatch()

  const activity = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'activity',
      'response',
    ])
  )
  const {history, addHistory} = useHistoryContext()
  const {
    id,
    userId,
    username,
    groups,
    method,
    endpoint,
    timestamp,
    description,
  } = activity

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    if (activityId) {
      fork(({message}) => {
        createNotification({
          title: 'Failed to fetch Activity',
          message,
          variant: 'error',
          autoHide: true,
        })
      })(({id, name, method}) => {
        addHistory({type: 'activity', id: id, name, method})
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getActivityFuture({
          params: [{key: 'id', value: activityId}],
        })
      )
    }
  }, [activityId])

  const topContent = (
    <DetailsHeader
      icon={
        <Chip size={'large'} type={method}>
          {method}
        </Chip>
      }
      name={username}
      id={userId}
      pending={false}
      data={[
        {
          key: 'id',
          value: id,
        },
        {
          key: 'method',
          value: method,
        },
        {
          key: 'endpoint',
          value: endpoint,
        },
        {
          key: 'groups',
          value: groups,
        },
        {
          key: 'description',
          value: description,
        },
        {
          key: 'timestamp',
          value: timestamp,
          Component: DateCellRow,
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 48,
        gap: 16
      }}
    >
      <Tabs
        value={'raw'}
        options={[
          {
            id: 'raw',
            Component: TabComponent,
            componentProps: {
              label: 'Raw',
            },
          },
        ]}
      />
      <ActivitySingleButtons
        texts={true}
        getFuture={getActivityFuture({
          params: [{key: 'id', value: activityId}],
        })}
        pending={false}
        data={activity}
        redirect={false}
      />
    </div>
  )
  const bottomContent = <Json src={activity} />

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityActivityDetailsView
