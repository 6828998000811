import getSearch from '../../../../api/lineage/getSearch'
import useFuture from '../../../../hooks/useFuture'
import React, {useEffect, useMemo, useState} from 'react'
import {fork} from 'fluture'
import {useParams} from 'react-router-dom'
import {isEmpty, pathOr, pipe, prop} from 'ramda'
import {useSelector} from 'react-redux'
import {
  EMPTY_ARRAY,
  HORIZONTAL,
  VERTICAL,
} from '../../../../constants'
import {
  Input,
  ResizeLayout,
  ResourceButton,
  VirtualizedList,
} from 'frontcore'
import TableGraphView from '../../../Lineage/TableGraphView'
import NoItemsFound from '../../../../components/NoResultFound'
import {TabContainer} from './JobDetailsTimelineView'

import Line from '../../../../components/line'
import {ILUM_LINEAGE_ENABLED} from '../../../../helpers/runtimeEnv'
import ViewNotAvailable from '../../../../components/viewNotAvailable'
import RButton from '../../../../components/RButton'
import {LetterMiniature} from '../../../../components/miniatures'

const ListButton = (props) => {
  const {name, onClick, active, selected} = props

  return (
    <TabContainer leftShift={4}>
      <RButton
        type={'job'}
        name={name}
        active={selected.name === name}
        fullWidth={true}
        IconComponent={LetterMiniature}
        iconComponentProps={{type: 'job', icon: 'gear'}}
        onClick={() => onClick(props)}
      />

    </TabContainer>
  )
}

const JobLineage = () => {
  const {jobId} = useParams()

  const getSearchFuture = useFuture(getSearch)

  const rrr = jobId.replaceAll('-', '_')

  useEffect(() => {
    fork((message) => {})(() => {})(
      getSearchFuture({
        params: [
          {
            key: 'text',
            value: rrr,
          },
        ],
      })
    )
  }, [rrr])

  const model = useSelector(prop('workloads'))

  const list = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, [
          'data',
          'lineageJobs',
          'response',
          'results',
        ])
      )(model),
    [model]
  )

  const pending = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, [
          'data',
          'lineageJobs',
          'pending',
        ])
      )(model),
    [model]
  )

  const [selected, setSelected] = useState({})

  const {name, namespace} = selected

  return ILUM_LINEAGE_ENABLED.toLowerCase() === 'true' ? (
    <div style={{overflow: 'hidden', height: '100%'}}>
      <ResizeLayout

        configuration={{
          orientation: HORIZONTAL,
          firstNode: {
            measurement: 'px',
            value: 8 * 32,

          },
          secondNode: {
            measurement: 'flex',
            value: 1,
          },
        }}
        firstNode={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div style={{padding: '24px 16px 8px 16px'}}>
              <Input
                fullWidth
                disabled
                topLabelProps={{
                  children: 'Search Executors',
                }}
              />
            </div>
            <Line />
            {isEmpty(list) ? (
              <NoItemsFound />
            ) : (
              <VirtualizedList
                items={list}
                itemHeight={32}
                gap={4}
                ItemComponent={ListButton}
                itemComponentProps={{
                  selected,
                  onClick: (value) => {
                    setSelected(value)
                  },
                }}
              />
            )}
          </div>
        }
        secondNode={
          <TableGraphView
            nodeId={name}
            nodeType={'job'}
            namespace={namespace}
            selected={selected}
          />
        }
      />
    </div>
  ) : (
    <ViewNotAvailable name={'Lineage'} />
  )
}

export default JobLineage
