import { createSlice } from "@reduxjs/toolkit";
import { EMPTY_OBJECT } from "../../constants";
import {FONT_SIZE} from "./editor";

const initialState = {
  linter: false,
  vim: false,
  fontSize: FONT_SIZE.DEFAULT,
  pointer: null,
  results: EMPTY_OBJECT,
  queryPending: EMPTY_OBJECT,
  queryErrors: EMPTY_OBJECT,
  diagnostics: EMPTY_OBJECT,
  markdownEdit: EMPTY_OBJECT,
}

const notebookSlice = createSlice({
  name: 'sql/notebook',
  initialState,
  reducers: {
    toggleLinter: (state) => {
      state.linter = !state.linter
    },
    toggleVim: (state) => {
      state.vim = !state.vim
    },
    setPointer: (state, { payload }) => {
      state.pointer = payload
    },
    decreaseFontSize: (state) => {
      state.fontSize = Math.max(
        FONT_SIZE.MIN,
        state.fontSize - 1,
      )
    },
    increaseFontSize: (state) => {
      state.fontSize = Math.min(
        FONT_SIZE.MAX,
        state.fontSize + 1,
      )
    },
    setDiagnostics: (state, { payload }) => {
      const {
        statementId,
        diagnostics,
      } = payload
      state.diagnostics[statementId] = diagnostics
    },
    setMarkdownEdit: (state, { payload }) => {
      const {
        statementId,
        edit,
      } = payload
      state.markdownEdit[statementId] = edit
    },
    setResult: (state, { payload }) => {
      const {
        statementId,
        result,
      } = payload
      state.results[statementId] = result
    },
    setQueryPending: (state, { payload }) => {
      const {
        statementId,
        pending,
      } = payload
      state.queryPending[statementId] = pending
    },
    setQueryError: (state, { payload }) => {
      const {
        statementId,
        error
      } = payload
      state.queryErrors[statementId] = error
    },
  },
})

export default notebookSlice.reducer
export const {
  toggleLinter,
  toggleVim,
  decreaseFontSize,
  increaseFontSize,
  setPointer,
  setDiagnostics,
  setResult,
  setQueryPending,
  setQueryError,
  setMarkdownEdit,
} = notebookSlice.actions
