import React, {useEffect, useState} from 'react'
import {
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import CustomNode from './customNode'
import BatchNode from './batchNode'
import {isEmpty} from 'ramda'
import './batch-node.css'
import useLayout from './useLayout'
import FieldNode from './fieldNode'
import ColumnNode from './columnNode'

const nodeTypes = {
  BATCH: BatchNode,
  DB_TABLE: CustomNode,
  FIELD: FieldNode,
  COLUMN: ColumnNode
}

const LayoutFlow = ({n, e, onNodeClick}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(n)
  const [edges, setEdges, onEdgesChange] = useEdgesState(e)
  const {getLayout} = useLayout()
  useEffect(() => {
    if (n && isEmpty(nodes)) {
      setNodes(n)
    }

    if (e && isEmpty(edges)) {
      setEdges(e)
    }
  }, [e, n])

  const [aligned, setAligned] = useState(false)

  useEffect(() => {
    if (nodes?.[0]?.measured && !aligned) {
      getLayout({
        'elk.algorithm': 'layered',
        'elk.direction': 'RIGHT',
      })
      setAligned(true)
    }
  }, [nodes])

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      nodeTypes={nodeTypes}
      onNodeClick={onNodeClick}
    >
      <MiniMap />
      <Controls showInteractive={false}/>
      <Background />
    </ReactFlow>
  )
}

export default function ({n, e, onNodeClick}) {
  return (
    <ReactFlowProvider>
      <LayoutFlow n={n} e={e} onNodeClick={onNodeClick} />
    </ReactFlowProvider>
  )
}
