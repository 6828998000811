import React, {memo} from 'react'
import {Handle, Position} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import './index.css'
import {Root} from './styles'
import {isNotEmpty} from '../../../helpers'
import RButton from '../../../components/RButton'
import {LetterMiniature} from '../../../components/miniatures'

const BatchNode = (props) => {
  const {data, selected} = props

  return (
    <Root className={'nodrag'} $selected={selected}>
      <RButton
        type={'job'}
        name={data?.name}
        IconComponent={LetterMiniature}
        iconComponentProps={{type: 'job', icon: 'gear'}}
      />

      {isNotEmpty(data?.inEdges) && (
        <Handle
          type="target"
          position={Position.Left}
          style={{background: '#555', border: 'none'}}
          isConnectable={false}
        />
      )}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: '#555',
          border: 'none',
        }}
        isConnectable={false}
      />
    </Root>
  )
}

export default memo(BatchNode)
