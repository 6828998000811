import asyncThunk from '../index'

export const getDataset = asyncThunk({
  method: "GET",
  type: "/dataset/get",
  path: "/namespaces/${namespace}/datasets/${dataset}",
  api: true,
  lineage: true
});

export default getDataset;
