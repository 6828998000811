import Filters from '../../../WorkloadsView/filters'
import {Button, Icon, Input, Select} from 'frontcore'
import ListLayout from '../../../../components/layouts/listLayout'
import React from 'react'
import UsersList from './UsersList'
import {useListRefreshFuture} from '../../../WorkloadsView/hooks'

import getUsers from '../../../../api/security/getUsers'
import {useNavigate} from 'react-router-dom'
import usePending from '../../../../hooks/usePending'
import UserBatchButtons from '../../Components/UserButtons/UserBatchButtons'
import {isNotEmpty} from '../../../../helpers'
import GroupBatchButtons from '../../../WorkloadsView/Components/ServiceBatchButtons'
import {EMPTY_ARRAY} from "../../../../constants";

const schema = [
    {
        id: 'search',
        Component: Input,
        defaultValue: '',
        props: {
            StartComponent: Icon,
            startComponentProps: {
                size: 16,
                icon: 'search',
            },
            placeHolder: 'Search',
            topLabelProps: {
                children: 'Users Search',
            },
            label: 'Users Search',
        },
    },
    {
        id: 'state',
        Component: Select,
        defaultValue: EMPTY_ARRAY,
        props: {
            label: 'State',
            value: [],
            options: ['ENABLED', 'DISABLED'],
            multiSelect: true,
            buttonProps: {
                width: '200px',
            },
        },
    },
]

const SecurityUsersListView = () => {
  const listHandler = useListRefreshFuture(
    getUsers,
    'security',
    'users',
    {
      page: 0,
        size: 25,
      sort: {
        creationTime: 'desc',
      },
      filter: {
        groupStates: ['ACTIVE'],
      },
    }
  )

  const {selected} = listHandler

  const navigate = useNavigate()

  const pending = usePending(
    ['security', 'data'],
    [
      'users',
      'createUser',
      'deleteUser',
      'enableUser',
      'disableUser',
    ]
  )

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <UserBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'users'}
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <div style={{display: 'flex', gap: '10px'}}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              navigate('/security/create/user')
            }
            EndComponent={Icon}
            endComponentProps={{
              icon: 'plus',
              size: 18,
              color: 'white',
            }}
          >
            Add User
          </Button>
        </div>
      }
      contentNode={
        <UsersList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default SecurityUsersListView
