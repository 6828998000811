
export const usernameDescription =  'Unique identifier used for login'
export const passwordDescription =  'Secure key for authenticating the user'
export const fullnameDescription = 'Complete name of the user'
export const emailDescription =  `User's contact email address`
export const descriptionDescription =  'Short summary or details about the user'
export const departmentDescription =  'Organizational unit the user belongs to'
export const groupsDescription =  'Permissions granted to a user via a group are independent of direct assignments'
export const rolesDescription =  'Permissions granted to a user via a role are independent of direct assignments'
export const permissionsDescription =  'Permissions can be explicitly assigned to a user, and these direct assignments operate independently from permissions granted through roles or groups'
export const enabledDescription =  'Indicates whether the account is active'

