import asyncThunk from '../index'

export const getBatchRunHistory = asyncThunk({
  method: "GET",
  type: "/batch/runHistory/get",
  path: "/namespaces/${namespace}/jobs/${job}/runs?limit=100&offset=0",
  api: true,
  lineage: true
});

export default getBatchRunHistory;
