import {Icon} from 'frontcore'
import React from 'react'
import styled, {css, useTheme} from 'styled-components'
import {createTransitions} from '../../helpers'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const fulWidthCss = css`
  width: 100%;
`

const smallCss = css`
  height: 24px;

  ${IconContainer} {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
  }
`

const mediumCss = css`
  height: 32px;

  ${IconContainer} {
    height: 32px;
    width: 32px;

    min-width: 32px;
    min-height: 32px;
  }
`

const sizeCssMap = {
  small: smallCss,
  medium: mediumCss,
  large: smallCss,
}

const Root = styled.div`
  cursor: default;
  overflow: hidden;
  ${({$fullWidth}) => $fullWidth && fulWidthCss}
  padding-left: ${({$padding}) => $padding + 'px'};
  padding-right: 8px;
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: space-between;
  //font-size: 12px;
  ${({$size}) => sizeCssMap[$size]};
  gap: 4px;
  transition: ${createTransitions(['background-color'])};
  background-color: ${({theme, $active}) =>
    $active ? theme.palette['surface-secondary'] : 'transparent'};
  color: ${({theme}) => theme.palette.text.tertiary};

  &:hover {
    background-color: ${({theme}) => theme.palette['surface-tertiary']};
  }
`

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
`

const TypeContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  //font-weight: 900;
  text-transform: capitalize;
  color: ${({theme}) => theme.palette.text.tertiary};
`
const RButton = ({
  name,
  type,
  icon,
  size = 'medium',
  IconComponent,
  iconComponentProps,
  onClick,
  children,
  active,
  StartComponent,
  startComponentProps,
  padding = 4,
  fullWidth = false,
  rootProps = {},
}) => {
  const {palette} = useTheme()

  return (
    <Root
      {...rootProps}
      $size={size}
      onClick={onClick}
      $fullWidth={fullWidth}
      $active={active}
      $padding={padding}
    >
      <div
        style={{
          display: 'flex',
          gap: 4,
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        {StartComponent && <StartComponent {...startComponentProps} size={size} />}
        {IconComponent && <IconComponent {...iconComponentProps} size={size} />}
        {icon && (
          <IconContainer>
            <Icon icon={icon} size={16} color={palette.text.tertiary} />
          </IconContainer>
        )}

        <div style={{display: 'flex', overflow: 'hidden', alignItems: 'center', gap: 4}}>
          {type && <TypeContainer>{type}:</TypeContainer>}
          <NameContainer>{name}</NameContainer>
        </div>
      </div>
      <div style={{display: 'flex', gap: 4}}>{children}</div>
    </Root>
  )
}

export default RButton
