import {map, pipe} from 'ramda'
import React, {useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import List from '../../../../components/list'
import HeaderComponent from '../../../../components/list/headerComponent'
import ItemComponent from '../../../../components/list/itemComponent'
import ToolTipContentComponent from './toolTipContentComponent'
import {EMPTY_FUNCTION} from '../../../../constants'
import schema from './schema'
import ServiceSingleButtons from '../ServiceSingleButtons'
import {useNotificationContext} from "../../../../hooks/useNotificationsContext";

const ServicesList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists,
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    selected,
    setSelected,
  } = listHandler

  const {filter} = lists

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    fork(({message}) => {
      createNotification({
        title: 'Failed to fetch Services',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  return (
      <List
        filters={filter}
        schema={schema}
        data={extendedData}
        HeaderComponent={HeaderComponent}
        headerComponentProps={{
          sort,
          onSort: handleOnSort,
          skeleton: pending,
        }}
        selected={selected}
        onSelect={setSelected}
        onReset={handleOnReset}
        ItemComponent={ItemComponent}
        itemComponentProps={{
          toFn: ({id}) => '/workloads/details/service/' + id,
          buttonsWidth: '0px',
          skeleton: pending,
          ButtonsComponent: ServiceSingleButtons,
          ToolTipComponent: ToolTipContentComponent,
          typeComponentProps: {
            type: 'service',
          },
          buttonsComponentProps: {
            pending,
            texts: false,
            getFuture: getFuture,
            redirect: false
          },
        }}
        pagerProps={{
          size,
          page,
          totalPages,
          onChangeSize: handleOnSizeChange,
          onChangePage: handleOnPageChange,
        }}
      />
  )
}

export default ServicesList
