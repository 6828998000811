import {memo, useMemo} from "react"
import styled from "styled-components"
import mapField from '../results/modes/typeMapper'
import {fromPairs, map, path, pipe, zip} from "ramda"
import {GraphicWalker} from "@kanaries/graphic-walker"
import {VegaTheme} from "@kanaries/graphic-walker/vis/theme"
import {useSelector} from "react-redux"

const Root = styled.div`
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
// background-color: white;
  background-color: ${({ theme }) => theme.palette['surface-primary']};
`

const Stretch = styled.div`
  &, & > * {
    width: 100%;
    height: 100%;
  }
`

const ResultsExplorer = ({ results }) => {
  const { columns, rows } = results

  const walkerFields = useMemo(() => {
    return columns.map(mapField)
  }, [columns])

  const walkerData = useMemo(() => {
    const fields = map(({ name }) => name, columns)
    return map(pipe(zip(fields), fromPairs), rows)
  }, [columns, rows])

  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))

  return (
    <div style={{ 
      width: '100%', 
      height: '100%', 
      position: 'relative',
      overflow: 'auto',
    }}>
      <Root>
        <Stretch>
          <GraphicWalker
            data={walkerData}
            fields={walkerFields}
            vizThemeConfig={VegaTheme}
            darkMode={darkMode ? 'dark' : 'light'}
          />
        </Stretch>
      </Root>
    </div>
  )
}

export default memo(ResultsExplorer)
