import {isNil, pathOr} from 'ramda'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import Configuration from '../../../../../components/configuration'
import NoResultFound from '../../../../../components/NoResultFound'

export const Root = styled.div`
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
`

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ScheduleDetailsParameters = () => {
  const params = useSelector(pathOr({}, [
    'workloads',
    'data',
    'schedule',
    'response',
    'jobConfig',
  ]))

  const paramPairs = useMemo(() => {
    if (isNil(params)) return []
    return Object.entries(params).map(([key, value]) => {
      return Object.freeze({key, value})
    })
  }, [params])

  return (
    <Root>
      {paramPairs.length > 0 ? (
        <Configuration
          data={paramPairs}
          schema={[
            {
              id: 'key',
              label: 'Key',
              copy: true,
            },
            {
              id: 'value',
              label: 'Value',
              copy: true,
              obfuscate: true,
            },
          ]}
        />
      ) : (
        <Centered>
          <NoResultFound
            title="No Parameters Found"
            text="There are no parameters for this schedule"
          />
        </Centered>
      )}
    </Root>
  )
}

export default ScheduleDetailsParameters
