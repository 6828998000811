import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  padding: 12px;
  color: ${({theme}) => theme.palette.neutral[500]};
`;

const ToolTipContentLayout = ({children}) => <Root>
  {children}
</Root>;

export default ToolTipContentLayout;