import asyncThunk from "./index";

export const createLicense = asyncThunk({
  method: "POST",
  type: "license/create",
  path: "/license",
  api: true,
});

export default createLicense;
