import React, {useCallback} from 'react'
import styled from 'styled-components'
import {LetterMiniature} from '../../../components/miniatures'
import ToolTip from '../../../components/toolTip'
import ToolTipContent from './ToolTipContent'

import {Icon} from 'frontcore'
import RButton from '../../../components/RButton'

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({theme}) => theme.palette.neutral[800]};
`

const iconMap = {
  root: {
    type: 'ROOT',
  },
  cluster: {
    IconComponent: LetterMiniature,
    iconComponentProps: {type: 'cluster'},
  },

  storage: {
    icon: 'server',
  },
  bucket: {
    icon: 'trash',
  },

  folder: {
    icon: 'directory',
  },
  file: {
    icon: 'project',
  },
}

const GridItem = (props) => {
  const {data, onClick} = props
  const {type, name} = data
  const aaa = iconMap[type]

  const handleOnClick = useCallback(
    (value) => {
      if (type !== 'file') {
        onClick(value)
      }
    },
    [type]
  )
  return (
    <ToolTip content={<ToolTipContent item={data} />}>
      <div>
        <RButton {...aaa} name={name} type={type} onClick={handleOnClick} />
      </div>
    </ToolTip>
  )
}

export default GridItem
