import React, {useEffect, useMemo, useState} from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import Home from './views/home'
import Workloads from './views/WorkloadsView'
import styled, {StyleSheetManager} from 'styled-components'

import DocumentationView from './views/documentation'
import SettingsView from './views/SettingsView'
import TableExplorerView from './views/TableExplorerView'
import MinioDataView from './views/minio'
import SqlView from './views/sql'
import {Provider as StoreProvider, useSelector} from 'react-redux'
import store from './reducers'
import {NotificationProvider} from './contexts/notificationProvider'
import LoginView from './views/login'
import ModulesView from './views/modules'
import AirflowView from './views/airflow'
import NotebookView from './views/nootebook'
import GiteaView from './views/gitea'
import MlflowView from './views/mlflow'
import HistoryView from './views/history'
import LineageView from './views/Lineage'
import SupersetView from './views/superset'
import GrafanaView from './views/grafana'
import N8nView from './views/n8n'
import {isNil, isNotNil, path, pathOr} from 'ramda'
import WorkloadsClustersListView from './views/WorkloadsView/WorkloadsListView/WorkloadsClustersListView'
import WorkloadsServicesListView from './views/WorkloadsView/WorkloadsListView/WorkloadsServicesListView'
import WorkloadsJobsListView from './views/WorkloadsView/WorkloadsListView/WorkloadsJobsListView'
import WorkloadsRequestsListView from './views/WorkloadsView/WorkloadsListView/WorkloadsRequestsListView'
import WorkloadsDetailsView from './views/WorkloadsView/WorkloadsDetailsView'
import WorkloadsClusterDetailsView from './views/WorkloadsView/WorkloadsDetailsView/WorkloadsClusterDetailsView'
import WorkloadsServiceDetailsView from './views/WorkloadsView/WorkloadsDetailsView/WorkloadsServiceDetailsView'
import WorkloadsJobDetailsView from './views/WorkloadsView/WorkloadsDetailsView/WorkloadsJobDetailsView'
import WorkloadsRequestDetailsView from './views/WorkloadsView/WorkloadsDetailsView/WorkloadsRequestDetailsView'
import {AuthProvider, useAuth} from 'react-oidc-context'
import {
  ILUM_ENVIRONMENT,
  ILUM_OAUTH2_CLIENT_ID,
  ILUM_OAUTH2_CLIENT_SECRET,
  ILUM_OAUTH2_ISSUER_URI,
} from './helpers/runtimeEnv'
import isPropValid from '@emotion/is-prop-valid'
import LicenseBar from './components/licenseBar'
import useFuture from './hooks/useFuture'
import {fork} from 'fluture'
import {FrontCoreThemeProvider, Input} from 'frontcore'
import FileExplorerView from './views/FileExplorerView'
import darkTheme from './styles/darkTheme'
import lightTheme from './styles/lightTheme'
import TableGraphView from './views/Lineage/TableGraphView'
import useFetch from './hooks/useFetch'
import {singleCallFetchOptions} from './api/helpers'
import {EMPTY_FUNCTION, EMPTY_OBJECT} from './constants'
import LineageListView from './views/Lineage/LineageListView'
import LineageJobsListView from './views/Lineage/LineageListView/LineageJobsListView'
import LineageDatasetsListView from './views/Lineage/LineageListView/LineageDatasetsListView'
import ColumnGraphView from './views/Lineage/ColumnGraphView'
import {getLocalStorage} from './views/WorkloadsView/hooks'
import posthog from 'posthog-js'
import {PostHogProvider} from 'posthog-js/react'
import {PostHogPageView} from './components/posthog/pageview'
import getClustersFetch from './api/workloads/clusters/getClustersFetch'
import createSQLEngine from './api/sql/createSQLEngine'
import getSQLEngines from './api/sql/getSQLEngines'
import WorkloadsSchedulesListView from './views/WorkloadsView/WorkloadsListView/WorkloadsSchedulesListView'
import SecurityView from './views/SecurityView'
import SecurityListView from './views/SecurityView/SecurityListView'
import SecurityDetailsView from './views/SecurityView/SecurityDetailsView'
import SecurityUsersListView from './views/SecurityView/SecurityListView/SecurityUsersListView'
import SecurityGroupsListView from './views/SecurityView/SecurityListView/SecurityGroupsListView'
import SecurityRolesListView from './views/SecurityView/SecurityListView/SecurityRolesListView'
import SecurityUserDetailsView from './views/SecurityView/SecurityDetailsView/SecurityUserDetailsView'
import SecurityGroupDetailsView from './views/SecurityView/SecurityDetailsView/SecurityGroupDetailsView'
import SecurityRoleDetailsView from './views/SecurityView/SecurityDetailsView/SecurityRoleDetailsView'
import SecurityCreateView from './views/SecurityView/SecurityCreateView'
import SecurityCreateRoleView from './views/SecurityView/SecurityCreateView/SecurityCreateRoleView'
import SecurityCreateUserView from './views/SecurityView/SecurityCreateView/SecurityCreateUserView'
import SecurityCreateGroupView from './views/SecurityView/SecurityCreateView/SecurityCreateGroupView'
import SecurityEditView from './views/SecurityView/SecurityEditView'
import SecurityEditUserView from './views/SecurityView/SecurityEditView/SecurityEditUserView'
import SecurityActivitiesListView from './views/SecurityView/SecurityListView/SecurityActivitiesListView'
import SecurityEditGroupView from './views/SecurityView/SecurityEditView/SecurityEditGroupView'
import SecurityEditRoleView from './views/SecurityView/SecurityEditView/SecurityEditRoleView'
import SecurityActivityDetailsView from './views/SecurityView/SecurityDetailsView/SecurityActivityDetailsView'
import WorkloadsCreateView from './views/WorkloadsView/WorkloadsCreateView'
import WorkloadsCreateClusterView from './views/WorkloadsView/WorkloadsCreateView/WorkloadsCreateClusterView'
import WorkloadsCreateServiceView from './views/WorkloadsView/WorkloadsCreateView/WorkloadsCreateServiceView'
import WorkloadsCreateJobView from './views/WorkloadsView/WorkloadsCreateView/WorkloadsCreateJobView'
import WorkloadsCreateScheduleView from './views/WorkloadsView/WorkloadsCreateView/WorkloadsCreateScheduleView'
import WorkloadsEditView from './views/WorkloadsView/WorkloadsEditView'
import WorkloadsEditClusterView from './views/WorkloadsView/WorkloadsEditView/WorkloadsEditClusterView'
import WorkloadsEditScheduleView from './views/WorkloadsView/WorkloadsEditView/WorkloadsEditScheduleView'
import WorkloadsEditServiceView from './views/WorkloadsView/WorkloadsEditView/WorkloadsEditServiceView'
import WorkloadsListView from './views/WorkloadsView/WorkloadsListView'
import WorkloadsSchedulesDetailsView from './views/WorkloadsView/WorkloadsDetailsView/WorkloadsScheduleDetailsView'
import AITestView from './views/AITestView'
import ThemeView from './views/ThemeView'
import SecurityEditPasswordView from './views/SecurityView/SecurityEditView/SecurityEditPasswordView'
import {HistoryProvider} from './contexts/historyProvider'
import NavigationBar from './components/NavigationBar'
import RootLayout from './layouts/RootLayout'
import UserDropdown from './components/userDropdown'
import SearchBar from './components/SearchBar'
import {WebStorageStateStore} from 'oidc-client-ts'
import {getSynchronize} from './api/security/getSynchronize'
import {StorageProvider} from './contexts/StorageProvider/StorageProvider'
import NotificationsView from "./views/NotificationsView";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  autocapture: false,
  capture_pageview: false,
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'always',
  loaded: function (ph) {
    if (ILUM_ENVIRONMENT !== 'production') {
      ph.opt_out_capturing()
      ph.set_config({disable_session_recording: true})
    }
  },
})

const LogoRoot = styled.div`
  height: 48px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  overflow: hidden;
`

const logoMap = {
  dark: '/logoWhite.svg',
  light: '/logo.svg',
}

const Logo = () => {
  const mode = useSelector(path(['settings', 'settings', 'darkMode']))
  return (
    <LogoRoot>
      <img style={{width: 100, height: 200}} src={logoMap[mode ? 'dark' : 'light']} alt="ilum" />
    </LogoRoot>
  )
}

const Root = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.palette['surface-primary']};
`

const useStartupSQL = () => {
  const token = localStorage.getItem('ilum_token')

  const {data: clusters} = useFetch(token ? getClustersFetch : EMPTY_OBJECT, singleCallFetchOptions)
  const firstClusterId = useMemo(() => {
    if (isNil(clusters) || clusters.length <= 0) {
      return null
    }
    return clusters[0]?.id
  }, [clusters])

  const [engines, setEngines] = useState(null)
  const getSQLEnginesFuture = useFuture(getSQLEngines)
  useEffect(() => {
    if (isNil(firstClusterId)) {
      return
    }
    fork(EMPTY_FUNCTION)((response) => {
      setEngines(response)
    })(
      getSQLEnginesFuture({
        params: [{key: 'clusterId', value: firstClusterId}],
      })
    )
  }, [firstClusterId])

  const createSQLEngineFuture = useFuture(createSQLEngine)
  useEffect(() => {
    if (isNil(firstClusterId) || isNil(engines)) {
      return
    }

    const activeEngine = engines.find((engine) => {
      return ['RUNNING', 'STARTING'].includes(engine.state)
    })

    if (isNotNil(activeEngine)) {
      console.log(`SQL: default active engine is ${activeEngine.type}`)
      return
    }

    console.info(`SQL: No running engines found. Starting up default one`)
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      createSQLEngineFuture({
        params: [{key: 'clusterId', value: firstClusterId}],
        async: true,
      })
    )
  }, [engines, firstClusterId])
}

const ContentComponent = () => {
    const location = useLocation()
    return  <Routes location={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="workloads" element={<Workloads />}>
            <Route path={'list'} element={<WorkloadsListView />}>
                <Route path={'clusters'} element={<WorkloadsClustersListView />} />
                <Route path={'services'} element={<WorkloadsServicesListView />} />
                <Route path={'jobs'} element={<WorkloadsJobsListView />} />
                <Route path={'requests'} element={<WorkloadsRequestsListView />} />
                <Route path={'schedules'} element={<WorkloadsSchedulesListView />} />
            </Route>
            <Route path={'details'} element={<WorkloadsDetailsView />}>
                <Route
                    path={'cluster/:clusterId/:clusterName'}
                    element={<WorkloadsClusterDetailsView />}
                />
                <Route
                    path={'service/:serviceId'}
                    element={<WorkloadsServiceDetailsView />}
                />
                <Route
                    path={'schedule/:scheduleId'}
                    element={<WorkloadsSchedulesDetailsView />}
                />
                <Route path={'job/:jobId'} element={<WorkloadsJobDetailsView />} />
                <Route
                    path={'request/:requestId'}
                    element={<WorkloadsRequestDetailsView />}
                />
            </Route>
            <Route path={'create'} element={<WorkloadsCreateView />}>
                <Route path={'cluster'} element={<WorkloadsCreateClusterView />} />
                <Route path={'service'} element={<WorkloadsCreateServiceView />} />
                <Route path={'job'} element={<WorkloadsCreateJobView />} />
                <Route path={'schedule'} element={<WorkloadsCreateScheduleView />} />
            </Route>
            <Route path={'edit'} element={<WorkloadsEditView />}>
                <Route
                    path={'cluster/:clusterId'}
                    element={<WorkloadsEditClusterView />}
                />
                <Route
                    path={'service/:serviceId'}
                    element={<WorkloadsEditServiceView />}
                />
                <Route
                    path={'schedule/:scheduleId'}
                    element={<WorkloadsEditScheduleView />}
                />
            </Route>
        </Route>
        <Route path="/documentation" element={<DocumentationView />} />
        <Route path="/data" element={<TableExplorerView />} />
        <Route path="/file-explorer" element={<FileExplorerView />} />
        <Route path="/minio" element={<MinioDataView />} />
        <Route path="/sql/:queryId?" element={<SqlView />} />
        <Route path="/security" element={<SecurityView />}>
            <Route path="create" element={<SecurityCreateView />}>
                <Route path={'user'} element={<SecurityCreateUserView />} />
                <Route path={'group'} element={<SecurityCreateGroupView />} />
                <Route path={'role'} element={<SecurityCreateRoleView />} />
            </Route>
            <Route path="edit" element={<SecurityEditView />}>
                <Route path={'user/:userId'} element={<SecurityEditUserView />} />
                <Route path={'group/:groupId'} element={<SecurityEditGroupView />} />
                <Route path={'role/:roleId'} element={<SecurityEditRoleView />} />
                <Route path={'password/:userId'} element={<SecurityEditPasswordView />} />
            </Route>
            <Route path="list" element={<SecurityListView />}>
                <Route path={'users'} element={<SecurityUsersListView />} />
                <Route path={'groups'} element={<SecurityGroupsListView />} />
                <Route path={'roles'} element={<SecurityRolesListView />} />
                <Route path={'activities'} element={<SecurityActivitiesListView />} />
            </Route>
            <Route path="details" element={<SecurityDetailsView />}>
                <Route path={'user/:userId'} element={<SecurityUserDetailsView />} />
                <Route path={'group/:groupId'} element={<SecurityGroupDetailsView />} />
                <Route path={'role/:roleId'} element={<SecurityRoleDetailsView />} />
                <Route
                    path={'activity/:activityId'}
                    element={<SecurityActivityDetailsView />}
                />
            </Route>
        </Route>
        <Route path="/settings/*" element={<SettingsView />} />
        <Route path="/login" element={<LoginView />} />
        <Route path="/airflow" element={<AirflowView />} />
        <Route path="/notebook" element={<NotebookView />} />
        <Route path="/gitea" element={<GiteaView />} />
        <Route path="/mlflow" element={<MlflowView />} />
        <Route path="/history" element={<HistoryView />} />
        <Route path="/superset" element={<SupersetView />} />
        <Route path="/grafana" element={<GrafanaView />} />
        <Route path="/n8n" element={<N8nView />} />
        <Route path="/ilum-lineage" element={<LineageView />}>
            <Route path="list" element={<LineageListView />}>
                <Route path="jobs" element={<LineageJobsListView />}></Route>
                <Route path="datasets" element={<LineageDatasetsListView />}></Route>
            </Route>
            <Route
                path="table-graph/:nodeType/:nodeId/:namespace"
                element={<TableGraphView />}
            />
            <Route
                path="column-graph/:nodeType/:nodeId/:namespace"
                element={<ColumnGraphView />}
            />
        </Route>
        <Route path="/modules" element={<ModulesView />} />
        <Route path="/ai" element={<AITestView />} />
        <Route path="/theme" element={<ThemeView />} />
        <Route path="/notifications" element={<NotificationsView />} />
    </Routes>
}

const App = () => {
  const notificationHistoryConfig = useMemo(() => ({history: [], notificationHistory: []}), [])
  const location = useLocation()
  useStartupSQL()
  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))
  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode])
  return (
    <StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
      <FrontCoreThemeProvider theme={theme}>
        <StorageProvider schema={notificationHistoryConfig}>
          <HistoryProvider>
            <NotificationProvider>
              <Root>
                <LicenseBar />
                <RootLayout
                  TopContentComponent={SearchBar}
                  BottomLeftContentComponent={NavigationBar}
                  BottomRightContentComponent={ContentComponent}
                />
                <PostHogPageView />
              </Root>
            </NotificationProvider>
          </HistoryProvider>
        </StorageProvider>
      </FrontCoreThemeProvider>
    </StyleSheetManager>
  )
}

const oidcConfig = {
    authority: ILUM_OAUTH2_ISSUER_URI,
    client_id: ILUM_OAUTH2_CLIENT_ID,
    client_secret: ILUM_OAUTH2_CLIENT_SECRET,
    userStore: new WebStorageStateStore({store: window.localStorage}),
    redirect_uri: window.location.origin + '/workloads/list/services',
    onSigninCallback: (user) => {
        const cachedUrl = localStorage.getItem('cachedUrl')
        if (cachedUrl) {
            window.location.href = cachedUrl
        }
    },
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <PostHogProvider client={posthog}>
    <AuthProvider {...oidcConfig}>
      <StoreProvider store={store}>
        <BrowserRouter
          future={{
            v7_relativeSplatPath: true,
          }}
        >
          <App />
        </BrowserRouter>
      </StoreProvider>
    </AuthProvider>
  </PostHogProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
