import {Select, Tabs} from 'frontcore'
import React, {useCallback, useEffect, useMemo} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import Line from '../../../components/line'
import useFuture from '../../../hooks/useFuture'
import getNamespaces from '../../../api/lineage/getNamespaces'
import {fork} from 'fluture'
import {map, path, pathOr, pipe, prop, propOr} from 'ramda'
import {EMPTY_ARRAY, EMPTY_STRING} from '../../../constants'
import {useDispatch, useSelector} from 'react-redux'
import {setNamespace} from '../../../reducers/lineage'
import LineageSearch from '../LineageSearch'
import styled from 'styled-components'

const SearchContainer = styled.div`
  display: flex;
 
  
  align-items: center;
    
  box-sizing: border-box;
  gap: 16px;
`

// const SearchContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   position: absolute;
//   right: 16px;
//     gap: 16px;
//   left: 0;
//   top: 24px;
//   box-sizing: border-box;
//   pointer-events: none;
//
//     z-index: 1;
//     right: 16px;
//     top: 24px;
// `

const LineageListView = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const {pathname} = useLocation()

  const handleOnTabClick = useCallback(
    (value) => {
      navigate(value)
    },
    [pathname, navigate]
  )

  const model = useSelector(prop('lineage'))

  const namespaces = pipe(
    pathOr(EMPTY_ARRAY, ['data', 'namespaces', 'response', 'namespaces']),
    map(prop('name'))
  )(model)

  const namespace = useMemo(() => propOr(EMPTY_STRING, 'namespace', model), [model])

  const getNamespacesFuture = useFuture(getNamespaces)

  useEffect(() => {
    fork((r) => {})(({namespaces}) => {
      const result = path([0, 'name'], namespaces)
      dispatch(setNamespace(result))
    })(getNamespacesFuture({}))
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',

        position: 'relative',
      }}
    >
      <div
        style={{
          height: 48,
          paddingLeft: 16,
          paddingRight: 16,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={pathname}
          onChange={handleOnTabClick}
          options={[
            {
              id: '/ilum-lineage/list/jobs',
              children: 'Jobs',
            },
            {
              id: '/ilum-lineage/list/datasets',
              children: 'Datasets',
            },
          ]}
        />
        <SearchContainer>
          <Select
            buttonProps={{width: '400px'}}
            value={namespace}
            onChange={(value) => {
              dispatch(setNamespace(value))
            }}
            options={namespaces}
          />
        </SearchContainer>
      </div>
      <Line />
      <div style={{flex: 1, overflow: 'hidden'}}>
        <Outlet />
      </div>
    </div>
  )
}

export default LineageListView
