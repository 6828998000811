import asyncThunk from "../../index";

export const getJobs = asyncThunk({
  method: "GET",
  type: "jobs/get",
  path: "/job",
  api: true
});

export default getJobs;
