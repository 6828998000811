import asyncThunk from "../../index";

export const createCluster = asyncThunk({
  method: "POST",
  type: "clusters/post",
  path: "/cluster",
  api: true
});

export default createCluster;
