import {useCallback} from 'react'
import {join, pipe} from 'ramda'
import {and, fork} from 'fluture'
import GroupButtons from '../index'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {EMPTY_ARRAY} from '../../../../../constants'
import {deleteGroup} from '../../../../../api/security/deleteGroup'
import {enableGroup} from '../../../../../api/security/enableGroup'
import {disableGroup} from '../../../../../api/security/disableGroup'

const GroupBatchButtons = ({
  pending,
  data,
  getFuture,
  selected,
  setSelected,
}) => {
  const {id} = data

  const {createNotification} = useNotificationContext()

  const deleteGroupFuture = useFuture(deleteGroup)
  const enableGroupFuture = useFuture(enableGroup)
  const disableGroupFuture = useFuture(disableGroup)

  const handleOnDelete = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to delete Groups',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        setSelected(EMPTY_ARRAY)
        createNotification({
          title: 'Groups deleted successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteGroupFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  const handleOnEnable = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to enable Groups',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Groups enabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      enableGroupFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  const handleOnDisable = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to disable Groups',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Groups disabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      disableGroupFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  return (
    <GroupButtons
      texts={false}
      id={id}
      data={data}
      pending={pending}
      onEnable={handleOnEnable}
      onDisable={handleOnDisable}
      onDelete={handleOnDelete}
    />
  )
}

export default GroupBatchButtons
