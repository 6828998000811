import {memo, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {IconButton} from './NotebookActions'
import {isNil} from 'ramda'

const Container = styled.div`
  position: absolute;
  left: 50%;
  ${({$position = 'top'}) =>
    $position === 'top'
      ? `
  top: 0;
  transform: translate(-50%, -50%);
`
      : `
  bottom: 0;
  transform: translate(-50%, 50%);

`}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  & > div {
    opacity: 0;
  }
  &:hover > div {
    opacity: 1;
  }
`

const InnerContainer = styled.div`
  transition: 0.2s opacity ease-in-out;
  border: 1px solid ${({theme}) => theme.palette['border-tooltip']};
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.palette['surface-primary']};
`

const SecondaryActions = ({statement, state, actions}) => {
  const upRef = useRef()
  const downRef = useRef()

  useEffect(() => {
    const setIndexFor = (ref, clientX, clientY) => {
      if (isNil(ref.current)) return
      const {x, y, width, height} = ref.current.getBoundingClientRect()
      const positionX = x + width / 2
      const positionY = y + height / 2
      const distance = Math.sqrt((clientX - positionX) ** 2 + (clientY - positionY) ** 2)
      ref.current.style['z-index'] = Math.max(1000 - Math.round(distance), 1)
    }

    const mouseTracker = ({clientX, clientY}) => {
      setIndexFor(upRef, clientX, clientY)
      setIndexFor(downRef, clientX, clientY)
    }

    window.addEventListener('mousemove', mouseTracker)
    return () => {
      window.removeEventListener('mousemove', mouseTracker)
    }
  }, [])

  return (
    <>
      <Container onClick={(event) => event.stopPropagation()} ref={upRef}>
        <InnerContainer>
          <IconButton
            onClick={() => actions.addCellBefore(statement.id)}
            icon="plus"
            description="Add a cell before"
          />
          <IconButton
            onClick={() => actions.addMarkdownBefore(statement.id)}
            icon="pencil"
            description="Add a markdown cell before"
          />
          <IconButton
            onClick={() => actions.moveCellUp(statement.id)}
            icon="arrow-top"
            description="Move the cell up"
          />
        </InnerContainer>
      </Container>
      <Container onClick={(event) => event.stopPropagation()} ref={downRef} $position="bottom">
        <InnerContainer>
          <IconButton
            onClick={() => actions.addCellAfter(statement.id)}
            icon="plus"
            description="Add a cell after"
          />
          <IconButton
            onClick={() => actions.addMarkdownAfter(statement.id)}
            icon="pencil"
            description="Add a markdown cell after"
          />
          <IconButton
            onClick={() => actions.moveCellDown(statement.id)}
            icon="arrow-down"
            description="Move the cell down"
          />
        </InnerContainer>
      </Container>
    </>
  )
}

export default memo(SecondaryActions)
