import asyncThunk from "./index";

export const getLicense = asyncThunk({
  method: "GET",
  type: "license/get",
  path: "/license",
  api: true,
});

export default getLicense;
