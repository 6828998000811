import {isNil} from "ramda";
import {EMPTY_STRING} from "../constants";

const timeValidator = value => {
    if (isNil(value)) {
        return EMPTY_STRING
    }
    if (value < Date.now()) {
        return 'Please, select moment from the future'
    }
    return EMPTY_STRING
}

export default timeValidator