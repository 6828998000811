import ServiceButtons from '../ServiceButtons'
import React, {useCallback} from 'react'
import {map, pipe} from 'ramda'
import {and, fork, parallel} from 'fluture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFuture from '../../../../hooks/useFuture'
import deleteService from '../../../../api/workloads/services/deleteService'
import archiveService from '../../../../api/workloads/services/archiveService'
import scaleService from '../../../../api/workloads/services/scaleService'
import pauseService from '../../../../api/workloads/services/pauseService'
import resumeService from '../../../../api/workloads/services/resumeService'
import restartService from '../../../../api/workloads/services/restartService'

const ServiceBatchButtons = (props) => {
  const {selected, getFuture} = props

  const {createNotification} = useNotificationContext()

  const deleteServiceFuture = useFuture(deleteService)
  const archiveServiceFuture = useFuture(archiveService)
  const scaleServiceFuture = useFuture(scaleService)
  const pauseServiceFuture = useFuture(pauseService)
  const resumeServiceFuture = useFuture(resumeService)
  const restartServiceFuture = useFuture(restartService)

  const handleOnBatchResume = useCallback(() => {
    pipe(
      map((id) =>
        resumeServiceFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to resume Services',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Services successfully resumed',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchPause = useCallback(() => {
    pipe(
      map((id) =>
        pauseServiceFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to pause Services',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Services successfully paused',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchRestart = useCallback(() => {
    pipe(
      map((id) =>
        restartServiceFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to restart Services',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Services successfully restarted',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchScale = useCallback(
    ({value = 1}) => {
      pipe(
        map((id) =>
          scaleServiceFuture({
            params: [
              {key: 'id', value: id},
              {key: 'scale', value},
            ],
          })
        ),
        parallel(10),
        and(getFuture),
        fork(({message}) => {
          createNotification({
            title: 'Failed to scale Services',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(() => {
          createNotification({
            title: 'Services successfully scaled',
            autoHide: true,
            variant: 'success',
          })
        })
      )(selected)
    },
    [selected, getFuture]
  )

  const handleOnBatchArchive = useCallback(() => {
    pipe(
      map((id) =>
        archiveServiceFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to archive Services',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Services successfully archived',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchDelete = useCallback(() => {
    pipe(
      map((id) =>
        deleteServiceFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({ message }) => {
        createNotification({
          title: 'Failed to delete Services',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          title: 'Services successfully deleted',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  return (
    <ServiceButtons
      texts={false}
      onResume={handleOnBatchResume}
      onPause={handleOnBatchPause}
      onRestart={handleOnBatchRestart}
      onArchive={handleOnBatchArchive}
      onDelete={handleOnBatchDelete}
      onScale={handleOnBatchScale}
    />
  )
}

export default ServiceBatchButtons
