import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useSSE from '../../hooks/useSSE'
import {getBaseUrl} from '../../helpers'
import useFuture from '../../hooks/useFuture'
import getLogs from '../../api/getLogs'
import {fork} from 'fluture'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import styled from 'styled-components'
import skeletonCss from '../../styles/skeletonCss'
import {Button, Icon} from 'frontcore'
import scrollIntoView from 'scroll-into-view-if-needed'
import withDebounce from '../../helpers/withDebounce'
import Line from '../../components/line'
import DetailsHeader from '../../components/DetailsHeader'
import StatusIndicator from '../../components/StatusIndicator'
import {toUpper} from 'ramda'
import ResourceIcon from '../../components/Icons/ResourceIcon'

const Root = styled.div`
  color: ${({theme}) => theme.palette.text.tertiary};
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const LogsContainer = styled.div`
       
`
const iconMap = {
  'kubernetes-pod': 'kubernetes',
  'spark-submit-process': 'spark',
}


function download(data, filename) {
  const file = new Blob([data])
  if (window.navigator.msSaveOrOpenBlob)
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    const a = document.createElement('a'),
      url = URL.createObjectURL(file)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}

const Logs = styled.div`
  ${skeletonCss};
`

const ResourceLogs = ({data, jobId}) => {
  const resourceId = data?.name

  const ref = useRef()

  const baseUrl = getBaseUrl()

  const apiUrl = process.env.REACT_APP_API_URL

  const url = useMemo(
    () =>
      `${baseUrl}${apiUrl}/truly/job/${jobId}/resources/${resourceId}/log/stream`,
    [baseUrl, jobId, resourceId]
  )

  const [pending, setPending] = useState(true)

  useEffect(() => {
    setPending(true)
  }, [resourceId])

  const getLogsFuture = useFuture(getLogs)

  const {closeNotification, createNotification} =
    useNotificationContext()

  const debouncedScrollDown = useCallback(withDebounce((element) => {
    if(ref.current?.parentNode) {
      scrollIntoView(ref.current, {
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
        scrollMode: 'if-needed',
        boundary: ref.current?.parentNode,
      })
    }


  })(300), [])

  useSSE(url, [
    {
      name: 'message',
      handler: (event) => {
        setPending(false)
        const preContent = document.getElementById('logs')
        preContent.innerHTML += event.data
        debouncedScrollDown(preContent.parentNode)
      },
    },
  ])

  useEffect(() => {
    const preContent = document.getElementById('logs')
    preContent.innerHTML = ''
  }, [resourceId])

  const handleOnDownloadLogs = useCallback(() => {
    fork(({message}) => {
      createNotification({
        message,
        autoHide: false,
        variant: 'error',
      })
    })((text) => {
      const regExpFilename = /filename="(?<filename>.*)"/;
      const filename = regExpFilename.exec(text.filename)?.groups?.filename ?? null;
      text.content.blob().then((result) => {
        download(
          result,
          filename,

        )
      })


    })(
      getLogsFuture({
        params: [
          {key: 'jobId', value: jobId},
          {key: 'resourceName', value: resourceId},
        ],
      })
    )
  }, [jobId, resourceId])

  const scrollDown = useCallback(() => {
    if (!ref?.current) return
    scrollIntoView(ref.current, {
      block: 'end',
      inline: 'nearest',
      behavior: 'smooth',
      scrollMode: 'if-needed',
      boundary: ref.current?.parentNode,
    })
  }, [ref])

  const scrollUp = useCallback(() => {
    if (!ref?.current) return
    scrollIntoView(ref.current, {
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
      scrollMode: 'if-needed',
      boundary: ref.current?.parentNode,
    })
  }, [ref])

  return (
    <Root>
      <div
        style={{
          height: 64,
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          fontSize: 12,
          padding: 16,

          justifyContent: 'space-between',
        }}
      >
        <DetailsHeader
          icon={<ResourceIcon type={data?.type} />}
          id={data?.role}
          name={data?.name}
          pending={false}
          data={[
            {
              key: 'role',
              value: data?.role,
            },

            {
              key: 'type',
              value: data?.type,
            },
            {
              key: 'state',
              Component: StatusIndicator,
              componentProps: {
                 value: data?.state,
                variant: data?.state    === 'running' ? 'success' : 'default'
              },
            },
          ]}
        />

        <Button
          tabIndex={-1}
          variant={'text'}
          StartComponent={Icon}
          startComponentProps={{
            icon: 'download',
            size: 24,
          }}
          onClick={handleOnDownloadLogs}
        >
          Download Logs
        </Button>
      </div>
      <Line/>
      <LogsContainer
        style={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Logs
          $skeleton={pending}
          style={{
            overflow: 'scroll',
            flex: 1,
            padding: '0 8px',
            // border: '1px solid rgba(34, 24, 90, 0.2)',
            // borderRadius: 4,
          }}
        >
          <pre
            ref={ref}
            id={'logs'}
            style={{
              fontSize: 12,
            }}
          />
        </Logs>
        <div
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            gap: 8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            square={true}
            skeleton={pending}
            justifyContent={'center'}
            StartComponent={Icon}
            size={'small'}
            onClick={scrollUp}
            // variant={'outlined'}
            startComponentProps={{
              icon: 'arrow-top',
              size: 16,
            }}
          />
          <Button
            square={true}
            skeleton={pending}
            onClick={scrollDown}
            justifyContent={'center'}
            StartComponent={Icon}
            size={'small'}
            // variant={'outlined'}
            startComponentProps={{
              icon: 'arrow-down',
              size: 16,
            }}
          />
        </div>
      </LogsContainer>
    </Root>
  )
}

export default ResourceLogs
