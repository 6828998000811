import {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import {and, fork} from 'fluture'
import {useNavigate} from 'react-router-dom'
import UserButtons from '../index'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {deleteUser} from '../../../../../api/security/deleteUser'
import {enableUser} from '../../../../../api/security/enableUser'
import {disableUser} from '../../../../../api/security/disableUser'

const UserSingleButtons = ({
  pending,
  data,
  texts,
  getFuture,
  redirect = true,
}) => {
  const {id} = data

  const navigate = useNavigate()

  const {createNotification} = useNotificationContext()

  const deleteUserFuture = useFuture(deleteUser)
  const enableUserFuture = useFuture(enableUser)
  const disableUserFuture = useFuture(disableUser)

  const handleOnEnable = useCallback(({id}) => {
    pipe(
      when(always(!redirect), and(getFuture)),
      fork(({message}) => {
        createNotification({
          title: 'Failed to enable User',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'User enabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      enableUserFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])
  const handleOnDisable = useCallback(({id}) => {
    pipe(
      when(always(!redirect), and(getFuture)),
      fork(({message}) => {
        createNotification({
          title: 'Failed to disable User',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        redirect && navigate('/security/list/users')
        createNotification({
          title: 'User disabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      disableUserFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnDelete = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to delete User',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(({message}) => {
          redirect && navigate('/security/list/users')
          createNotification({
            title: 'User deleted successfully',
            message,
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        deleteUserFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnEdit = useCallback(
    ({id}) => navigate('/security/edit/user/' + id),
    []
  )

  const handleOnToggle = useCallback(
    ({id}) => {

      return data?.state === 'ENABLED'
        ? handleOnDisable({id})
        : handleOnEnable({id})
    },
    [data, handleOnDisable, handleOnEnable]
  )

  const handleOnChangePassword= useCallback(
      ({id}) => navigate('/security/edit/password/' + id),
      []
  )

  return (
    <UserButtons
      texts={texts}
      id={id}
      data={data}
      pending={pending}
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
      onToggle={handleOnToggle}
      onChangePassword={handleOnChangePassword}
    />
  )
}

export default UserSingleButtons
