import {sql} from '@codemirror/lang-sql'
import {linter} from '@codemirror/lint'
import CodeMirror from '@uiw/react-codemirror'
import {vim} from '@replit/codemirror-vim'
import {Resizer} from 'frontcore'
import {path, pathOr} from 'ramda'
import {memo, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import EditorFontController from './EditorFontController'
import {sidebarMutateQuery} from '../../../reducers/sql/sidebar'
import {editorSetDiagnostics} from '../../../reducers/sql/editor'
import {useLinterDiagnostics} from '../linter'
import {getSqlDialect, useSqlSchemaCompletion} from './sqlSupport'
import {useExecuteGutter} from '../EditorGutter'

const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const FontModifier = styled.div`
  .cm-content,
  .cm-gutters {
    font-size: ${({$fontSize}) => $fontSize}px;
  }
`

const EditorArea = () => {
  const dispatch = useDispatch()

  const queryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryCode = useMemo(() => {
    const query = queries.find(({id}) => id === queryId)
    return query?.statement
  }, [queryId, queries])
  const setQueryCode = useCallback(
    (newCode) => {
      dispatch(
        sidebarMutateQuery({
          id: queryId,
          statement: newCode,
        })
      )
    },
    [queryId]
  )

  const gutter = useExecuteGutter()

  const currentEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const vimEnabled = useSelector(path(['sqlViewer', 'editor', 'vimEnabled']))
  const linterDiagnostics = useSelector(pathOr([], ['sqlViewer', 'editor', 'diagnostics', queryId]))
  const handleSetDiagnostics = useCallback(
    (diagnostics) => {
      dispatch(
        editorSetDiagnostics({
          id: queryId,
          diagnostics,
        })
      )
    },
    [queryId]
  )
  const enabled = useSelector(pathOr(false, ['sqlViewer', 'editor', 'linterEnabled']))
  useLinterDiagnostics({
    code: queryCode, 
    onChange: handleSetDiagnostics,
    dependencies: [queryId],
    enabled,
  })

  const sqlSchema = useSqlSchemaCompletion()

  const extensions = useMemo(() => {
    return [
      sql({
        upperCaseKeywords: true,
        dialect: getSqlDialect(currentEngine),
        schema: sqlSchema,
      }),
      linter(() => linterDiagnostics),
      gutter,
      ...(vimEnabled ? [vim({status: true})] : []),
    ]
  }, [vimEnabled, linterDiagnostics, currentEngine, sqlSchema, gutter])

  const fontSize = useSelector(path(['sqlViewer', 'editor', 'fontSize']))
  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))

  return (
    <Root>
      <EditorFontController />
      <Resizer>
        {({width, height}) => {
          const editorWidth = `${width}px`
          const editorHeight = `${height}px`

          return (
            <FontModifier $fontSize={fontSize}>
              <CodeMirror
                theme={darkMode ? 'dark' : 'light'}
                value={queryCode}
                onChange={setQueryCode}
                width={editorWidth}
                height={editorHeight}
                extensions={extensions}
              />
            </FontModifier>
          )
        }}
      </Resizer>
    </Root>
  )
}

export default memo(EditorArea)
