import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {path} from 'ramda'
import ExplorerBreadcrumbs from './components/ExplorerBreadcrumbs'
import StorageDetails from '../CatalogDetails'
import MetaStoreDetails from '../MetaStoreDetails'
import DatabaseDetails from '../DatabaseDetails'
import React, {useEffect} from 'react'
import TableDetails from '../TableDetails'
import {setBreadCrumbs} from "../../../reducers/tableExplorer";

const Root = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`

const componentMap = {
  metaStore: MetaStoreDetails,
  catalog: StorageDetails,
  database: DatabaseDetails,
  table: TableDetails,
}

const ExplorerWindow = () => {
  const selectedElement = useSelector(
    path(['tableExplorer', 'selectedElement'])
  )

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setBreadCrumbs(selectedElement))
    }, [selectedElement]);

  const type = selectedElement?.type

  const Component = componentMap[type]

  return (
    <Root>

      <div
        style={{
          padding: '16px 16px 0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
        }}
      >
        <ExplorerBreadcrumbs />
      </div>
      {Component && <Component />}
    </Root>
  )
}
export default ExplorerWindow
