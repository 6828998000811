import asyncThunk from "../../index";

export const createService = asyncThunk({
  method: "POST",
  type: "service/post",
  path: "/group",
  api: true
});

export default createService;
