import {CheckBox, SliderV3} from "frontcore"
import MemorySlider from '../../../components/MemorySlider'
import {EMPTY_OBJECT} from '../../../constants'
import {pathOr, propOr} from 'ramda'
import MemorySliderTemp from "../../../components/MemorySliderTemp";

const MAX_RESOURCES = Object.freeze({
  EXECUTORS: 128,
  DRIVER_CORES: 16,
  EXECUTOR_CORES: 16,
  DRIVER_MEMORY: 64,
  EXECUTOR_MEMORY: 64,
})

const memorySettings = (data = EMPTY_OBJECT) => {

  const executors =  pathOr(1 , ['memorySettings', 'executors'], data)
  const driverCores =  pathOr(1 , ['memorySettings', 'driverCores'], data)
  const executorCores =  pathOr(1 , ['memorySettings', 'executorCores'], data)
  const driverMemory =  pathOr('1g' , ['memorySettings', 'driverMemory'], data)
  const executorMemory =  pathOr('1g' , ['memorySettings', 'executorMemory'], data)
  const dynamicAllocation =  pathOr(false , ['memorySettings', 'dynamicAllocation'], data)

  const initialExecutors =  pathOr(1 , ['memorySettings', 'initialExecutors'], data)
  const minExecutors =  pathOr(1 , ['memorySettings', 'minExecutors'], data)
  const maxExecutors =  pathOr(1 , ['memorySettings', 'maxExecutors'], data)

  const showDynamicAllocation = propOr(dynamicAllocation, 'dynamicAllocation', data)

  return [
    {
      id: 'executors',
      name: 'Executors',
      section: 'Memory',
      description: 'Number of executors',
      Component: SliderV3,
      defaultValue: executors,
      componentProps: { min: 1, max: MAX_RESOURCES.EXECUTORS },
    },
    {
      id: 'driverCores',
      name: 'Driver Cores',
      section: 'Memory',
      description: 'Number of driver cores',
      Component: SliderV3,
      defaultValue: driverCores,
      componentProps: { min: 1, max: MAX_RESOURCES.DRIVER_CORES },
    },
    {
      id: 'executorCores',
      name: 'Executor Cores',
      section: 'Memory',
      description: 'Number of executor cores',
      Component: SliderV3,
      defaultValue: executorCores,
      componentProps: { min: 1, max: 16 },
    },
    {
      id: 'driverMemory',
      name: 'Driver Memory',
      section: 'Memory',
      description: 'Driver memory',
      Component: MemorySlider,
      defaultValue: driverMemory,
    },
    {
      id: 'executorMemory',
      name: 'Executor Memory',
      section: 'Memory',
      description: 'Executor memory',
      Component: MemorySlider,
      defaultValue: executorMemory,
    },
    {
      id: 'dynamicAllocation',
      name: 'Dynamic Allocation',
      section: 'Memory',
      description: 'Allows for automatic adjustment of the number of executors allocated to an application',
      Component: CheckBox,
      defaultValue: dynamicAllocation,
      componentProps: {}
    },
    ...(showDynamicAllocation ? [
      {
        id: 'initialExecutors',
        name: 'Initial Executors',
        section: 'Memory',
        description: 'Initial number of executors to run if dynamic allocation is enabled',
        Component: SliderV3,
        defaultValue: initialExecutors,
        componentProps: { min: 1, max: MAX_RESOURCES.EXECUTORS }
      },
      {
        id: 'minExecutors',
        name: 'Minimal number of executors',
        section: 'Memory',
        description: 'Lower bound for the number of executors if dynamic allocation is enabled',
        Component: SliderV3,
        defaultValue: minExecutors,
        componentProps: { min: 1, max: MAX_RESOURCES.EXECUTORS }
      },
      {
        id: 'maxExecutors',
        name: 'Maximal number of executors',
        section: 'Memory',
        description: 'Upper bound for the number of executors if dynamic allocation is enabled',
        Component: SliderV3,
        defaultValue: maxExecutors,
        componentProps: { min: 1, max: MAX_RESOURCES.EXECUTORS }
      }
    ] : [])
  ]
}

export default memorySettings
