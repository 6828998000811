import useFetch from '../../hooks/useFetch'
import getJobMetricsFetch from '../../api/workloads/jobs/getJobMetricsFetch'
import {useEffect, useMemo, useState} from 'react'
import {map, pipe, propOr, toPairs} from 'ramda'
import {Key, Value} from '../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'
import {EMPTY_OBJECT} from '../../constants'

const metricsNameMap = {
  allocatedMemory: 'allocated memory',
  allocatedCPU: 'allocated CPU',
  runningContainers: 'running containers',
}

const JobMetrics = ({name, jobId}) => {
  const [api, setApi] = useState(EMPTY_OBJECT)

  useEffect(() => {
    setTimeout(() => setApi(getJobMetricsFetch), 500)
  }, [])

  const {data} = useFetch(
    api,
    {
      refreshInterval: 10000,
    },
    {params: [{key: 'id', value: jobId}]}
  )

  const extendedData = useMemo(() => {
    return pipe(toPairs)(data)
  }, [data])

  return (
    <>
      {map(
        (a) => (
          <>
            <Key>{propOr(a[0], a[0], metricsNameMap)}</Key>
            <Value>{a[1]}</Value>
          </>
        ),
        extendedData
      )}
    </>
  )
}

export default JobMetrics
