import {Button, Input} from 'frontcore'
import {isNil, isNotNil, path, pipe} from 'ramda'
import {memo, useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {sidebarMutateQuery} from '../../../reducers/sql/sidebar'
import ToolTip from '../../../components/toolTip'
import FileImportExport from './FileImportExport'
import EditorEngineSelector from './EditorEngineSelector'
import {editorSetMaxRows} from '../../../reducers/sql/editor'
import EditorSaveQuery from './EditorSaveQuery'
import EditorDeleteQuery from './EditorDeleteQuery'
import EditorAutosave from './EditorAutosave'
import useFuture from '../../../hooks/useFuture'
import executeSQLStatement from '../../../api/sql/executeSQLStatement'
import {fork} from 'fluture'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {EMPTY_FUNCTION} from '../../../constants'
import QueryTimer from './QueryTimer'
import {resultsSetErrorMessage} from '../../../reducers/sql/results'
import EditorClusterSelector from './EditorClusterSelector'
import EditorEngineStatus from './EditorEngineStatus'
import EditorRefreshEngines from './EditorRefreshEngines'
import EngineState from '../EngineState'

const Root = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const RightControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
`

const EngineControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
`

const EditorTopControls = () => {
  const queryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const chosenQuery = useMemo(() => {
    return queries.find(({id}) => {
      return id === queryId
    })
  }, [queryId, queries])

  const dispatch = useDispatch()

  const handleRenameQuery = useCallback(
    (newName) => {
      dispatch(
        sidebarMutateQuery({
          id: queryId,
          name: newName,
        })
      )
    },
    [queryId]
  )

  const maxRows = useSelector(path(['sqlViewer', 'editor', 'maxRows']))
  const handleMaxRows = useCallback(pipe(Number, editorSetMaxRows, dispatch), [])

  const chosenEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))

  const chosenClusterId = useSelector(path(['sqlViewer', 'sidebar', 'chosenCluster', 'id']))

  const sqlEngines = useSelector(path(['sqlViewer', 'editor', 'data', 'liveEngines', 'response']))

  const engineInfo = useMemo(() => {
    return sqlEngines?.find((engine) => {
      return engine.type === chosenEngine
    })
  }, [sqlEngines, chosenEngine])

  const engineIsRunning = useMemo(() => {
    return engineInfo?.state === EngineState.RUNNING
  }, [engineInfo])

  const {createNotification} = useNotificationContext()
  const executeSQLStatementFuture = useFuture(executeSQLStatement)
  const handleRunQuery = useCallback(() => {
    if (!engineIsRunning || isNil(chosenQuery) || chosenQuery?.type === 'notebook') {
      return
    }

    let statement = chosenQuery.statement
    const selection = window.getSelection()
    if (selection?.type?.toLowerCase() === 'range') {
      statement = selection.toString()
    }

    if (isNil(chosenEngine) || isNil(chosenClusterId)) {
      createNotification({
        title: 'Warning',
        message: 'Please, to execute a statement choose a cluster and an engine',
        variant: 'warning',
        autoHide: true,
      })
      return
    }
    fork(({message}) => {
      dispatch(resultsSetErrorMessage(message))
      createNotification({
        title: 'Error',
        message: 'Failed to run query. Check logs for details',
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(
      executeSQLStatementFuture({
        maxRows,
        engine: chosenEngine,
        clusterId: chosenClusterId,
        statement,
      })
    )
  }, [engineIsRunning, maxRows, chosenEngine, chosenQuery, chosenClusterId])

  useEffect(() => {
    const onMouseDown = (event) => {
      if (event.ctrlKey && event?.key?.toLowerCase() === 'enter') {
        event.preventDefault()
        event.stopPropagation()
        handleRunQuery()
      }
    }
    window.addEventListener('keydown', onMouseDown, true)
    return () => {
      window.removeEventListener('keydown', onMouseDown, true)
    }
  }, [handleRunQuery])

  const isRunning = useSelector(path(['sqlViewer', 'editor', 'data', 'executeQuery', 'pending']))

  return (
    <Root>
      {isNotNil(chosenQuery) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Input
            placeHolder="Enter query name..."
            value={chosenQuery.name}
            onChange={handleRenameQuery}
          />
          <EditorSaveQuery />
          {!chosenQuery.local && <EditorAutosave />}
          {chosenQuery.type !== 'notebook' && <FileImportExport />}
          <EditorDeleteQuery />
        </div>
      )}
      <RightControls>
        {isNotNil(chosenQuery) && chosenQuery?.type !== 'notebook' && (
          <>
            <QueryTimer />
            <ToolTip
              config={{position: 'bottom_right'}}
              content={(
                engineInfo?.state !== EngineState.RUNNING && (
                  <div style={{padding: 8}}>
                    {engineInfo?.state === EngineState.FAILED && 'Engine has failed. Please, try to restart it'}
                    {engineInfo?.state === EngineState.STARTING && 'Engine is starting. Please, wait till it starts'}
                    {isNil(engineInfo?.state) && 'Engine is off. Please, start it in order to run queries'}
                  </div>
                )
              )}
            >
              <div>
                <Button
                  skeleton={isRunning}
                  onClick={handleRunQuery}
                  disabled={!chosenEngine || !engineIsRunning}
                >
                  Execute
                </Button>
              </div>
            </ToolTip>
          </>
        )}
        <EngineControls>
          <EditorClusterSelector />
          <EditorEngineSelector />
          <EditorEngineStatus />
          <EditorRefreshEngines />
        </EngineControls>
        {isNotNil(chosenQuery) && (
          <ToolTip
            config={{position: 'bottom_right'}}
            content={
              <div style={{padding: 8}}>
                <b>Max rows</b> parameter will try to insert <b>`limit`</b> clause for you to your
                SQL query and always limits the amount of downloaded rows
              </div>
            }
          >
            <Input
              topLabelProps={{
                children: 'Max rows',
              }}
              placeHolder="Max rows: "
              value={maxRows}
              onChange={handleMaxRows}
              rawInputProps={{
                style: {
                  width: '60px',
                },
              }}
            />
          </ToolTip>
        )}
      </RightControls>
    </Root>
  )
}

export default memo(EditorTopControls)
