import {ILUM_N8N_PATH} from "../../helpers/runtimeEnv";

const N8nView = () => {
    return (

        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_N8N_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    );
};

N8nView.dispayName = "N8nView";

export default N8nView;

