import asyncThunk from '..'

const executeSQLStatement = asyncThunk({
  method: 'POST',
  type: 'sql/execute',
  path: '/sql/execute',
  api: true,
})

export default executeSQLStatement
