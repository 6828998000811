import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import CodeMirror from '@uiw/react-codemirror'
import {sql} from '@codemirror/lang-sql'
import {SparkSQL} from '../editor/sqlSupport'
import {vim} from '@replit/codemirror-vim'
import {isNotNil} from 'ramda'
import {linter} from '@codemirror/lint'
import NotebookActions from './NotebookActions'
import NotebookResults from './NotebookResults'
import NotebookPending from './NotebookPending'
import NotebookError from './NotebookError'
import ResizableWindow from '../../../components/ResizableWindow'
import SecondaryActions from './SecondaryActions'
import {useLinterDiagnostics} from '../linter'
import {createExecuteGutter} from '../EditorGutter'

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette['surface-primary']};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette['border-tooltip']};
  position: relative;
  border-left: 6px solid
    ${({theme, $chosen}) =>
      $chosen
        ? theme.palette.primary[500]
        : theme.palette.neutral[500]};
`

const FontModifier = styled.div`
  .cm-content, .cm-gutters {
    font-size: ${({ $fontSize }) => $fontSize}px;
  }
`

const NotebookCell = ({statement, state, actions}) => {
  const handleStatementChange = useCallback((newCode) => {
    actions.changeStatement(statement.id, newCode)
  }, [actions.changeStatement, statement.id])

  const handleNotebookDiagnostics = useCallback((diagnostics) => {
    actions.setDiagnostics(statement.id, diagnostics)
  }, [actions.setDiagnostics, statement.id])

  useLinterDiagnostics({
    code: statement.code,
    onChange: handleNotebookDiagnostics,
    dependencies: [state.notebookId],
    enabled: state.linterEnabled,
  })

  const diagnostics = state.notebookDiagnostics[statement.id] ?? []

  const runFragment = useCallback(code => {
    actions.runFragment(statement.id, code)
  }, [actions.runFragment, statement.id])

  const extensions = useMemo(() => {
    return [
      sql({
        upperCaseKeywords: true,
        dialect: SparkSQL,
        schema: state.sqlSchema,
      }),
      ...(state.vimEnabled
        ? [vim({ status: true })]
        : []
      ),
      ...(state.linterEnabled
        ? [linter(() => diagnostics)]
        : []
      ),
      createExecuteGutter(runFragment),
    ]
  }, [state.sqlSchema, state.vimEnabled, state.linterEnabled, diagnostics, runFragment])

  const result = state.notebookResults[statement.id]
  const pending = state.notebookPending[statement.id] ?? false
  const error = state.notebookErrors[statement.id]

  const editorRef = useRef()
  useEffect(() => {
    editorRef?.current?.editor?.click()
  }, [])

  const [ready, setReady] = useState(false)

  return (
    <Root
      onClick={() => actions.setPointer(statement.id)}
      $chosen={state.pointer === statement.id}
    >
      {!ready && (
        <div style={{ padding: '32px 0' }}>
          <NotebookPending />
        </div>
      )}
      <FontModifier $fontSize={state.fontSize}>
        <CodeMirror
          autoFocus
          onCreateEditor={() => setReady(true)}
          ref={editorRef}
          theme={state.darkMode ? 'dark' : 'light'}
          value={statement.code}
          onChange={handleStatementChange}
          extensions={extensions}
        />
      </FontModifier>
      {(isNotNil(result) || pending || error) && (
        <ResizableWindow
          initialHeight={200}
          minHeight={100}
          style={{display: 'flex', flexDirection: 'column'}}
          handleSize={24}
        >
          {isNotNil(result) && (
            <NotebookResults results={result} />
          )}
          {pending && <NotebookPending />}
          {error && <NotebookError error={error} />}
        </ResizableWindow>
      )}
      <NotebookActions
        statement={statement}
        state={state}
        actions={actions}
      />
      <SecondaryActions
        statement={statement}
        state={state}
        actions={actions}
      />
    </Root>
  )
}

export default memo(NotebookCell)
