import asyncThunk from "../../index";

export const pauseSchedule = asyncThunk({
  method: 'POST',
  type: 'schedule/pause',
  path: '/schedule/${id}/pause',
  api: true,
})

export default pauseSchedule
