import {Icon, Input, Select} from "frontcore";
import {EMPTY_ARRAY} from "../../../../constants";
import getClustersNamesFetch from "../../../../api/workloads/clusters/getClustersNamesFetch";
import FilterComplexItemComponent from "../../../../components/filters/filterComplexItemComponent";
import FilterComplexComponent from "../../../../components/filters/filterComplexComponent";
import getServicesNamesFetch from "../../../../api/workloads/services/getServicesNamesFetch";

const schema = [
    {
        id: "search",
        Component: Input,
        defaultValue: "",
        props: {
            StartComponent: Icon,
            startComponentProps: {
                icon: 'search',
                size: 16,
            },
            placeHolder: "Requests Search",
            topLabelProps: {
                children: 'Requests Search',
            },
        },
    },
    {
        id: "clusterNames",
        Component: Select,
        defaultValue: EMPTY_ARRAY,
        optionsApi: getClustersNamesFetch,
        props: {
            label: "Clusters",
            value: [],
            valueProp: 'name',
            multiSelect: true,
            ItemComponent: FilterComplexItemComponent,
            buttonProps: {
                width: '200px',
                Component: FilterComplexComponent
            },
        },
    },
    {
        id: 'groupIds',
        Component: Select,
        width: 200,
        defaultValue: EMPTY_ARRAY,
        optionsApi: getServicesNamesFetch,
        props: {
            label: 'Services',
            value: [],
            valueProp: 'id',
            ItemComponent: FilterComplexItemComponent,
            multiSelect: true,
            buttonProps: {
                width: '200px',
                Component: FilterComplexComponent,
            },
        },
    },
];

export default schema;
