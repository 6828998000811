import {useSelector} from 'react-redux';
import {any, equals, map, pathOr, pick, pipe, prop, values} from 'ramda';
import {EMPTY_OBJECT} from '../constants';
import {useMemo} from 'react';
import {useThrottle} from 'frontcore';


const usePending = (path = [], keys = []) => {
  const data = useSelector(pathOr(EMPTY_OBJECT, path));
  const pending =  useMemo(
    () =>
      pipe(pick(keys), map(prop("pending")), values, any(equals(true)))(data),
    [data, keys]
  );

  return useThrottle(pending, 200);
};

export default usePending;
