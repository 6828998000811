import asyncThunk from '../../index'

export const stopSchedule = asyncThunk({
  method: 'POST',
  type: 'schedule/stop',
  path: '/schedule/${id}/stop',
  api: true,
})

export default stopSchedule
