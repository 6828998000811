import styled from 'styled-components';
import ToolTipContentLayout from './toolTipContentLayout';
import React from 'react';

const Root = styled.div`
  display: grid;
  grid-template-columns: minmax(80px, auto) 1fr;
  gap: 8px;
  font-size: 12px;
`;

export const Key = styled.div`
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
`;

export const Value = styled.div`

`;

export const Row = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;


const ToolTipDetailsGridContentLayout = ({children}) => (
  <ToolTipContentLayout>
    <Root>
      {children}
    </Root>
  </ToolTipContentLayout>
);

export default ToolTipDetailsGridContentLayout;