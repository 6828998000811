import {ResizeLayout} from 'frontcore'
import {HORIZONTAL} from '../../../../../constants'
import styled from 'styled-components'

const Root = styled.div`
    background: ${({theme}) => theme.palette['surface-secondary']};

`

const Layout = ({
  topLeftNode,
  topMiddleNode,
  topRightNode,
  bottomLeftNode,
  bottomMiddleNode,
  bottomRightNode,
  rightNode,
}) => (
  <ResizeLayout
    secondNode={rightNode}
    firstNode={
      <Root
        style={{

          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          // background: 'red',
          overflow: 'hidden',
          padding: 24,
          gap: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 24,
          }}
        >
          <div style={{flex: 1}}>{topLeftNode}</div>
          <div style={{flex: 1}}>{topMiddleNode}</div>
          <div style={{flex: 1}}>{topRightNode}</div>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 3,
              gap: 24,
            }}
          >
            {/*<div style={{flex: 1}}>{bottomLeftNode}</div>*/}
            <div style={{flex: 2}}>{bottomMiddleNode}</div>
            {/*<div style={{flex: 1}}>{bottomRightNode}</div>*/}
          </div>
        </div>
      </Root>
    }
    configuration={{
      orientation: HORIZONTAL,
      firstNode: {
        measurement: 'flex',
        value: 1,
      },
      secondNode: {
        measurement: 'px',
        value: 0,
      },
    }}
  ></ResizeLayout>
)

export default Layout
