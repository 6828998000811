import {Button, Icon} from 'frontcore'

import styled, {keyframes} from 'styled-components'

const dottedStillAnimation = keyframes`
    0%,
    100% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2), 2.5em 0 0 0 rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.2), 0 2.5em 0 0 rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.6em 0 0 0 rgba(0, 0, 0, 0.5), -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.7);
    }
    12.5% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.7), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0), 2.5em 0 0 0 rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.2), 0 2.5em 0 0 rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.6em 0 0 0 rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.5);
    }
    25% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.5), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.7), 2.5em 0 0 0 rgba(0, 0, 0, 0), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.2), 0 2.5em 0 0 rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.6em 0 0 0 rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2);
    }
    37.5% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.5), 2.5em 0 0 0 rgba(0, 0, 0, 0.7), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0), 0 2.5em 0 0 rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.6em 0 0 0 rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2);
    }
    50% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2), 2.5em 0 0 0 rgba(0, 0, 0, 0.5), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.6em 0 0 0 rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2);
    }
    62.5% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2), 2.5em 0 0 0 rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.5), 0 2.5em 0 0 rgba(0, 0, 0, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2);
    }
    75% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2), 2.5em 0 0 0 rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.2), 0 2.5em 0 0 rgba(0, 0, 0, 0.5), -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2);
    }
    87.5% {
        box-shadow: 0 -2.6em 0 0 rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0 rgba(0, 0, 0, 0.2), 2.5em 0 0 0 rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0 rgba(0, 0, 0, 0.2), 0 2.5em 0 0 rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0 rgba(0, 0, 0, 0.5), -2.6em 0 0 0 rgba(0, 0, 0, 0.7), -1.8em -1.8em 0 0 #ffffff;
    }
`

export const SpinnerRoot = styled.div`
  --spinner-color: ${({$color}) => $color};
  --spinner-size: ${({$size}) => $size}px;
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  align-items: center;
  justify-content: center;
`

export const DottedStill = styled.div`
  font-size: 3.5px;
  display: inline-block;
  background: transparent;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: ${dottedStillAnimation} 1s infinite ease;
`

const ExpandListButton = ({pending, expanded, onClick}) => {
  return (
    <div>
      {!pending && (
        <Button
          variant={'text'}
          Component={Icon}
          square={true}
          tabIndex={-1}
          size={'small'}
          onClick={onClick}
          justifyContent={'center'}
          componentProps={{
            color: '#535E6C',
            size: 16,
            icon: expanded
              ? 'arrow-down-contained'
              : 'arrow-right-contained',
          }}
        />
      )}
      {pending && (
        <SpinnerRoot>
          <DottedStill />
        </SpinnerRoot>
      )}
    </div>
  )
}

export default ExpandListButton
