import {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import {and, fork} from 'fluture'
import {useNavigate} from 'react-router-dom'
import ActivityButtons from '../index'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {deleteActivity} from '../../../../../api/security/deleteActivity'

const ActivitySingleButtons = ({
  pending,
  data,
  texts,
  getFuture,
  redirect = true,
}) => {
  const {id} = data

  const navigate = useNavigate()

  const {createNotification} = useNotificationContext()

  const deleteActivityFuture = useFuture(deleteActivity)

  const handleOnDelete = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            title: 'Failed to delete Activity',
            message,
            autoHide: false,
            variant: 'error',
          })
        })(({message}) => {
          redirect && navigate('/security/list/activities')
          createNotification({
            title: 'Activity deleted successfully',
            message,
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        deleteActivityFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  return (
    <ActivityButtons
      texts={texts}
      id={id}
      data={data}
      pending={pending}
      onDelete={handleOnDelete}
    />
  )
}

export default ActivitySingleButtons
