import {createSlice} from '@reduxjs/toolkit'
import {extraReducersMapper} from '../../api'
import getSQLEngines from '../../api/sql/getSQLEngines'
import {EMPTY_OBJECT, MAX_JAVA_INT32} from '../../constants'
import {max, min, pipe} from 'ramda'
import getSQLEngineConfig from '../../api/sql/getSQLEngineConfig'
import executeSQLStatement from '../../api/sql/executeSQLStatement'
import createSQLEngine from '../../api/sql/createSQLEngine'
import deleteSQLEngine from '../../api/sql/deleteSQLEngine'

const INITIAL_DATA = Object.freeze({
  response: null,
  pending: true,
  error: false,
})

export const FONT_SIZE = Object.freeze({
  MIN: 10,
  DEFAULT: 14,
  MAX: 28,
})

const PRIMARY_ENGINE = 'SPARK_SQL'

const initialState = Object.freeze({
  vimEnabled: false,
  linterEnabled: false,
  fontSize: FONT_SIZE.DEFAULT,
  chosenEngine: PRIMARY_ENGINE,
  maxRows: 1000,
  diagnostics: EMPTY_OBJECT,
  data: {
    liveEngines: INITIAL_DATA,
    engineConfig: INITIAL_DATA,
    executeQuery: { ...INITIAL_DATA, pending: false },
  }
})

const editorSlice = createSlice({
  name: 'sql/editor',
  initialState,
  reducers: {
    editorChooseEngine: (state, { payload }) => {
      state.chosenEngine = payload
    },
    editorToggleVimMode: (state) => {
      state.vimEnabled = !state.vimEnabled
    },
    editorToggleLinter: (state) => {
      state.linterEnabled = !state.linterEnabled
    },
    editorSetDiagnostics: (state, { payload }) => {
      const { id, diagnostics } = payload
      state.diagnostics[id] = diagnostics
    },
    editorIncreaseFontSize: state => {
      state.fontSize = Math.min(FONT_SIZE.MAX, state.fontSize + 1)
    },
    editorDecreaseFontSize: state => {
      state.fontSize = Math.max(FONT_SIZE.MIN, state.fontSize - 1)
    },
    editorSetMaxRows: (state, { payload }) => {
      const verifiedAmount = pipe(
        max(1),
        min(MAX_JAVA_INT32),
      ) (payload)
      state.maxRows = verifiedAmount
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getSQLEngines,
      name: 'liveEngines',
    },
    {
      asyncThunk: getSQLEngineConfig,
      name: 'engineConfig',
    },
    {
      asyncThunk: executeSQLStatement,
      name: 'executeQuery',
    },
    {
      asyncThunk: createSQLEngine,
      name: 'engineCreate',
    },
    {
      asyncThunk: deleteSQLEngine,
      name: 'engineDelete',
    },
  ]),
})

export default editorSlice.reducer
export const {
  editorChooseEngine,
  editorToggleVimMode,
  editorToggleLinter,
  editorSetDiagnostics,
  editorIncreaseFontSize,
  editorDecreaseFontSize,
  editorSetMaxRows,
} = editorSlice.actions
