import React, {memo, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import DetailsLayout from '../../../../components/layouts/detailsLayout'
import useFuture from '../../../../hooks/useFuture'
import getSchedule from '../../../../api/workloads/schedules/getSchedule'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useDispatch, useSelector} from 'react-redux'
import {isNotNil, pathOr, pipe, propOr} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import {fork} from 'fluture'
import {Tabs} from 'frontcore'
import {CountMiniature} from '../../../../components/miniatures'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import SlideTransition from '../../../../components/transitions/slideTransition'

import ScheduleDetailsJobsList from './ScheduleDetailsJobsList'
import ScheduleDetailsArguments from './ScheduleDetailsArguments'
import ScheduleDetailsMetrics from './ScheduleDetailsMetrics'
import ScheduleDetailsParameters from './ScheduleDetailsParameters'
import ScheduleDetailsTags from './ScheduleDetailsTags'
import ScheduleSingleButtons from '../../Components/ScheduleSingleButtons'
import DetailsHeader from '../../../../components/DetailsHeader'
import DateCellRow from '../../dateCellRow'
import ServiceState from '../../../../components/ServiceState'
import cronstrue from 'cronstrue'
import {convertObjectToArrayFn} from '../../../../helpers'
import ScheduleDetailsMemory from './ScheduleDetailsMemory'
import ResourceIcon from '../../../../components/Icons/ResourceIcon'
import {useHistoryContext} from "../../../../hooks/useHistoryContext";

const TabRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const TabComponent = memo(({active, type, value, label}) => (
  <TabRoot>
    {label}
    {isNotNil(value) && <CountMiniature value={value} active={active} type={type} />}
  </TabRoot>
))

const WorkloadsSchedulesDetailsView = () => {
  const dispatch = useDispatch()
  const {scheduleId} = useParams()
  const {createNotification} = useNotificationContext()
  const getScheduleFuture = useFuture(getSchedule)

  const {response, pending, error} = useSelector(
    pathOr(EMPTY_OBJECT, ['workloads', 'data', 'schedule'])
  )
  const {history, addHistory} = useHistoryContext()
  const data = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'schedule', 'response']))

  useEffect(() => {
    if (!error) return
    createNotification({
      title: 'Error',
      message: error,
      variant: 'error',
      autoHide: true,
    })
  }, [error])

  useEffect(() => {
    if (!scheduleId) return
    fork(({message}) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })((data) => {
      const {id, name, clusterId, clusterName} = data
      addHistory({type: 'schedule', id: scheduleId})
      dispatch(
        setBreadCrumbs({
          cluster: {
            id: clusterId,
            name: clusterName,
          },
          schedule: {
            id,
            name,
            active: true,
          },
        })
      )
    })(
      getScheduleFuture({
        params: [{key: 'id', value: scheduleId}],
      })
    )
  }, [scheduleId])

  const [tab, setTab] = useState('jobs')

  const tabsOptions = useMemo(() => {
    const mapping = Object.freeze({
      jobs: {
        type: 'job',
        label: 'Jobs',
        value: response?.jobsCount,
      },
      params: 'Parameters',
      args: 'Arguments',
      tags: 'Tags',
      metrics: 'Metrics',
      memory: 'Memory',
    })

    return Object.entries(mapping).map(([key, value]) => {
      return Object.freeze({
        id: key,
        Component: TabComponent,
        componentProps: typeof value === 'object' ? value : {label: value},
      })
    })
  }, [response])

  const memory = useMemo(
    () =>
      pipe(propOr(EMPTY_OBJECT, 'memorySettings'), convertObjectToArrayFn('key', 'value'))(data),
    [data]
  )

  console.log('memory', memory)

  const middleContent = useMemo(
    () => (
      <>
        <Tabs value={tab} onChange={setTab} options={tabsOptions} />
        <ScheduleSingleButtons
          texts={true}
          getFuture={getScheduleFuture({
            params: [{key: 'id', value: data.id}],
          })}
          pending={pending}
          data={data}
          redirect={true}
        />
      </>
    ),
    [tab, tabsOptions]
  )

  const bottomContent = useMemo(
    () => (
      <SlideTransition trigger={tab} duration={150}>
        {tab === 'params' && <ScheduleDetailsParameters />}
        {tab === 'args' && <ScheduleDetailsArguments />}
        {tab === 'tags' && <ScheduleDetailsTags />}
        {tab === 'memory' && <ScheduleDetailsMemory data={memory} />}
        {tab === 'metrics' && <ScheduleDetailsMetrics scheduleId={scheduleId} />}
        {tab === 'jobs' && <ScheduleDetailsJobsList scheduleId={scheduleId} />}
      </SlideTransition>
    ),
    [tab, scheduleId]
  )

  let frequency
  try {
    frequency = cronstrue.toString(response.cron, {
      throwExceptionOnParseError: true,
      verbose: true,
    })
  } catch {
    frequency = 'Unknown'
  }

  const {name, id, language, createdTime, startTime, endTime, cron, state} = data

  const topContent = (
    <DetailsHeader
      name={name}
      id={id}
      icon={<ResourceIcon type={'schedule'} />}
      pending={pending}
      data={[
        {
          key: 'language',
          value: language,
        },
        {
          key: 'created time',
          value: createdTime,
          Component: DateCellRow,
        },
        {
          key: 'start time',
          value: startTime,
          Component: DateCellRow,
        },
        {
          key: 'end time',
          value: endTime,
          Component: DateCellRow,
        },
        {
          key: 'cron',
          value: cron,
        },
        {
          key: 'state',
          value: state,
          Component: ServiceState,
        },
        {
          key: 'frequency',
          value: frequency,
        },
      ]}
    />
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default WorkloadsSchedulesDetailsView
