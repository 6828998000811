import {Button} from 'frontcore'
import StatusIndicator from '../StatusIndicator'
import React from 'react'

const LABEL_MAPPING = {
  alpha: 'error',
  beta: 'warning',
  certified: 'success',
  other: 'information',
}

export const SparkVersions = [
  {
    label: 'certified',
    value: 'ilum/spark:3.5.3-delta',
  },
  {
    label: 'certified',
    value: 'ilum/spark:3.5.3',
  },
  {
    label: 'certified',
    value: 'ilum/spark:3.5.2-delta',
  },
  {
    label: 'certified',
    value: 'ilum/spark:3.5.2',
  },
  {
    label: 'beta',
    value: 'ilum/spark:3.5.0-sedona',
  },
  {
    label: 'certified',
    value: 'ilum/spark:3.5.0',
  },
  {
    label: 'certified',
    value: 'ilum/spark:3.4.2',
  },
  {
    label: 'beta',
    value: 'ilum/spark:3.4.1-trino',
  },
  {
    label: 'certified',
    value: 'ilum/spark:3.4.1',
  },
]
const SparkVersionAutoCompleteItem =  ({value, label, onClick}) => (
  <Button
    tabIndex={-1}
    rounding={false}
    variant={'text'}
    onClick={() => onClick(value)}
    Component={() => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>{value}</div>
        <div>
          <StatusIndicator
            value={label}
            variant={LABEL_MAPPING[label]}
          />
        </div>
      </div>
    )}
  />
)

export default SparkVersionAutoCompleteItem;
