import asyncThunk from "..";

export const getSQLStatements = asyncThunk({
  method: 'GET',
  type: 'sql/statements/get',
  path: '/sql/statement',
  api: true,
})

export default getSQLStatements
