import asyncThunk from './index';

export const authenticate = asyncThunk({
  method: "POST",
  type: "authenticate/ldap",
  path: "/authenticate",
  api: false
});

export default authenticate;
