import {memo, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {
  removeQueriesBar,
  sidebarChooseQuery,
} from '../../reducers/sql/sidebar'
import {isNotNil, path} from 'ramda'
import RButton from '../../components/RButton'
import {Button, Icon} from 'frontcore'

const Root = styled.div`
  display: flex;
  overflow: hidden;
`

/*
 * Horizontal tabs with active (open)
 * queries and notebooks
 */
const QueryTabs = () => {
  // actions: choose and remove
  const dispatch = useDispatch()

  const handleChooseQuery = useCallback(
    (queryId) => () => {
      dispatch(sidebarChooseQuery(queryId))
    },
    []
  )

  const handleRemoveQuery = useCallback(
    (queryId) => (event) => {
      event.stopPropagation()
      dispatch(removeQueriesBar(queryId))
    }
  )

  // data: list of query tabs
  const queriesBar = useSelector(
    path(['sqlViewer', 'sidebar', 'queriesBar'])
  )
  const queries = useSelector(
    path(['sqlViewer', 'sidebar', 'queries'])
  )
  const chosenQuery = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenQuery'])
  )

  const extendedQueriesBar = useMemo(() => {
    return queriesBar
      .map((queryId) => {
        return queries.find((query) => query.id === queryId)
      })
      .filter(isNotNil)
  }, [queries, queriesBar])

  return (
    <Root>
      {extendedQueriesBar.map(({name, id, type}) => (
        <RButton
          icon={type === 'notebook' ? 'pencil' : 'database'}
          name={name}
          active={id === chosenQuery}
          onClick={handleChooseQuery(id)}
        >
          <Button
            square={true}
            variant={'text'}
            tabIndex={-1}
            size={'small'}
            justifyContent={'center'}
            Component={Icon}
            componentProps={{icon: 'close', size: 18}}
            onClick={handleRemoveQuery(id)}
          />
        </RButton>
      ))}
    </Root>
  )
}

export default memo(QueryTabs)
