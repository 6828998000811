import asyncThunk from "../../index";

export const activateSchedule = asyncThunk({
  method: 'POST',
  type: 'schedule/activate',
  path: '/schedule/${id}/activate',
  api: true,
})

export default activateSchedule
