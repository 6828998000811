import React from "react";
import styled, { css } from "styled-components";
import NoResultFound from '../../../components/NoResultFound'

const Empty = () => {
  return (
    <Root>
      <NoResultFound/>
    </Root>
  );
};

export default Empty;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const textCss = css`
  font-size: 16px;
`;

const EmptyText = styled.div`
  ${textCss};
  color: ${({ theme }) => theme.palette.neutral[500]}
`;
