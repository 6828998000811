import React, {useEffect, useMemo, useState} from 'react'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../constants'
import useFetch from '../../../hooks/useFetch'
import {
  fromPairs,
  isNotNil,
  map,
  omit,
  pipe,
  toPairs,
} from 'ramda'
import {
  Key,
  Value,
} from '../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'
import styled from 'styled-components'
import Line from '../../../components/line'
import {isNotEmpty} from '../../../helpers'

const options = Object.freeze({
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  shouldRetryOnError: false,
  refreshInterval: 0,
})

const Root = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, auto) 1fr;
  gap: 8px;
  font-size: 12px;
  padding: 12px;
`

const getQueryString = (item) => {
  const prefix = item?.id?.replace(/[^\/]+\/$/, '')
  const name = item?.name
  return `?prefix=${prefix}&name=${name}`
}

// Used within display (inserting into JSX), not with preprocessing
const uncamel = (original) =>
  original
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (firstLetter) =>
      firstLetter.toUpperCase()
    )

// All functions below are used in data item preprocessing
const hideObjectPrivateData = omit([
  'storages',
  'defaultApplicationConfig',
  'configurationFiles',
  'metadata',
  'onClick',
])

const flattenObject = (object) => {
  const output = {}
  for (const [key, value] of Object.entries(object)) {
    if (typeof value === 'object' && isNotNil(value)) {
      const flattened = flattenObject(value)
      for (const [subkey, subvalue] of Object.entries(
        flattened
      )) {
        const newkey =
          key + subkey.replace(/^./, (e) => e.toUpperCase())
        output[newkey] = subvalue
      }
      continue
    }
    output[key] = value
  }
  return output
}

const prettifyObjectDates = pipe(
  toPairs,
  map(([key, value]) => {
    // https://stackoverflow.com/questions/12756159/regex-and-iso8601-formatted-datetime
    const pattern =
      /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i
    if (typeof value !== 'string' || !pattern.test(value)) {
      return [key, value]
    }
    const date = new Date(value)
    const formattedDate = date.toUTCString()
    return [key, formattedDate]
  }),
  fromPairs
)

const preprocess = pipe(
  hideObjectPrivateData,
  flattenObject,
  prettifyObjectDates,
  toPairs
)

// To fetch additional information about item
const to = (...args) => '/' + args.join('/')

const API_MAP = Object.freeze({
  cluster: ({id}) => to('cluster', id),

  storage: ({clusterId, id}) =>
    to('cluster', clusterId, 'storage', id),

  bucket: ({clusterId, storageId, id}) =>
    to(
      'cluster',
      clusterId,
      'storages',
      storageId,
      'bucket',
      id
    ),

  default: ({clusterId, storageId, bucketId, ...rest}) =>
    to(
      'cluster',
      clusterId,
      'storages',
      storageId,
      'bucket',
      bucketId,
      'item' + getQueryString(rest)
    ),
})

const ToolTipContent = ({item}) => {
  const requestObject = useMemo(() => {
    const getPath =
      item.type in API_MAP
        ? API_MAP[item.type]
        : API_MAP.default
    return {
      method: 'GET',
      path: getPath(item),
      api: true,
    }
  }, [item])

  const [api, setApi] = useState(EMPTY_OBJECT)

  useEffect(() => {
    setTimeout(() => setApi(requestObject), 500)
  }, [])

  const {
    data = EMPTY_ARRAY,
    error,
    isLoading,
  } = useFetch(api, options)

  const extendedItem = useMemo(() => {
    return preprocess(item)
  }, [item])

  const extendedData = useMemo(() => {
    return preprocess(data)
  }, [data])

  return (
    <Root>
      {map(
        ([key, value]) => (
          <>
            <Key>{uncamel(key)}: </Key>
            <Value>{value}</Value>
          </>
        ),
        extendedItem
      )}
      {isNotEmpty(data) && (
        <div style={{gridColumn: '1/3'}}>
          <Line />
        </div>
      )}
      {map(
        ([key, value]) => (
          <>
            <Key>{uncamel(key)}: </Key>
            <Value>{value}</Value>
          </>
        ),
        extendedData
      )}
    </Root>
  )
}

export default ToolTipContent
