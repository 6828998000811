import styled, {css} from 'styled-components'
import {EMPTY_STRING} from "../../constants";
import {toLower} from "ramda";

const errorCss = css`
  color: ${({theme}) => theme.palette.red[700]};
  div {
    background: ${({theme}) => theme.palette.red[600]};
  }
`

const successCss = css`
  color: ${({theme}) => theme.palette.green[800]};
  div {
    background: ${({theme}) => theme.palette.green[600]};
  }
`

const warningCss = css`
  color: ${({theme}) => theme.palette.yellow[700]};
  border-color: #ffcc80;
  div {
    background: ${({theme}) => theme.palette.yellow[600]};
  }
`

const informationCss = css`
  color: ${({theme}) => theme.palette.blue[700]};
  div {
    background: ${({theme}) => theme.palette.blue[600]};
  }
`

const defaultCss = css`
    color: ${({theme}) => theme.palette.neutral[700]};
    div {
        background: ${({theme}) => theme.palette.neutral[600]};
    }
`

const variantMapCss = {
  default: defaultCss,
  success: successCss,
  failed: errorCss,
  warning: warningCss,
  information: informationCss,
  error: errorCss,
  red: errorCss,
  yellow: warningCss,
  green: successCss,
  running: successCss,
  pending: defaultCss,
  succeeded: successCss,
  unknown: warningCss,
}

const Root = styled.div`
  font-size: 12px;
  font-family: Roboto, serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  align-items: center;
  ${({$variant}) => variantMapCss[$variant?.toLowerCase()]}
`

const StatusIndicator = ({
  variant = 'default',
  value = '',
  onClick,
}) => <Root onClick={onClick} $variant={variant}><div style={{width: 8, height: 8, borderRadius: '50%', minWidth: 8, minHeight: 8}}/>{toLower(value || EMPTY_STRING)}</Root>

export default StatusIndicator
