import React, {useCallback, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import useFuture from '../../hooks/useFuture'
import authenticate from '../../api/authenticate'
import {chain, fork, reject, resolve} from 'fluture'
import SlideTransition from '../../components/transitions/slideTransition'
import {Button, CheckBox, Input} from 'frontcore'
import styled from 'styled-components'
import {createTransitions} from '../../helpers'
import Form from '../../components/Form'
import SS1 from './SS1.png'
import SS2 from './SS2.png'
import SS3 from './SS3.png'
import {cond, groupBy, isEmpty, map, pathOr, pipe, prop, T} from 'ramda'
import {useAuth} from 'react-oidc-context'
import {ILUM_OAUTH2_ISSUER_URI} from '../../helpers/runtimeEnv'
import {useSelector} from 'react-redux'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import requiredValidator from '../../validators/requiredValidator'
import useLocalStorage from '../../hooks/useLocalStorage'
import {EMPTY_FUNCTION, EMPTY_STRING} from '../../constants'
import getLicense from '../../api/getLicense'
import getLicenseKey from '../../api/getLicenseKey'
import invalidateLicense from '../../api/invalidateLicense'
import { validateLicenseKeyFuture } from '../../api/licenseValidator';

const FormLayout = ({fields, buttons}) => {
  const sections = groupBy(prop('section'), fields)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          flex: 1,
          gap: 32,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {map(({data, element}) => element, sections[1])}
        {buttons}
      </div>
    </div>
  )
}

const formSchema = (data, options) => {
  const {pending} = options

  return {
    fields: [
      {
        Component: Input,
        section: 1,
        id: 'username',
        componentProps: {
          topLabelProps: {
            children: 'Username',
          },
          label: 'Username',
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        Component: Input,
        section: 1,
        id: 'password',
        componentProps: {
          type: 'password',
          topLabelProps: {
            children: 'Password',
          },
          skeleton: pending,
          fullWidth: true,
        },
        validators: [requiredValidator],
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Login',
          type: 'submit',
          color: 'primary',
          fullWidth: true,
        },
      },
    ],
  }
}

const mainTitle = 'Modular Data Lakehouse for a Hybrid World.'

const pageOneTitle = 'Modular Architecture'
const pageOneDescription = 'Tailor your TableExplorerView lakehouse to your specific needs.'

const pageTwoTitle = 'Multi-Environment Support'
const pageTwoDescription =
  'Deploy Ilum anywhere—be it on the cloud, on-premise, or a hybrid environment.'

const pageThreeTitle = 'Interactive Spark Sessions'
const pageThreeDescription = 'Ilum offers interactive sessions that enable real-time analytics.'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 512px;
  gap: 36px;
`

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  background: ${({theme}) => theme.palette.blue[900]};
`

const LeftPanel = styled.div`
  padding: 64px 0;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  color: ${({theme}) => theme.palette.neutral[50]};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`
const RightPanel = styled.div`
  flex: 1;
  border-radius: 10px 0 0 10px;
  background: ${({theme}) => theme.palette['surface-primary']};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 72px;
`

const Dot = styled.div`
  transition: ${createTransitions(['background-color'])};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({isActive, theme}) =>
    isActive ? theme.palette.neutral[50] : theme.palette.blue[800]};

  &:hover {
    background-color: ${({isActive, theme}) =>
      isActive ? theme.palette.neutral[50] : theme.palette.blue[500]};
  }
`

const Reminder = styled.div`
  display: flex;
  gap: 4px;
`
const Controls = styled.div`
  display: flex;
  gap: 16px;
`

const LoginView = () => {
  const [index, setIndex] = useState(0)

  const navigate = useNavigate()

  const loginFuture = useFuture(authenticate)

  const {createNotification} = useNotificationContext()

  const pending = useSelector(pathOr(false, ['login', 'data', 'authenticate', 'pending']))

  const getLicenseFuture = useFuture(getLicense)

  const getLicenseKeyFuture = useFuture(getLicenseKey)
  const validateLicenseFuture = useFuture(validateLicenseKeyFuture);
  const invalidateLicenseFuture = useFuture(invalidateLicense);

  const a = () => {
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(getLicenseFuture({}))
    b()
  }

  const b = () =>
    pipe(
        chain((result) => {
            const key = prop(['key'], result)
            if (!key) {
                return reject('no license')
            } else {
                return validateLicenseFuture({"key": isEmpty(key) ? "empty" : key})
            }
        }),
        chain(
            (result) => {
                let valid = result?.meta?.valid;

                if(valid === false)
                    return invalidateLicenseFuture({})

                return resolve("license is valid")
            },
        ),
        fork((message) => console.warn(message))(EMPTY_FUNCTION)
    )(getLicenseKeyFuture({}))

  const handleOnLogin = useCallback(({username, password}) => {
    localStorage.removeItem('ilum_token')

    fork(({message}) => {
      createNotification({
        message,
        autoHide: true,
        variant: 'error',
        title: 'Authenticate',
      })
    })(({token}) => {
      localStorage.setItem('ilum_token', token)
      const cachedUrl = localStorage.getItem('cachedUrl')
      a()
      if (cachedUrl) {
        localStorage.removeItem('cachedUrl')
        navigate(cachedUrl)
      } else {
        navigate('/workloads/list/services')
      }
    })(loginFuture({username, password}))
  }, [])

  const auth = useAuth()

  const result = useMemo(
    () =>
      cond([
        [(auth) => auth.activeNavigator === 'signinSilent', () => <div>Signing you in...</div>],
        [(auth) => auth.activeNavigator === 'signoutRedirect', () => <div>Signing you out...</div>],
        [({isLoading}) => isLoading, () => <div>Loading...</div>],
        [({error}) => error, ({error}) => <div>Oops... {error.message}</div>],
        [
          ({isAuthenticated}) => isAuthenticated,
          ({user, removeUser}) => (
            <div>
              Hello {user?.profile.sub} <Button onClick={() => removeUser()}>Log out</Button>
            </div>
          ),
        ],
        [T, (a) => <Button onClick={() => a.signinRedirect()}>Log in</Button>],
      ])(auth),
    [auth]
  )

  return (
    <Root>
      <LeftPanel>
        <div
          style={{
            gap: 64,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={'logoWhite.svg'} width={128} height={24} alt={'ilum'} />
          <div style={{fontSize: 24}}>{mainTitle}</div>
        </div>
        <LeftContent>
          <SlideTransition trigger={index}>
            {index === 0 && (
              <div
                style={{
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 64,
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    borderRadius: 8,
                    overflow: 'hidden',
                    width: 580,
                    height: 320,
                  }}
                >
                  <img src={SS1} alt="" width={580} height={320} />
                </div>
                <div
                  style={{
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                  }}
                >
                  <div style={{fontSize: 24}}>{pageOneTitle}</div>
                  <div style={{fontSize: 16}}>{pageOneDescription}</div>
                </div>
              </div>
            )}
            {index === 1 && (
              <div
                style={{
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 64,
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    borderRadius: 8,
                    overflow: 'hidden',
                    width: 580,
                    height: 320,
                  }}
                >
                  <img src={SS2} alt="" width={580} height={320} priority={true} />
                </div>
                <div
                  style={{
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                  }}
                >
                  <div style={{fontSize: 24}}>{pageTwoTitle}</div>
                  <div style={{fontSize: 16}}>{pageTwoDescription}</div>
                </div>
              </div>
            )}
            {index === 2 && (
              <div
                style={{
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 64,
                }}
              >
                <div
                  style={{
                    borderRadius: 8,
                    overflow: 'hidden',
                    width: 580,
                    height: 320,
                  }}
                >
                  <img src={SS3} alt="" width={580} height={320} />
                </div>
                <div
                  style={{
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                  }}
                >
                  <div style={{fontSize: 24}}>{pageThreeTitle}</div>
                  <div style={{fontSize: 16}}>{pageThreeDescription}</div>
                </div>
              </div>
            )}
          </SlideTransition>

          <Description>
            <Controls>
              <Dot isActive={index === 0} onClick={() => setIndex(0)} />
              <Dot isActive={index === 1} onClick={() => setIndex(1)} />
              <Dot isActive={index === 2} onClick={() => setIndex(2)} />
            </Controls>
          </Description>
        </LeftContent>
      </LeftPanel>
      <RightPanel>
        <Container>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              gap: 32,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{width: '100%', paddingBottom: 64}}>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 32,
                  color: '#6B7285',
                }}
              >
                Welcome to
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 64,
                  color: '#2660FF',
                }}
              >
                ILUM
              </div>
            </div>

            <div
              style={{
                display: 'none',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div style={{display: 'flex', gap: 16}}>
                <CheckBox value={true} />
                <div style={{fontSize: 16, color: '#6B7285'}}>Remember me?</div>
              </div>
              <Button variant={'text'}>
                <span style={{color: '#2660FF'}}>Forgot Password?</span>
              </Button>
            </div>

            <div
              style={{
                display: 'none',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div style={{fontSize: 16, color: '#6B7285'}}>Don't have an account?</div>
              <Button variant={'text'}>
                <span style={{color: '#2660FF'}}>Register</span>
              </Button>
            </div>
          </div>

          {!isEmpty(ILUM_OAUTH2_ISSUER_URI) && result}
          {isEmpty(ILUM_OAUTH2_ISSUER_URI) && (
            <Form
              schema={formSchema}
              options={{
                pending,
              }}
              LayoutComponent={FormLayout}
              onSubmit={handleOnLogin}
            />
          )}

          <Reminder />
        </Container>
      </RightPanel>
    </Root>
  )
}

export default LoginView
