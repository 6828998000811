import { calculateLastUpdate, formatDate } from "../../helpers";
import React from "react";

const DateCellRow = ({ value }) => {
  const formattedDate = formatDate(value);

  const duration = calculateLastUpdate(value);

  return value ? (
    <div
      style={{
        display: "flex",
        gap: 8,
        opacity: 0.8,
        fontSize: 11,
      }}
    >
      <div>{formattedDate[0]}</div>
      <div>{formattedDate[1]}</div>
      <div>{duration}</div>
    </div>
  ) : (
    <div>N/A</div>
  );
};

export default DateCellRow;
