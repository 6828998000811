import styled from 'styled-components';
import {append, equals, filter, groupBy, includes, map, pipe, prop, reject, toLower, toPairs} from 'ramda';
import InformationOverlay from './InformationOverlay';
import ilumModules from './ilum-modules.json';
import {ViewTitle} from '../../components/typography';
import {Input} from 'frontcore';
import {EMPTY_STRING} from '../../constants';
import {useCallback, useState} from 'react';
import Line from '../../components/line';
import {convertObjectToArray, isNotEmpty} from '../../helpers'
import Section from './Section';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
    width: 100%;
`;

const TopContainer = styled.div`
padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 32px;
  gap: 32px;
`;

const ModulesSubtitle = styled.small`
  color: grey;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  display: block;
  margin-bottom: 16px;
`;

const Modules = () => {

  const [search, setSearch] = useState(EMPTY_STRING);

  const [collapsed, setCollapsed] = useState([]);

  const extendedCollapsed = isNotEmpty(search) ? [] : collapsed;

  const extendedData = pipe(
    filter(({name}) => includes(toLower(search), toLower(name))),
    groupBy(prop('section')),
    convertObjectToArray,
  )(ilumModules || []);

  const handleOnClickExpand = useCallback((value) => {
    if (includes(value, collapsed)) {
      const result = reject(equals(value), collapsed);
      setCollapsed(result);
    } else {
      const result = append(value, collapsed);
      setCollapsed(result);
    }

  }, [collapsed]);

  return (
    <Root>
      <TopContainer>
        <InformationOverlay />
        <ViewTitle>Ilum Modules</ViewTitle>
        <Input
          value={search}
          onChange={setSearch}
          placeHolder={'Modules Search'} />
        <ModulesSubtitle>
          Extend the original application with
          addons from our partners
        </ModulesSubtitle>
      </TopContainer>
      <Line />
      <BottomContainer>
        {map(({key, value}) =>
          <Section
            expanded={!includes(key, extendedCollapsed)}
            key={key}
            id={key}
            data={value}
            onClickExpand={handleOnClickExpand}
          />, extendedData)
        }
      </BottomContainer>
    </Root>
  );
};

export default Modules;
