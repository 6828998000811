import ScheduleButtons from '../ScheduleButtons'
import {useCallback} from 'react'
import {map, pipe} from 'ramda'
import {and, fork, parallel} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import deleteSchedule from '../../../../api/workloads/schedules/deleteSchedule'
import pauseSchedule from '../../../../api/workloads/schedules/pauseSchedule'
import resumeSchedule from '../../../../api/workloads/schedules/resumeSchedule'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import disableSchedule from '../../../../api/workloads/schedules/disableSchedule'
import stopSchedule from "../../../../api/workloads/schedules/stopSchedule";
import {EMPTY_ARRAY} from "../../../../constants";

const ScheduleBatchButtons = (props) => {

  const {selected,setSelected, getFuture} = props

  const {createNotification} = useNotificationContext()
  const stopScheduleFuture = useFuture(stopSchedule)
  const deleteScheduleFuture = useFuture(deleteSchedule)
  const disableScheduleFuture = useFuture(disableSchedule)
  const pauseScheduleFuture = useFuture(pauseSchedule)

  const resumeScheduleFuture = useFuture(resumeSchedule)


  const handleOnBatchResume = useCallback(() => {
    pipe(
      map((id) =>
        resumeScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(() => {
        createNotification({
          title: 'Failed to resume Schedules',
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          message: 'Service successfully resumed',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchPause = useCallback(() => {
    pipe(
      map((id) =>
        pauseScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(() => {
        createNotification({
          message: 'Failed to pause Schedules',
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          message: 'Schedules successfully paused',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])


  const handleOnBatchDelete = useCallback((aaa) => {
    pipe(
      map((id) =>
        deleteScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(({ message }) => {
        createNotification({
          message: 'Failed to delete Schedules: ' + message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        setSelected(EMPTY_ARRAY)
        createNotification({
          message: 'Schedules successfully Schedules',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  const handleOnBatchArchive = useCallback(() => {
    pipe(
      map((id) =>
        disableScheduleFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      parallel(10),
      and(getFuture),
      fork(() => {
        createNotification({
          message: 'Failed to archive Schedules',
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        createNotification({
          message: 'Schedules successfully archived',
          autoHide: true,
          variant: 'success',
        })
      })
    )(selected)
  }, [selected, getFuture])

  return (
    <ScheduleButtons
      texts={false}
      onDelete={handleOnBatchDelete}
      onPause={handleOnBatchPause}
      onResume={handleOnBatchResume}
      onArchive={handleOnBatchArchive}
    />
  )
}

export default ScheduleBatchButtons
