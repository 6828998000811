import asyncThunk from "../../index";

export const getRequest = asyncThunk({
  method: "GET",
  type: "request/get",
  path: "/request/${id}",
  api: true,
});

export default getRequest;
