import Configuration from '../../../../../components/configuration'
import React, {useEffect, useMemo} from 'react'
import {map, pathOr, pick, pipe, prop, propOr} from 'ramda'
import StatusIndicator from '../../../../../components/StatusIndicator'
import {formatUrlParam} from '../../../helpers'
import {useSelector} from 'react-redux'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../../../../constants'
import useFuture from '../../../../../hooks/useFuture'
import getBatchRunHistory from '../../../../../api/lineage/getBatchRunHistory'
import {fork} from 'fluture'

const statusMap = {
  COMPLETED: 'success',
}

const BatchRunHistory = () => {
  const model = useSelector(prop('lineage'))
  const selectedNode = useMemo(
    () => propOr(EMPTY_OBJECT, 'selectedNode', model),
    [model]
  )

  const {name, namespace} = selectedNode

  const getBatchRunHistoryFuture = useFuture(
    getBatchRunHistory
  )

  const versions = useMemo(
    () =>
      pathOr(
        EMPTY_ARRAY,
        ['data', 'batchRunHistory', 'response', 'runs'],
        model
      ),
    [model]
  )

  useEffect(() => {
    fork((message) => {})(() => {})(
      getBatchRunHistoryFuture({
        params: [
          {
            key: 'namespace',
            value: formatUrlParam(namespace),
          },
          {
            key: 'job',
            value: formatUrlParam(name),
          },
        ],
      })
    )
  }, [namespace, name])

  const extendedData = useMemo(
    () =>
      pipe(
        map(
          pick([
            'id',
            'state',
            'createdAt',
            'startedAt',
            'endedAt',
            'duration',
          ])
        )
      )(versions),
    [versions]
  )

  return (
    <div
      style={{
        padding: 8,
        overflow: 'hidden',
        height: '100%',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
flexDirection: 'column'
      }}
    >
      <Configuration
        data={extendedData}
        schema={[
          {
            id: 'id',
            label: 'id',
            copy: true,
          },
          {
            id: 'state',
            label: 'Type',
            Component: ({value}) => (
              <div style={{display: 'flex'}}>
                <StatusIndicator
                  value={value}
                  variant={statusMap[value]}
                />
              </div>
            ),
          },
          {
            id: 'createdAt',
            label: 'Tags',
            copy: false,
          },
          {
            id: 'startedAt',
            label: 'Tags',
            copy: false,
          },
          {
            id: 'endedAt',
            label: 'Tags',
            copy: false,
          },
        ]}
      />
    </div>
  )
}

export default BatchRunHistory
