import {
  map,
  find,
  append,
  reject,
  equals,
  length,
  pipe,
  prop,
  uniq,
  add,
  times,
  join,
  sortWith,
  ascend,
  path,
  pathOr,
  intersperse,
  toLower,
  groupBy,
} from 'ramda'
import {CheckBox} from 'frontcore'
import Chip from '../Chip'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants'
import styled from 'styled-components'
import React, {useCallback, useMemo} from 'react'
import ToolTip from '../toolTip'
import ToolTipContentLayout from '../layouts/toolTip/toolTipContentLayout'
import StyledLink from '../StyledLink'
import Line from '../line'
import {convertObjectToArray, convertObjectToArrayFn, createTransitions} from '../../helpers'
import {style} from 'd3'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: ${({gap}) => gap * 8 + 'px'};
`
const FlexRow = styled.div`
  display: flex;
  gap: ${({gap}) => gap * 8 + 'px'};
  overflow: hidden;
  flex-wrap: wrap;
`

const ToolTipRoot = styled(FlexColumn)`
  max-width: 400px;
  overflow: hidden;
`

const Root = styled.div`
  display: grid;
  background: ${({theme}) => theme.palette['surface-secondary']};
  grid-template-columns: ${({$gridTemplateColumns}) => $gridTemplateColumns};
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;

  border-color: ${({theme}) => theme.palette.line};
  font-family: Roboto, serif;
  color: ${({theme}) => theme.palette.neutral[500]};
  font-size: 12px;
  overflow: hidden;

  > div {
    padding-left: 5px;
    padding-right: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    border-color: ${({theme}) => theme.palette.line};
  }
`

const CheckBoxWrapper = ({value, onChange}) => {
  const sources = pathOr([], ['sources'], value)

  const result = useMemo(
    () =>
      pipe(
        groupBy(prop('type')),
        convertObjectToArrayFn('type', 'elements'),
        sortWith([ascend(prop('type'))])
      )(sources),
    [sources]
  )

  return (
    <div>
      <ToolTip
        lockable={true}
        content={
          <ToolTipContentLayout>
            <ToolTipRoot gap={1}>
              {intersperse(
                <Line />,
                map(
                  ({type, elements = EMPTY_ARRAY}) => (
                    <FlexColumn gap={1} style={{fontSize: 12, fontFamily: 'Roboto'}}>
                      <div style={{fontWeight: 600}}>{type}</div>
                      {type === 'DIRECT' && (
                        <div style={{fontWeight: 400, textJustify: 'inter-word'}}>
                          Permission is given directly. You can remove it by editing this element.
                        </div>
                      )}
                      {type === 'GROUP' && (
                        <div style={{fontWeight: 400, textJustify: 'auto'}}>
                          Permission is provided directly by the GROUPS listed below.
                        </div>
                      )}
                      {type === 'ROLE' && (
                        <div style={{fontWeight: 400, textJustify: 'inter-word'}}>
                          Permission is provided directly by the ROLES listed below.
                        </div>
                      )}
                      <FlexRow gap={1}>
                        {type !== 'DIRECT' &&
                          map(
                            ({name, sourceId, type}) => (
                              <div>
                                <StyledLink to={`/security/details/${toLower(type)}/` + sourceId}>
                                  <Chip type={type}>{name}</Chip>
                                </StyledLink>
                              </div>
                            ),
                            elements
                          )}
                      </FlexRow>
                    </FlexColumn>
                  ),
                  result
                )
              )}
            </ToolTipRoot>
          </ToolTipContentLayout>
        }
      >
        <div>
          <CheckBox value={value} onChange={onChange} />
        </div>
      </ToolTip>
    </div>
  )
}

const PermissionsSelect = ({value = EMPTY_ARRAY, onChange, options = EMPTY_ARRAY}) => {
  const headerMap = {
    'Enable/Disable': 'Enable Disable',
    'Archive and Delete': 'Archive Delete',
    'Pause/Resume': 'Pause Resume',
  }

  const extendedOptions = pipe(sortWith([ascend(path(['resource']))]))(options)

  const titleKeys = pipe(map(prop('action')), uniq)(extendedOptions)

  const resourceKeys = pipe(map(prop('resource')), uniq)(extendedOptions)

  const gridTemplateColumns = pipe(
    length,
    add(1),
    times(() => '1fr'),
    join(' ')
  )(titleKeys)
  return (
    <Root $gridTemplateColumns={gridTemplateColumns}>
      <div></div>
      {map(
        (a) => (
          <div
            style={{
              overflow: 'hidden',
              fontWeight: 500,
              justifyContent: 'center',
              display: 'flex',
              // textJustify: 'center',
              minWidth: 48,
              textAlign: 'center',
            }}
          >
            {headerMap[a] || a}
          </div>
        ),
        titleKeys
      )}
      {map(
        (kind) => (
          <>
            <div
              style={{
                fontSize: 12,
                textAlign: 'center',
              }}
            >
              {kind}
            </div>
            {/*<div style={{fontWeight: 500, display: 'flex', justifyContent: 'center'}}>{kind}</div>*/}
            {map((title) => {
              const result = find(
                (option) => option.resource === kind && option.action === title,
                options
              )

              const singleValue = find((a) => a?.name === result?.name, value)
              const selected = !!singleValue

              const handleOnChange = (v) => {
                if (v) {
                  onChange(append(result, value))
                } else {
                  onChange(reject(({name}) => equals(name, result.name), value))
                }
              }

              return result ? (
                <div>
                  <CheckBoxWrapper onChange={onChange && handleOnChange} value={singleValue} />
                </div>
              ) : (
                <div></div>
              )
            }, titleKeys)}
          </>
        ),
        resourceKeys
      )}
    </Root>
  )
}

export default PermissionsSelect
