import {createSlice} from '@reduxjs/toolkit'
import {assoc} from 'ramda'

const initialState = {}

export const tableExplorerSlice = createSlice({
  name: 'tableExplorer',
  initialState,
  reducers: {
    init: () => {
      return initialState
    },
    setSelectedElement: (state, {payload}) => assoc('selectedElement', payload, state),
    setBreadCrumbs: (state, {payload}) => assoc('breadCrumbs', payload, state),
  },
})

export const {init, setSelectedElement,setBreadCrumbs} = tableExplorerSlice.actions

export default tableExplorerSlice.reducer
