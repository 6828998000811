import { useContext } from "react";

import {StorageContext} from "../contexts/StorageProvider/StorageProvider";

export const useStorage = () => {
  const context = useContext(StorageContext);

  if (context === undefined) {
    throw new Error(
      "useStorageContext must be used within a StorageProvider"
    );
  }

  return context;
};
