import {Outlet} from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
`

const OutletContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
`

const GlobalSearchLayout = () => {
  return (
    <Root>
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </Root>
  )
}

export default GlobalSearchLayout
