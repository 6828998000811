import List from '../../../../../components/list'

import HeaderComponent from '../../../../../components/list/headerComponent'
import ItemComponent from '../../../../../components/list/itemComponent'
import ToolTipContentComponent from '../../../../WorkloadsView/Components/ServicesList/toolTipContentComponent'
import React, {useEffect, useMemo} from 'react'
import {join, map, pipe, take} from 'ramda'
import {fork} from 'fluture'
import {EMPTY_FUNCTION} from '../../../../../constants'
import DateCell from '../../../../WorkloadsView/dateCell'
import LabelIcon from '../../../../../components/Icons/LabelIcon'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import ActivitySingleButtons from '../../../Components/ActivityButtons/ActivitySingleButtons'
import StyledLink from '../../../../../components/StyledLink'
import {Button} from "frontcore";
import Chip from "../../../../../components/Chip";

const schema = [
  {
    value: 'name',
    label: 'Name',
    size: 2,
  },
    {
        value: 'method',
        label: 'method',
        size: 1,
    },
  {
    value: 'username',
    label: 'User',

    size: 2,
    Component: ({data}) => (
        <div>
          <StyledLink to={'/security/details/user/' + data?.userId}>
            <Button
              variant={'text'}
              StartComponent={() => (
                <div
                  style={{
                    background: '#FF8B95',
                    width: 24,
                    height: 24,
                    color: 'white',
                    borderRadius: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                      textTransform: 'uppercase'
                  }}
                >
                    {take(1, data?.username || "")}
                </div>
              )}
            >{data?.username}</Button>
          </StyledLink>
        </div>
      ),
  },
  {
    value: 'timestamp',
    label: 'Timestamp',
    size: 2,
    Component: DateCell,
  },
]

const ActivitiesList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists = {},
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    selected,
    setSelected,
  } = listHandler

  const {createNotification} = useNotificationContext()

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  useEffect(() => {
    fork(({message}) =>
      createNotification({
        title: 'Failed to fetch Activities',
        message,
        variant: 'error',
        autoHide: true,
      })
    )(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const {filter} = lists

  return (
    <List
      filters={filter}
      schema={schema}
      data={extendedData}
      HeaderComponent={HeaderComponent}
      headerComponentProps={{
        sort,
        onSort: handleOnSort,
        skeleton: pending,
        iconWidth: '120px',
      }}
      selected={selected}
      onSelect={setSelected}
      onReset={handleOnReset}
      ItemComponent={ItemComponent}
      itemComponentProps={{
        toFn: ({id}) => '/security/details/activity/' + id,
        buttonsWidth: '0px',
        skeleton: pending,
        ToolTipComponent: ToolTipContentComponent,
        ButtonsComponent: ActivitySingleButtons,
        buttonsComponentProps: {
          pending,
          texts: false,
          getFuture: getFuture,
          redirect: false,
        },
        IconComponent: ({data}) =>  <Chip type={data?.method}>{data?.method}</Chip>,
        iconWidth: '120px',
        iconComponentProps: {
          type: 'activity',
        },
      }}
      pagerProps={{
        size,
        page,
        totalPages,
        onChangeSize: handleOnSizeChange,
        onChangePage: handleOnPageChange,
      }}
    />
  )
}

export default ActivitiesList
