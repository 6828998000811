import asyncThunk from "../../index";

export const getJob = asyncThunk({
  method: "GET",
  type: "job/get",
  path: "/job/${id}",
  api: true,
});

export default getJob;
