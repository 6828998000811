import asyncThunk from "../../index";

export const editService = asyncThunk({
  method: 'PUT',
  type: 'service/edit',
  path: '/group/${id}',
  api: true,
})

export default editService
