import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {createTransitions} from '../../../helpers'
import Metric from '../../Metric'

const variantError = css`
  background-color: ${({theme, $updated}) =>
    $updated
      ? theme.palette.yellow[500]
      : theme.palette.red[500]};
  color: ${({theme}) => theme.palette.neutral[0]};
`

const variantDefault = css`
  background-color: ${({theme, $updated}) =>
    $updated
      ? theme.palette.yellow[500]
      : theme.palette['surface-primary']};
  color: ${({theme}) => theme.palette.neutral[400]};
`

const variantMap = {
  default: variantDefault,
  error: variantError,
}

const sizeMap = {
  small: css`
    height: 64px;
  `,
  medium: css`
    height: 60px;
  `,
  large: css`
    height: 125px;
  `,
}

const Root = styled.div`
    width: 100%;
    transition: ${createTransitions(['background-color'])};
    ${({variant}) => variantMap[variant]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
    ${({$size}) => sizeMap[$size]}
    //box-shadow: 10px 10px 30px 0px rgba(63, 69, 85, 0.08);
    border: 1px solid black;
    border-color: ${({theme}) => theme.palette.line};
`

const TitleContainer = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
    font-family: Roboto
`

const MetricChart = ({
  name,
  value,
  unit,
  highlightChanges = true,
  variant = 'default',
  size = 'medium',
}) => {
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    setUpdated(true)
    setTimeout(() => {
      setUpdated(false)
    }, 500)
  }, [value])

  return (
    <Root
      $size={size}
      $updated={highlightChanges ? updated : false}
      variant={variant}
    >
      <TitleContainer>{name}</TitleContainer>
      <Metric value={value} unit={unit} size={size} color={variant === 'error' && 'white'}/>
    </Root>
  )
}

export default MetricChart
