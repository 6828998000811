import {Icon, Input, Select} from 'frontcore'
import {EMPTY_ARRAY} from '../../../../constants'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import FilterComplexItemComponent from '../../../../components/filters/filterComplexItemComponent'
import FilterComplexComponent from '../../../../components/filters/filterComplexComponent'
import getServicesNamesFetch from '../../../../api/workloads/services/getServicesNamesFetch'
import getSchedulesNamesFetch from '../../../../api/workloads/schedules/getSchedulesNamesFetch'

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Jobs Search',
      topLabelProps: {
        children: 'Jobs Search',
      },
    },
  },
  {
    id: 'jobTypes',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Job Types',
      value: [],
      options: ['SINGLE', 'INTERACTIVE'],
      multiSelect: true,
      buttonProps: {
        width: '200px',
      },
    },
  },
  {
    id: 'jobStates',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Job States',
      size: 'small',
      value: [],
      options: [
        'SUBMITTED',
        'STARTING',
        'READY',
        'BUSY',
        'FAILED',
        'FINISHED',
        'UNKNOWN',
      ],
      multiSelect: true,
      buttonProps: {
        width: '200px',
      },
    },
  },
  {
    id: 'clusterNames',
    Component: Select,
    width: '200px',
    defaultValue: EMPTY_ARRAY,
    optionsApi: getClustersNamesFetch,
    props: {
      label: 'Clusters',
      size: 'small',
      value: [],
      valueProp: 'name',
      multiSelect: true,
      ItemComponent: FilterComplexItemComponent,
      buttonProps: {
        width: '200px',
        Component: FilterComplexComponent,
      },
    },
  },
  {
    id: 'groupIds',
    Component: Select,
    width: 200,
    defaultValue: EMPTY_ARRAY,
    optionsApi: getServicesNamesFetch,
    props: {
      label: 'Services',
      size: 'small',
      value: [],
      valueProp: 'id',
      ItemComponent: FilterComplexItemComponent,
      multiSelect: true,
      buttonProps: {
        width: '200px',
        Component: FilterComplexComponent,
      },
    },
  },
  {
    id: 'scheduleIds',
    Component: Select,
    width: 200,
    defaultValue: EMPTY_ARRAY,
    optionsApi: getSchedulesNamesFetch,
    props: {
      label: 'Schedules',
      size: 'small',
      value: [],
      valueProp: 'id',
      ItemComponent: FilterComplexItemComponent,
      multiSelect: true,
      buttonProps: {
        width: '200px',
        Component: FilterComplexComponent,
      },
    },
  },
]

export default schema;