import {Button, Icon} from 'frontcore'
import {memo, useCallback} from 'react'
import styled from 'styled-components'
import {EMPTY_FUNCTION} from '../../../constants'
import ToolTip from '../../../components/toolTip'
import {useSelector} from 'react-redux'
import {path} from 'ramda'

const Root = styled.div`
  position: absolute;
  right: 8px;
  bottom: -8px;
  border: 1px solid ${({theme}) => theme.palette['border-tooltip']};
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.palette['surface-primary']};
`

export const IconButton = ({icon, description, pending = false, onClick = EMPTY_FUNCTION}) => {
  return (
    <ToolTip
      config={{position: 'left_bottom'}}
      content={<div style={{padding: 8}}>{description}</div>}
    >
      <Button
        onClick={onClick}
        variant="text"
        size="small"
        square
        Component={Icon}
        componentProps={{
          size: 12,
          icon,
        }}
        skeleton={pending}
        justifyContent="center"
      />
    </ToolTip>
  )
}

const NotebookActions = ({statement, state, actions}) => {
  const handleDeleteStatement = useCallback(() => {
    actions.deleteStatement(statement.id)
  }, [statement.id, actions.deleteStatement])

  const handleRunStatement = useCallback(() => {
    actions.runStatement(statement.id)
  }, [statement.id, actions.runStatement])

  const handleRunBeforeStatement = useCallback(() => {
    actions.runBeforeStatement(statement.id)
  }, [statement.id, actions.runBeforeStatement])

  const handleRunAfterStatement = useCallback(() => {
    actions.runAfterStatement(statement.id)
  }, [statement.id, actions.runAfterStatement])

  const queryPending = useSelector(path(['sqlViewer', 'notebook', 'queryPending']))
  const pending = queryPending[statement.id]

  return (
    <Root onClick={(event) => event.stopPropagation()}>
      {statement.language !== 'markdown' && (
        <IconButton
          pending={pending}
          icon="play"
          description="Execute this cell"
          onClick={handleRunStatement}
        />
      )}
      <IconButton
        pending={pending}
        icon="trash"
        description="Delete this cell"
        onClick={handleDeleteStatement}
      />
      {statement.language !== 'markdown' && (
        <IconButton
          pending={pending}
          icon="arrow-top"
          description="Execute cells before"
          onClick={handleRunBeforeStatement}
        />
      )}
      {statement.language !== 'markdown' && (
        <IconButton
          pending={pending}
          icon="arrow-down"
          description="Execute cells after"
          onClick={handleRunAfterStatement}
        />
      )}
      <IconButton
        pending={pending}
        icon="gear"
        description={`Change cell type to ${statement.language === 'markdown' ? 'SQL' : 'Markdown'}`}
        onClick={() => actions.flipLanguage(statement.id)}
      />
      <IconButton
        pending={false}
        icon="plus"
        description="Duplicates the chosen cell, creating a cell with the same content"
        onClick={() => actions.duplicateCell(statement.id)}
      />
    </Root>
  )
}

export default memo(NotebookActions)
