import asyncThunk from "./index";

export const getLogs = asyncThunk({
  method: "GET",
  type: "logs/get",
  path: "/job/${jobId}/resources/${resourceName}/log",
  api: true,
});

export default getLogs;
