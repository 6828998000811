import asyncThunk from "../../index";

export const getSchedules = asyncThunk({
  method: 'GET',
  type: 'schedules/get',
  path: '/schedule',
  api: true,
})

export default getSchedules
