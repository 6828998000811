import {memo} from 'react'
import styled from 'styled-components'
import {Button, Icon} from 'frontcore'
import NotebookCell from './NotebookCell'
import MarkdownCell from './MarkdownCell'

const Root = styled.div`
  flex: 1;
  position: relative;
  background-color: ${({theme}) => theme.palette['surface-primary']};
`

const InnerContainer = styled.div`
  position: absolute;
  inset: 0;
  overflow-y: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ListContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`

const EmptyModel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 64px;
  border: 1px solid ${({theme}) => theme.palette['border-tooltip']};
  color: ${({theme}) => theme.palette.text.tertiary};
  background-color: ${({theme}) => theme.palette['surface-primary']};
  h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
`

const FreeSpace = styled.div`
  max: 100%;
  min-height: 560px;
`

const NotebookEditor = ({state, actions}) => {
  return (
    <Root>
      <InnerContainer>
        {state.statements.length <= 0 && (
          <ListContainer>
            <EmptyModel>
              <h2>Empty notebook</h2>
              <p>Create your first cell and start exploring the data!</p>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, }}>
                <Button onClick={actions.appendStatement} color="success">
                  New Cell
                </Button>
                <Button onClick={actions.appendMarkdown} color="primary">
                  New Markdown
                </Button>
              </div>
            </EmptyModel>
          </ListContainer>
        )}
        {state.statements.length > 0 && (
          <ListContainer>
            {state.statements.map((statement, index) =>
              statement.language === 'markdown' ? (
                <MarkdownCell
                  key={statement.id ?? index}
                  statement={statement}
                  state={state}
                  actions={actions}
                />
              ) : (
                <NotebookCell
                  key={statement.id ?? index}
                  statement={statement}
                  state={state}
                  actions={actions}
                />
              )
            )}
            <div
              style={{
                position: 'relative',
                zIndex: 1002,
                  display: 'flex',
                  gap: 8,
              }}
            >
              <Button
                StartComponent={Icon}
                startComponentProps={{
                  size: 16,
                  icon: 'plus',
                }}
                onClick={actions.appendStatement}
                size="small"
                variant="outlined"
              >
                New Cell
              </Button>
              <Button
                StartComponent={Icon}
                startComponentProps={{
                  size: 16,
                  icon: 'plus',
                }}
                onClick={actions.appendMarkdown}
                size="small"
                variant="outlined"
              >New Markdown</Button>
            </div>
            <FreeSpace />
          </ListContainer>
        )}
      </InnerContainer>
    </Root>
  )
}

export default memo(NotebookEditor)
