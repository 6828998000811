import {memo, useEffect, useMemo} from 'react'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import {EMPTY_ARRAY, EMPTY_FUNCTION} from '../../constants'
import useFuture from '../../hooks/useFuture'
import getMetaStores from '../../api/table/getMetaStores'
import {useSelector} from 'react-redux'
import {isEmpty, pathOr} from 'ramda'
import {flattenTree} from './helpers'
import {VirtualizedList} from 'frontcore'
import ListItem from './ListItem'

const MetastoreBrowser = () => {
  const {createNotification} = useNotificationContext()

  const tree = useSelector(
    pathOr(EMPTY_ARRAY, ['data', 'tree'])
  )
  const flattenedTree = useMemo(() => {
    return flattenTree(tree)
  }, [tree])

  const getMetastoreFuture = useFuture(getMetaStores)
  useEffect(() => {
    if (!isEmpty(flattenedTree)) return
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to fetch metastore information',
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(getMetastoreFuture())
  }, [])

  return (
    <VirtualizedList
      items={flattenedTree}
      itemHeight={32}
      gap={0}
      ItemComponent={ListItem}
    />
  )
}

export default memo(MetastoreBrowser)
