import {createSlice} from '@reduxjs/toolkit'
import {EMPTY_STRING} from '../../constants'
import {extraReducersMapper} from '../../api'
import executeSQLStatement from '../../api/sql/executeSQLStatement'
import getNamespaces from '../../api/lineage/getNamespaces'
import getDatasets from '../../api/lineage/getDatasets'
import getJobs from '../../api/lineage/getJobs'
import getTable from '../../api/lineage/getTable'
import getColumn from '../../api/lineage/getColumn'
import getDataset from '../../api/lineage/getDataset'
import {assoc} from 'ramda'
import getDatasetVersions from '../../api/lineage/getDatasetVersions'
import getBatchRunHistory from '../../api/lineage/getBatchRunHistory'
import getSearch from '../../api/lineage/getSearch'

const initialState = {}

const lineageSlice = createSlice({
  name: 'lineage',
  initialState,
  reducers: {
    setNamespace: (state, {payload}) =>  assoc('namespace', payload, state),
    setSelectedNode: (state, {payload}) =>
      assoc('selectedNode', payload, state),
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getNamespaces,
      name: 'namespaces',
    },
    {
      asyncThunk: getDatasets,
      name: 'datasets',
    },
    {
      asyncThunk: getDataset,
      name: 'dataset',
    },
    {
      asyncThunk: getJobs,
      name: 'jobs',
    },
    {
      asyncThunk: getTable,
      name: 'table',
    },
    {
      asyncThunk: getSearch,
      name: 'search',
    },
    {
      asyncThunk: getColumn,
      name: 'column',
    },
    {
      asyncThunk: getDatasetVersions,
      name: 'datasetVersions',
    },
    {
      asyncThunk: getBatchRunHistory,
      name: 'batchRunHistory',
    },
  ]),
})

export default lineageSlice.reducer
export const {setSelectedNode, setNamespace} =
  lineageSlice.actions
