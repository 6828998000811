import { Input } from "frontcore";
import { path } from "ramda";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../reducers/fileExplorer";
import styled from "styled-components";

const InMemorySearch = () => {
  const search = useSelector(path(["fileExplorer", "search"]));

  const dispatch = useDispatch();
  const handleChange = useCallback((value) => {
    dispatch(setSearch(value));
  }, []);

  return (
      <Input
        value={search}
        onChange={handleChange}
        placeHolder="Search by name..."
      />
  );
};

export default InMemorySearch;

