import { createSlice } from "@reduxjs/toolkit";
import { loadSettingsState } from "./persist";

const settingsSlice = createSlice({
  name: "settings",
  initialState: loadSettingsState(),
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setSettings: (state, {payload}) => {
      state.settings = payload
    }
  },
});

export default settingsSlice.reducer;
export const {
  setSearch,
  setSettings,
} = settingsSlice.actions;
