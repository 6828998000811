function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return {value: 0, unit: 'B'}
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
const a = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))

  return {value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i]}
}

export default formatBytes;