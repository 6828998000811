import {useCallback} from 'react';
import {Input} from 'frontcore';


const Column = ({value,label, name, skeleton, onChange}) => {

  const handleOnChange = useCallback((value) => {
    onChange({value,name})
  }, [name,onChange]);

  return <Input
    fullWidth
    skeleton={skeleton}
    value={value}
    label={label}
    onChange={handleOnChange}
  />;
};

export default Column;