import ListLayout from '../../../../../components/layouts/listLayout';
import Filters from '../../../filters';
import schema from './schema';
import RequestsList from '../../../Components/RequestsList';
import React from 'react';
import usePending from '../../../../../hooks/usePending';
import {useListRefreshFuture} from '../../../hooks';
import getRequests from '../../../../../api/workloads/requests/getRequests';
import {EMPTY_OBJECT} from '../../../../../constants';

const ServiceDetailsRequestsList = ({serviceId}) => {

  const listHandler = useListRefreshFuture(
      getRequests,
    'workloads',
    'requests',
    {
      page: 0,
        size: 25,
      sort: {
        startTime: 'desc',
      },
      filter: EMPTY_OBJECT,
    },
    {groupIds: [serviceId]},
  );

  const pending = usePending(['workloads', 'data'],[
    'requestsDetails',
    'scaleService',
    'archiveService',
    'deleteService',
  ]);

  return  <ListLayout
    filtersNode={<Filters
      name={'requestsDetails'}
      schema={schema}
      pending={pending}
    />}
    contentNode={<RequestsList
      listHandler={listHandler}
      pending={pending}
    />}
  />
};

export default ServiceDetailsRequestsList;