import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  any,
  assoc,
  complement,
  find,
  fromPairs,
  isEmpty,
  isNil,
  lensPath,
  map,
  mergeAll,
  mergeLeft,
  pipe,
  propOr,
  reject,
  set,
  toPairs,
  values,
} from 'ramda'
import {isNotNull, mapWithKey, notEquals} from '../../helpers'
import styled from 'styled-components'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants'

const Root = styled.form`
  width: 100%;
  height: 100%;
`

const Form = ({
  autoComplete = 'on',
  schema,
  onSubmit,
  LayoutComponent,
  layoutComponentProps = EMPTY_OBJECT,
  initialData = EMPTY_OBJECT,
  options,
}) => {
  const [fields, setFields] = useState(EMPTY_OBJECT)

  const [errors, setErrors] = useState(EMPTY_OBJECT)

  const [touched, setTouched] = useState(EMPTY_OBJECT)

  const extendedSchema = useMemo(() => {
    return schema(fields, options)
  }, [fields, options, schema])

  const extendedFieldsSchema = useMemo(() => propOr(EMPTY_ARRAY, 'fields', extendedSchema), [extendedSchema])

  const extendedButtonsSchema = useMemo(
    () => propOr(EMPTY_ARRAY, 'buttons', extendedSchema),
    [extendedSchema]
  )

  const handleSetErrors = useCallback((nextValue) => {
    setErrors((previousValue) => (notEquals(previousValue, nextValue) ? nextValue : previousValue))
  }, [])

  const handleSetTouched = useCallback((nextValue) => {
    setTouched((previousValue) => (notEquals(previousValue, nextValue) ? nextValue : previousValue))
  }, [])

    const mergedFields = mergeLeft(fields,initialData)

  const tempFields = useMemo(
    () =>
      pipe(
        map(({id, defaultValue}) => [id, isNotNull(mergedFields[id]) ? mergedFields[id] : defaultValue]),
        fromPairs
      )(extendedFieldsSchema),
    [extendedFieldsSchema, mergedFields]
  )

  const handleOnChange = useCallback(
    async ({id, value}) => {
      let result = assoc(id, value, fields)
      const {validators = [], onChangeFn} = find((a) => a.id === id)(extendedSchema.fields)
      const errorsResult = pipe(
        map((validator) => validator(value)),
        reject(isEmpty),
        reject(isNil)
      )(validators)
      const newErrors = assoc(id, errorsResult, errors)
      handleSetErrors(newErrors)
      const newTouched = assoc(id, true, touched)
      handleSetTouched(newTouched)
      if (onChangeFn) {
        result = await onChangeFn(id, value, result)
        setFields(result)
      } else {
        setFields(result)
      }
    },
    [fields, setFields, touched, errors, handleSetErrors, handleSetTouched]
  )

  const extendedFields = pipe(
    map((field) => {
      const {id} = field
      const valueLens = lensPath(['componentProps', 'value'])
      const onChangeLens = lensPath(['componentProps', 'onChange'])
      const errorLens = lensPath(['componentProps', 'errors'])

      const onChange = async (value) => {
        await handleOnChange({id, value})
      }

      const value = tempFields[id]
      const error = touched[id] ? errors[id] : []

      return pipe(
        set(valueLens, value),
        set(errorLens, error),
        set(onChangeLens, onChange),
        (props) => ({
          ...props,
          element: <props.Component {...props.componentProps} name={'form'} />,
        })
      )(field)
    })
  )(extendedFieldsSchema)

  const extendedButtons = useMemo(
    () =>
      mapWithKey(
        ({Component, componentProps}, index) => <Component key={index} {...componentProps} />,
        extendedButtonsSchema
      ),
    [extendedButtonsSchema]
  )

  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      const result = map(({validators = [], id}) => {
        const value = tempFields[id]
        const errors = pipe(
          map((validator) => validator(value)),
          reject(isEmpty)
        )(validators)
        return {id, errors}
      }, extendedFieldsSchema)

      const newErrors = pipe(
        map(({id, errors}) => ({[id]: errors})),
        mergeAll
      )(result)
      handleSetErrors(newErrors)
      const newTouched = pipe(
        map(({id}) => ({[id]: true})),
        mergeAll
      )(extendedFieldsSchema)
      handleSetTouched(newTouched)
      const hasErrors = pipe(values, any(complement(isEmpty)))(newErrors)

      if (!hasErrors) {
        const result = pipe(
          toPairs,
          map(([key, value]) => {
            const {onSubmitFn = (a) => a} = find(({id}) => id === key, extendedFields)
            return {[key]: onSubmitFn(value)}
          }),
          mergeAll
        )(tempFields)

        onSubmit(result)
      }
    },
    [extendedSchema, touched, onSubmit, tempFields, extendedFields]
  )

  return (
    <Root onSubmit={handleOnSubmit} autoComplete={autoComplete}>
      <LayoutComponent {...layoutComponentProps} fields={extendedFields} buttons={extendedButtons} />
    </Root>
  )
}

export default Form
