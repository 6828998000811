import asyncThunk from "../../index";

export const getSchedule = asyncThunk({
  method: 'GET',
  type: 'schedule/get',
  path: '/schedule/${id}',
  api: true,
})

export default getSchedule
