import { path } from 'ramda'
import {memo, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {Button} from 'frontcore'
import {format as sqlFormat} from 'sql-formatter'
import {editorToggleLinter, editorToggleVimMode} from '../../../reducers/sql/editor'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {sidebarMutateQuery} from '../../../reducers/sql/sidebar'
import ToolTip from '../../../components/toolTip'

const Root = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 10px;
  right: 40px;
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 4px;
  }
`

const EditorControls = () => {
  const { createNotification } = useNotificationContext()
  const dispatch = useDispatch()

  const vimEnabled = useSelector(path(['sqlViewer', 'editor', 'vimEnabled']))
  const handleToggleVim = useCallback(() => {
    dispatch(editorToggleVimMode())
  }, [])

  const linterEnabled = useSelector(path(['sqlViewer', 'editor', 'linterEnabled']))
  const handleToggleLinter = useCallback(() => {
    dispatch(editorToggleLinter())
  }, [])

  const queryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryCode = useMemo(() => {
    const query = queries.find(({ id }) => {
      return id === queryId
    })
    return query?.statement
  }, [queryId, queries])

  const handleFormat = useCallback(() => {
    try {
      const formatted = sqlFormat(queryCode, {
        language: 'sql',
        tabWidth: 2,
        keywordCase: 'preserve',
        linesBetweenQueries: 1,
      })
      dispatch(sidebarMutateQuery({
        id: queryId,
        statement: formatted,
      }))
    } catch {
      createNotification({
        title: 'Formatter Error',
        message: 'Please, try again',
        variant: 'error',
        autoHide: true,
      })
    }
  }, [queryId, queryCode])

  return (
    <Root>
      <Button 
        onClick={handleFormat}
        variant='outlined'
      >Format</Button>
      <ToolTip config={{ position: 'top_right' }} content={(
        <div style={{ padding: 8 }}>
          <b>Warning:</b> linter is currently
          showing errors for any non-standard
          SQL syntax
        </div>
      )}>
        <Button 
          onClick={handleToggleLinter}
          variant={linterEnabled ? 'contained' : 'text'}
    // color={linterEnabled ? 'primary' : 'secondary'}
        >Linter: {linterEnabled ? 'ON' : 'OFF'}</Button>
      </ToolTip>
      <Button
        onClick={handleToggleVim}
        variant={vimEnabled ? 'contained' : 'text'}
    // color={vimEnabled ? 'primary' : 'secondary'}
      >
        VIM
      </Button>
    </Root>
  )
}

export default memo(EditorControls)
