import {useNavigate, useParams} from 'react-router-dom'
import FormLayout2 from '../../../../components/tabsFormLayout'
import React, {useCallback, useEffect, useMemo} from 'react'
import Form from '../../../../components/Form'
import schema from './schema'
import useFuture from '../../../../hooks/useFuture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import useFetch from '../../../../hooks/useFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import getPermissionsFetch from '../../../../api/security/getPermissionsFetch'
import {__, assoc, evolve, filter, find, isNil, map, or, path, pipe, prop, reject} from 'ramda'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {useDispatch, useSelector} from 'react-redux'
import getRole from '../../../../api/security/getRole'
import {EMPTY_ARRAY} from '../../../../constants'
import {editRole} from '../../../../api/security/editRole'
import SecurityFormLayout from '../../../../components/SecurityFormLayout'

const SecurityEditRoleView = () => {
  const navigate = useNavigate()

  const editRoleFuture = useFuture(editRole)

  const {createNotification} = useNotificationContext()

  const {data: permissions} = useFetch(getPermissionsFetch, singleCallFetchOptions)

  const getRoleFuture = useFuture(getRole)

  const {roleId} = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (roleId) {
      fork(({message}) =>
        createNotification({
          title: 'Failed to fetch Role',
          message,
          autoHide: false,
          variant: 'error',
        })
      )(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getRoleFuture({
          params: [{key: 'id', value: roleId}],
        })
      )
    }
  }, [roleId])

  const handleOnSubmit = useCallback(
    (fields) => {
      const result = pipe(
        evolve({
          permissions: pipe(or(__, []), map(prop('name'))),
        }),
        assoc('state', 'ENABLED')
      )(fields)
      result.params = [{key: 'id', value: roleId}]
      fork(({message}) => {
        createNotification({
          title: 'Failed to edit Role',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        navigate(-1)
        createNotification({
          title: 'Role edited successfully',
          autoHide: true,
          variant: 'success',
        })
      })(editRoleFuture(result))
    },
    [roleId]
  )

  const data = useSelector(path(['security', 'data', 'role', 'response']))

  const extendedData = useMemo(
    () =>
      pipe(
        evolve({
          permissions: filter(({sources}) => {
            return find(({type}) => type === 'DIRECT', sources)
          }),
        }),
        evolve({
          permissions: map((permission) => {
            const array = permissions || EMPTY_ARRAY
            const result = find(({name}) => name === permission.name, array)
            return {...result, ...permission}
          }),
          state: (value) => value === 'ENABLED',
        })
      )(data),
    [data, permissions]
  )

  return (
    <Form
      autocomplete={'off'}
      initialData={extendedData}
      options={{
        pending: false,
        navigate,
        permissions,
      }}
      LayoutComponent={SecurityFormLayout}
      layoutComponentProps={{
        title: 'EDIT ROLE',
      }}
      schema={schema}
      onSubmit={handleOnSubmit}
    />
  )
}

export default SecurityEditRoleView
