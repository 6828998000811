import {ILUM_DATA_PATH} from "../../helpers/runtimeEnv";
import {useHistoryContext} from "../../hooks/useHistoryContext";
import {useEffect} from "react";

const MinioDataView = () => {

    const {addHistory} = useHistoryContext()

    useEffect(() => {
        addHistory({type: 'module', id: 'Minio', name: 'Minio'})
    }, [addHistory])

    return (

        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_DATA_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    );
};

MinioDataView.dispayName = "MinioDataView";

export default MinioDataView;
