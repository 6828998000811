import asyncThunk from "../../index";

export const editSchedule = asyncThunk({
  method: 'PUT',
  type: 'schedule/edit',
  path: '/schedule/${id}',
  api: true
})

export default editSchedule
