import React from 'react'
import {Button, Icon} from 'frontcore'
import {DescriptionText} from '../typography'
import ToolTip from '../toolTip'

const FallbackRenderer = ({error, resetErrorBoundary}) => {
  return (
    <div
      style={{
        background: '#ffc8c8',

        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <DescriptionText>Error Boundary: There are some errors.</DescriptionText>

      <ToolTip content={<div style={{padding: 10}}>{error.name}: {error.message}</div>}>
        <div>
          <Icon icon={'question'} size={24} color={'gray'}/>
        </div>
      </ToolTip>
      <Button onClick={() => resetErrorBoundary()}>Reset</Button>
      <Button onClick={() => resetErrorBoundary()}>Copy Error To Clipboard</Button>
    </div>
  )
}

export default FallbackRenderer
