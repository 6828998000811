import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {isNil, path} from 'ramda'
import EditorArea from './EditorArea'
import EditorBottomControls from './EditorBottomControls'
import QueryTabs from '../QueryTabs'
import {useMemo} from 'react'

const Root = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;

  & .cm-editor {
    outline: none !important;
  }

  display: flex;
  flex-direction: column;
`

const EditorContainer = styled.div`
  flex: 1;
  min-width: 0;
  min-height: 0;
  position: relative;
`

const NoQuerySelected = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  user-select: none;
`

const Editor = () => {
  const tab = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))

  const tabExists = useMemo(() => {
    return queries.some(({ id }) => {
      return id === tab
    })
  }, [tab, queries])

  if (!tabExists) {
    return (
      <Root>
        <NoQuerySelected>
          Please, select an existing query or create a new
          one
        </NoQuerySelected>
      </Root>
    )
  }

  return (
    <Root>
      <QueryTabs />
      <EditorContainer>
        <EditorBottomControls />
        <EditorArea />
      </EditorContainer>
    </Root>
  )
}

export default Editor
