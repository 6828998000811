import {memo, useEffect, useRef, useState} from 'react'
import markdownit from 'markdown-it'
import styled from 'styled-components'

import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'
import {full as emoji} from 'markdown-it-emoji'
import sup from 'markdown-it-sup'
import sub from 'markdown-it-sub'
import mark from 'markdown-it-mark'
import ins from 'markdown-it-ins'
import footnote from 'markdown-it-footnote'
import abbr from 'markdown-it-abbr'

const MarkdownRoot = styled.div`
  padding-right: 16px;

  pre {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  img {
    max-width: 300px;
    height: auto;
  }
`

const MarkdownRenderer = ({children}) => {
  const parser = useRef(
    markdownit({
      html: true,
      linkify: true,
      typographer: true,
      langPrefix: 'language-',
      highlight: (str, lang) => {
        try {
          if (lang.length > 0 && hljs.getLanguage(lang)) {
            const highlighted = hljs.highlight(str, {
              language: lang,
              ignoreIllegals: true,
            }).value
            return highlighted
          }
          /*
          // TODO: maybe the feature of auto-highlighting 
          // TODO: is not that great after all
          const autoHighlighted = hljs.highlightAuto(str).value
          return autoHighlighted
          */
          return str
        } catch {
          return str
        }
      },
    })
      .use(emoji)
      .use(sup)
      .use(sub)
      .use(mark)
      .use(ins)
      .use(footnote)
      .use(abbr)
  )

  const [markdown, setMarkdown] = useState('')
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setPending(true)
    ;(async () => {
      const outputMarkdown = parser.current.render(children)
      setMarkdown(outputMarkdown)
      setPending(false)
    })()
  }, [children])

  return (
    <MarkdownRoot
      dangerouslySetInnerHTML={{
        __html: pending ? 'Wait a second...' : markdown,
      }}
    />
  )
}

export default memo(MarkdownRenderer)
