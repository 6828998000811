import React, {useCallback} from 'react'
import {LetterMiniature} from '../../../components/miniatures'
import ToolTip from '../../../components/toolTip'
import RButton from '../../../components/RButton'
import ToolTipContent from './ToolTipContent'

const iconMap = {
  root: {
    name: 'ROOT',
    type: 'ROOT',
  },
  cluster: {
    IconComponent: LetterMiniature,
    iconComponentProps: {type: 'cluster'},
  },
  storage: {
    icon: 'server',
  },
  bucket: {
    icon: 'bucket',
  },
  folder: {
    icon: 'directory',
  },
  file: {
    icon: 'project',
  },
}
const ListItem = (props) => {
  const {type, name, onClick} = props

  const aaa = iconMap[type]
  const handleOnClick = useCallback(
    (value) => {
      if (type !== 'file') {
        onClick(props)
      }
    },
    [type]
  )

  return (
    <ToolTip content={<ToolTipContent item={props} />}>
      <div>
        <RButton {...aaa} name={name} type={type} onClick={handleOnClick} />
      </div>
    </ToolTip>
  )
}

export default ListItem
