import {ILUM_AIRFLOW_PATH} from '../../helpers/runtimeEnv'
import {useEffect} from 'react'
import {useHistoryContext} from '../../hooks/useHistoryContext'

const AirflowView = () => {
  const {addHistory} = useHistoryContext()

  useEffect(() => {
    addHistory({type: 'module', id: 'Airflow', name: 'Airflow'})
  }, [addHistory])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <iframe src={ILUM_AIRFLOW_PATH} style={{height: '100%', width: '100%', border: 'none'}} />
    </div>
  )
}

AirflowView.dispayName = 'AirflowView'

export default AirflowView
