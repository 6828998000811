import asyncThunk from "..";

const getSQLEngines = asyncThunk({
  method: 'GET',
  type: 'sql/engines/get',
  path: '/sql/engine/${clusterId}',
  api: true,
})

export default getSQLEngines
