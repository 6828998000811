import {useMemo} from 'react'
import {filter, includes, isEmpty, map, pipe, reject, toLower} from 'ramda'
import {EMPTY_STRING} from '../../../constants'
import useFetch from '../../../hooks/useFetch'
import getElementsFetch from '../../../api/getElementsFetch'
import getSecuritySearchFetch from '../../../api/security/getSecuritySearchFetch'
import {singleCallFetchOptions} from '../../../api/helpers'
import useDebounce from '../../../hooks/useDebounce'
import getSQLStatementsFetch from '../../../api/sql/getSQLStatementsFetch'

const typeMap = {
  cluster: 'cluster',
  group: 'service',
  schedule: 'schedule',
  job: 'job',
  request: 'request',
}

const securityTypeMap = {
  userrole: 'role',
  usergroup: 'group',
  user: 'user',
  activity: 'activity',
}

const modules = [
  {name: 'Jupyter', url: '/notebook'},
  {name: 'Minio', url: '/minio'},
  {name: 'Grafana', url: '/grafana'},
  {name: 'Airflow', url: '/airflow'},
  {name: 'Gitea', url: '/gitea'},
  {name: 'MLFlow', url: '/mlflow'},
  {name: 'Superset', url: '/superset'},
]

export const useSearchResults = (search) => {
  const debouncedSearch = useDebounce(search, 500)

  const {data: workloadsData = [], isLoading: workloadsPending} = useFetch(
    getElementsFetch,
    singleCallFetchOptions,
    {
      search: debouncedSearch,
    }
  )

  const {
    data: securityData = [],
    isLoading: securityPending,
  } = useFetch(getSecuritySearchFetch, singleCallFetchOptions, {
    search: debouncedSearch,
  })

  const {data: sqlData = [], isLoading: sqlPending} = useFetch(
    getSQLStatementsFetch,
    singleCallFetchOptions,
    {
      search: debouncedSearch,
    }
  )

  const pending = useMemo(() => {
    return sqlPending || securityPending || workloadsPending
  }, [sqlPending, securityPending, workloadsPending])

  const extendedWorkloadsData = useMemo(() => {
    return map((data) => {
      const type = typeMap[data?.type]
      const id = data?.id
      const name = type === 'cluster' ? '/' + data?.name : EMPTY_STRING
      return {data: {...data, type}, url: `workloads/details/${type}/${id}` + name}
    }, workloadsData)
  }, [workloadsData])

  const extendedSecurityData = useMemo(() => {
    return map((data) => {
      const type = securityTypeMap[data?.type] || data?.type
      const id = data?.id
      const name = data?.name
      return {
        data: {
          type,
          name,
        },
        url: `security/details/${type}/${id}`,
      }
    }, securityData)
  }, [securityData])

  const extendedSqlData = useMemo(() => {
    return map(
      ({name, id, engine, maxRows, statement}) => ({
        data: {
          type: 'sql',
          name,
          engine,
          maxRows,
          statement,
        },
        url: `/sql/${id}`,
      }),
      sqlData?.content || []
    )
  }, [sqlData])

  const extendedModules = useMemo(
    () =>
      pipe(
        filter(({name}) => includes(toLower(debouncedSearch), toLower(name))),
        map(({name, url}) => ({data: {name, type: 'module'}, url}))
      )(modules),
    [debouncedSearch]
  )

  return {
    data: [
      ...extendedWorkloadsData,
      ...extendedSecurityData,
      ...extendedSqlData,
      ...extendedModules,
    ],
    pending,
  }
}
