import React, {memo} from 'react'
import {Handle, Position} from '@xyflow/react'
import {ResourceButton} from 'frontcore'
import '@xyflow/react/dist/style.css'
import './index.css'
import {Root} from './styles'
import {isNotEmpty} from '../../../helpers'

const FieldNode = (props) => {

  const {data, selected} = props

  return (
    <Root className={'nodrag'} $selected={selected}>
      <ResourceButton
        color={'#000000'}
        size={'large'}
        label={'Field'}
        StartComponent={() => <div>{data?.name}</div>}
        fullWidth
      />
      {isNotEmpty(data?.inEdges) && (<Handle
        type="target"
        position={Position.Left}
        style={{background: '#555', border: 'none'}}
        isConnectable={false}
      />)}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: '#555', border: 'none'
        }}
        isConnectable={false}
      />
    </Root>
  )
}

export default memo(FieldNode)
