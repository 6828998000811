import {Icon, Input, Select} from 'frontcore';
import {EMPTY_ARRAY} from '../../../../../constants';

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Groups Search',
      topLabelProps: {
        children: 'Groups Search',
      },
    },
  },
  {
    id: 'groupStates',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Group States',
      value: [],
      options: ['ACTIVE', 'ARCHIVED'],
      multiSelect: true,
      buttonProps: {
        width: '200px',
        justifyContent: 'space-between',
      },
    },
  },
  {
    id: 'groupKinds',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Group Kinds',
      value: [],
      options: ['JOB', 'CODE'],
      multiSelect: true,
      buttonProps: {
        width: '200px',
        justifyContent: 'space-between',
      },
    },
  },
];

export default schema;