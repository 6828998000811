import asyncThunk from "..";

export const createSQLStatement = asyncThunk({
  method: 'POST',
  type: 'sql/statement/create',
  path: '/sql/statement',
  api: true,
})

export default createSQLStatement
