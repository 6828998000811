import {EMPTY_ARRAY} from '../../constants'
import React, {useCallback, useState} from 'react'
import {Button, Icon, Input, Portal} from 'frontcore'
import Form from '../Form'
import FileUpload from '../fileUpload'
import {any, append, assoc, equals, evolve, isEmpty, isNil, map, propOr, reject, remove} from 'ramda'
import styled from 'styled-components'
import {isNotNull, mapWithKey, onSubmitParametersFn} from '../../helpers'
import RadioButtons from '../RadioButtons'
import requiredValidator from '../../validators/requiredValidator'
import SecurityFormLayout from "../SecurityFormLayout";
import Parameters from "../parameters";

const description = 'Default Description'

const formSchema = (data, options) => {
  const {pending, onCancel} = options

  const type  = propOr('gcs', 'type', data)

  const s3 =
    type === 's3'
      ? [
        {
          section: 'S3',
          id: 'endpoint',
          name: 'Endpoint',
          description: 'AWS S3 endpoint to connect to',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
            autoFocus: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'sparkBucket',
          name: 'Spark Bucket',
          description: 'Name of the bucket for storing spark resources',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'dataBucket',
          name: 'Data Bucket',
          description: 'Name of the bucket for storing ilum tables',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },


        {
          section: 'S3',
          id: 'accessKey',
          name: 'Access Key',
          description: 'AWS access key',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'S3',
          id: 'secretKey',
          name: 'Secret Key',
          description: 'AWS secret key',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
      ]
      : []

  const gcs =
    type === 'gcs'
      ? [
        {
          section: 'GCS',
          id: 'clientEmail',
          name: 'Client Email',
          description: 'Google cloud account email',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
            autoFocus: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'sparkBucket',
          name: 'Spark Bucket',
          description: 'Name of the bucket for storing spark resources',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'dataBucket',
          name: 'Data Bucket',
          description: 'Name of the bucket for storing ilum tables',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'GCS',
          id: 'privateKey',
          name: 'Private Key',
          description: 'Google cloud account private key',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'GCS',
          id: 'privateKeyId',
          name: 'Private Key Id',
          description: 'Google cloud account private key id',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
      ]
      : []

  const wasbs =
    type === 'wasbs'
      ? [
        {
          section: 'WASBS',
          id: 'accountName',
          name: 'Account Name',
          description: 'Azure account name',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
            autoFocus: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'sparkBucket',
          name: 'Spark Bucket',
          description: 'Name of the bucket for storing spark resources',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'dataBucket',
          name: 'Data Bucket',
          description: 'Name of the bucket for storing ilum tables',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'WASBS',
          id: 'accessKey',
          name: 'Access Key',
          description: 'Azure account access key',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
      ]
      : []

  const hdfs =
    type === 'hdfs'
      ? [
        {
          section: 'General',
          id: 'sparkBucket',
          name: 'Spark Bucket',
          description: 'Name of the bucket for storing spark resources',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
            autoFocus: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'General',
          id: 'dataBucket',
          name: 'Data Bucket',
          description: 'Name of the bucket for storing ilum tables',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'HDFS',
          id: 'hadoopUsername',
          name: 'Hadoop Username',
          description: 'Hadoop user who will perform spark jobs',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'HDFS',
          id: 'configurationFiles',
          name: 'Configuration Files',
          description: 'Xml hdfs configuration files',
          Component: FileUpload,
          componentProps: {
            skeleton: pending,
            label: 'Configuration Files',
            fullWidth: true,
            toBase64: true,
          },
          validators: [requiredValidator],
        },
        {
          section: 'HDFS',
          id: 'principal',
          name: 'Principal',
          description: 'Kerberos principal',
          Component: Input,
          componentProps: {
            skeleton: pending,
            fullWidth: true,
          },
          validators: [],
        },
        {
          section: 'HDFS',
          id: 'keyTab',
          name: 'Key Tab',
          description: 'Kerberos keyTab file',
          Component: FileUpload,
          componentProps: {
            skeleton: pending,
            multiple: false,
            fullWidth: true,
            toBase64: true,
          },
          validators: [],
        },
        {
          section: 'HDFS',
          id: 'trustStore',
          name: 'Trust Store',
          description: 'TrustStore file',
          Component: FileUpload,
          componentProps: {
            skeleton: pending,
            multiple: false,
            fullWidth: true,
            toBase64: true,
          },
          validators: [],
        },
        {
          section: 'HDFS',
          id: 'krb5',
          name: 'KRB5',
          description: 'Krb5 configuration file',
          Component: FileUpload,
          componentProps: {
            skeleton: pending,
            multiple: false,
            fullWidth: true,
            toBase64: true,
          },
          validators: [],
        },
      ]
      : []

  return {
    fields: [
      {
        section: 'General',
        id: 'name',
        name: 'Name',
        description,
        Component: Input,
        componentProps: {
          autoFocus: true,
          skeleton: pending,
          fullWidth: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'type',
        section: 'General',
        name: 'Type',
        description,
        defaultValue: 'gcs',
        Component: RadioButtons,
        componentProps: {
          buttonProps: {
            skeleton: pending,
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'gcs', label: 'gcs'},
            {id: 's3', label: 's3'},
            {id: 'wasbs', label: 'wasbs'},
            {id: 'hdfs', label: 'hdfs'},
          ],
        },
      },

      ...s3,
      ...gcs,
      ...wasbs,
      ...hdfs,
      {
        section: 'General',
        id: 'extraBuckets',
        name: 'List of Extra Buckets',
        description: 'Name of the bucket for storing ilum tables',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          fields: [{label: 'Value', name: 'value'}],
          buttonText: "Add new bucket"
        },
        // onSubmitFn: onSubmitParametersFn,
        validators: [
          (value) => {
            const result = map(
                (a) => isEmpty(a.value),
                value || []
            )
            return any(equals(true), result)
                ? 'This field is required'
                : ''
          },
        ],
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          type: 'button',
          variant: 'outlined',
          onClick: onCancel,
        },
      },
    ],
  }
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const ContentRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: ${({theme}) => theme.palette['surface-primary']};
`

const StorageButton = ({name, type, skeleton = false, onDelete, onEdit, index}) => {
  const handleOnDelete = useCallback(() => onDelete(index), [onDelete, index])

  const handleOnEdit = useCallback(() => onEdit(index), [onEdit, index])

  return (
    <div style={{display: 'flex', gap: 8}}>
      <Input
        readOnly={true}
        tabIndex={-1}
        value={`type: ${type}   name: ${name}`}
        fullWidth={true}
      />
      <Button
        skeleton={skeleton}
        tabIndex={-1}
        square
        onClick={handleOnEdit}
        justifyContent={'center'}
        Component={Icon}
        componentProps={{
          icon: 'pencil',
          size: 24,
        }}
        variant={'text'}
      />
      <Button
        skeleton={skeleton}
        tabIndex={-1}
        square
        onClick={handleOnDelete}
        justifyContent={'center'}
        Component={Icon}
        componentProps={{
          icon: 'close',
          size: 24,
        }}
        variant={'text'}
      />
    </div>
  )
}

const StorageMap = ({value = EMPTY_ARRAY, onChange, skeleton = false}) => {
  const handleOnDelete = useCallback(
    (index) => {
      const result = remove(index, 1, value)
      onChange(result)
    },
    [value, onChange],
  )

  const [editIndex, setEditIndex] = useState()

  const [show, setShow] = useState(false)

  const handleOnSubmit = useCallback(
    (fields) => {
      const filtered =  reject(isNil,fields)
      let result
      if (isNotNull(editIndex)) {
        result = assoc(editIndex, filtered, value)
      } else {
        result = append(filtered, value)
      }
      setEditIndex()
      onChange(result)
      setShow(false)
    },
    [value, onChange, editIndex],
  )

  const [initialData, setInitialData] = useState()

  return (
    <Root>
      {mapWithKey(
        (props, index) => (
          <StorageButton
            skeleton={skeleton}
            index={index}
            {...props}
            onEdit={() => {
              setEditIndex(index)
              setInitialData(props)
              setShow(true)
            }}
            onDelete={handleOnDelete}
          />
        ),
        value,
      )}
      <Button skeleton={skeleton} fullWidth onClick={() => {
        setShow(true)
        setInitialData()
      }} color={'primary'}>
        Add Storage
        </Button>
        <Portal anchorId={'form'}>
        {show && (
          <ContentRoot>
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <div style={{padding: '0 0 16px 16px'}}>
                <Button
                  size={'small'}
                  variant={'text'}
                  StartComponent={Icon}
                  startComponentProps={{
                    icon: 'arrow-left',
                    size: 18,
                  }}
                  tabIndex={-1}
                  justifyContent={'center'}
                  onClick={() => setShow(false)}
                >
                  Back to Cluster
                </Button>
              </div>
              <div style={{flex: 1, overflow: 'hidden'}}>
                <Form
                  options={{
                    pending: false,
                    onCancel: () => setShow(false),
                  }}
                  initialData={initialData}
                  LayoutComponent={SecurityFormLayout}
                  layoutComponentProps={{title: isNil(initialData) ? 'NEW STORAGE' : 'EDIT STORAGE'}}
                  schema={formSchema}
                  onSubmit={handleOnSubmit}
                />
              </div>
            </div>
          </ContentRoot>
        )}
      </Portal>
    </Root>
  )
}

export default StorageMap
