import React, {useMemo} from 'react'
import styled from 'styled-components'
import {path, pathOr, pipe, propOr} from 'ramda'
import {EMPTY_OBJECT} from '../../constants'
import SingleMetricBarChart from '../../components/charts/singleMericBarChart'
import {useSelector} from 'react-redux'
import {formatBytes} from '../../helpers'
import formatNumber from '../../helpers/formatNumber'
import {singleCallFetchOptions} from '../../api/helpers'
import useFetch from '../../hooks/useFetch'
import getClustersMetricsFetch from '../../api/workloads/clusters/getClustersMetricsFetch'

const Root = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding-top: 8px;
  flex-direction: column;
`

const ClusterMetrics = ({name}) => {
  const {metricsRefreshRate = 600000} = useSelector(path(['settings', 'settings']))

  const fetchOptions = Number(metricsRefreshRate)
    ? {refreshInterval: Number(metricsRefreshRate)}
    : singleCallFetchOptions

  const {data, error} = useFetch(getClustersMetricsFetch, fetchOptions)
  const {usedCpu, availableCpu, requestedCpu, availableMemory, usedMemory, requestedMemory} =
    pathOr(EMPTY_OBJECT, ['metrics', name, 'metric'], data)

  return (
    <Root>
      <SingleMetricBarChart
        label={'CPU'}
        values={[
          {
            key: 'USED',
            label: formatNumber(usedCpu, 2),
            value: formatNumber(usedCpu, 2),
          },
          {
            key: 'REQUESTED',
            label: formatNumber(requestedCpu, 2),
            value: formatNumber(requestedCpu, 2),
          },
        ]}
        max={{
          kay: 'AVAILABLE',
          label: availableCpu,
          value: availableCpu,
        }}
      />
      <SingleMetricBarChart
        label={'CPU'}
        values={[
          {
            key: 'USED',
            label: formatBytes(usedMemory),
            value: usedMemory,
          },
          {
            key: 'REQUESTED',
            label: formatBytes(requestedMemory),
            value: requestedMemory,
          },
        ]}
        max={{
          kay: 'AVAILABLE',
          label: formatBytes(availableMemory),
          value: availableMemory,
        }}
      />
    </Root>
  )
}

export default ClusterMetrics
