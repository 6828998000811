import React from 'react';
import {EMPTY_OBJECT} from '../../../../constants';
import RequestsList from '../../Components/RequestsList';
import Filters from '../../filters';
import {useListRefreshFuture} from '../../hooks';
import getRequests from '../../../../api/workloads/requests/getRequests';
import usePending from '../../../../hooks/usePending';
import ListLayout from '../../../../components/layouts/listLayout';
import schema from "./schema";

const RequestsListView = () => {
  const pending = usePending(
    ['workloads', 'data'],
    ["requests"]
  );

  const requestsListHandler = useListRefreshFuture(
    getRequests,
    "workloads",
    "requests",
    {
      page: 0,
        size: 25,
      sort: {
        startTime: "desc",
      },
      filter: EMPTY_OBJECT,
    }
  );

  return (
    <ListLayout
      filtersNode={<Filters
        name={"requests"}
        schema={schema}
        pending={pending}
      />}
      contentNode={<RequestsList
        pending={pending}
        listHandler={requestsListHandler}
      />}
    />
  );
};

export default RequestsListView;
