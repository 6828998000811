import {memo, useCallback, useState} from 'react'
import styled from 'styled-components'
import NoResultFound from '../../../components/NoResultFound'
import ToolTip from '../../../components/toolTip'
import {Button} from 'frontcore'

const Root = styled.div`
  box-sizing: border-box;
// background-color: white;
  background-color: ${({ theme }) => theme.palette['surface-primary']};
  padding: 20px 20px;
  overflow: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const Container = styled.div`
  font-size: 16px;
  color: grey;
  p {
    margin: 0;
    padding: 0;
    font-family: monospace;
    font-size: 14px;
    text-wrap: ${({$wrap}) => ($wrap ? 'wrap' : 'nowrap')};
  }
`

const WrapButtonContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: ${({ theme }) => theme.palette['surface-primary']};
  z-index: 1;
`

const ResultsLog = ({results}) => {
  const {logs} = results

  const [wrap, setWrap] = useState(true)
  const handleToggleWrap = useCallback(() => {
    setWrap((prev) => !prev)
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <WrapButtonContainer>
        <ToolTip
          content={
            <div style={{padding: 8}}>
              {wrap ? (
                <>
                  When wrap is <b>enabled</b>, long lines
                  will be shown as multiple ones
                </>
              ) : (
                <>
                  When wrap is <b>disabled</b>, long lines
                  will be scrollable.
                </>
              )}
            </div>
          }
        >
          <Button
            onClick={handleToggleWrap}
            variant="outlined"
          >
            {wrap ? 'Wrap' : 'No wrap'}
          </Button>
        </ToolTip>
      </WrapButtonContainer>

      <Root>
        {logs ? (
          <Container $wrap={wrap}>
            {logs.map((log, index) => (
              <p key={index}>{log}</p>
            ))}
          </Container>
        ) : (
          <NoResultFound
            title="No logs are available"
            text="This query left no logs after execution"
          />
        )}
      </Root>
    </div>
  )
}

export default memo(ResultsLog)
