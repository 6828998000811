import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react'
import {assoc, evolve, forEach, includes, keys, pipe, toPairs} from 'ramda'
import {EMPTY_OBJECT} from '../../constants'
import {isNotNull, notEquals} from '../../helpers'

export const StorageContext = createContext(undefined)

export const StorageProvider = ({children, schema = EMPTY_OBJECT}) => {
  const [_localStorage, setLocalStorage] = useState()

  useEffect(() => {
    pipe(
      toPairs,
      forEach(([key, value]) => {
        const hasProperty = !!localStorage.getItem(key)

        if (!hasProperty) {
          try {
            const string = JSON.stringify(value)

            localStorage.setItem(key, string)
          } catch (error) {
            console.log(error)
          }
        }
      })
    )(schema)

    const items = {...localStorage}
    setLocalStorage(items)
  }, [schema])

  useEffect(() => {

    window.addEventListener(
      'storage',
      function (event, b, c) {
        if (includes(event.key, keys(schema))) {
          setLocalStorage(state => {


            if (notEquals(state?.[event.key], event.newValue)) {
              return assoc(event.key, event.newValue, state)
            } else {
              return state
            }

          })
        }
      },
      false
    )
  }, [])

  const setLocalStorageItem = useCallback((key, value) => {

    setLocalStorage((state) => {
      if (state) {
        try {
          let result
          const parsed = JSON.parse(state?.[key])
          if (typeof value === 'function') {
            result = value(parsed)
          } else {
            result = value
          }

          if (notEquals(parsed, result)) {
            result = JSON.stringify(result)
            localStorage.setItem(key, result)
            return assoc(key, result, state)
          } else {
            return state
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }, [])

  const getLocalStorageItem = useCallback(
    (key) => {
      try {
        console.log(key)
        return JSON.parse(localStorage.getItem(key))
      } catch (error) {
        console.log(error)
      }
    },
    [_localStorage]
  )

  const context = useMemo(
    () => ({
      getLocalStorageItem,
      setLocalStorageItem,
    }),
    [setLocalStorageItem, getLocalStorageItem]
  )

  return <StorageContext.Provider value={context}>{children}</StorageContext.Provider>
}
