import Editor from './editor'
import Results from './results'
import Sidebar from './sidebar'
import {ResizeLayout, Tabs} from 'frontcore'
import {memo, useCallback, useEffect, useMemo} from 'react'
import useFuture from '../../hooks/useFuture'
import getSQLEngines from '../../api/sql/getSQLEngines'
import getSQLStatements from '../../api/sql/getSQLStatements'
import {fork} from 'fluture'
import {
  EMPTY_FUNCTION,
  HORIZONTAL,
  VERTICAL,
} from '../../constants'
import {ILUM_SQL_ENABLED} from '../../helpers/runtimeEnv'
import ViewNotAvailable from '../../components/viewNotAvailable'
import Line from '../../components/line'
import {
  sidebarSetTab,
  SidebarTabs,
} from '../../reducers/sql/sidebar'
import {useDispatch, useSelector} from 'react-redux'
import {isNil, path} from 'ramda'
import styled from 'styled-components'
import useFetch from '../../hooks/useFetch'
import getClustersFetch from '../../api/workloads/clusters/getClustersFetch'
import {singleCallFetchOptions} from '../../api/helpers'
import EditorTopControls from './editor/EditorTopControls'
import Notebook from './notebook'
import getSQLNotebooks from '../../api/sql/getSQLNotebooks'

const TabsContainer = styled.div`
  padding: 24px 24px 0 24px;
  height: 42px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
    height: 48px
`

const SqlView = () => {
  const {data: clusters} = useFetch(
    getClustersFetch,
    singleCallFetchOptions,
  )
  const defaultFirstClusterId = useMemo(() => {
    if (isNil(clusters) || clusters.length <= 0) {
      return null
    }
    return clusters[0].id
  }, [clusters])

  const chosenClusterId = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenCluster', 'id'])
  )

  const clusterId = useMemo(() => {
    return chosenClusterId ?? defaultFirstClusterId
  }, [chosenClusterId, defaultFirstClusterId])

  const getSQLEnginesFuture = useFuture(getSQLEngines)
  useEffect(() => {
    if (isNil(clusterId)) return
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getSQLEnginesFuture({
        params: [{key: 'clusterId', value: clusterId}],
      })
    )
  }, [clusterId])

  const getSQLStatementsFuture = useFuture(getSQLStatements)
  const getSQLNotebooksFuture = useFuture(getSQLNotebooks)
  useEffect(() => {
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getSQLStatementsFuture({
        size: 1000,
      })
    )
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getSQLNotebooksFuture({
        size: 1000,
      })
    )
  }, [])

  const dispatch = useDispatch()
  const tab = useSelector(
    path(['sqlViewer', 'sidebar', 'tab'])
  )

  const handleTab = useCallback((newTab) => {
    dispatch(sidebarSetTab(newTab))
  }, [])

  const chosenQueryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const chosenQueryInfo = useMemo(() => {
    return queries.find(({ id }) => {
      return id === chosenQueryId
    })
  }, [chosenQueryId, queries])

  // TODO: remove it from redux
  // useQueryIdSync()

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TabsContainer>
        <Tabs
          value={tab}
          onChange={handleTab}
          options={Object.values(SidebarTabs).map(
            (tabName) =>
              Object.freeze({
                id: tabName,
                children: tabName,
              })
          )}
        />
        <div style={{flex: 1}}>
          <EditorTopControls />
        </div>
      </TabsContainer>
      <Line />
      <ResizeLayout
        configuration={{
          orientation: HORIZONTAL,
          firstNode: {
            measurement: 'px',
            value: 32 * 8,
            max: 32 * 16,
          },
          secondNode: {
            measurement: 'flex',
            value: 1,
          },
        }}
        firstNode={<Sidebar />}
        secondNode={(
          chosenQueryInfo?.type === 'notebook' ? (
            <Notebook />
          ) : (
            <ResizeLayout
              configuration={{
                orientation: VERTICAL,
                firstNode: {
                  measurement: 'flex',
                  value: 1,
                },
                secondNode: {
                  measurement: 'px',
                  value: 260,
                },
              }}
              firstNode={<Editor />}
              secondNode={<Results />}
            />
          )
        )}
      />
    </div>
  )
}

export default memo(() => {
  return ILUM_SQL_ENABLED.toLowerCase() === 'true' ? (
    <SqlView />
  ) : (
    <ViewNotAvailable name={'SQL'} />
  )
})
