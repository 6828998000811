import {useCallback} from 'react'
import {join, pipe} from 'ramda'
import {and, fork} from 'fluture'
import ActivityButtons from '../index'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {EMPTY_ARRAY} from '../../../../../constants'
import {deleteActivity} from "../../../../../api/security/deleteActivity";

const ActivityBatchButtons = ({
  pending,
  data,
  getFuture,
  selected,
  setSelected,
}) => {
  const {id} = data

  const {createNotification, createNotificationWithContext} = useNotificationContext()

  const deleteGroupFuture = useFuture(deleteActivity)

  const handleOnDelete = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to delete Activities',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        setSelected(EMPTY_ARRAY)
        createNotification({
          title: 'Activities deleted successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteGroupFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  return (
    <ActivityButtons
      texts={false}
      id={id}
      data={data}
      pending={pending}
      onDelete={handleOnDelete}
    />
  )
}

export default ActivityBatchButtons
