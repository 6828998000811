import { createSlice } from "@reduxjs/toolkit";
import { EMPTY_STRING } from "../../constants";

const initialState = Object.freeze({
  tipsEnabled: true,
  search: EMPTY_STRING
});

const fileExplorerSlice = createSlice({
  name: "fileExplorer",
  initialState,
  reducers: {
    toggleTipsEnabled: (state) => ({
      ...state,
      tipsEnabled: !state.tipsEnabled,
    }),

    setSearch: (state, { payload }) => ({
      ...state,
      search: payload
    }),
  },
});

export default fileExplorerSlice.reducer;
export const {
  toggleTipsEnabled,
  setSearch
} = fileExplorerSlice.actions;
