import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  __,
  assoc,
  equals,
  find,
  isNil,
  map,
  mergeAll,
  or,
  pathOr,
  pick,
  pipe,
  values,
} from 'ramda'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  HORIZONTAL,
} from '../../../../../constants'
import useFuture from '../../../../../hooks/useFuture'
import React, {useEffect, useMemo, useState} from 'react'
import {fork} from 'fluture'
import getStagesExecutors from '../../../../../api/workloads/jobs/getStagesExecutors'
import {
  Input,
  ResizeLayout,
  VirtualizedList,
} from 'frontcore'
import Line from '../../../../../components/line'
import {useTheme} from 'styled-components'
import {TabContainer} from '../JobDetailsTimelineView'
import {
  Border,
  DetailsContainer,
  RightContainer,
  Title,
} from '../styles'
import StatusIndicator from '../../../../../components/StatusIndicator'
import MetricChart from '../../../../../components/charts/MetricChart'
import formatBytes from '../../../../../helpers/formatBytes'
import RButton from '../../../../../components/RButton'
import DetailsHeader from '../../../../../components/DetailsHeader'
import ResourceIcon from '../../../../../components/Icons/ResourceIcon'

const ListButton = (props) => {
  const {id, onClick, active, isActive} = props

  return (
    <TabContainer leftShift={4}>
      <RButton
        onClick={() => onClick(id)}
        icon={'gear'}
        name={id}
        type={'executor'}
        active={active}
        fullWidth={true}
      >
        <StatusIndicator
          value={isActive ? 'ACTIVE' : 'INACTIVE'}
          variant={isActive ? 'success' : 'default'}
        />
      </RButton>
    </TabContainer>
  )
}

const JobDetailsExecutors = () => {
  const {jobId} = useParams()

  const executors = useSelector(
    pathOr(EMPTY_ARRAY, [
      'workloads',
      'data',
      'stagesExecutors',
      'response',
    ])
  )

  const [selected, setSelected] = useState()

  useEffect(() => {
    if (!selected && executors?.[0]) {
      setSelected(executors[0]?.id)
    }
  }, [selected, executors])

  const executor = useMemo(
    () =>
      pipe(
        find(({id}) => id === selected),
        or(__, EMPTY_OBJECT)
      )(executors),
    [executors, selected]
  )

  const getStagesExecutorsFuture = useFuture(
    getStagesExecutors
  )

  const data = useMemo(
    () =>
      pipe(
        pick([
          'peakMemoryMetrics',
          'peakProcessTreeMemoryMetrics',
        ]),
        values,
        mergeAll,
        map((value) =>
          isNil(value) ? undefined : formatBytes(value, 2)
        )
      )(executor),
    [executor]
  )

  const {
    DirectPoolMemory,
    JVMHeapMemory,
    JVMOffHeapMemory,
    MappedPoolMemory,
    OffHeapExecutionMemory,
    OffHeapUnifiedMemory,
    OnHeapExecutionMemory,
    OnHeapStorageMemory,
    OnHeapUnifiedMemory,
    ProcessTreeJVMRSSMemory,
    ProcessTreeJVMVMemory,
    ProcessTreeOtherRSSMemory,
    ProcessTreeOtherVMemory,
    ProcessTreePythonRSSMemory,
    ProcessTreePythonVMemory,
  } = data

  const {id, cores, memory, completedTasks, failedTasks} =
    executor

  useEffect(() => {
    fork(() => {})(() => {})(
      getStagesExecutorsFuture({
        params: [{key: 'jobId', value: jobId}],
      })
    )
  }, [jobId])

  const extendedData = useMemo(
    () =>
      map((item) => {
        const active = equals(item.id, selected)
        return assoc('active', active, item)
      }, executors),
    [selected, executors]
  )

  return (
    <ResizeLayout
      configuration={{
        orientation: HORIZONTAL,
        firstNode: {
          measurement: 'px',
          value: 260,
        },
        secondNode: {
          measurement: 'flex',
          value: 1,
        },
      }}
      firstNode={
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{padding: '24px 16px 8px 16px'}}>
            <Input
              fullWidth
              disabled
              topLabelProps={{children: 'Search Executors'}}
            />
          </div>
          <Line />
          <VirtualizedList
            items={extendedData}
            itemHeight={32}
            gap={4}
            ItemComponent={ListButton}
            itemComponentProps={{
              onClick: (id) => {
                setSelected(id)
              },
            }}
          />
        </div>
      }
      secondNode={
        <RightContainer>
          <DetailsContainer>
            <DetailsHeader
              icon={<ResourceIcon type={'executor'} />}
              name={id}
              pending={false}
              data={[
                {
                  key: 'cores',
                  value: cores,
                },
                {
                  key: 'memory',
                  value: memory,
                },
                {
                  key: 'completed tasks',
                  value: completedTasks,
                },
                {
                  key: 'failed tasks',
                  value: failedTasks,
                },
              ]}
            />
          </DetailsContainer>
          <Line />
          <div
            style={{
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
            }}
          >
            <Border>
              <div
                style={{
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 8,
                }}
              >
                <Title>Peak Memory Metrics</Title>
              </div>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: 2,
                }}
              >
                <MetricChart
                  name={'Direct Pool Memory'}
                  {...DirectPoolMemory}
                />
                <MetricChart
                  name={'JVM Heap Memory'}
                  {...JVMHeapMemory}
                />
                <MetricChart
                  name={'JVM Off Heap Memory'}
                  {...JVMOffHeapMemory}
                />
                <MetricChart
                  name={'Mapped Pool Memory'}
                  {...MappedPoolMemory}
                />
                <MetricChart
                  name={'Off Heap Execution Memory'}
                  {...OffHeapExecutionMemory}
                />
                <MetricChart
                  name={'Off Heap Unified Memory'}
                  {...OffHeapUnifiedMemory}
                />
                <MetricChart
                  name={'On Heap Execution Memory'}
                  {...OnHeapExecutionMemory}
                />
                <MetricChart
                  name={'On Heap Storage Memory'}
                  {...OnHeapStorageMemory}
                />
                <MetricChart
                  name={'On Heap Unified Memory'}
                  {...OnHeapUnifiedMemory}
                />
              </div>
            </Border>

            <Border>
              <div
                style={{
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 8,
                }}
              >
                <Title>
                  Peak Process Tree Memory Metrics
                </Title>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: 2,
                }}
              >
                <MetricChart
                  name={'Process Tree JVM RSS Memory'}
                  {...ProcessTreeJVMRSSMemory}
                />
                <MetricChart
                  name={'Process Tree JVM VMemory'}
                  {...ProcessTreeJVMVMemory}
                />
                <MetricChart
                  name={'Process Tree Other RSS Memory'}
                  {...ProcessTreeOtherRSSMemory}
                />
                <MetricChart
                  name={'Process Tree Other VMemory'}
                  {...ProcessTreeOtherVMemory}
                />
                <MetricChart
                  name={'Process Tree Python RSS Memory'}
                  {...ProcessTreePythonRSSMemory}
                />
                <MetricChart
                  name={'Process Tree Python VMemory'}
                  {...ProcessTreePythonVMemory}
                />
              </div>
            </Border>
          </div>
        </RightContainer>
      }
    />
  )
}

export default JobDetailsExecutors
