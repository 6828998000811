import {
  assoc,
  evolve,
  fromPairs,
  isEmpty,
  isNil, join,
  map,
  mergeAll,
  omit,
  path,
  pipe, prop,
  propOr,
  reject,
  values,
} from 'ramda'
import {EMPTY_ARRAY} from '../../../constants'
import {onSubmitFnMultipleFiles, onSubmitSingleFile} from '../../../helpers/form'

const clusterFormSubmitFn = (params) => {
  const clusterName = params.name

  const sparkStorage = path(['sparkStorage', 'name'], params)

  const storages = pipe(
    propOr(EMPTY_ARRAY, 'storages'),
    map(
      evolve({
        configurationFiles: onSubmitFnMultipleFiles,
        keyTab: onSubmitSingleFile,
        trustStore: onSubmitSingleFile,
        krb5: onSubmitSingleFile,
        extraBuckets: pipe(map(prop('value')))
      })
    ),
    reject(isNil),
    reject(isEmpty),
    map((storage) => {
      if (storage.type === 'hdfs') {
        return {
          [storage.name]: pipe(assoc('clusterName', clusterName), reject(isNil))(storage),
        }
      } else {
        return {
          [storage.name]: pipe(omit(['name']), reject(isNil))(storage),
        }
      }
    }),
    mergeAll
  )(params)



  const defaultApplicationConfig = pipe(
    propOr(EMPTY_ARRAY, 'defaultApplicationConfig'),
    map(values),
    fromPairs
  )(params)

  return pipe(
    assoc('defaultApplicationConfig', defaultApplicationConfig),
    assoc('storages', storages),
    assoc('sparkStorage', sparkStorage),
    reject(isNil),
    reject(isEmpty)
  )(params)
}

export default clusterFormSubmitFn
