import Configuration from '../../../../../components/configuration'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {prop, propOr} from 'ramda'
import {EMPTY_OBJECT, EMPTY_ARRAY} from '../../../../../constants'



const DatasetFields = () => {
  const model = useSelector(prop('lineage'))
  const selectedNode = useMemo(
    () => propOr(EMPTY_OBJECT, 'selectedNode', model),
    [model]
  )
  const fields = useMemo(() => propOr(EMPTY_ARRAY, 'fields', selectedNode), [selectedNode])

  return <div
    style={{
      padding: 8,
      overflow: 'hidden',
      height: '100%',
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Configuration
      data={fields}
      schema={[
        {
          id: 'name',
          label: 'Name',
          copy: false,
        },
        {
          id: 'type',
          label: 'Type',
          copy: false,
        },
        {
          id: 'tags',
          label: 'Tags',
          copy: false,
          Component: ({value}) =>
            value?.length && value?.length(),
        },
        {
          id: 'description',
          label: 'Description',
          copy: false,
          Component: ({value}) =>
            value || 'No description',
        },
      ]}
    />
  </div>
}

export default DatasetFields;