import {useDispatch} from 'react-redux'
import React, {useCallback, useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {forEach, head, isNil, pipe, propOr, reduce, reject, toPairs} from 'ramda'
import {isArray} from '../../../../helpers'
import useFetch from '../../../../hooks/useFetch'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import createService from '../../../../api/workloads/services/createService'
import usePending from '../../../../hooks/usePending'
import {useNavigate} from 'react-router-dom'
import schema from './schema'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import Form from '../../../../components/Form'
import useFuture from '../../../../hooks/useFuture'
import SecurityFormLayout from '../../../../components/SecurityFormLayout'
import getClusterMemorySettingsFetch from '../../../../api/workloads/clusters/getClusterMemorySettingsFetch'
import {EMPTY_OBJECT} from '../../../../constants'

const useQueryParams = () => {
  const {search} = window.location
  return new URLSearchParams(search)
}

const WorkloadsCreateServiceView = () => {
  const createServiceFuture = useFuture(createService)
  const {createNotification} = useNotificationContext()

  const navigate = useNavigate()

  const {data: clusters, error} = useFetch(getClustersNamesFetch)
  const dispatch = useDispatch()

  const pending = usePending(['workloads', 'data'], ['createService'])

  const query = useQueryParams()
  const cluster = useMemo(() => propOr(EMPTY_OBJECT, 0, clusters), [clusters])
  const {data: clusterMemorySettings} = useFetch(getClusterMemorySettingsFetch, EMPTY_OBJECT, {
    params: [{key: 'id', value: cluster.id}],
  })

  const handleOnSubmit = useCallback((params) => {
    const result = pipe(
        reject(isNil),
      toPairs,
      reduce((acc, [fieldName, fieldValue]) => {
        if (isArray(fieldValue)) {
          forEach(([key, value]) => {
            acc.append(fieldName, key, value)
          }, fieldValue)
        } else {
          acc.append(fieldName, fieldValue)
        }
        return acc
      }, new FormData())
    )(params)

    fork(({message}) => {
      createNotification({
        title: 'Failed to create Service',
        message,
        autoHide: true,
        variant: 'error',
      })
    })(({message}) => {
      navigate(-1)
      createNotification({
        title: 'Service created successfully',
        message,
        autoHide: true,
        variant: 'success',
      })
    })(createServiceFuture(result))
  }, [])

  useEffect(() => {
    dispatch(
      setBreadCrumbs({
        group: {
          name: 'Services',
        },
      })
    )
  }, [])

  const options = useMemo(
    () => ({
      pending,
      clusters,
      cluster: query.get('cluster'),
      navigate,
    }),
    [pending, clusters]
  )

  return (
    <Form
      options={options}
      initialData={{
        clusterName: cluster.name,
        ...clusterMemorySettings,
      }}
      LayoutComponent={SecurityFormLayout}
      schema={schema}
      onSubmit={handleOnSubmit}
      layoutComponentProps={{
        title: 'NEW SERVICE',
      }}
    />
  )
}

export default WorkloadsCreateServiceView
