import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';

export const PostHogPageView = () => {
    let location = useLocation();

    useEffect(() => {
        if (posthog) {
            posthog.capture(
                '$pageview',
                {
                    '$current_url': window.location.href,
                }
            )
        }
    }, [location])

    return null
}
