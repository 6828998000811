import asyncThunk from '../index'

export const getUser = asyncThunk({
  method: 'GET',
  type: 'user/get',
  path: '/users/${id}',
  api: true,
  mock2: {
    id: '12345',
    username: 'johndoe',
    fullname: 'John Doe',
    email: 'johndoe@example.com',
    description:
      'A software developer with expertise in web technologies.',
    department: 'Engineering',
    lastTimeOnline: 1713084000,
    lastTimeCreated: 1617187200,
    enabled: true,
    roles: ['admin', 'developer', 'manager'],
    groups: ['dev-team', 'product-team'],
    permissions: ['read', 'write', 'execute', 'admin'],
    operations: 512,
  },
})

export default getUser
