import {
  flatten,
  groupBy,
  intersperse,
  map,
  length,
  pathOr,
  pipe,
  prop,
  propOr,
  toPairs,
} from 'ramda'
import React, {useEffect, useMemo, useState} from 'react'
import styled, {css} from 'styled-components'
import Line from '../line'
import {Tabs} from 'frontcore'
import {EMPTY_STRING} from '../../constants'
import {
  convertObjectToArray,
  isNotEmpty,
} from '../../helpers'
import {CountMiniature} from '../miniatures'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px 0 16px 0;
  overflow: hidden;
`

const TitleContainer = styled.div`
  padding-bottom: 32px;
    padding-left: 24px;
`

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0 8px 0 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 16px;
  padding-right: 16px;
  gap: 16px;
`

const WhiteSpace = styled.div`
  flex: 0.2;
`

const titleCss = css`
  color: ${({theme}) => theme.palette.blue[500]};
  font-family: Roboto, serif;
  font-weight: 500;
  font-size: 18px;
`

const Title = styled.div`
  ${titleCss};
`

const Row = styled.div`
  display: flex;
  gap: 80px;
  padding: 24px 0;
`

const FirstColumn = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 24px;
`

const NameContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const DescriptionContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const SecondColumn = styled.div`
  flex: 1;
`

const TabRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({$invalid, theme}) =>
    $invalid && theme.palette.red[500]};
`

const TabComponent = ({name, value, active}) => {
  const errors = pipe(
    map(({componentProps}) => componentProps.errors),
    flatten
  )(value)

  return (
    <TabRoot $invalid={isNotEmpty(errors)}>{name}</TabRoot>
  )
}

const TabsFormLayout = ({title, buttons, fields}) => {
  const tabs = useMemo(
    () =>
      pipe(
        groupBy(prop('section')),
        convertObjectToArray
      )(fields),
    [fields]
  )

  const [tab, setTab] = useState('')

  useEffect(() => {
    const result = pathOr(EMPTY_STRING, [0, 'key'], tabs)
    setTab(result)
  }, [])

  const content = useMemo(
    () =>
      pipe(
        groupBy(prop('section')),
        propOr([], tab),
        map(({id, element, name, description}) => (
          <Row key={id}>
            <FirstColumn>
              <NameContainer>{name}</NameContainer>
              <DescriptionContainer>
                {description}
              </DescriptionContainer>
            </FirstColumn>
            <SecondColumn>{element}</SecondColumn>
            {/*<WhiteSpace />*/}
          </Row>
        )),
        intersperse(<Line />)
      )(fields),
    [fields, tab]
  )

  return (
    <Root>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <div style={{paddingLeft: 24}}>
        <Tabs
          value={tab}
          onChange={setTab}
          options={map(
            ({key, value}) => ({
              id: key,
              children: key,
              componentProps: {
                name: key,
                value,
              },
              Component: TabComponent,
            }),
            tabs
          )}
        />
      </div>
      <Line />
      <ContentContainer>{content}</ContentContainer>
      <Line />
      <ButtonsContainer>{buttons}</ButtonsContainer>
    </Root>
  )
}
export default TabsFormLayout
