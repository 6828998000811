import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import Line from '../line'
import StyledLink from '../StyledLink'
import {ErrorBoundary} from 'react-error-boundary'
import {any, groupWith, isNil, map, pipe, reject, startsWith} from 'ramda'
import FallbackRenderer from '../FallbackRenderer'
import UserIcon from '../Icons/UserIcon'
import Chip from '../Chip'
import {mapWithKey, toBoolean} from '../../helpers'
import {
  ILUM_AIRFLOW_ENABLED,
  ILUM_DATA_ENABLED,
  ILUM_GITEA_ENABLED,
  ILUM_GRAFANA_ENABLED,
  ILUM_JUPYTER_ENABLED,
  ILUM_MLFLOW_ENABLED,
  ILUM_SUPERSET_ENABLED,
} from '../../helpers/runtimeEnv'
import {Icon} from 'frontcore'
import {useLocation} from 'react-router-dom'
import styled, {useTheme} from 'styled-components'

const buttons = [
  {
    section: 'Workloads',
    children: 'Clusters',
    icon: 'cluster',
    to: '/workloads/list/clusters',
    active_paths: ['/workloads/list/clusters', '/workloads/details/cluster'],
  },
  {
    section: 'Workloads',
    children: 'Services',
    icon: 'box',
    to: '/workloads/list/services',
    active_paths: ['/workloads/list/services', '/workloads/details/service'],
  },
  {
    section: 'Workloads',
    children: 'Schedules',
    icon: 'clock',
    to: '/workloads/list/schedules',
    active_paths: ['/workloads/list/schedules', '/workloads/details/schedule'],
  },
  {
    section: 'Workloads',
    children: 'Jobs',
    icon: 'database',
    to: '/workloads/list/jobs',
    active_paths: ['/workloads/list/jobs', '/workloads/details/job'],
  },
  {
    section: 'Workloads',
    children: 'Requests',
    icon: 'table',
    to: '/workloads/list/requests',
    active_paths: ['/workloads/list/requests', '/workloads/details/request'],
  },
  {
    children: 'History Server',
    icon: 'history',
    to: '/history',
    active_paths: ['/history'],
  },
  {
    children: 'Table Explorer',
    icon: 'table-2',
    to: '/data',
    active_paths: ['/data'],
  },
  {
    children: 'File Explorer',
    icon: 'directory',
    to: '/file-explorer',
    active_paths: ['/file-explorer'],
  },
  {
    children: 'SQL',
    icon: 'database',
    to: '/sql',
    active_paths: ['/sql'],
  },
  {
    children: 'Lineage',
    icon: 'Vector',
    to: '/ilum-lineage/list/jobs',
    active_paths: ['/ilum-lineage'],
  },
  {
    children: 'User Guides',
    icon: 'info',
    to: '/documentation',
    active_paths: ['/documentation'],
  },
  {
    section: 'Security',
    children: 'Users',
    to: '/security/list/users',
    active_paths: ['/security/list/users', '/security/details/user'],
  },
  {
    section: 'Security',
    children: 'Groups',
    to: '/security/list/groups',
    active_paths: ['/security/list/groups', '/security/details/group'],
  },
  {
    section: 'Security',
    children: 'Roles',
    to: '/security/list/roles',
    active_paths: ['/security/list/roles', '/security/details/role'],
  },
  {
    section: 'Security',
    children: 'Activities',
    to: '/security/list/activities',
    active_paths: ['/security/list/activities', '/security/details/activity'],
  },
  {
    section: 'Modules',
    children: 'Overview',
    icon: 'modules',
    to: '/modules',
    active_paths: ['/modules'],
  },
  {
    section: 'Modules',
    icon: 'minio',
    children: 'Minio',
    to: '/minio',
    active_paths: ['/minio'],
  },
  {
    section: 'Modules',
    icon: 'jupyter',
    children: 'Jupyter',
    to: '/notebook',
    active_paths: ['/notebook'],
  },
  ILUM_AIRFLOW_ENABLED
    ? {
        section: 'Modules',
        icon: 'apacheairflow',
        children: 'Airflow',
        to: '/airflow',
        active_paths: ['/airflow'],
      }
    : null,
  {
    section: 'Modules',
    children: 'Gitea',
    icon: 'git3',
    to: '/gitea',
    active_paths: ['/gitea'],
  },
  {
    section: 'Modules',
    children: 'MLflow',
    icon: 'mlflow',
    to: '/mlflow',
    active_paths: ['/mlflow'],
  },
  {
    section: 'Modules',
    children: 'Superset',
    icon: 'superset',
    to: '/superset',
    active_paths: ['/superset'],
  },
  {
    section: 'Modules',
    children: 'Grafana',
    icon: 'grafana',
    to: '/grafana',
    active_paths: ['/grafana'],
  },
]
const SectionTitle = styled.div`
  font-size: 14px;
  height: 24px;

  align-items: center;
  color: ${({theme}) => theme.palette.neutral[700]};
  display: flex;
  text-transform: uppercase;
  padding: 0 16px;
`

const NavigationButtonRoot = styled.div`
  border-radius: 4px;
  font-family: Inter,serif;
  font-size: 14px;
  cursor: default;
  color: ${({theme, isActive}) =>
    isActive ? theme.palette.neutral[900] : theme.palette.neutral[700]};
  height: 32px;
  padding: 0 16px;
  display: flex;
  letter-spacing: 0.25px;
  font-weight: ${({isActive}) => (isActive ? '600' : '400')};
  gap: 8px;
  align-items: center;
  background: ${({isActive}) => (isActive ? 'rgba(21, 24, 28, 0.08)' : 'transparent')};
  transition: all 0.2s ease;

  &:hover {
    background: rgba(21, 24, 28, 0.04);
  }

  &:active {
    background: rgba(21, 24, 28, 0.12);
  }
`

const NavigationButton = ({to, children, icon, active_paths = []}) => {
  const {pathname} = useLocation()

  const theme = useTheme()
  const isActive = any((a) => startsWith(a, pathname))(active_paths)

  const iconColor = isActive ? theme.palette.neutral[900] : theme.palette.neutral[600]
  const textColor = isActive ? theme.palette.neutral[900] : theme.palette.neutral[700]

  return (
    <StyledLink to={to}>
      <NavigationButtonRoot isActive={isActive}>
        {icon && <Icon icon={icon} size={16} color={iconColor} />}
        {children}
      </NavigationButtonRoot>
    </StyledLink>
  )
}
const NavigationBar = () => {
  const sections = useMemo(
    () =>
      pipe(
        reject(isNil),
        groupWith((a, b) => a.section === b.section)
      )(buttons),
    [buttons]
  )

  return (
    <div
      style={{
        padding: 4,
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 4,
          fontFamily: 'Roboto',
          overflowY: 'scroll',
          gap: 16,
        }}
      >
        {mapWithKey(
          (section, index) => (
            <div key={index} style={{display: 'flex', flexDirection: 'column', gap: 8}}>
              {section[0]?.section && <SectionTitle>{section[0]?.section}</SectionTitle>}
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {mapWithKey(
                  (props, index) => (
                    <ErrorBoundary key={index} fallbackRender={FallbackRenderer}>
                      <NavigationButton {...props} />
                    </ErrorBoundary>
                  ),
                  section
                )}
              </div>
            </div>
          ),
          sections
        )}
      </div>
    </div>
  )
}

export default NavigationBar
