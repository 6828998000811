import { combineReducers } from '@reduxjs/toolkit'
import sidebarReducer from './sidebar'
import editorReducer from './editor'
import resultsReducer from './results'
import notebookReducer from './notebook'

const sqlViewerReducer = combineReducers({
  sidebar: sidebarReducer,
  editor: editorReducer,
  results: resultsReducer,
  notebook: notebookReducer,
})

export default sqlViewerReducer
