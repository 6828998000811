import FileUpload from '../../../components/fileUpload'
import {onSubmitFilesFn, onSubmitParametersFn} from '../../../helpers'
import Parameters from '../../../components/parameters'
import {any, equals, isEmpty, map} from 'ramda'

const resourcesSchema = (data, options) => {
  const {pending} = options;
  return [
    {
      section: 'Resources',
      id: 'jars',
      name: 'Jars',
      description: 'JARs to be loaded in the classpath of drivers and executors.',
      Component: FileUpload,
      componentProps: {
        skeleton: pending,
        fullWidth: true,
        accept: '.jar',
        size: 'small',
        downloadable: false
      },
      onSubmitFn: onSubmitFilesFn,
      validators: [],
    },
    {
      section: 'Resources',
      id: 'files',
      name: 'Files',
      description: 'List of files to be placed in the working directory of each executor',
      Component: FileUpload,
      componentProps: {
        skeleton: pending,
        fullWidth: true,
        size: 'small',
        downloadable: false
      },
      onSubmitFn: onSubmitFilesFn,
      validators: [],
    },
    {
      section: 'Resources',
      id: 'pyFiles',
      name: 'PyFiles',
      description: 'List of .zip, .egg, or .py files to place on PYTHONPATH.',
      Component: FileUpload,
      componentProps: {
        skeleton: pending,
        fullWidth: true,
        size: 'small',
        downloadable: false
      },
      onSubmitFn: onSubmitFilesFn,
      validators: [],
    },
    {
      section: 'Resources',
      id: 'pyRequirements',
      name: 'Requirements',
      description: 'Additional Python packages to be installed before running the job.',
      Component: Parameters,
      componentProps: {
        skeleton: pending,
        size: 'small',
        fullWidth: true,
        fields: [{label: 'Value', name: 'value'}],
      },
      onSubmitFn: onSubmitParametersFn,
      validators: [
        (value) => {
          const result = map((a) => isEmpty(a.value), value || []);
          return any(equals(true), result) ? 'This field is required' : '';
        },
      ],
    },
  ]
}

export default resourcesSchema;
