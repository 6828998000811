// this component is for search highlighting
// you may also include it into your branch / frontcore
// cause i think it is helpful

// this component is used together with
// searches to create a beautiful
// highlighting effect

import {useMemo} from 'react'
import styled, {css} from 'styled-components'
import {EMPTY_STRING} from '../../constants'

const Colorful = styled.span`
  background-color: ${({$color}) => $color};
  ${({ $textColor }) => $textColor && css`
    color: ${$textColor} !important;
  `}
`

const Highlight = ({
  text,
  search,
  color = 'yellow',
  lowercase = true,
  textColor,
  Component = 'span',
}) => {
  const [highlighted, normal] = useMemo(() => {
    const matches = lowercase
      ? text.toLowerCase().startsWith(search.toLowerCase())
      : text.startsWith(search)
    if (matches) {
      return [
        text.slice(0, search.length),
        text.slice(search.length),
      ]
    }
    return [EMPTY_STRING, text]
  }, [text, search, lowercase])

  return (
    <Component>
      <Colorful $color={color} $textColor={textColor}>
        {highlighted}
      </Colorful>
      {normal}
    </Component>
  )
}

export default Highlight
