import React, {useEffect, useMemo, useState} from 'react'
import {fork} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {map, pathOr, pipe, toPairs} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import getRequest from '../../../../api/workloads/requests/getRequest'
import {initRequest, setBreadCrumbs} from '../../../../reducers/workloads'
import {Input, Tabs} from 'frontcore'
import DateCellRow from '../../dateCellRow'
import SlideTransition from '../../../../components/transitions/slideTransition'
import {useParams} from 'react-router-dom'
import {CountMiniature} from '../../../../components/miniatures'
import styled from 'styled-components'
import Configuration from '../../../../components/configuration'
import DetailsLayout from '../../../../components/layouts/detailsLayout'
import usePending from '../../../../hooks/usePending'
import Line from '../../../../components/line'
import DetailsHeader from '../../../../components/DetailsHeader'
import ResourceIcon from '../../../../components/Icons/ResourceIcon'
import {useHistoryContext} from '../../../../hooks/useHistoryContext'

const ResultTabStyles = styled.div`
  color: ${({theme, error}) => (error ? theme.palette.red[500] : theme.palette.default[500])};
`

const ResultTab = ({value, error, label}) => (
  <ResultTabStyles error={error}>{value}</ResultTabStyles>
)

const WorkloadsRequestDetailsView = () => {
  const getRequestFuture = useFuture(getRequest)

  const {
    requestId: id,
    requestName: name,
    config = EMPTY_OBJECT,
    clazz,
    startTime,
    endTime,
    error,
    result,
  } = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'request', 'response']))
  const {addHistory} = useHistoryContext()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(initRequest())
  }, [])

  const configuration = useMemo(
    () =>
      pipe(
        toPairs,
        map((element) => ({
          key: element[0],
          value: element[1],
        }))
      )(config),
    [config]
  )

  const {requestId} = useParams()

  useEffect(() => {
    if (requestId) {
      fork(() => {})((data) => {
        const {clusterId, clusterName, groupId, groupName, jobId, jobName, requestId} = data
          addHistory({type: 'request', id: requestId})
        dispatch(
          setBreadCrumbs({
            cluster: {
              id: clusterId,
              name: clusterName,
            },
            group: {
              id: groupId,
              name: groupName,
            },
            job: {
              id: jobId,
              name: jobName,
            },
            request: {
              id: requestId,
              name: requestId,
              active: true,
            },
          })
        )
      })(
        getRequestFuture({
          params: [{key: 'id', value: requestId}],
        })
      )
    }
  }, [requestId])

  const [tab, setTab] = useState('result')

  const pending = usePending(['workloads', 'data'], ['request'])

  const topContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'request'} />}
      id={id}
      name={name}
      data={[
        {
          key: 'Class',
          value: clazz,
        },
        {
          key: 'start time',
          value: startTime,
          Component: DateCellRow,
        },
        {
          key: 'end time',
          value: endTime,
          Component: DateCellRow,
        },
      ]}
    />
  )

  const middleContent = (
    <Tabs
      value={tab}
      options={[
        {
          id: 'result',
          Component: ResultTab,
          componentProps: {
            label: 'Result',
            value: 'Result',
            error: error,
          },
        },
        {
          id: 'configuration',
          Component: () => (
            <div
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
              }}
            >
              Configuration
              <CountMiniature value={configuration.length || 0} />
            </div>
          ),
        },
      ]}
      onChange={setTab}
    />
  )

  const bottomContent = (
    <SlideTransition trigger={tab} duration={150}>
      {tab === 'result' && !error && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              flex: 1,
              overflowY: 'scroll',
              padding: '0 8px',
              boxSizing: 'border-box',
            }}
          >
            <pre
              style={{
                fontSize: 12,
                padding: 16,
                whiteSpace: 'pre-wrap',
              }}
            >
              {result}
            </pre>
          </div>
        </div>
      )}
      {tab === 'result' && error && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          <div style={{padding: 16}}>
            <Input fullWidth label={'Error'} value={error?.message} onChange={() => {}} />
          </div>
          <Line />
          <div
            style={{
              padding: 1,
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                flex: 1,
                overflowY: 'scroll',
              }}
            >
              <pre
                style={{
                  fontSize: 12,
                  padding: 16,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {error?.stackTrace}
              </pre>
            </div>
          </div>
        </div>
      )}
      {tab === 'configuration' && (
        <div style={{overflow: 'hidden'}}>
          <Configuration
            data={configuration}
            schema={[
              {
                id: 'key',
                label: 'Key',
                copy: true,
              },
              {
                id: 'value',
                label: 'Value',
                copy: true,
                obfuscate: true,
              },
            ]}
          />
        </div>
      )}
    </SlideTransition>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default WorkloadsRequestDetailsView
