import {createAsyncThunk} from '@reduxjs/toolkit'
import {assocPath, dissocPath, identity, pipe, reduce, startsWith} from 'ramda'
import {calculateBody, calculateHeaders, calculateUrl} from './helpers'
import {EMPTY_OBJECT} from '../constants'

function parseError(response) {
  const status = response.status.toString()
  return response.text().then((text) => {
    try {
      return {message: JSON.parse(text), status}
    } catch (err) {
      return {message: text, status}
    }
  })
}

const asyncThunk = ({
  type,
  query,
  method = 'GET',
  mock,
  variables = EMPTY_OBJECT,
  transform = identity,
  path = '',
  api = true,
  lineage = false,
}) =>
  createAsyncThunk(
    type,
    (args) =>
      new Promise((resolve, reject) => {
        const body = calculateBody(args, method, variables, query)
        const url = calculateUrl(args, path, method, api, lineage)
        const headers = calculateHeaders(args)

        if (mock) {
          resolve(mock)
        } else {
          fetch(url, {
            method,
            headers,
            body,
          })
            .then((res) => {
              const contentDisposition = res.headers.get('content-disposition')
              if (contentDisposition) {
                return {
                  content: res,
                  filename: contentDisposition,
                }
              } else {
                return parseError(res)
              }
            })
            .then(({message, status}) => {
              if (startsWith('401', status)) {
                reject(new Error(`${status}: Unauthorized`))
              } else if (startsWith('5', status)) {
                reject(new Error(`${status}: ${message}`))
              } else if (startsWith('4', status)) {
                reject(new Error(`${status}: ${message}`))
              }
              return resolve(transform(message))
            })
            .catch((error) => {
              return reject(new Error(error))
            })
        }
      })
  )
const defaultFn = () => (state) => state

export const extraReducersMapper =
  (calls = []) =>
  (builder) =>
    reduce(
      (
        accumulator,
        {asyncThunk, name, pendingFn, fulfilledFn = defaultFn, rejectedFn = defaultFn}
      ) =>
        accumulator
          .addCase(asyncThunk.pending, (state) =>
            pipe(
              assocPath(['data', name, 'pending'], true),
              assocPath(['data', name, 'error'], false),
              dissocPath(['data', name, 'reject']),
              (state) => (pendingFn ? pendingFn(state) : state)
            )(state)
          )
          .addCase(asyncThunk.fulfilled, (state, {payload, meta}) =>
            pipe(
              assocPath(['data', name, 'response'], payload),
              assocPath(['data', name, 'pending'], false),
              fulfilledFn(payload, meta)
            )(state)
          )
          .addCase(asyncThunk.rejected, (state, {payload}) =>
            pipe(
              dissocPath(['data', name, 'response']),
              assocPath(['data', name, 'reject'], payload),
              assocPath(['data', name, 'pending'], false),
              assocPath(['data', name, 'error'], true),
              rejectedFn(payload)
            )(state)
          ),
      builder,
      calls
    )

export default asyncThunk
