import asyncThunk from "../../index";

export const disableSchedule = asyncThunk({
  method: 'POST',
  type: 'schedule/activate',
  path: '/schedule/${id}/stop',
  api: true,
})

export default disableSchedule
