import {Tabs} from "frontcore"
import {memo, useState} from "react"
import styled from "styled-components"
import ResultsList from "./ResultsList"
import ResultsExplorer from "./ResultsExplorer"
import ResultsStatistics from "./ResultsStatistics"
import ResultsLogs from "./ResultsLogs"
import {pipe, propOr} from "ramda"
import {curry} from "ramda"
import StatusIndicator from "../../../components/StatusIndicator"

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const TabsRoot = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
`

const formatDuration = curry((from, to, info) => {
  const timeUnits = Object.freeze([
    ['ms', 1000],
    ['s',    60],
    ['min',  60],
    ['h',    24],
  ])
  const difference = info[to] - info[from]
  const { fragments } = timeUnits.reduce(({difference, fragments}, [postfix, modulo]) => {
    return Object.freeze({
      difference: ~~(difference / modulo),
      fragments: [...fragments, {
        value: difference % modulo,
        postfix,
      }]
    })
  }, { difference, fragments: [] })
  return fragments
    .filter(({ value }) => value > 0)
    .map(({ value, postfix }) => `${value}${postfix}`)
    .reverse()
    .join(' ')
})


const TABS = Object.freeze([
  {
    id: 'list',
    children: 'Data List',
  },
  {
    id: 'explorer',
    children: 'Data Explorer',
  },
  {
    id: 'statistics',
    children: 'Statistics',
  },
  {
    id: 'logs',
    children: 'Logs',
  },
])

const NotebookResults = ({ results }) => {
  const [tab, setTab] = useState('list')

  const runtime = pipe(
    propOr([], 'info'),
    formatDuration('startTime', 'completionTime')
  )(results)

  return (
    <Root>
      <TabsRoot>
        <Tabs
          value={tab}
          onChange={setTab}
          options={TABS}
        />
        <div style={{ marginLeft: 16 }}>
          <StatusIndicator
            variant='success'
            value={`Finished in ${runtime}`}
          />
        </div>
      </TabsRoot>
      <div style={{ flex: 1 }}>
      {tab === 'list' && (
        <ResultsList results={results} />
      )}
      {tab === 'explorer' && (
        <ResultsExplorer results={results} />
      )}
      {tab === 'statistics' && (
        <ResultsStatistics results={results} />
      )}
      {tab === 'logs' && (
        <ResultsLogs results={results} />
      )}
      </div>
    </Root>
  )
}

export default memo(NotebookResults)
