import React, {useCallback, useMemo} from 'react';
import ToolTip from '../../../../components/toolTip';
import {Button, DropDownButton, Icon} from 'frontcore';
import ToolTipContentLayout from '../../../../components/layouts/toolTip/toolTipContentLayout';
import DropDownButtonContentLayout from '../../../../components/layouts/dropDownButtonContentLayout'
const JobButtons = (props) => {

  const {
    jobId,
    onKill,
    onDelete,
    onSpark,
    onClone,
    jobName,
    jobType = 'SINGLE',
    clusterType,
    state = 'UNKNOWN',
    texts = true,
    pending = false,
    disabled = false
  } = props;

  const handleOnKill = useCallback(
    () => onKill({id: jobId}),
    [jobId, onKill],
  );

  const handleOnDelete = useCallback(
    () => onDelete({id: jobId}),
    [jobId, onDelete],
  );

  const handleOnClone = useCallback(() => {
    onClone({ id: jobId })
  }, [jobId, onClone])

  const defaultProps = useMemo(() => ({
    variant: 'text',
    tabIndex: -1,
    skeleton: pending,
  }), [pending]);

  const size = 24;

  const buttonProps = useMemo(() => texts ? {square: false} : {square: true,justifyContent: 'center'}, [texts]);

  const sparkProps = useMemo(() => texts ?
    {StartComponent: Icon, startComponentProps: {icon: 'spark', size}, children: 'Spark'} :
    {Component: Icon, componentProps: {icon: 'spark', size}}, [texts]);

  const yarnProps = useMemo(() => texts ?
    {StartComponent: Icon, startComponentProps: {icon: 'hadoop', size}, children: 'Yarn'} :
    {Component: Icon, componentProps: {icon: 'hadoop', size}}, [texts]);


  const killButtonProps = useMemo(() => texts ?
    {StartComponent: Icon, startComponentProps: {icon: 'close', size}, children: 'Kill'} :
    {Component: Icon, componentProps: {icon: 'close', size}}, [texts]);

  const deleteButtonProps = useMemo(() => texts ?
    {StartComponent: Icon, startComponentProps: {icon: 'trash', size}, children: 'Delete'} :
    {Component: Icon, componentProps: {icon: 'trash', size}}, [texts]);

  const cloneButtonProps = useMemo(() => {
    return texts
      ? {StartComponent: Icon, startComponentProps: {icon: 'plus', size}, children: 'Clone'}
      : {Component: Icon, componentProps: {icon: 'plus', size}}
  }, [texts])

  return (
    <div style={{display: 'flex', gap: 8}}>
      <a target='_blank' href={`/core/api/dev/reactive/job/${jobId}/ui`} style={{textDecoration: 'none'}}>
        {onSpark && (<ToolTip
          content={<ToolTipContentLayout>Redirect to Spark App</ToolTipContentLayout>}
        >
          <Button
            {...defaultProps}
            {...sparkProps}
            {...buttonProps}
            disabled={disabled}
          />
        </ToolTip>)}
      </a>
      {clusterType === 'YARN' && <a target='_blank' href={`/core/api/dev/reactive/job/${jobId}/clusterui`} style={{textDecoration: 'none'}}>
        <ToolTip
          content={<ToolTipContentLayout>Redirect to Yarn App</ToolTipContentLayout>}
        >
          <Button
            {...defaultProps}
            {...yarnProps}
            {...buttonProps}
          />
        </ToolTip>
      </a>}
      {state !== 'FINISHED' && state !== 'FAILED' && (
        <ToolTip content={<ToolTipContentLayout>Kill</ToolTipContentLayout>}>
          <DropDownButton
            ContentComponent={({closeFn}) => (
              <DropDownButtonContentLayout>
                <div style={{fontSize: 16}}>Kill Job: {jobName}</div>
                <div style={{fontSize: 12}}>
                  Are You sure you want to kill this job?
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color={'error'}
                    tabIndex={-1}
                    onClick={() => {
                      handleOnKill();
                      closeFn();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    tabIndex={-1}
                    variant={'outlined'}
                    onClick={() => {
                      closeFn();
                    }}
                  >
                    No
                  </Button>
                </div>
              </DropDownButtonContentLayout>
            )}
            buttonProps={{
              ...killButtonProps,
              ...defaultProps,
              ...buttonProps,
              disabled,
            }}
          />
        </ToolTip>
      )}
      <ToolTip content={<ToolTipContentLayout>Delete</ToolTipContentLayout>}>
        <DropDownButton
          ContentComponent={({closeFn}) => (
            <DropDownButtonContentLayout>
              <div style={{fontSize: 16}}>Delete Job: {jobName}</div>
              <div style={{fontSize: 12}}>
                Are You sure you want to delete this job?
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color={'error'}
                  tabIndex={-1}
                  onClick={() => {
                    handleOnDelete();
                    closeFn();
                  }}
                >
                  Yes
                </Button>
                <Button
                  tabIndex={-1}
                  variant={'outlined'}
                  onClick={() => {
                    closeFn();
                  }}
                >
                  No
                </Button>
              </div>
            </DropDownButtonContentLayout>
          )}
          buttonProps={{
            ...deleteButtonProps,
            ...defaultProps,
            ...buttonProps,
            disabled,
          }}
        />
      </ToolTip>
      {jobType === 'SINGLE' && onClone && <ToolTip content={<ToolTipContentLayout>Clone</ToolTipContentLayout>}>
        <DropDownButton
          ContentComponent={({ closeFn }) => (
            <DropDownButtonContentLayout>
              <div style={{fontSize: 16}}>Clone Job: {jobName}</div>
              <div style={{fontSize: 12}}>
                Are You sure you want to clone this job?
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color={'error'}
                  tabIndex={-1}
                  onClick={() => {
                    handleOnClone()
                    closeFn();
                  }}
                >
                  Yes
                </Button>
                <Button
                  tabIndex={-1}
                  variant={'outlined'}
                  onClick={() => {
                    closeFn();
                  }}
                >
                  No
                </Button>
              </div>
            </DropDownButtonContentLayout>
          )}
          buttonProps={{
            ...defaultProps,
            ...buttonProps,
            ...cloneButtonProps,
            disabled,
          }}
        />
      </ToolTip>}
    </div>
  );
};

export default JobButtons;
