import Filters from '../../../filters'
import RequestsList from '../../../Components/RequestsList'
import ListLayout from '../../../../../components/layouts/listLayout'
import React from 'react'
import schema from './schema'
import usePending from '../../../../../hooks/usePending'
import {useListRefreshFuture} from '../../../hooks'
import {EMPTY_OBJECT} from '../../../../../constants'
import getRequests from "../../../../../api/workloads/requests/getRequests";

const ClusterDetailsRequestsList = ({
  clusterId,
  clusterName,
}) => {
  const listHandler = useListRefreshFuture(
    getRequests,
    'workloads',
    'requests',
    {
      page: 0,
        size: 25,
      sort: {
        startTime: 'desc',
      },
      filter: EMPTY_OBJECT,
    },
    {clusterNames: [clusterName]}
  )

  const pending = usePending(
    ['workloads', 'data'],
    ['requestsDetails', 'deleteCluster']
  )

  return (
    <ListLayout
      filtersNode={
        <Filters
          name={'requestsDetails'}
          schema={schema}
          pending={pending}
        />
      }
      contentNode={
        <RequestsList
          pending={pending}
          listHandler={listHandler}
          borderTop={true}
        />
      }
    />
  )
}

export default ClusterDetailsRequestsList
