import {useCallback, useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import Form from '../../../../components/Form'
import TabsFormLayout from '../../../../components/tabsFormLayout'
import formSchema from './schema'
import usePending from '../../../../hooks/usePending'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFuture from '../../../../hooks/useFuture'
import {createSchedule} from '../../../../api/workloads/schedules/createSchedule'
import {fork} from 'fluture'
import useFetch from '../../../../hooks/useFetch'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import {useDispatch} from 'react-redux'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {head, isNil, prop, propOr} from 'ramda'
import SecurityFormLayout from '../../../../components/SecurityFormLayout'
import getClusterMemorySettingsFetch from '../../../../api/workloads/clusters/getClusterMemorySettingsFetch'
import {EMPTY_OBJECT} from '../../../../constants'

const DATA_TRANSFORM = Object.freeze({
  cron: (cronValue) => {
    const parts = cronValue.split(' ')
    if (parts.length > 5) parts.pop()
    if (parts.length > 5) parts.shift()
    return parts.join(' ')
  },
})

const WorkloadsCreateScheduleView = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      setBreadCrumbs({
        schedule: {
          name: 'Schedules',
        },
      })
    )
  }, [])

  const navigate = useNavigate()
  const pending = usePending(['workloads', 'data'], ['createSchedule'])
  const {createNotification} = useNotificationContext()
  const createScheduleFuture = useFuture(createSchedule)

  const handleSubmit = useCallback(
    (fields) => {
      const formData = Object.entries(fields).reduce((formData, [fieldName, fieldValue]) => {
        if (isNil(fieldValue)) {
          return formData
        }
        if (fieldName in DATA_TRANSFORM) {
          fieldValue = DATA_TRANSFORM[fieldName](fieldValue)
        }
        if (fieldValue instanceof Array) {
          fieldValue.forEach(([k, v]) => {
            formData.append(fieldName, k, v)
          })
        } else {
          formData.append(fieldName, fieldValue)
        }
        return formData
      }, new FormData())

      fork(({message}) => {
        createNotification({
          title: 'Error',
          message,
          autoHide: true,
          variant: 'error',
        })
      })(() => {
        navigate(-1)
        createNotification({
          title: 'Success',
          message: 'Schedule created successfully!',
          autoHide: true,
          variant: 'success',
        })
      })(createScheduleFuture(formData))
    },
    [navigate, createNotification, createScheduleFuture]
  )

  const {data: clusters, error} = useFetch(getClustersNamesFetch)

  const cluster = useMemo(() => propOr(EMPTY_OBJECT, 0, clusters), [clusters])

  const {data: clusterMemorySettings} = useFetch(getClusterMemorySettingsFetch, EMPTY_OBJECT, {
    params: [{key: 'id', value: cluster.id}],
  })
  useEffect(() => {
    if (!error) return
    createNotification({
      title: 'Error',
      message: 'Failed to get clusters names',
      variant: 'error',
      autoHide: true,
    })
  }, [error])

  const query = useMemo(() => {
    const {search} = window.location
    return new URLSearchParams(search)
  }, [window.location])

  const options = useMemo(
    () =>
      Object.freeze({
        clusters,
        cluster: query.get('cluster'),
        pending,
        navigate,
      }),
    [pending, clusters, query]
  )

  const initialData = useMemo(
    () => ({
      clusterName: cluster.name,
      ...clusterMemorySettings,
    }),
    [cluster, clusterMemorySettings]
  )

  return (
    <Form
      initialData={initialData}
      options={options}
      LayoutComponent={SecurityFormLayout}
      schema={formSchema}
      onSubmit={handleSubmit}
      layoutComponentProps={{
        title: 'NEW SCHEDULE',
      }}
    />
  )
}

export default WorkloadsCreateScheduleView
