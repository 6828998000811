import React from 'react'
import styled from 'styled-components'
import {Icon} from "frontcore";

const Root = styled.div`
  font-family: Roboto,serif;
  color: ${({theme}) => theme.palette.text.secondary};
    font-size: 16px;
`

const BreadCrumbsSeparatorComponent = () => <Root><Icon icon={'arrow-right'} size={14}/></Root>

export default BreadCrumbsSeparatorComponent
