import asyncThunk from "../../index";

export const getSchedulesMinor = asyncThunk({
  method: 'GET',
  type: 'schedules/minor/get',
  path: '/schedule/minor',
  api: true,
})

export default getSchedulesMinor
