import React, {useCallback} from 'react'
import ClustersList from '../../Components/ClustersList'
import Filters from '../../filters'
import {useNavigate} from 'react-router-dom'
import {Button, Icon, Input} from 'frontcore'
import ListLayout from '../../../../components/layouts/listLayout'
import {useListRefreshFuture} from '../../hooks'
import getClusters from '../../../../api/workloads/clusters/getClusters'
import usePending from '../../../../hooks/usePending'
import {isNotEmpty} from '../../../../helpers'
import ClusterBatchButtons from '../../Components/ClusterBatchButtons'
import recreateCluster from '../../../../api/workloads/clusters/recreateCluster'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFuture from '../../../../hooks/useFuture'
import {and, isEmpty, pipe} from 'ramda'
import {fork} from 'fluture'

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        size: 16,
        icon: 'search',
      },
      placeHolder: 'Clusters Search',
      topLabelProps: {
        children: 'Clusters Search',
      },
      label: 'Clusters Search',
    },
  },
]

const WorkloadsClustersListView = () => {
  const navigate = useNavigate()

  const listHandler = useListRefreshFuture(
    getClusters,
    'workloads',
    'clusters'
  )

  const {selected, data, getFuture,lists} = listHandler

  const {filter} = lists

  const pending = usePending(
    ['workloads', 'data'],
    [
      'clusters',
      'createCluster',
      'deleteCluster',
      'recreateCluster',
    ]
  )

  const {createNotification} = useNotificationContext()

  const recreateClusterFuture = useFuture(recreateCluster)

  const handleOnClickNewClusterButton = useCallback(() => {
    navigate('/workloads/create/cluster')
  }, [])

  const handleOnClickRecreateDefaultClusterButton =
    useCallback(() => {
      pipe(
        and(getFuture),
        fork(({message}) => {
          createNotification({
            message:
              'An error occurred while recreating the default clusters. Default clusters might already exist.',
            autoHide: false,
            variant: 'error',
          })
        })(() => {
          createNotification({
            message: 'Default clusters created successfully',
            autoHide: true,
            variant: 'success',
          })
        })
      )(recreateClusterFuture())
    }, [getFuture])


  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <ClusterBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'clusters'}
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <div style={{display: 'flex', gap: '10px'}}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleOnClickNewClusterButton}
            EndComponent={Icon}
            endComponentProps={{
              icon: 'plus',
              size: 18,
              color: 'white',
            }}
          >
            New Cluster
          </Button>
          {isEmpty(data) && isEmpty(filter) && (
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={
                handleOnClickRecreateDefaultClusterButton
              }
            >
              Restore Default Cluster
            </Button>
          )}
        </div>
      }
      contentNode={
        <ClustersList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default WorkloadsClustersListView
