import {memo, useCallback, useEffect} from 'react'
import ToolTip from '../../../components/toolTip'
import {Button, Icon} from 'frontcore'
import {useSelector} from 'react-redux'
import {isNil, path, pathOr} from 'ramda'
import useFuture from '../../../hooks/useFuture'
import getSQLEngines from '../../../api/sql/getSQLEngines'
import {fork} from 'fluture'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {EMPTY_FUNCTION} from '../../../constants'

const EditorRefreshEngines = () => {
  const chosenClusterId = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenCluster', 'id'])
  )

  const getSQLEnginesFuture = useFuture(getSQLEngines)
  const { createNotification } = useNotificationContext()
  const refreshEngines = useCallback(() => {
    if (isNil(chosenClusterId)) return
    const future = getSQLEnginesFuture({
      params: [{ key: 'clusterId', value: chosenClusterId, }]
    })
    fork(({message}) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(future)
  }, [chosenClusterId])

  const refreshRate = useSelector(pathOr(10 * 1000, ['settings', 'settings', 'sqlEngineRefreshRate']))

  useEffect(() => {
    const handle = setInterval(() => {
      refreshEngines()
    }, refreshRate)
    return () => {
      clearInterval(handle)
    }
  }, [refreshEngines, refreshRate])

  const pending = useSelector(path(['sqlViewer', 'editor', 'data', 'liveEngines', 'pending']))

  return (
    <ToolTip content={(
      <p style={{ padding: '0px 8px' }}>Refresh engines</p>
    )} config={{ position: 'bottom_right' }}>
      <Button
        skeleton={pending}
        variant={'text'}
        square
        Component={Icon}
        componentProps={{size: 16, icon: 'restart'}}
        justifyContent={'center'}
        tabIndex={-1}
        onClick={refreshEngines}
      />
    </ToolTip>
  )
}

export default memo(EditorRefreshEngines)
