

const statusVariantMap = {
    SUBMITTED: 'information',
    STARTING: 'warning',
    READY: 'success',
    BUSY: 'warning',
    FAILED: 'error',
    FINISHED: 'default',
    UNKNOWN: 'default',
    ACTIVE: 'success',
    ARCHIVED: 'default',
    PAUSED: 'default',
    ENABLED: 'success',
    DISABLED: 'default'
}

export default statusVariantMap