import React from 'react'
import {Button, Icon} from 'frontcore'
import styled, {useTheme} from 'styled-components'
import DropDownButtonContentLayout from "../layouts/dropDownButtonContentLayout";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${({theme}) => theme.palette.red[600]} !important;
`

const TopContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  gap: 10px;
  align-items: center;
`

const MiddleContainer = styled.div`
  font-size: 14px;
`

const BottomContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`

const DeleteElementModalComponent = ({
  closeFn,
  title = 'No Title',
  message = 'No Message',
  onDelete,
  confirmButtonLabel = 'Yes, Delete',
  rejectButtonLabel = 'No, Cancel',
}) => {
  const {palette} = useTheme()

  return (
    <DropDownButtonContentLayout width={'300px'}>
      <Root>
        <TopContainer>
          <Icon
            icon="alert-contained"
            size={24}
            color={palette.red[600]}
          />
          {title}
        </TopContainer>
        <MiddleContainer>{message}</MiddleContainer>
        <BottomContainer>
          <Button
            color={'error'}
            tabIndex={-1}
            onClick={() => {
              onDelete()
              closeFn()
            }}
          >
            {confirmButtonLabel}
          </Button>

          <Button
            tabIndex={-1}
            variant={'outlined'}
            onClick={() => {
              closeFn()
            }}
          >
            {rejectButtonLabel}
          </Button>
        </BottomContainer>
      </Root>
    </DropDownButtonContentLayout>
  )
}

export default DeleteElementModalComponent
