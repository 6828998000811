import styled from 'styled-components'


const Root = styled.div`
    background: ${({theme}) => theme.palette['surface-primary']};
    padding-bottom: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color:${({theme}) => theme.palette['surface-tertiary']};
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`
const ChartPaper = ({children}) => {
  return <Root>{children}</Root>
}

export default ChartPaper;