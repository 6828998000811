import asyncThunk from "../../index";

export const getRequests = asyncThunk({
  method: "GET",
  type: "requests/get",
  path: "/request",
  api: true
});

export default getRequests;
