import {useCallback} from 'react'
import ListLayout from '../../../../components/layouts/listLayout'
import {Button, Icon} from 'frontcore'
import Filters from '../../filters'
import SchedulesList from '../../Components/SchedulesList'
import usePending from '../../../../hooks/usePending'
import {useListRefreshFuture} from '../../hooks'
import {EMPTY_OBJECT} from '../../../../constants'
import getSchedules from '../../../../api/workloads/schedules/getSchedules'
import {useNavigate} from 'react-router-dom'
import schema from './schema'
import {isNotEmpty} from '../../../../helpers'
import ScheduleBatchButtons from '../../Components/ScheduleBatchButtons'

const defaultListOptions = Object.freeze({
  page: 0,
    size: 25,
  sort: EMPTY_OBJECT,
  filter: EMPTY_OBJECT,
})

const WorkloadsSchedulesListView = () => {
  const pending = usePending(
    ['workloads', 'data'],
    ['schedules', 'stopSchedule', 'deleteSchedule']
  )

  const listHandler = useListRefreshFuture(
    getSchedules,
    'workloads',
    'schedules',
    defaultListOptions
  )

  const {selected} = listHandler

  const navigate = useNavigate()
  const handleNewSchedule = useCallback(() => {
    navigate('/workloads/create/schedule')
  }, [])

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <ScheduleBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name="schedules"
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewSchedule}
          EndComponent={Icon}
          endComponentProps={{
            icon: 'plus',
            size: 18,
            color: 'white',
          }}
        >
          New Schedule
        </Button>
      }
      contentNode={
        <SchedulesList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default WorkloadsSchedulesListView
