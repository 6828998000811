import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  EMPTY_STRING,
} from '../constants'
import {
  always,
  append,
  assoc,
  concat,
  dissoc,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  reduce,
  reject as Rreject,
  replace,
  toPairs,
  type as RType,
  when,
} from 'ramda'
import {getBaseUrl} from '../helpers'
import {User} from 'oidc-client-ts'

const calculateEndpointUrl = (
  path = EMPTY_STRING,
  parameters = EMPTY_ARRAY
) => {
  let endpointUrl = path
  if (parameters) {
    endpointUrl = reduce(
      (acc, {key, value}) => {
        return replace('${' + key + '}', value, acc)
      },
      path,
      parameters
    )
  }
  return endpointUrl
}

function getUser() {
  const clientId =
    window.__RUNTIME_CONFIG__.ILUM_OAUTH2_CLIENT_ID
  const authority =
    window.__RUNTIME_CONFIG__.ILUM_OAUTH2_ISSUER_URI

  const oidcStorage = localStorage.getItem(
    `oidc.user:${authority}:${clientId}`
  )
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

const calculateParametersUrl = (args, method) => {
  let parameters
  if (method === 'GET') {
    parameters = pipe(
      Rreject(isEmpty),
      Rreject(isNil),
      toPairs,
      Rreject((a) => a[0] === 'params'),
      reduce((acc, [key, value]) => {
        if (RType(value) === 'Array') {
          const result = map((v) => [key, [v]], value)
          return concat(result, acc)
        } else {
          return append([key, [value]], acc)
        }
      }, []),
      map(([key, value]) => key + '=' + join(',', value)),
      join('&')
    )(args)
  }
  return parameters ? '?' + parameters : EMPTY_STRING
}

export const calculateApiUrl = (api, lineage) => {
  return !lineage && api
    ? process.env.REACT_APP_API_URL || EMPTY_STRING
    : EMPTY_STRING
}

const calculateUrl = (
  args = EMPTY_OBJECT,
  path,
  method,
  api,
  linage
) => {
  const baseUrl = getBaseUrl(linage)
  const endpointUrl = calculateEndpointUrl(
    path,
    args.params
  )
  const parametersUrl = calculateParametersUrl(args, method)
  const apiUrl = calculateApiUrl(api, linage)
  return baseUrl + apiUrl + endpointUrl + parametersUrl
}

const calculateHeaders = (args) => {
  const user = getUser()
  const token = localStorage.getItem('ilum_token')

  return pipe(
    when(
      always(user?.id_token),
      assoc('Authorization', `Bearer ${user?.id_token}`)
    ),
    when(
      always(token),
      assoc('Authorization', `Bearer ${token}`)
    ),
    when(
      () => !(args instanceof FormData),
      assoc('Content-Type', 'application/json')
    )
  )({
    Accept: 'application/json, text/plain, */*',
  })
}

const calculateBody = (args, method, variables, query) => {
  let body
  if (
    ['POST', 'DELETE', 'PUT'].includes(method.toUpperCase())
  ) {
    if (args instanceof FormData) {
      body = args
    } else {
      body = query
        ? JSON.stringify({
            query,
            variables: {...variables, ...args},
          })
        : pipe(dissoc('params'), JSON.stringify)(args)
    }
  }
  return body
}

const singleCallFetchOptions = Object.freeze({
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  shouldRetryOnError: false,
  refreshInterval: 0,
})

export {
  getUser,
  calculateUrl,
  calculateHeaders,
  calculateBody,
  singleCallFetchOptions,
}
