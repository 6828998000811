import styled, {css} from 'styled-components'
import {isNil} from 'ramda'
import React from 'react'

const sizeSmallCss = css`
    font-size: 16px;
    span {
        font-size: 12px;
    }
`

const sizeMediumCss = css`
    font-size: 24px;
    span {
        font-size: 18px;
    }
`

const sizeLargeCss = css`
    font-size: 32px;
    span {
        font-size: 24px;
    }
`

const sizeStylesMap = {
  small: sizeSmallCss,
  medium: sizeMediumCss,
  large: sizeLargeCss,
}

const Root = styled.div`
  ${({$size}) => sizeStylesMap[$size]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  color: ${({theme,$color}) => $color || theme.palette.neutral[500]};
`

const Span = styled.span`
 
  color: ${({theme}) => theme.palette.neutral[300]};
`
const Metric = ({value, unit, size='medium', color}) => {
  return (
    <Root $size={size} $color={color}>
      {value}
      <Span>{isNil(value) ? 'N/A' : unit}</Span>
    </Root>
  )
}
export default Metric
