import {Icon, Input} from 'frontcore';

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Requests Search',
      topLabelProps: {
        children: 'Requests Search',
      },
    },
  },
];

export default schema;