import {Button, Icon} from 'frontcore'
import React from 'react'
import JobsList from '../../Components/JobsList'
import getJobs from '../../../../api/workloads/jobs/getJobs'
import Filters from '../../filters'
import {EMPTY_OBJECT} from '../../../../constants'
import {useListRefreshFuture} from '../../hooks'
import usePending from '../../../../hooks/usePending'
import {useNavigate} from 'react-router-dom'
import ListLayout from '../../../../components/layouts/listLayout'
import {isNotEmpty} from '../../../../helpers'
import JobBatchButtons from '../../Components/JobBatchButtons'
import schema from './schema'

const WorkloadsJobsListView = () => {
  const navigate = useNavigate()

  const listHandler = useListRefreshFuture(
    getJobs,
    'workloads',
    'jobs',
    {
      page: 0,
      size: 25,
      sort: {
        submitTime: 'desc',
      },
      filter: EMPTY_OBJECT,
    }
  )

  const {selected, getFuture} = listHandler

  const pending = usePending(
    ['workloads', 'data'],
    ['jobs', 'killJob', 'deleteJob']
  )

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <JobBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'jobs'}
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            navigate('/workloads/create/job')
          }}
          EndComponent={Icon}
          endComponentProps={{
            icon: 'plus',
            size: 18,
            color: 'white',
          }}
        >
          New Job
        </Button>
      }
      contentNode={
        <JobsList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default WorkloadsJobsListView
