import {Select} from 'frontcore'
import {isNil, path, pathOr, propOr} from 'ramda'
import {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {editorChooseEngine} from '../../../reducers/sql/editor'
import StatusIndicator from '../../../components/StatusIndicator'
import styled, {css} from 'styled-components'
import useFuture from '../../../hooks/useFuture'
import getSQLEngines from '../../../api/sql/getSQLEngines'
import {fork} from 'fluture'
import {
  EMPTY_FUNCTION,
  EMPTY_OBJECT,
} from '../../../constants'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import ToolTip from '../../../components/toolTip'
import EngineState from '../EngineState'

const ENGINES = Object.freeze([
  {
    type: 'SPARK_SQL',
    available: true,
  },
  {
    type: 'TRINO',
    available: true,
  },
  {
    type: 'FLINK_SQL',
    available: false,
  },
  {
    type: 'HIVE_SQL',
    available: false,
  },
])

const BADGE_MAPPING = Object.freeze({
  SOON: 'information',
  [EngineState.RUNNING]: 'success',
  [EngineState.STARTING]: 'warning',
  [EngineState.FAILED]: 'error',
  OFF: 'error',
})

const EngineCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.1s background-color ease-in-out;
  gap: 16px;
  color: ${({ theme }) => theme.palette.text.tertiary};
  ${({$chosen, theme}) =>
    $chosen &&
    css`
    // background-color: #eee;
      background-color: ${theme.palette['surface-tertiary']};
    `}
  &:hover {
    // background-color: #dadada;
    background-color: ${({ theme }) => theme.palette['surface-tertiary']};
  }
`

const EditorEngineSelector = () => {
  const chosenCluster = useSelector(
    pathOr(EMPTY_OBJECT, [
      'sqlViewer',
      'sidebar',
      'chosenCluster',
    ])
  )

  const sqlEngines = useSelector(
    pathOr(
      [],
      [
        'sqlViewer',
        'editor',
        'data',
        'liveEngines',
        'response',
      ]
    )
  )

  const engineInformation = useMemo(() => {
    return ENGINES.map(({type, available}) => {
      const engineInformation = sqlEngines.find(engine => {
        return engine.type === type
      })
      const status = available
        ? propOr('OFF', 'state', engineInformation)
        : 'SOON'
      return Object.freeze({
        type,
        status,
        id: engineInformation?.id,
      })
    })
  }, [sqlEngines])

  const { createNotification } = useNotificationContext()
  const getSQLEnginesFuture = useFuture(getSQLEngines)
  const handleRefreshEngines = useCallback(() => {
    if (isNil(chosenCluster)) return
    const future = getSQLEnginesFuture({
      params: [{key: 'clusterId', value: chosenCluster.id}],
    })
    fork(({message}) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(future)
  }, [chosenCluster])

  const currentEngine = useSelector(
    path(['sqlViewer', 'editor', 'chosenEngine'])
  )

  return (
    <div onClick={handleRefreshEngines}>
      <Select
        label="Engine"
        ItemComponent={EngineSelectOption}
        options={engineInformation.map(opt => ({
          ...opt,
          currentEngine,
        }))}
        value={currentEngine}
      />
    </div>
  )
}

export default memo(EditorEngineSelector)

const EngineSelectOption = memo(
  ({value: engine, closeFn}) => {
    const dispatch = useDispatch()

    const [engineStatus, setEngineStatus] = useState(engine.status)
    useEffect(() => {
      setEngineStatus(engine.status)
    }, [engine.status])

    const handleClick = useCallback(() => {
      if (engineStatus === 'SOON') return
      dispatch(editorChooseEngine(engine.type))
      closeFn()
    }, [engineStatus, engine])

    const cellFragment = (
      <EngineCell
        $soon={engineStatus === 'SOON'}
        $chosen={engine.type === engine.currentEngine}
        onClick={handleClick}
      >
        <p>{engine.type}</p>
        <StatusIndicator
          variant={BADGE_MAPPING[engineStatus]}
          value={engineStatus}
        />
      </EngineCell>
    )

    return engineStatus === 'SOON' ? (
      <ToolTip
        content={
          <div style={{padding: 8}}>
            <b>{engine.type}</b> is coming soon!
          </div>
        }
      >
        {cellFragment}
      </ToolTip>
    ) : (
      cellFragment
    )
  }
)
