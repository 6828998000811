import {memo} from "react"
import styled from "styled-components"
import EnhancedSpinner from '../../../components/spinner/enhanced'

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NotebookPending = () => {
  return (
    <Root>
      <EnhancedSpinner
        scale={2}
      />
    </Root>
  )
}

export default memo(NotebookPending)
