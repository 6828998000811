import React from 'react'
import StatusIndicator from '../StatusIndicator'
import statusVariantMap from "../../helpers/statusVariantMap";
import {EMPTY_STRING} from "../../constants";
import {toLower} from "ramda";

const ServiceState = ({value}) => (
  <StatusIndicator value={toLower(value || EMPTY_STRING)} variant={statusVariantMap[value]}/>
);

export default ServiceState;