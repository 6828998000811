import {useDispatch, useSelector} from 'react-redux'
import React, {useCallback} from 'react'
import {path, pipe, propEq, when} from 'ramda'
import {BreadCrumbs} from 'frontcore'
import RButton from '../../../../components/RButton'
import {setSelectedElement} from '../../../../reducers/tableExplorer'
import styled from "styled-components";

export const propsMap = {
  metaStore: {
    icon: 'server',
  },
  catalog: {
    icon: 'directory',
  },
  database: {
    icon: 'database',
  },
  table: {
    icon: 'table',
  },
}

const SeparatorRoot = styled.div`
    color: ${({theme}) => theme.palette.text.tertiary};
`

const Separator = () => <SeparatorRoot>/</SeparatorRoot>

const BreadCrumbButton = (props) => {
  const {onClick, data} = props
  const {item, active} = data
  const {type} = item
  const name = item[type + 'Name']
  const extendedProps = propsMap[type]
  const handleOnClick = useCallback(() => {
    onClick(data)
  }, [data, onClick])

  return (
    <RButton
      icon={extendedProps.icon}
      name={name}
      onClick={handleOnClick}
      type={type}
      active={active}
    />
  )
}

const calculateMetaStoreBreadCrumbsData = ({metaStoreId, metaStoreName}) => [
  {
    item: {
      type: 'metaStore',
      metaStoreName,
      metaStoreId,
    },
    active: true,
  },
]

const calculateCatalogBreadCrumbsData = ({catalogId, catalogName, metaStoreId, metaStoreName}) => {
  const metaStore = {
    item: {
      type: 'metaStore',
      metaStoreName,
      metaStoreId,
    },
    active: false,
  }
  const catalog = {
    item: {
      type: 'catalog',
      catalogId,
      catalogName,
      metaStoreId,
      metaStoreName,
    },
    active: true,
  }
  return [metaStore, catalog]
}

const calculateDatabaseBreadCrumbsData = ({
  metaStoreName,
  metaStoreId,
  catalogId,
  catalogName,
  databaseId,
  databaseName,
}) => {
  const metaStore = {
    item: {
      metaStoreId,
      metaStoreName,
      type: 'metaStore',
    },
    active: false,
  }

  const catalog = {
    item: {
      catalogId,
      catalogName,
      type: 'catalog',
      metaStoreId,
      metaStoreName,
    },
    active: false,
  }
  const database = {
    item: {
      databaseId,
      databaseName,
      type: 'database',
      metaStoreId,
      metaStoreName,
      catalogId,
      catalogName,
    },
    active: true,
  }
  return [metaStore, catalog, database]
}

const calculateTableBreadCrumbsData = ({
  catalogId,
  catalogName,
  databaseId,
  databaseName,
  metaStoreName,
  metaStoreId,
  tableId,
  tableName,
}) => {
  const metaStore = {
    item: {
      metaStoreId,
      metaStoreName,
      type: 'metaStore',
    },
    active: false,
  }

  const catalog = {
    item: {
      catalogId,
      catalogName,
      type: 'catalog',
      metaStoreId,
      metaStoreName,
    },
    active: false,
  }
  const database = {
    item: {
      databaseId,
      databaseName,
      type: 'database',
      metaStoreId,
      metaStoreName,
      catalogId,
      catalogName,
    },
    active: false,
  }
  const table = {
    item: {
      tableName,
      tableId,
      type: 'table',
      metaStoreId,
      metaStoreName,
      catalogId,
      catalogName,
      databaseId,
      databaseName,
    },
    active: true,
  }
  return [metaStore, catalog, database, table]
}

const ExplorerBreadcrumbs = () => {
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))
  const data = pipe(
    when(propEq('metaStore', 'type'), calculateMetaStoreBreadCrumbsData),
    when(propEq('catalog', 'type'), calculateCatalogBreadCrumbsData),
    when(propEq('database', 'type'), calculateDatabaseBreadCrumbsData),
    when(propEq('table', 'type'), calculateTableBreadCrumbsData)
  )(selectedElement)

  const dispatch = useDispatch()

  const handleOnClick = useCallback(({item}) => {
    dispatch(setSelectedElement(item))
  }, [])
  return (
    <BreadCrumbs
      data={data || []}
      onClick={handleOnClick}
      ItemComponent={BreadCrumbButton}
      itemComponentProps={{}}
      SeparatorComponent={Separator}
    />
  )
}

export default ExplorerBreadcrumbs
