import styled from 'styled-components'
import {Button, Icon} from 'frontcore'
import {useCallback} from 'react'
import {startsWith} from 'ramda'

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8px;
  gap: 4px;
  align-items: center;
  background-color: ${({theme}) => theme.palette.blue[500]};
  color: ${({theme}) => theme.palette.neutral[0]};
  height: 16px;
  border-radius: 4px;
  position: relative;
  font-weight: 600;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Root = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const File = (a) => {

  const {filename, skeleton, onDelete, data, downloadable} = a

  const handleOnDelete = useCallback(() => {
    onDelete({filename})
  }, [filename, onDelete])

  const handleOnDownload = useCallback(() => {
    const element = document.createElement('a')
    element.setAttribute('download', filename)
    if (startsWith('TableExplorerView:', data)) {
      element.href = data
    } else {
      element.href = 'TableExplorerView:text;base64,' + data
    }

    element.setAttribute('target', '_blank')
    element.click()
  }, [filename, onDelete])

  return (
    <Root>
      <FileContainer $skeleton={skeleton}>
        {filename}
      </FileContainer>
      {downloadable &&
        <Button
          variant={'text'}
          square
          size={'small'}
          skeleton={skeleton}
          justifyContent={'center'}
          Component={Icon}
          componentProps={{
            icon: 'download',
            size: 16,
          }}
          onClick={handleOnDownload}
        />
      }
      <Button
        variant={'text'}
        square
        size={'small'}
        skeleton={skeleton}
        justifyContent={'center'}
        Component={Icon}
        componentProps={{
          icon: 'close',
          size: 16,
        }}
        onClick={handleOnDelete}
      />
    </Root>
  )
}

export default File
