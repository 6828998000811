import asyncThunk from "..";

export const deleteSQLNotebook = asyncThunk({
  method: 'DELETE',
  type: 'sql/notebook/delete',
  path: '/sql/notebooks/${notebookId}',
  api: true,
})

export default deleteSQLNotebook
