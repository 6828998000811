import {useEffect, useState} from 'react';

const useWindowSize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const {innerWidth, innerHeight} = window;
    setWidth(innerWidth);
    setHeight(innerHeight);
    window.addEventListener('resize', (event) => {
      const {innerWidth, innerHeight} = window;
      setWidth(innerWidth);
      setHeight(innerHeight);
    });
  }, []);

  return [width, height];
};

export default useWindowSize;
