import React, {useCallback, useMemo} from 'react'
import ToolTip from '../../../../components/toolTip'
import {Button, DropDownButton, Icon} from 'frontcore'
import styled from 'styled-components'
import ToolTipContentLayout from '../../../../components/layouts/toolTip/toolTipContentLayout'
import DeleteContentComponent from '../../../WorkloadsView/Components/ClusterButtons/deleteContentComponent'
import Switch from '../../../../components/Switch'

const Root = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const GroupButtons = ({
  id,
  name,
  onDelete,
  onEdit,
  onEnable,
  onDisable,
  onToggle,
  texts = true,
  pending = false,
  disabled = false,
  data,
}) => {
  const handleOnDelete = useCallback(
    () => onDelete({id}),
    [id, onDelete]
  )

  const handleOnEdit = useCallback(
    () => onEdit({id}),
    [id, onEdit]
  )

  const handleOnEnable = useCallback(
    () => onEnable({id}),
    [id, onEnable]
  )

  const handleOnDisable = useCallback(
    () => onDisable({id}),
    [id, onDisable]
  )

  const handleOnToggle = useCallback(
    () => onToggle({id}),
    [id, onToggle]
  )

  const size = 24
  const baseButtonsProps = useMemo(
    () => ({
      variant: 'text',
      tabIndex: -1,
      skeleton: pending,
    }),
    [pending]
  )

  const squareButtonProps = useMemo(
    () =>
      texts
        ? {square: false}
        : {square: true, justifyContent: 'center'},
    [texts]
  )

  const editButtonProps = useMemo(
    () =>
      texts
        ? {
            children: 'Edit',
            StartComponent: Icon,
            startComponentProps: {icon: 'pencil', size},
          }
        : {
            Component: Icon,
            componentProps: {icon: 'pencil', size},
          },
    [texts]
  )

  const deleteButtonProps = useMemo(
    () =>
      texts
        ? {
            children: 'Delete',
            StartComponent: Icon,
            startComponentProps: {icon: 'trash', size},
          }
        : {
            Component: Icon,
            componentProps: {icon: 'trash', size},
          },
    [texts]
  )

  const enableButtonProps = useMemo(
    () =>
      texts
        ? {
            children: 'Enable',
            StartComponent: Icon,
            startComponentProps: {icon: 'play', size},
          }
        : {
            Component: Icon,
            componentProps: {icon: 'play', size},
          },
    [texts]
  )

  const disableButtonProps = useMemo(
    () =>
      texts
        ? {
            children: 'Disable',
            StartComponent: Icon,
            startComponentProps: {icon: 'pause', size},
          }
        : {
            Component: Icon,
            componentProps: {icon: 'pause', size},
          },
    [texts]
  )

  return (
    <Root>
      {onToggle && (
        <Switch
          value={data?.state === 'ENABLED'}
          onClick={handleOnToggle}
        />
      )}
      {onEdit && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Edit
            </ToolTipContentLayout>
          }
        >
          <Button
            {...baseButtonsProps}
            {...squareButtonProps}
            {...editButtonProps}
            onClick={handleOnEdit}
            disabled={disabled}
          />
        </ToolTip>
      )}
      {onEnable && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Enable
            </ToolTipContentLayout>
          }
        >
          <Button
            {...baseButtonsProps}
            {...squareButtonProps}
            {...enableButtonProps}
            onClick={handleOnEnable}
            disabled={disabled}
          />
        </ToolTip>
      )}
      {onDisable && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Disable
            </ToolTipContentLayout>
          }
        >
          <Button
            {...baseButtonsProps}
            {...squareButtonProps}
            {...disableButtonProps}
            onClick={handleOnDisable}
            disabled={disabled}
          />
        </ToolTip>
      )}
      {onDelete && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Delete
            </ToolTipContentLayout>
          }
        >
          <DropDownButton
            ContentComponent={DeleteContentComponent}
            contentComponentProps={{
              name,
              onDelete: handleOnDelete,
            }}
            buttonProps={{
              ...baseButtonsProps,
              ...squareButtonProps,
              ...deleteButtonProps,
              disabled,
            }}
          >
            Delete
          </DropDownButton>
        </ToolTip>
      )}
    </Root>
  )
}

export default GroupButtons
