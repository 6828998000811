import asyncThunk from '../index'

export const getActivitiesSearch = asyncThunk({
  method: 'GET',
  type: 'activitiesSearch/get',
  path: '/activities',
  api: true,
})

export default getActivitiesSearch
