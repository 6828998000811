// global libraries
import { filter, pipe, prop, equals } from 'ramda'
import { createSlice } from '@reduxjs/toolkit'

// global imports
import { EMPTY_ARRAY } from '../../../constants'
import {notEquals} from '../../../helpers'

//==========SLICE==========

const initialState = {
  chosenItem: null,

  clusters: EMPTY_ARRAY,
  expandedClusters: EMPTY_ARRAY,

  storages: EMPTY_ARRAY,
  expandedStorages: EMPTY_ARRAY,

  tables:   EMPTY_ARRAY
}

const flatTreeSlice = createSlice({
  name: 'flatTree',
  initialState,
  reducers: {
    toggleChosenItem: (state, { payload }) => {
      if (equals(state.chosenItem, payload)) {
        state.chosenItem = null
        return
      }
      state.chosenItem = payload
    },

    setChosenItem: (state, { payload }) => {
      state.chosenItem = payload
    },

    addClusters: (state, { payload }) => {
      state.clusters = payload
    },
    toggleClusterExpansion: (state, { payload }) => {
      if (state.expandedClusters.includes(payload)) {
        state.expandedClusters = filter (
          notEquals(payload), 
          state.expandedClusters
        )
        return
      }
      state.expandedClusters.push(payload)
    },
    ensureClusterExpansion: (state, { payload }) => {
      if (state.expandedClusters.includes(payload)) return
      state.expandedClusters.push(payload)
    },

    addStorages: (state, { payload }) => {
      const { parent, children } = payload
      const filteredStorages = filter (
        pipe (
          prop('clusterId'),
          notEquals(parent.id)
        ),
        state.storages
      )
      state.storages = [...filteredStorages, ...children]
    },
    toggleStorageExpansion: (state, { payload }) => {
      if (state.expandedStorages.includes(payload)) {
        state.expandedStorages = filter (
          notEquals(payload),
          state.expandedStorages
        )
        return
      }
      state.expandedStorages.push(payload)
    },
    ensureStorageExpansion: (state, { payload }) => {
      if (state.expandedStorages.includes(payload)) return
      state.expandedStorages.push(payload)
    },

    addTables: (state, { payload }) => {
      const { parent, children } = payload
      const filteredTables = filter (table => {
        return table.clusterId !== parent.clusterId ||
          table.storageId !== parent.id
      }, state.tables)
      state.tables = [...filteredTables, ...children]
    },
  }
})

export default flatTreeSlice.reducer
export const {
  toggleChosenItem,
  setChosenItem,
  addClusters,
  toggleClusterExpansion,
  ensureClusterExpansion,
  addStorages,
  toggleStorageExpansion,
  ensureStorageExpansion,
  addTables
} = flatTreeSlice.actions

