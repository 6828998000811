import styled from 'styled-components'
import React, {useCallback} from 'react'
import {Button, Icon} from 'frontcore'
import ToolTip from '../../components/toolTip'
import {createTransitions} from '../../helpers'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import ToolTipContentLayout from '../../components/layouts/toolTip/toolTipContentLayout'

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  height: 24px;
  opacity: 0 !important;
  transition: ${createTransitions(['opacity'])};
`

const Value = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`

const Spacer = styled.div`
  width: 0;
  transition: ${createTransitions(['width'])};
`

const Root = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  height: 32px;
  font-weight: 500;
  &:hover {
    ${ButtonContainer} {
      opacity: 1 !important;
    }
    ${Spacer} {
      width: 40px;
    }
  }
`

const NameCell = ({value}) => {
  const {createNotification} = useNotificationContext()

  const handleOnClick = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      navigator.clipboard.writeText(value)
      createNotification({
        message: 'Text copied to clipboard',
        autoHide: true,
        variant: 'information',
        title: 'Clipboard',
      })
    },
    [value]
  )

  return (
    <Root>
      <Value> {value}</Value>
      <Spacer />
      <ButtonContainer>
        <ToolTip
          content={
            <ToolTipContentLayout>
              Copy Value To Clipboard
            </ToolTipContentLayout>
          }
        >
          <Button
            variant={'text'}
            square
            size={'small'}
            Component={Icon}
            componentProps={{
              icon: 'project',
              size: 16,
            }}
            justifyContent={'center'}
            tabIndex={-1}
            onClick={handleOnClick}
          />
        </ToolTip>
      </ButtonContainer>
    </Root>
  )
}

export default NameCell
