import React from 'react'
import styled, {css} from 'styled-components'
import {createTransitions} from '../../helpers'
import {Transition, TransitionGroup} from 'react-transition-group'

const Root = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
`

const entering = css`
  left: 0;
  transform: translate(-100%, 0);
  opacity: 0;
`;

const entered = css`
  transform: translate(0, 0);
  filter: blur(0px);
  opacity: 1;
`;

const exiting = css`
  left: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translate(100%, 0);
  right: 0;
  opacity: 0;
`;

const exited = css`
  transform: translate(-100%, 0);
  opacity: 0;
`;

const stateMap = {
  entering,
  entered,
  exiting,
  exited,
};

const Container = styled.div`
  transition: ${({ duration }) =>
    createTransitions(["opacity", "transform", "filter"], duration)};
  height: 100%;
  ${({ state }) => stateMap[state]};
`;

const SlideTransition = ({ children, trigger, duration = 150 }) => {
  return (
    <Root>
      {children}
      {/*<TransitionGroup component={null}>*/}
      {/*  <Transition key={trigger} timeout={{ entering: 0, exit: duration }} exit={false} unmountOnExit={true}>*/}
      {/*    {(state) =>console.log('state',state) || (*/}
      {/*      <Container state={state} duration={duration}>*/}
      {/*        {children}*/}
      {/*      </Container>*/}
      {/*    )}*/}
      {/*  </Transition>*/}
      {/*</TransitionGroup>*/}
    </Root>
  );
};

export default SlideTransition;
