import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {path} from 'ramda'

const LogoRoot = styled.div`
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const logoMap = {
  dark: '/logoWhite.svg',
  light: '/logo.svg',
}

const Logo = () => {
  const mode = useSelector(path(['settings', 'settings', 'darkMode']))
  return (
    <LogoRoot>
      <img style={{width: 100, height: 200}} src={logoMap[mode ? 'dark' : 'light']} alt="ilum" />
    </LogoRoot>
  )
}

export default Logo 