import {createContext, useCallback, useMemo} from 'react'
import {pipe, prepend, uniq} from 'ramda'
import {useLocation} from 'react-router-dom'
import {useStorage} from '../../hooks/useStorage'

export const HistoryContext = createContext(undefined)

export const HistoryProvider = ({children}) => {
  const {getLocalStorageItem, setLocalStorageItem} = useStorage()
  const {pathname} = useLocation()

  const addHistory = useCallback(
    (value) => {
      setLocalStorageItem('history', (history) => {
        const result = pipe(prepend({data: value, url: pathname}), uniq)(history)
        return result
      })
    },
    [pathname, getLocalStorageItem, setLocalStorageItem]
  )

  const context = useMemo(
    () => ({
      history: getLocalStorageItem('history'),

      addHistory,
    }),
    [addHistory]
  )

  return <HistoryContext.Provider value={context}>{children}</HistoryContext.Provider>
}
