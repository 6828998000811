import {useReactFlow} from '@xyflow/react'
import {useCallback} from 'react'
import {map} from 'ramda'
import ELK from 'elkjs/lib/elk.bundled.js'


const elk = new ELK()

const useLayout = () => {
  const {getNodes, setNodes, getEdges, fitView} =
    useReactFlow()
  const defaultOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': 64,
  }

  const getLayout = useCallback((options) => {
    const layoutOptions = {...defaultOptions, ...options}

    const n = map(
      (node) => ({
        ...node,
        // targetPosition: 'left',
        // sourcePosition: 'right',

        width: node.measured.width,
        height: node.measured.height,
      }),
      getNodes()
    )

    const graph = {
      id: 'root',
      layoutOptions: layoutOptions,
      children: n,
      edges: getEdges(),
    }

    elk.layout(graph).then(({children}) => {
      children.forEach((node) => {
        node.position = {x: node.x, y: node.y}
      })

      setNodes(children)
      window.requestAnimationFrame(() => {
        fitView()
      })
    })
  }, [])

  return {getLayout}
}

export default useLayout;