import {EMPTY_FUNCTION} from '../constants'
import {ErrorBoundary} from 'react-error-boundary'
import FallbackRenderer from '../components/FallbackRenderer'
import styled from 'styled-components'
import {useLocation} from 'react-router-dom'
import {startsWith} from 'ramda'
import {Button, Icon} from 'frontcore'

import useLocalStorageState from '../hooks/useLocalStorageState'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const TopContentContainer = styled.div`
  height: 48px;
  overflow: hidden;
  display: flex;
  background: ${({theme}) => theme.palette['surface-secondary']};
`

const BottomContentContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`

const BottomRightContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.palette.line};
`

const BottomLeftContentContainer = styled.div`
  background: ${({theme}) => theme.palette['surface-secondary']};
  overflow: hidden;
`

const RootLayout = ({
  TopContentComponent = EMPTY_FUNCTION,
  BottomLeftContentComponent = EMPTY_FUNCTION,
  BottomRightContentComponent = EMPTY_FUNCTION,
}) => {
  const {pathname} = useLocation()

  const [expand, setExpand] = useLocalStorageState('navigation_bar_expanded', 'yes')

  const isLogin = startsWith('/login', pathname)
  return (
    <Root>
      {!isLogin && (
        <TopContentContainer>
          <ErrorBoundary fallbackRender={FallbackRenderer}>
            <div
              style={{
                width: 48,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => setExpand((expand) => !expand)}
                square={true}
                variant={'text'}
                Component={Icon}
                componentProps={{icon: expand ? 'arrow-left' : 'arrow-right'}}
              />
            </div>
            <div style={{flex: 1}}>
              <TopContentComponent />
            </div>
          </ErrorBoundary>
        </TopContentContainer>
      )}
      <BottomContentContainer>
        {!isLogin && expand && (
          <BottomLeftContentContainer>
            <ErrorBoundary fallbackRender={FallbackRenderer}>
              <BottomLeftContentComponent />
            </ErrorBoundary>
          </BottomLeftContentContainer>
        )}
        <BottomRightContentContainer>
          <ErrorBoundary fallbackRender={FallbackRenderer}>
            <BottomRightContentComponent />
          </ErrorBoundary>
        </BottomRightContentContainer>
      </BottomContentContainer>
    </Root>
  )
}

export default RootLayout
