import React from 'react'
import JobMetrics from '../../jobMetrics'
import ToolTipDetailsGridContentLayout, {
  Key,
  Value,
} from '../../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'
const ToolTipContentComponent = ({data}) => (
  <ToolTipDetailsGridContentLayout>
    <Key>Name</Key>
    <Value>{data?.jobName}</Value>
    <Key>Id</Key>
    <Value>{data?.jobId}</Value>
    <Key>Cluster</Key>
    <Value>{data?.jobId}</Value>
    <JobMetrics
      data={data}
      jobId={data?.jobId}
      name={data?.clusterName}
    />
  </ToolTipDetailsGridContentLayout>
)

export default ToolTipContentComponent
