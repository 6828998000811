import {useEffect, useState} from 'react';

const useBoundingSize = (ref) => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        const {x, y, top, left, right} = ref?.current?.getBoundingClientRect();

        setWindowSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
          x,
          y,
          top,
          left,
          right,
        });
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [ref?.current]);
  return windowSize;
};

export default useBoundingSize;
