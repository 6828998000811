import asyncThunk from '../index'

export const getRolesSearch = asyncThunk({
  method: 'GET',
  type: 'rolesSearch/get',
  path: '/roles',
  api: true,

})

export default getRolesSearch
