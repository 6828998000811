import { gutter, GutterMarker} from '@codemirror/view'
import useFuture from '../../hooks/useFuture'
import executeSQLStatement from '../../api/sql/executeSQLStatement'
import {useDispatch, useSelector} from 'react-redux'
import { path, pathOr } from 'ramda'
import {useCallback, useMemo} from 'react'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import {resultsSetErrorMessage} from '../../reducers/sql/results'
import {EMPTY_FUNCTION} from '../../constants'

class QueryGutterMarker extends GutterMarker {
  constructor(onClick) {
    super()
    this.onClick = onClick
  }

  toDOM = (view) => {
    const button = document.createElement('button')
    button.style.boxSizing = 'border-box'
    button.style.display = 'flex'
    button.style.justifyContent = 'center'
    button.style.marginLeft = '4px'
    button.style.border = 'none'
    button.style.backgroundColor = '#14CC76'
    button.style.color = 'white'
    button.style.width = '15px'
    button.style.height = '15px'
    button.style.position = 'relative'
    button.style.transform = 'translateX(-2px)'
    button.style.borderRadius = '4px'
    button.style.fontSize = '11px'
    button.style.opacity = '0.6'
    button.style.transition = '0.2s all ease-in-out'
    button.style.cursor = 'pointer'
    // button.textContent = '\u25BA'
    button.textContent = '➤'
    button.onclick = this.onClick
    button.onmouseenter = () => button.style.opacity = '1'
    button.onmouseleave = () => button.style.opacity = '0.6'
    return button
  }
}

const detectRunPositions = text => {
  const output = []
  let startPosition = null
  let pointer = 0;
  for (; pointer < text.length; pointer++) {
    // skipping comments
    //while (text.slice(pointer).startsWith('--')) {
    //  const pattern = /^\-\-[^\n]*/
    //  const match = text.slice(pointer).match(pattern)
    //  if (match) {
    //    pointer += match[0].length
    //  } else break
    //}

    // skipping comments but in a better way
    let match
    while (match = text.slice(pointer).match(/^\-\-[^\n]*/)) {
      pointer += match[0].length
    }
    while (match = text.slice(pointer).match(/^\/\*.*\*\//s)) {
      pointer += match[0].length
    }

    const letter = text[pointer]
    if (startPosition === null && !/^\s$/.test(letter)) {
      startPosition = pointer
      continue
    }
    if (startPosition !== null && letter === ';') {
      output.push([startPosition, pointer])
      startPosition = null
    }
  }
  if (startPosition !== null) {
    output.push([startPosition, pointer])
  }
  return output
}

export const createExecuteGutter = (onCodeRun = console.log) => {
  return gutter({
    lineMarker: (view, line) => {
      const code = view.state.doc.toString()
      const queries = detectRunPositions(code)
      const currentQuery = queries.find(([start, _]) => {
        return start === line.from
      })
      if (!currentQuery) return null
      const [from, to] = currentQuery
      const codeFragment = code.slice(from, to)
      return new QueryGutterMarker(() => {
        onCodeRun(codeFragment)
        view.dispatch({
          selection: {
            anchor: from,
            head: to,
          }
        })
      })
    },
  })
}

export const useExecuteGutter = () => {
  const executeFuture = useFuture(executeSQLStatement)
  const maxRows = useSelector(pathOr(1000, ['sqlViewer', 'editor', 'maxRows']))
  const engine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const clusterId =  useSelector(path(['sqlViewer', 'sidebar', 'chosenCluster', 'id']))

  const dispatch = useDispatch()
  const { createNotification } = useNotificationContext()

  const handleRun = useCallback((statement) => {
    const preparedFuture = executeFuture({
      maxRows,
      engine,
      clusterId,
      statement,
    })
    fork(({ message }) => {
      dispatch(resultsSetErrorMessage(message))
      createNotification({
        title: 'Error',
        message: 'Failed to run query. Check logs for details',
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(preparedFuture)
  }, [engine, clusterId])

  return useMemo(() => {
    return createExecuteGutter(handleRun)
  }, [handleRun])
}
