import React from 'react';
import StatusIndicator from '../StatusIndicator'
import {toLower} from "ramda";
import {EMPTY_STRING} from "../../constants";
import statusVariantMap from "../../helpers/statusVariantMap";

const JobState = ({value}) => (
  <StatusIndicator variant={statusVariantMap[value]} value={toLower(value || EMPTY_STRING)}/>
);

export default JobState;