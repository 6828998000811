import { memo, useMemo } from 'react'
import styled from 'styled-components'
import Configuration from '../../../components/configuration'

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const InnerContainer = styled.div`
  position: absolute;
  inset: 0;
  overflow: auto;
`

const ResultsStatistics = ({ results }) => {
  const { info } = results

  const timeData = useMemo(() => {
    const formatDate = (key) => {
      return new Date(info[key]).toISOString()
    }

    const formatDuration = (from, to) => {
      const timeUnits = Object.freeze([
        ['ms', 1000],
        ['s',    60],
        ['min',  60],
        ['h',    24],
      ])
      const difference = info[to] - info[from]
      const { fragments } = timeUnits.reduce(({difference, fragments}, [postfix, modulo]) => {
        return Object.freeze({
          difference: ~~(difference / modulo),
          fragments: [...fragments, {
            value: difference % modulo,
            postfix,
          }]
        })
      }, { difference, fragments: [] })
      return fragments
        .filter(({ value }) => value > 0)
        .map(({ value, postfix }) => `${value}${postfix}`)
        .reverse()
        .join(' ')
    }

    return Object.freeze([
      { key: 'Created', value: formatDate('creationTime') },
      { key: 'Started', value: formatDate('startTime') },
      { key: 'Completed', value: formatDate('completionTime') },
      { key: 'Query runtime', value: formatDuration('startTime', 'completionTime') },
      { key: 'Total elapsed', value: formatDuration('creationTime', 'completionTime') },
    ])
  }, [info])

  return (
    <Root>
      <InnerContainer>
        <Configuration 
          data={timeData}
          schema={[
            {
              id: 'key',
              label: 'Key',
              copy: true,
            },
            {
              id: 'value',
              label: 'Value',
              copy: true,
            },
          ]}
        />
      </InnerContainer>
    </Root>
  )
}

export default memo(ResultsStatistics)
