import asyncThunk from '..'

const getSQLEngineConfig = asyncThunk({
  method: 'GET',
  type: 'sql/config/get',
  path: '/sql/config',
  api: true,
})

export default getSQLEngineConfig
