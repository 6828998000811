import asyncThunk from '../index'

export const getGroups = asyncThunk({
  method: 'GET',
  type: 'groups/get',
  path: '/usergroups',
  api: true,
  mock2: {
    content: [
      {
        id: '1',
        name: 'Administrator',
        description:
          'Full access to all systems and settings.',
        roles: ['admin', 'superuser'],
        permissions: [
          'read',
          'write',
          'delete',
          'manage',
          'execute',
        ],
        users: 5,
        operations: 20,
        enabled: true,
        creationTime: 1675000000,
        lastActivity: 1680198900,
      },
      {
        id: '2',
        name: 'Developer',
        description:
          'Responsible for developing software and features.',
        roles: ['developer', 'engineer'],
        permissions: ['read', 'write', 'execute'],
        users: 12,
        operations: 30,
        enabled: true,
        creationTime: 1675500000,
        lastActivity: 1680295000,
      },
      {
        id: '3',
        name: 'Product Manager',
        description:
          'Oversees the product lifecycle and development roadmaps.',
        roles: ['product_manager', 'lead'],
        permissions: ['read', 'write', 'manage'],
        users: 8,
        operations: 15,
        enabled: true,
        creationTime: 1676000000,
        lastActivity: 1680391100,
      },
      {
        id: '4',
        name: 'Designer',
        description:
          'Responsible for the design and user experience of products.',
        roles: ['designer', 'ux_ui'],
        permissions: ['read', 'write', 'manage'],
        users: 6,
        operations: 25,
        enabled: true,
        creationTime: 1676500000,
        lastActivity: 1680497200,
      },
      {
        id: '5',
        name: 'Support Specialist',
        description:
          'Provides customer support and resolves user issues.',
        roles: ['support', 'customer_service'],
        permissions: ['read', 'write'],
        users: 20,
        operations: 50,
        enabled: true,
        creationTime: 1677000000,
        lastActivity: 1680593300,
      },
      {
        id: '6',
        name: 'HR Manager',
        description:
          'Handles human resources, hiring, and employee relations.',
        roles: ['hr', 'manager'],
        permissions: ['read', 'write', 'manage'],
        users: 4,
        operations: 10,
        enabled: false,
        creationTime: 1678000000,
        lastActivity: 1680692500,
      },
      {
        id: '7',
        name: 'Marketing Specialist',
        description:
          'Focuses on marketing campaigns and brand growth.',
        roles: ['marketing', 'campaign_manager'],
        permissions: ['read', 'write', 'manage'],
        users: 5,
        operations: 18,
        enabled: true,
        creationTime: 1677500000,
        lastActivity: 1680795100,
      },
      {
        id: '8',
        name: 'Sales Executive',
        description:
          'Responsible for driving sales and customer acquisition.',
        roles: ['sales', 'business_development'],
        permissions: ['read', 'write', 'manage'],
        users: 15,
        operations: 40,
        enabled: true,
        creationTime: 1678000000,
        lastActivity: 1680898700,
      },
      {
        id: '9',
        name: 'Finance Officer',
        description:
          'Manages financial operations and budgeting.',
        roles: ['finance', 'accountant'],
        permissions: ['read', 'manage'],
        users: 3,
        operations: 12,
        enabled: false,
        creationTime: 1678500000,
        lastActivity: 1681000300,
      },
      {
        id: '10',
        name: 'Operations Manager',
        description:
          'Oversees daily operations and ensures smooth workflow.',
        roles: ['operations', 'manager'],
        permissions: ['read', 'write', 'manage'],
        users: 10,
        operations: 35,
        enabled: true,
        creationTime: 1679000000,
        lastActivity: 1681100000,
      },
    ],
  },
})

export default getGroups
