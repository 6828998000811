import {append, join, map, pipe, reverse, split, splitEvery, when} from 'ramda'

function formatBigNumber(value, decimals = 2) {
  if (!+value) return {value: 0, unit: ''}
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    '',
    'K',
    'M',
    'G',
    'T',
    'P',
    'E',
    'Z',
    'Y',
  ]
  const i = Math.floor(Math.log(value) / Math.log(k))
  // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`

  return {value: parseFloat((value / Math.pow(k, i)).toFixed(dm)), unit: sizes[i]}
}

export default formatBigNumber;