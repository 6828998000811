import {Button} from 'frontcore'
import React from 'react'

const FilterComplexItemComponent = ({
  value,
  active,
  onClick,
}) => {

  return (
    <Button
      variant={'text'}
      tabIndex={-1}
      active={active}
      onClick={() => onClick(value.id)}
      fullWidth={true}
      rounding={false}
    >
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          gap: 4,
          flexDirection: 'column',
          justifyContent: 'start',
        }}
      >
        <div style={{fontSize: 12, display: 'flex'}}>
          {value.name}:
        </div>
        <div style={{fontSize: 10, display: 'flex'}}>
          {value.id}
        </div>
      </div>
    </Button>
  )
}

FilterComplexItemComponent.displayName =
  'FilterComplexItemComponent'

export default FilterComplexItemComponent
