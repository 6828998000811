import {useCallback} from 'react'
import {join, pipe} from 'ramda'
import {and, fork} from 'fluture'

import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {EMPTY_ARRAY} from '../../../../../constants'
import RolesButtons from '../index'
import {deleteRole} from '../../../../../api/security/deleteRole'
import {enableRole} from '../../../../../api/security/enableRole'
import {disableRole} from '../../../../../api/security/disableRole'

const RolesBatchButtons = ({
  pending,
  data,
  getFuture,
  selected,
  setSelected,
}) => {
  const {id} = data

  const {createNotification} = useNotificationContext()

  const deleteRoleFuture = useFuture(deleteRole)
  const enableRoleFuture = useFuture(enableRole)
  const disableRoleFuture = useFuture(disableRole)

  const handleOnDelete = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to delete Roles',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        setSelected(EMPTY_ARRAY)
        createNotification({
          title: 'Roles deleted successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteRoleFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  const handleOnEnable = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to enable Roles',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Roles enabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      enableRoleFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  const handleOnDisable = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to disable Roles',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Roles disabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      disableRoleFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  return (
    <RolesButtons
      texts={false}
      id={id}
      data={data}
      pending={pending}
      onEnable={handleOnEnable}
      onDisable={handleOnDisable}
      onDelete={handleOnDelete}
    />
  )
}

export default RolesBatchButtons
