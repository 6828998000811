import {memo} from "react"
import styled from "styled-components"

const Root = styled.div`
  padding: 8px 16px;
// height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.red[300]};
  overflow: auto;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.red[500]};
  font-size: 24px;
  margin: 0;
  padding: 0;
`

const ErrorText = styled.p`
  color: ${({ theme }) => theme.palette.red[500]};
  font-size: 16px;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  white-space: pre-wrap;
`

const NotebookError = ({ error }) => {
  return (
    <Root>
      <Title>Error:</Title>
      <ErrorText>{error}</ErrorText>
    </Root>
  )
}

export default memo(NotebookError)
