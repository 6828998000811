import React from 'react'
import { map, toUpper } from 'ramda'
import { DescriptionText } from '../typography'
import Line from '../line'
import StyledLink from '../StyledLink'
import RButton from '../RButton'
import { LetterMiniature } from '../miniatures'
import { useHistoryContext } from '../../hooks/useHistoryContext'
import UserIcon from '../Icons/UserIcon'
import Chip from '../Chip'

const getIconComponent = (type, name, method) => {
    switch (type) {
        case 'role':
            return () => <Chip type="ROLE">{name}</Chip>
        case 'group':
            return () => <Chip type="GROUP">{name}</Chip>
        case 'activity':
            return () => <Chip type={method}>{method}</Chip>
        case 'user':
            return () => (
                <UserIcon color="#FF8B96">
                    {toUpper(name?.substring(0, 1) || '')}
                </UserIcon>
            )
        default:
            return LetterMiniature
    }
}

const renderHistoryItem = ({ location, type, name, id, method }) => {
    const displayName = name || id
    const IconComponent = getIconComponent(type, displayName, method)
    return (
        <StyledLink key={id} to={location}>
            <RButton
                type={type}
                name={displayName}
                IconComponent={IconComponent}
                iconComponentProps={{ type }}
            />
        </StyledLink>
    )
}

const RecentlyVisitedList = () => {
    const { history } = useHistoryContext()

    return (
        <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: 4 }}>
                <DescriptionText>Recently Visited</DescriptionText>
            </div>
            <Line />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    padding: 4,
                }}
            >
                {map(renderHistoryItem, history)}
            </div>
        </div>
    )
}

export default RecentlyVisitedList
