import asyncThunk from "../../index";

export const createJob = asyncThunk({
  method: "POST",
  type: "job/post",
  path: "/job",
  api: true,
});

export default createJob;
