import {Icon, Input, Select} from 'frontcore'
import {EMPTY_ARRAY} from '../../../../constants'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import FilterComplexItemComponent from '../../../../components/filters/filterComplexItemComponent'
import FilterComplexComponent from '../../../../components/filters/filterComplexComponent'

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        size: 16,
        icon: 'search',
      },
      placeHolder: 'Groups Search',
      topLabelProps: {
        children: 'Groups Search',
      },
      fullWidth: true,
    },
  },
  {
    id: 'groupStates',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Group States',
      size: 'small',
      value: [],
      options: ['ACTIVE', 'ARCHIVED', 'PAUSED'],
      multiSelect: true,
      buttonProps: {
        width: '200px',
      },
    },
  },
  {
    id: 'groupKinds',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Group Kinds',
      value: [],
      options: ['JOB', 'CODE'],
      multiSelect: true,
      buttonProps: {
        width: '200px',
      },
    },
  },
  {
    id: 'clusterNames',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    valueProp: 'value',
    optionsApi: getClustersNamesFetch,
    props: {
      label: 'Clusters',
      value: [],
      valueProp: 'name',
      multiSelect: true,
      ItemComponent: FilterComplexItemComponent,
      buttonProps: {
        width: '200px',
        Component: FilterComplexComponent,
      },
    },
  },
]

export default schema