import React, {useEffect, useMemo, useState} from 'react'
import {fork} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {length, map, pathOr, pick, pipe, propOr, toPairs} from 'ramda'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../../constants'
import getCluster from '../../../../api/workloads/clusters/getCluster'
import {Tabs} from 'frontcore'
import SlideTransition from '../../../../components/transitions/slideTransition'
import {initCluster, setBreadCrumbs} from '../../../../reducers/workloads'
import usePending from '../../../../hooks/usePending'
import ClusterMetrics from '../../clusterMetrics'
import {CountMiniature} from '../../../../components/miniatures'
import {useParams} from 'react-router-dom'
import Configuration from '../../../../components/configuration'
import DetailsLayout from '../../../../components/layouts/detailsLayout'
import ClusterDetailsGroupsList from './ClusterDetailsServiceList'
import ClusterDetailsJobsList from './ClusterDetailsJobsList'
import ClusterDetailsRequestsList from './ClusterDetailsRequestsList'
import ClusterDetailsScheduleList from './ClusterDetailsScheduleList'
import ClusterSingleButtons from '../../Components/ClusterSingleButtons'
import DetailsHeader from '../../../../components/DetailsHeader'
import ResourceIcon from '../../../../components/Icons/ResourceIcon'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useHistoryContext} from '../../../../hooks/useHistoryContext'
import ClusterDetailsMemory from './ClusterDetailsMemory'
import {convertObjectToArrayFn} from '../../../../helpers'

function isNumeric(num) {
  return !isNaN(num)
}

const TabComponent = ({active, type, value, label}) => (
  <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
    {label}
    {isNumeric(value) && <CountMiniature value={value} active={active} type={type} />}
  </div>
)

const WorkloadsClusterDetailsView = () => {
  const {clusterId, clusterName} = useParams()
  const getClusterFuture = useFuture(getCluster)
  const [tab, setTab] = useState('groups')
  const dispatch = useDispatch()
  const {createNotification} = useNotificationContext()
  useEffect(() => {
    return () => dispatch(initCluster())
  }, [])
  const {addHistory} = useHistoryContext()

  useEffect(() => {
    if (clusterId) {
      fork(({message}) => {
        createNotification({
          title: 'Failed to fetch Cluster',
          message,
          variant: 'error',
          autoHide: true,
        })
      })((data) => {
        const {id, name} = data
        addHistory({type: 'cluster', id: clusterId, name: clusterName})
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getClusterFuture({
          params: [{key: 'id', value: clusterId}],
        })
      )
    }
  }, [clusterId])

  const cluster = useSelector(pathOr(EMPTY_ARRAY, ['workloads', 'data', 'cluster', 'response']))

  const configuration = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'defaultApplicationConfig'),
        toPairs,
        map((element) => ({
          key: element[0],
          value: element[1],
        }))
      )(cluster),
    [cluster]
  )

  const configurationLength = useMemo(() => length(configuration), [configuration])

  const pending = usePending(['workloads', 'data'], ['deleteCluster', 'cluster'])

  const topContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'cluster'} />}
      name={cluster?.name}
      id={cluster?.id}
      pending={pending}
      data={[
        {
          key: 'type',
          value: cluster?.type,
        },
        {
          key: 'url',
          value: cluster?.url,
        },
      ]}
    />
  )

  const memory = useMemo(
    () =>
      pipe(
        pick([
          'executors',
          'driverCores',
          'executorCores',
          'driverMemory',
          'executorMemory',
          'dynamicAllocation',
          'initialExecutors',
          'minExecutors',
          'maxExecutors',
        ]),
        convertObjectToArrayFn('key', 'value')
      )(cluster),
    [cluster]
  )

  const middleContent = (
    <>
      <Tabs
        value={tab}
        options={[
          {
            id: 'groups',
            Component: TabComponent,
            componentProps: {
              type: 'service',
              label: 'Services',
              value: cluster?.groupsCount,
            },
          },
          {
            id: 'schedules',
            Component: TabComponent,
            componentProps: {
              type: 'schedule',
              label: 'Schedules',
              value: cluster?.schedulesCount,
            },
          },
          {
            id: 'jobs',
            Component: TabComponent,
            componentProps: {
              type: 'job',
              label: 'Jobs',
              value: cluster?.jobsCount,
            },
          },
          {
            id: 'requests',
            Component: TabComponent,
            componentProps: {
              type: 'request',
              label: 'Requests',
              value: cluster?.requestsCount,
            },
          },
          {
            id: 'memory',
            Component: TabComponent,
            componentProps: {
              label: 'Memory',
            },
          },
          {
            id: 'metrics',
            Component: TabComponent,
            componentProps: {
              label: 'Metrics',
            },
          },
          {
            id: 'configuration',
            Component: TabComponent,
            componentProps: {
              type: 'default',
              label: 'Configuration',
              value: configurationLength,
            },
          },
        ]}
        onChange={setTab}
      />
      <ClusterSingleButtons
        texts={true}
        getFuture={getClusterFuture({
          params: [{key: 'id', value: clusterId}],
        })}
        pending={pending}
        data={cluster}
        redirect={true}
      />
    </>
  )

  const bottomContent = (
   <>
      {tab === 'groups' && (
        <ClusterDetailsGroupsList clusterId={clusterId} clusterName={clusterName} />
      )}
      {tab === 'schedules' && (
        <ClusterDetailsScheduleList clusterId={clusterId} clusterName={clusterName} />
      )}
      {tab === 'jobs' && <ClusterDetailsJobsList clusterId={clusterId} clusterName={clusterName} />}
      {tab === 'requests' && (
        <ClusterDetailsRequestsList clusterId={clusterId} clusterName={clusterName} />
      )}
      {tab === 'metrics' && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: 16,
          }}
        >
          <ClusterMetrics name={cluster?.name} />
        </div>
      )}
      {tab === 'memory' && <ClusterDetailsMemory data={memory} />}

        {tab === 'configuration' && (
          <Configuration
            data={configuration}
            schema={[
              {
                id: 'key',
                label: 'Key',
                copy: true,
              },
              {
                id: 'value',
                label: 'Value',
                copy: true,
                obfuscate: true,
              },
            ]}
          />
        )}

    </>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default WorkloadsClusterDetailsView
